import React, { PropsWithChildren } from 'react';
import classnames from 'classnames';
import { i18n } from 'Language';

type ValidationRules = {
    /**
     * The function used to determine whether to show the error or not
     *
     * If it returns `true`, the error is shown
     *
     * @example
     * {
     *   test: () => value > 2 // If > 2, show error
     * }
     */
    test: () => boolean;
    /**
     * The error message to shows if the `test` function passes
     *
     * It needs to contain a `message` property for backward compatibility reasons
     */
    error: {
        message: string;
    };
};

type Trigger = string | boolean | number;

export type Props = PropsWithChildren<{
    /**
     * Value(s) that trigger the validation upon change
     */
    triggers: Trigger | Array<Trigger>;
    /**
     * The validation rules the component will run to determine whether
     * to show the error or not
     *
     * Only one error at a time can be shown, so the rules order determines
     * the error priority
     */
    rules: ValidationRules[];
    /**
     * Whether the component should enable the validation immediately rather
     * than waiting for the field value to change
     */
    immediate?: boolean;
    /**
     * A callback that gets access to the outcome of the validation check
     */
    onValidation?: (isValid: boolean) => void;
}>;

type State = {
    error: string | null;
};

/**
 * Wraps a form input field, runs a given set of validation rules and displays
 * an error message on the field if any of the rules doesn't pass
 *
 * @example
 *   <InputValidation
 *     triggers={[field.value, someOtherValue]}
 *     onValidation={(isValid) => doSomethingWithIt(isValid)}
 *     rules={[
 *       {
 *         test: () => field.value > 2
 *         error: { message: 'Value cannot be greater than two' }
 *       },
 *       {
 *         test: () => someFunctionThatPerformsACheck()
 *         error: { message: 'Sorry, the function says this is in valid' }
 *       },
 *     ]}>
 *     <label>
 *       A text field:
 *       <input type="text" value={field.value} />
 *     </label>
 *   </InputValidation>
 */
export default class InputValidation extends React.Component<Props, State> {
    state = {
        error: null,
    };

    componentDidMount() {
        const { immediate } = this.props;

        if (immediate) {
            this.runValidation();
        }
    }

    componentDidUpdate(prevProps: Props) {
        let { triggers: prevTriggers } = prevProps;
        let { triggers } = this.props;

        if (!Array.isArray(triggers)) {
            triggers = [triggers];
            prevTriggers = [prevTriggers as Trigger];
        }

        const haveTriggersChanged = triggers.some(
            (trigger, index) => trigger !== prevTriggers[index]
        );

        if (haveTriggersChanged) {
            this.runValidation();
        }
    }

    runValidation() {
        const { rules, onValidation } = this.props;
        const failedRule = rules.find((rule) => rule.test());

        const error = failedRule
            ? // It might be that the error is not present yet
              failedRule.error
                ? failedRule.error.message
                : null
            : null;

        onValidation && onValidation(!error);

        this.setState({ error });
    }

    render() {
        const { error } = this.state;
        const className = classnames('input-validation', {
            'input-validation-error': !!error,
        });

        return (
            <div className={className}>
                <div>{this.props.children}</div>

                {error && (
                    <span className="input-validation-message">
                        {i18n(error)}
                    </span>
                )}
            </div>
        );
    }
}
