import { i18n } from 'Language';
import React from 'react';
import { Signature } from '../Models/ValidatorResponse';
import SignatureSection from './SignatureSection';

const SignaturesContainer = ({ signatures }: { signatures: Signature[] }) => {
    const validSignatures = signatures.filter(
        (sig) => sig.signatureValid && sig.signatureMatchDocument
    ).length;
    const invalidSignatures = signatures.length - validSignatures;

    return (
        <section
            aria-label={i18n`validatorSignatureValidationResults`}
            className="w-full flex-col justify-start items-start flex">
            <div className="inline-flex gap-2 flex-wrap items-center">
                <h3
                    className="text-gray-900 text-base font-semibold leading-tight inline-flex"
                    aria-label={
                        i18n`validatorTotalSignatures` +
                        ` ` +
                        `${signatures.length} total`
                    }>
                    {i18n`validatorDigitalSignatures`} ({signatures.length})
                </h3>
                {validSignatures + invalidSignatures > 0 && (
                    <>
                        {validSignatures === signatures.length ? (
                            <div className="text-success-600 text-base font-semibold leading-tight">
                                {i18n`validatorAllSignaturesValid`}
                            </div>
                        ) : (
                            <div className="text-[#ad2f3a] text-base font-semibold leading-tight">
                                {invalidSignatures > 1
                                    ? `${invalidSignatures} ${i18n`validatorNotAllSignaturesValid`}`
                                    : i18n`validatorOneInvalidSignature`}
                            </div>
                        )}
                    </>
                )}
            </div>
            <div className="w-full flex-col justify-start items-start gap-4 flex">
                {validSignatures + invalidSignatures < 1 ? (
                    <div className="text-sm font-normal text-gray-600">
                        {i18n`validatorSignatureEmptyA`}{' '}
                        {i18n`validatorSignatureEmptyB`}{' '}
                        <span className="font-semibold">
                            {i18n`validatorSignatureEmptyC`}
                        </span>{' '}
                        {i18n`validatorSignatureEmptyD`}.
                    </div>
                ) : (
                    signatures.map((sig, index) => (
                        <SignatureSection
                            key={sig.serialNumber}
                            signature={sig}
                            index={index}
                        />
                    ))
                )}
            </div>
        </section>
    );
};

export default SignaturesContainer;
