import React from 'react';
import InvitationExpired from 'resources/images/folder-share/invitation-expired.svg';
import { InvitationStatus } from './InvitationStatus';

export function InviteExpired() {
    return (
        <InvitationStatus
            imageSrc={InvitationExpired}
            imageAlt="Folder locked"
            titleKey="folderAccessExpiredTitle"
            bodyKey="folderAccessExpiredBody"
        />
    );
}
