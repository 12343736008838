import UIButton from 'Common/components/Vega/Button';
import { i18n } from 'Language';
import React from 'react';
import {
    UIModalContainer,
    UIModalFooter,
    UIModalHeader,
    UIModalOverlay,
} from 'Common/components/Vega/Modal';
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';

type Props = {
    handleCloseModal: () => void;
    submitDelete: () => void;
    isDeleting: boolean;
};

const DeleteTemplateModal = ({
    handleCloseModal,
    submitDelete,
    isDeleting,
}: Props) => {
    return (
        <UIModalOverlay onClose={handleCloseModal} zIndex="higher">
            <UIModalContainer>
                <UIModalHeader>
                    <div slot="icon">
                        <ExclamationTriangleIcon className="h-6 fill-danger-700" />
                    </div>
                    <span slot="title">{i18n`deleteFormTemplateModal.title`}</span>
                </UIModalHeader>
                <UIModalFooter>
                    <UIButton
                        slot="secondaryButton"
                        onClick={handleCloseModal}
                        priority="tertiary"
                        type="neutral">
                        {i18n`Cancel`}
                    </UIButton>
                    <UIButton
                        slot="primaryButton"
                        onClick={submitDelete}
                        priority="primary"
                        isLoading={isDeleting}
                        type="danger">
                        {i18n`Delete`}
                    </UIButton>
                </UIModalFooter>
            </UIModalContainer>
        </UIModalOverlay>
    );
};

export default DeleteTemplateModal;
