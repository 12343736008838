import { InformationCircleIcon } from '@heroicons/react/20/solid';
import { defaultBranding } from 'Branding/Constants';
import UIButton from 'Common/components/Vega/Button';
import { i18n } from 'Language';
import React from 'react';

const TopBar = () => {
    return (
        <header className="h-[60px] py-2 fixed top-0 w-full z-20 bg-[#04224c]">
            <div className="flex justify-between items-center h-full max-w-[1170px] mx-auto">
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    className="signing-header-logo-link h-7"
                    href={'https://penneo.com'}>
                    <img
                        src={defaultBranding.imageUrl}
                        alt="Penneo Logo"
                        className="signing-header-logo"
                    />
                </a>
                <UIButton
                    theme="dark"
                    role="link"
                    priority="tertiary"
                    type="accent"
                    size="sm"
                    className="text-white underline text-sm font-semibold"
                    onClick={() =>
                        window.open('https://www.support.penneo.com')
                    }>
                    <InformationCircleIcon className="w-5 h-5 text-white" />
                    {i18n('Help Center')}
                </UIButton>
            </div>
        </header>
    );
};

export default TopBar;
