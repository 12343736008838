import { i18n } from 'Language';
import React from 'react';
import { Helmet } from 'react-helmet';

import './styles/clients.scss';
import classNames from 'classnames';

type Props = {
    title: string;
    headerComponent?: JSX.Element;
    children: JSX.Element;
    center?: boolean;
};

export const ClientsMainContainer = ({
    title,
    headerComponent,
    children,
    center,
}: Props) => {
    return (
        <>
            <Helmet>
                <title>{i18n`Clients`}</title>
            </Helmet>
            <div
                className={classNames('clients-main-container', {
                    center: center,
                })}>
                <div className="white-container no-padding-container">
                    <>
                        {headerComponent ? (
                            headerComponent
                        ) : (
                            <h3 className="title">{i18n`Clients`}</h3>
                        )}
                    </>
                    {children}
                </div>
            </div>
        </>
    );
};
