import PropTypes from 'prop-types';
import React from 'react';
import CasefileStore from '../../stores/CasefileStore';
import { modal } from 'Common/components/Common/Modal';
import CasefileFolderSelectorModal from './CasefileFolderSelectorModal';
import { i18n } from 'Language';
import CasefileActions from '../../actions/CasefilesActionCreators';
import { Link } from 'react-router';
import Loading from 'Common/components/Loaders/LoadingData';
import { notify } from 'react-notify-toast';
import Message from 'Common/components/Common/Messages';
import Button from 'Common/components/Button';
import { translate } from 'Language';
import { AuthStore } from 'Auth';
import lodash from 'lodash';

export default class CasefileFolderSelector extends React.Component {
    static propTypes = {
        action: PropTypes.string,
        folders: PropTypes.array,
        selectedFolder: PropTypes.number,
        lastUsedFolder: PropTypes.number,
        forceUpdate: PropTypes.func,
    };

    static defaultProps = {
        folders: [],
        lastUsedFolder: null,
    };

    state = {
        title: null,
    };

    getActiveFolder = () => {
        let { lastUsedFolder, selectedFolder, folders, forceUpdate } =
            this.props;

        if (selectedFolder) {
            return CasefileStore.getFolder(selectedFolder);
        }

        if (lastUsedFolder) {
            let lastFolder = CasefileStore.getFolder(lastUsedFolder);

            /**
             * This is an edge case:
             * if the folder stored as lastUsedFolder has been deleted,
             * we look for the oldest folder
             * that belongs to the user (very likely the Unsorted folder),
             * preselect it and update user preferences
             */
            if (!lastFolder) {
                const user = AuthStore.getUser();
                const ownFolders = folders.filter(
                    (folder) => folder.userId === user.id
                );
                const firstFolder = lodash.sortBy(ownFolders, 'id')[0];

                if (firstFolder) {
                    const folderId = firstFolder.id;

                    lastFolder = CasefileStore.getFolder(folderId);

                    CasefileActions.changeFolder(folderId);
                    forceUpdate?.(folderId);
                }
            }

            return lastFolder;
        }

        return {};
    };

    handleCreateFolder = () => {
        let { title } = this.state;

        if (!title) {
            return false;
        }

        CasefileActions.createFolder(title);
    };

    handleChange = (event) => {
        let { value } = event.target;

        this.setState({ title: value });
    };

    componentWillReceiveProps(props) {
        this.handleNewFolderActions(props.action);
    }

    handleNewFolderActions = (action) => {
        switch (action) {
            case 'CREATE_FOLDER_REQUEST':
                break;
            case 'CREATE_FOLDER_SUCCESS':
                setTimeout(() => modal.hide(), 0);
                break;
            case 'CREATE_FOLDER_FAILURE':
                break;
            default:
                return false;
        }

        this.showNewFolderModal(action);
    };

    showNewFolderModal = (action) => {
        let body = (
            <div>
                {action === 'CREATE_FOLDER_FAILURE' && (
                    <Message
                        preventClose={true}
                        type="error compact"
                        timeout={3000}>
                        <h3 style={{ margin: '0px' }}>
                            {translate('An error occured. Please try again')}
                        </h3>
                    </Message>
                )}

                <div className="label">
                    <input
                        type="text"
                        className="match-parent"
                        placeholder={i18n('Folder name')}
                        onChange={this.handleChange}
                    />
                </div>

                <Button
                    theme="green"
                    onClick={this.handleCreateFolder}
                    className="mt">
                    {i18n`Create`}
                </Button>
            </div>
        );

        if (action === 'CREATE_FOLDER_REQUEST') {
            body = <Loading />;
        }

        if (action === 'CREATE_FOLDER_SUCCESS') {
            body = notify.show(
                <span>
                    {i18n`Success`} <i className="fa fa-check" />
                </span>,
                'success',
                3000
            );
        }

        let config = {
            className: 'medium penneo-casefiles',
            title: i18n`Create new folder`,
            body: body,
        };

        modal.show(config);
    };

    openFolderSelector = () => {
        let { folders, lastUsedFolder } = this.props;
        let loading = this.props.folders.length === 0;

        if (loading) {
            return false; // Don't show folder list if data is still loading
        }

        let config = {
            className: 'large penneo-casefiles', // needed for scss namespacing
            title: (
                <div className="react-modal-header-title-one">
                    {i18n('Select a folder')}
                    <Link
                        onClick={this.showNewFolderModal}
                        className="react-modal-header-title-one-create">
                        {i18n('Create new +')}
                    </Link>
                </div>
            ),
            body: (
                <CasefileFolderSelectorModal
                    folders={folders}
                    lastUsedFolder={lastUsedFolder}
                    active={this.getActiveFolder()}
                />
            ),
            buttons: null,
        };

        modal.show(config);
    };

    render() {
        let activeFolder = this.getActiveFolder();
        let loading = this.props.folders.length === 0;

        return (
            <div
                onClick={this.openFolderSelector}
                className="casefile-folder-selector-bar">
                <span className="folder-title">
                    {loading ? (
                        <span className="text-medium">
                            <i className="far fa-sync fa-spin"></i>&nbsp;
                            <span>{`Loading...`}</span>
                        </span>
                    ) : (
                        <span>
                            <i className="far fa-folder"></i>&nbsp;
                            <span>{activeFolder.title}</span>
                        </span>
                    )}
                </span>

                <Button theme="blue" className="pull-right" disabled={loading}>
                    {i18n`Change`}
                </Button>
            </div>
        );
    }
}
