import PropTypes from 'prop-types';
import React from 'react';
import CasefileNavigationActions from 'Casefiles/actions/CasefileNavigationActions';
import { Link } from 'react-router';
import { i18n } from 'Language';
import {
    CasefileValidation,
    DocumentValidation,
    SignerValidation,
} from 'types/DataValidation';
import analytics from 'Common/Analytics';
import isEmpty from 'lodash/isEmpty';
import { Router } from 'react-router';

type AppContextType = {
    router: Router;
};

type Props = {
    casefileValidation: CasefileValidation;
    documentValidation: DocumentValidation;
    signerValidation: SignerValidation;
    linkParams: any;
    active: number;
    navigationRoutes: any[];
};

export default class CasefileProgressBar extends React.Component<Props> {
    static contextTypes = {
        router: PropTypes.object,
    };
    context!: AppContextType;

    getActiveLinkIndex = (routes) => {
        let { router } = this.context;
        let { linkParams } = this.props;

        for (let i = 0; i < routes.length; i++) {
            if (router.isActive({ name: routes[i].name, params: linkParams })) {
                return i;
            }
        }

        return -1;
    };

    getCurrentRoute = () => {
        let routes = this.props.navigationRoutes;
        let index = this.getActiveLinkIndex(routes);

        return routes[index].name;
    };

    // Set current route as active
    componentDidMount() {
        CasefileNavigationActions.setInitialRoute(this.getCurrentRoute());
    }

    renderLinks = () => {
        let routes = this.props.navigationRoutes;
        let { linkParams } = this.props;

        const trackNavigation = (navName: string) => {
            const { casefileValidation, documentValidation, signerValidation } =
                this.props;
            const destination = navName.split('-')[1];
            const current = this.getCurrentRoute();

            if (destination && current) {
                analytics.track('Casefile progressbar navigation', {
                    destinationRoute: destination,
                    currentRoute: this.getCurrentRoute(),
                    hasCasefileValidationErrors: isEmpty(
                        casefileValidation.errors
                    ),
                    hasDocumentValidationErrors: isEmpty(
                        documentValidation.setup.errors
                    ),
                    hasSignerValidationErrors: isEmpty(
                        signerValidation.setup.errors
                    ),
                });
            }
        };

        return routes.map((r, i) => {
            let stepClassName = 'step';

            if (r.active) {
                stepClassName += ' active';
            }

            if (r.done) {
                stepClassName += ' done';
            }

            if (r.error) {
                stepClassName += ' error';
            }

            return (
                <li key={i} className={stepClassName}>
                    <Link
                        onClick={() => {
                            trackNavigation(r.name);
                        }}
                        className="step-link"
                        to={{
                            name: r.name,
                            params: {
                                casefileId: linkParams.casefileId,
                                action: linkParams.action,
                            },
                        }}>
                        <i className={r.icon} />
                        &nbsp;{i18n(r.title)}
                    </Link>

                    {/* Add a progress indicator to every step except the last one */}
                    {i !== routes.length - 1 && (
                        <span className="progress-indicator">
                            <span className="line" />
                            <span className="line" />
                            <span className="line" />

                            {/* Indicator on done menu items, shown with CSS */}
                            <span className="done-indicator">
                                {r.error ? (
                                    <i className="fas fa-exclamation-triangle" />
                                ) : (
                                    <i className="fas fa-check" />
                                )}
                            </span>
                        </span>
                    )}
                </li>
            );
        });
    };

    render() {
        return (
            <div className="progress-navigation">
                <ul>{this.renderLinks()}</ul>
            </div>
        );
    }
}
