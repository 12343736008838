import React from 'react';
import PropTypes from 'prop-types';

const Forms = ({ children, branding, location, user, customer }) => {
    const childProps = {
        location,
        branding,
        user,
        customer,
    };

    return (
        <div className="penneo-forms ui-v2">
            {React.cloneElement(children, childProps)}
        </div>
    );
};

Forms.propTypes = {
    children: PropTypes.object,
    branding: PropTypes.object,
    location: PropTypes.object,
    user: PropTypes.object,
    customer: PropTypes.object,
};

export default Forms;
