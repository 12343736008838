import PropTypes from 'prop-types';
import { useContext, useEffect } from 'react';
import { i18n } from 'Language';
import CasefileStore from '../../stores/CasefileStore';
import CasefileActions from '../../actions/CasefilesActionCreators';
import DocumentActions from '../../actions/DocumentActionCreators';
import SignerActions from '../../actions/SignerActionCreators';
import { ToastContext } from 'Common/components/ToastContext';

type RouterContext = {
    router: {
        push: (location: { name: string } | string) => void;
        createHref: (params: {
            name: string;
            params: any;
            query?: any;
        }) => string;
    };
};

const CasefileSuccess = (_props, context: RouterContext) => {
    const toast =
        useContext(ToastContext) ??
        (() => {
            console.error('ToastContext not found');

            return undefined;
        })();
    const addToast = toast?.addToast;

    const resetData = async () => {
        await CasefileActions.clearStore();
        await SignerActions.clearStore();
        await DocumentActions.clearStore();
    };

    const redirect = () => context.router.push({ name: 'casefile-details' });

    useEffect(() => {
        addToast?.(
            i18n`Emails will be arriving shortly to each recipient`,
            `${i18n`Casefile has been sent to all recipients`}!`,
            'accent',
            10000,
            i18n`Ok, got it`
        );
        const initialize = async () => {
            const casefile = CasefileStore.getCasefile();

            // If there hasn't been a casefile persisted (i.e. doesn't have an ID in the store)
            // redirect to casefile creator. Since this view shouldn't be loaded at this point.
            if (!casefile.id) {
                console.error('CasefileSuccess: No casefile found in store');
                redirect();

                return;
            }

            // reset before redirect
            await resetData();
            redirect();
        };

        initialize();
    }, []);

    return null;
};

CasefileSuccess.contextTypes = {
    router: PropTypes.object.isRequired,
};

export default CasefileSuccess;
