import { AppThunk } from 'Store';
import { ActionTypes } from './action-types';
import { CancelTokenSource } from 'axios';
import analytics from 'Common/Analytics';
import { ApiResponse } from 'Api/ApiClient';

const contactListRequestQueue: CancelTokenSource[] = [];

// @duplicate: A very similar function exists in archiveReducer.ts.
// @todo: Make implementing request cancelling easier.
const removeTokenFromRequestQueue = (cancelToken) => {
    const requestIndex = contactListRequestQueue.indexOf(cancelToken);

    contactListRequestQueue.splice(requestIndex, 1);
};

export const fetchContacts =
    (): AppThunk =>
    async (dispatch, getState, { api }) => {
        // Cancel all pending requests
        contactListRequestQueue.forEach((req) => req.cancel());
        // Add a new cancelToken to allow the request to be aborted
        const cancelToken = api.SigningAPI.getCancelToken();

        contactListRequestQueue.unshift(cancelToken);

        dispatch({ type: ActionTypes.CONTACTS_FETCH_REQUEST });

        try {
            const { pagination } = getState().contacts;
            const response = await api.SigningAPI.get(
                '/paginated-contacts',
                pagination,
                {
                    paginate: true,
                    cancelToken: cancelToken.token,
                }
            );

            removeTokenFromRequestQueue(cancelToken);

            dispatch({
                type: ActionTypes.CONTACTS_FETCH_SUCCESS,
                payload: {
                    items: response.data,
                    itemCount: response.count,
                },
            });
        } catch (error) {
            removeTokenFromRequestQueue(cancelToken);

            dispatch({
                type: ActionTypes.CONTACTS_FETCH_FAILURE,
                payload: error,
            });
        }
    };

export const changePagination =
    (property, value): AppThunk =>
    async (dispatch) => {
        dispatch({
            type: ActionTypes.CONTACT_LIST_PAGINATION_CHANGE,
            payload: {
                property,
                value,
            },
        });

        // Separate search to avoid tracking sensitive data
        // @todo: implement search tracking in the search input component and only a second after input has ended
        // to avoid tracking too many events for a single "search intent"
        if (property === 'fullSearch') {
            analytics.track('contact list - search updated', {
                length: value.length,
            });
        } else {
            analytics.track('contact list - table settings updated', {
                property,
                value,
            });
        }

        (dispatch as any)(fetchContacts());
    };

export const toggleContactEdit =
    (contactId: number): AppThunk =>
    (dispatch) => {
        dispatch({
            type: ActionTypes.CONTACT_LIST_TOGGLE_ROW_EDIT,
            payload: {
                contactId,
            },
        });

        analytics.track('contact list - enabled edit mode', {
            contactId,
        });
    };

export const disableContactEdit = (): AppThunk => (dispatch) => {
    dispatch({
        type: ActionTypes.CONTACT_LIST_DISABLE_ROW_EDIT,
    });
};

export const updateContactDraft =
    (property, value): AppThunk =>
    (dispatch) => {
        dispatch({
            type: ActionTypes.CONTACT_DRAFT_UPDATED,
            payload: {
                property,
                value,
            },
        });
    };

export const updateContact =
    (): AppThunk =>
    async (dispatch, getState, { api }) => {
        dispatch({ type: ActionTypes.CONTACT_UPDATE_REQUEST });
        const contact = getState().contacts.viewState.contactDraft;

        if (!contact) {
            return false;
        }

        try {
            const response = await api.SigningAPI.put(
                `/contacts/${contact.id}`,
                {
                    name: contact.name.trim(),
                    email: contact.email.trim(),
                    ...(contact.onBehalfOf && {
                        onBehalfOf: contact.onBehalfOf.trim(),
                    }),
                }
            );

            dispatch({
                type: ActionTypes.CONTACT_UPDATE_SUCCESS,
                payload: response,
            });

            analytics.track('contact list - contact updated', {
                contactId: contact.id,
            });

            return response;
        } catch (error) {
            dispatch({
                type: ActionTypes.CONTACT_UPDATE_FAILURE,
                payload: error,
            });

            analytics.track('contact list - contact update failed', {
                contactId: contact.id,
            });

            return error;
        }
    };

export const createContact =
    (
        name: string,
        email: string,
        onBehalfOf?: string
    ): AppThunk<Promise<ApiResponse>> =>
    async (dispatch, _, { api }) => {
        dispatch({ type: ActionTypes.CONTACT_CREATE_REQUEST });

        try {
            const response = await api.SigningAPI.post(`/contacts`, {
                name: name.trim(),
                email: email.trim(),
                ...(onBehalfOf && {
                    onBehalfOf: onBehalfOf.trim(),
                }),
            });

            dispatch({
                type: ActionTypes.CONTACT_CREATE_SUCCESS,
                payload: response,
            });

            analytics.track('contact list - contact added', {
                contactId: response.id,
                containsOnBehalfOf: !!onBehalfOf,
            });

            return response;
        } catch (error) {
            dispatch({
                type: ActionTypes.CONTACT_CREATE_FAILURE,
                payload: error,
            });

            analytics.track('contact list - contact add failed');

            return error;
        }
    };

export const deleteContact =
    (contactId: number): AppThunk<Promise<ApiResponse>> =>
    async (dispatch, _, { api }) => {
        dispatch({ type: ActionTypes.CONTACT_DELETE_REQUEST });

        try {
            const response = await api.SigningAPI.delete(
                `/contacts/${contactId}`
            );

            analytics.track('contact list - contact deleted', {
                contactId,
            });

            dispatch({
                type: ActionTypes.CONTACT_DELETE_SUCCESS,
                payload: response,
            });

            return response;
        } catch (error) {
            dispatch({
                type: ActionTypes.CONTACT_DELETE_FAILURE,
                payload: error,
            });

            analytics.track('contact list - contact delete failed', {
                contactId,
            });

            return error;
        }
    };
