import { AppThunk } from 'Store';
import { Folders, FolderItem } from './types';

import { FOLDER_FETCH_REQUEST, FOLDER_FETCH_SUCCESS } from './reducer';

// Actions

export const fetchFolders =
    (): AppThunk =>
    async (dispatch, _, { api }) => {
        dispatch({
            type: FOLDER_FETCH_REQUEST,
        });

        const folders: Folders[] = await api.SigningAPI.get('/folders', {
            permissions: 'read-write',
        });
        const defaultFolder: FolderItem = await api.SigningAPI.get(
            '/folders/default'
        );

        dispatch({
            type: FOLDER_FETCH_SUCCESS,
            payload: {
                items: folders,
                defaultFolder,
            },
        });
    };
