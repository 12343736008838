import React, { useState } from 'react';
import { AppDispatch } from 'Store';
import { extendExpiration } from './redux/actions';
import { connect } from 'react-redux';
import DateInput from 'Common/components/DateInput';
import { i18n } from 'Language';
import Button from 'Common/components/Button';
import './extend-deadline.scss';
import { useToast } from 'Common/hooks/useToast';
import { CheckCircleIcon } from '@heroicons/react/20/solid';

type Props = {
    dispatch: AppDispatch;
    onExtension: (value: any) => Promise<void>;
};

const ExtendDeadline = ({ dispatch, onExtension }: Props) => {
    const addToast = useToast()?.addToast;

    const [expireAt, setExpireAt] = useState<number | null>(null);

    const handleOnChange = (expireAt: number) => setExpireAt(expireAt);

    const extendDeadline = async () => {
        try {
            await dispatch(extendExpiration(expireAt));
            await onExtension(expireAt);
            addToast?.(
                undefined,
                i18n`toast.casefileExpDateUpdated`,
                'accent',
                10000,
                i18n`toast.cancelButton`,
                undefined,
                undefined,
                CheckCircleIcon
            );
        } catch (error) {
            addToast?.(
                undefined,
                i18n`Could not update the expiration date of this case file`,
                'danger',
                10000,
                i18n`toast.cancelButton`
            );
        }
    };

    return (
        <div className="extend-deadline">
            <h4 className="casefile-details-section-header">
                {i18n`Extend expiry date`}
            </h4>

            <DateInput
                className="extend-deadline-input"
                label={i18n`Case file will expire in:`}
                placeholder={i18n`Pick a date`}
                onChange={handleOnChange}
            />

            <Button
                theme="blue"
                variant="outline"
                className="extend-deadline-button ml"
                disabled={!expireAt}
                onClick={extendDeadline}>
                {i18n('Save')}
            </Button>
        </div>
    );
};

export default connect()(ExtendDeadline);
