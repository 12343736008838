/**
 * Routing utilities for handling PR-xx preview paths and generating full URLs
 */
const routingUtils = {
    /**
     * Extracts the PR number from the URL (if present)
     */
    getQAPreviewNumber(): string | null {
        const matches = /^\/(PR-\d+)/.exec(window.location.pathname);

        if (matches) {
            return matches[1]; // Return ticket number without trailing slash (First capturing group)
        }

        return null;
    },

    /**
     * If there is a PR number in URL, the application is considered to be loaded as a QA release.
     * A deployment that is only used for testing purposes. The app needs to load with the ticket number
     * as a base for react-router, so that all redirections are performed relative to the URL
     * containing the ticket number.
     *
     * i.e. If the PR number is 666, all redirections must be relative
     * to https://app.penneo.com/PR-666 this is achieved by setting the router basename as `/PR-666`
     */
    getBaseName(): string {
        const qaPreviewNumber = this.getQAPreviewNumber();
        const isQARelease = !!qaPreviewNumber;

        if (isQARelease) {
            return '/' + qaPreviewNumber;
        }

        return '';
    },

    getFullPath(path: string): string {
        const baseName = this.getBaseName();

        return `${baseName}${path}`;
    },
};

export default routingUtils;
