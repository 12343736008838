import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';

// Actions
import AuthMethodsActions from '../../actions/AuthMethodsActions';
import PasswordActions from '../../actions/PasswordActionCreators';

// Stores
import AuthMethodsStore from '../../stores/AuthMethodsStore';
import PasswordStore from '../../stores/PasswordStore';

import { i18n, TranslationStore } from 'Language';
import { modal } from 'Common/components/Common/Modal';

import ClassicCredentials from './ClassicCredentials';
import AuthProvidersTable from './AuthProvidersTable';
import Button from 'Common/components/Button';
import Message from 'Common/components/Message';
import { UserEntity as User, Credentials } from 'types/User';
import { Languages } from 'Language/Constants';
import { EID_METHODS } from 'EID/Constants';
import { Router } from 'react-router';

type AppContextType = {
    router: Router;
};

export type AuthProvider = {
    active: boolean;
    createdAt: string;
    id: number;
    name: string;
    provider: string;
    serialNumber: string;
    updatedAt: string;
};

export type ClassicCredentialsData = {
    createdAt: string;
    id: number;
    updatedAt: string;
    username: string;
};

export type Props = {
    credentials: Credentials;
    user: User;
    children: JSX.Element;
};

type State = {
    authProviders: AuthProvider[];
    passwordData: ClassicCredentialsData | null;
    languageCode: Languages;
};

type AddedAuthMethodEvent = {
    title: string;
    error: {
        withEnabling: boolean;
    };
};

export default class AuthMethodsList extends React.Component<Props, State> {
    static contextTypes = {
        router: PropTypes.object.isRequired,
    };

    context!: AppContextType;

    state: State = {
        authProviders: [],
        passwordData: null,
        languageCode: Languages.EN,
    };

    componentWillReceiveProps(props: Props) {
        if (!props.children) {
            modal.hide();

            return false;
        }

        const elementProps = {
            credentials: this.props.credentials,
        };

        const config = {
            title: null,
            body: React.cloneElement(props.children, elementProps),
            onClose: () => {
                this.context.router.push({ name: 'account-auth-methods' });
            },
            buttons: null,
            preventClose: false,
        };

        return modal.show(config);
    }

    componentDidMount() {
        PasswordStore.addChangeListener(this.onChange);
        AuthMethodsStore.addChangeListener(this.onChange);
        TranslationStore.addChangeListener(this.onChange);

        this.loadData();
    }

    componentWillUnmount() {
        PasswordStore.removeChangeListener(this.onChange);
        AuthMethodsStore.removeChangeListener(this.onChange);
        TranslationStore.removeChangeListener(this.onChange);

        modal.hide();
    }

    onChange = () => {
        this.setState({
            authProviders: AuthMethodsStore.getCredentials(),
            passwordData: PasswordStore.getPasswordCredentials() || {},
            languageCode: TranslationStore.getLanguage(),
        });
    };

    loadData = () => {
        PasswordActions.fetchPasswordCredentials();
        AuthMethodsActions.fetchCredentials();
    };

    _promptAdd = () => {
        const { router } = this.context;

        router.push({ name: 'account-auth-methods-providers' });
    };

    // @todo: replace for Loader component
    renderLoader = () => {
        return (
            <div>
                <br />
                <br />
                <div className="text-center text-medium">
                    <h1>
                        <i className="far fa-sync fa-lg fa-spin"></i>
                        <p>{i18n`Loading...`}</p>
                    </h1>
                </div>
                <br />
                <br />
            </div>
        );
    };

    /**
     * Checks if there are any query parameters mathing '?addedProvider=<provider>'
     * and returns the title of the provider if the defined provider:
     * 1. is an activated login method for the user
     * 2. was activated less than 5 minutes ago
     */
    getAddedAuthMethodEvent(): AddedAuthMethodEvent | undefined {
        const { authProviders } = this.state;

        const searchParams = new URLSearchParams(window.location.search);
        const addedProviderParam = searchParams.get('addedProvider');
        const addedProviderErrorParam = searchParams.get('error');

        const errorExists = !!addedProviderErrorParam;

        const addedProvider = authProviders.find(
            ({ provider }) => provider === addedProviderParam
        );

        if (!addedProvider) {
            return;
        }

        if (this.isMethodAdditionOld(addedProvider)) {
            return;
        }

        const addedAuthProviderType = EID_METHODS.find(
            (method) => method.credentialType() === addedProvider.provider
        );

        if (!addedAuthProviderType) {
            return;
        }

        return {
            title: addedAuthProviderType.title,
            error: {
                withEnabling: errorExists,
            },
        };
    }

    renderStatusMessage(addedProviderEvent: AddedAuthMethodEvent) {
        // A temporary & special case for adding MitID is that in the case a user adds it from the notification
        // center, we also need to call an endpoint that enables it for the user as an allowed login method.
        // Because of this, we need to be able to show a warning message in case MitID was added but the
        // request to enable it failed.
        //
        // TODO: This should be removed once we remove the possibility to add MitID through the notification center.
        const isMitIdEnablingError =
            addedProviderEvent.title === 'MitID' &&
            addedProviderEvent.error.withEnabling;

        return (
            <div className="mb">
                {isMitIdEnablingError ? (
                    <Message type="warning">{i18n`MitID was successfully added, but could not be enabled. Please contact your administrator to enable MitID as a login method.`}</Message>
                ) : (
                    <Message type="success">{i18n`You can now log in using ${addedProviderEvent.title}`}</Message>
                )}
            </div>
        );
    }

    /**
     * In case someone bookmarks the page with the query parameter set we only show
     * the banner if the time difference is less than 5 minutes.
     */
    isMethodAdditionOld(provider: AuthProvider, timeThreshold = 5) {
        const { createdAt } = provider;

        const providerCreatedAtTime = moment.utc(createdAt);
        const currentTime = moment();
        const timeDifference = currentTime.diff(
            providerCreatedAtTime,
            'minutes'
        );

        return timeDifference > timeThreshold;
    }

    render() {
        const { credentials } = this.props;
        const { authProviders, passwordData, languageCode } = this.state;

        const addedProviderEvent = this.getAddedAuthMethodEvent();

        const isLoaded = !!(authProviders && passwordData);

        if (!isLoaded) {
            return this.renderLoader();
        }

        const { allowed } = credentials;
        const hasNoAllowedCredentials = allowed.length === 0;

        const hasLinkedIdentities = Object.keys(authProviders).length > 0;

        const hasClassicCredentials = !!(passwordData && passwordData.id);
        const configuredMethodsCount =
            (authProviders as AuthProvider[]).length +
            (hasClassicCredentials ? 1 : 0);

        if (hasNoAllowedCredentials && !hasLinkedIdentities) {
            return (
                <div className="classic-credential-section">
                    <h3 className="mt">
                        {i18n`You can't manage any credentials`}
                    </h3>

                    <p>
                        {i18n`Your are not allowed to add any authentication methods
                            or electronic IDs to your account.`}
                        <br />
                        {i18n`Please get in touch with your administrator
                            to be able to use this feature`}
                    </p>
                </div>
            );
        }

        // Determine if user is allowed to manage classic credentials
        const canManageClassicCredentials = allowed.indexOf('classic') !== -1;

        // Determine if user is allowed to manage third party authentication providers filter all
        // non 'classic' or 'api' values and check if user has at least 1 allowed credential type
        const canManageAuthProviders =
            allowed.filter((a) => !/^(api|classic)$/.test(a)).length > 0;

        return (
            <div>
                {canManageClassicCredentials && (
                    <div className="classic-credential-section">
                        <h3>{i18n`Classic credentials`}</h3>
                        <ClassicCredentials
                            passwordData={passwordData}
                            languageCode={languageCode}
                        />
                    </div>
                )}

                {(canManageAuthProviders || hasLinkedIdentities) && (
                    <div className="auth-methods-list">
                        <h3 className="mt">
                            {i18n`Electronic identity providers`}
                        </h3>
                        {addedProviderEvent &&
                            this.renderStatusMessage(addedProviderEvent)}
                        {canManageAuthProviders && (
                            <div>
                                <Button
                                    theme="blue"
                                    variant="outline"
                                    icon="far fa-plus"
                                    onClick={this._promptAdd}
                                    renderIconLeft={true}>
                                    {i18n`Add new provider`}
                                </Button>
                            </div>
                        )}

                        <AuthProvidersTable
                            providers={authProviders}
                            configuredMethodsCount={configuredMethodsCount}
                        />
                    </div>
                )}
            </div>
        );
    }
}
