import React from 'react';
import FullScreen from 'Common/components/FullScreen';

export type Props = {
    title: string;
    description: string;
    children?: JSX.Element;
    errorRequestId?: string;
    errorTraceId?: string;
};

const FullScreenError: React.FunctionComponent<Props> = (props) => {
    const { title, description, children, errorRequestId, errorTraceId } =
        props;

    function renderErrorIds() {
        return (
            <p className="text-light small">
                {errorRequestId && (
                    <span data-testid="request-id">{errorRequestId}</span>
                )}
                {errorRequestId && errorTraceId && ':'}
                {errorTraceId && (
                    <span data-testid="trace-id">{errorTraceId}</span>
                )}
            </p>
        );
    }

    return (
        <FullScreen>
            <>
                <h1>
                    <i className="far fa-frown-open"></i>
                    <br />
                    {title}
                    <p>{description}</p>
                    {renderErrorIds()}
                </h1>
                {children}
            </>
        </FullScreen>
    );
};

export default FullScreenError;
