import React, { useState } from 'react';
import { i18n } from 'Language';
import { DocumentValidation } from 'types/DataValidation';
import { DocumentEntity, DocumentType, FileToUpload } from 'types/Document';
import DocumentActions from 'Casefiles/actions/DocumentActionCreators';
import DocumentStore from 'Casefiles/stores/DocumentStore';
import { getDocumentValidationErrorMessage } from 'Casefiles/utils/casefileValidation';
import Button from 'Common/components/Button';
import DocumentModalAdd from './DocumentModalAdd';
import DocumentModalEdit from './DocumentModalEdit';
import { modal } from 'Common/components/Common/Modal.jsx';
import { DocumentDropZone } from 'Common/components/DocumentDropZone';
import { CasefileRoundsFlowPreview } from './CasefileRounds';
import LaunchDarkly, { Flags } from 'Common/LaunchDarkly';
import { CaseFileEntity } from 'types/CaseFile';
import { DEFAULT_FILE_TYPE, IXBRL_FILE_TYPE } from 'types/FileType';
import CasefileFooterNavigation from './CasefileFooterNavigation';
import UIInlineMessage from 'Common/components/Vega/InlineMessage';
import { Button as VegaReactButton } from '@penneo/vega-ui-react';

import UILozenge from 'Common/components/Vega/Lozenge';
import {
    ArrowUpTrayIcon,
    ArrowUturnLeftIcon,
    RocketLaunchIcon,
} from '@heroicons/react/24/outline';
import storage from 'Core/Storage';
import { MAX_FILE_SIZE, StorageKeys } from 'Constants';

type Props = {
    casefile: CaseFileEntity;
    availableDocumentTypes: DocumentType[];
    documentValidation: DocumentValidation;
    documents: DocumentEntity[];
    linkParams: any;
    openDocumentPreview: Function;
    saveAsDraftButton: Function;
    uploadedDocuments: DocumentEntity[];
    savingAsDraft: boolean;
    sending: boolean;
};

export default function CasefileDocumentStep(props: Props) {
    const [showBanner, setShowBanner] = useState<boolean>(
        JSON.parse(storage.get(StorageKeys.SHOW_BETA_UPLOAD_BANNER) ?? 'true')
    );

    const [isBeta, setIsBeta] = useState<boolean>(
        JSON.parse(storage.get(StorageKeys.IS_BETA_UPLOAD_ENABLED) ?? 'false')
    );

    const handleBetaVersion = (isBetaVersion: boolean) => {
        storage.set(StorageKeys.IS_BETA_UPLOAD_ENABLED, isBetaVersion);
        setIsBeta(isBetaVersion);
    };

    const handleBetaBannerShow = (isBannerShown: boolean) => {
        storage.set(StorageKeys.SHOW_BETA_UPLOAD_BANNER, isBannerShown);
        setShowBanner(isBannerShown);
    };

    const onBetaButtonClick = () => {
        handleBetaVersion(true);
        handleBetaBannerShow(false);
    };

    const activateFileAddHandler = () => {
        const fileUpload = document.getElementById('fileupload') as HTMLElement;

        fileUpload.click();
    };

    const onFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) {
            return;
        }

        const files = Array.from(event.target.files || []);
        let data = files as FileToUpload[];

        if (data.length) {
            openDocumentImport(data);
        }
    };

    const openDocumentImport = (files: FileToUpload[]) => {
        if (files.length < 1) return;

        let config = {
            component: <DocumentModalAdd files={files} {...props} />,
            preventClose: false,
        };

        modal.show(config);
    };

    const openDocumentEdit = (index: number) => {
        let config = {
            component: <DocumentModalEdit index={index} {...props} />,
            preventClose: false,
        };

        modal.show(config);
    };

    const onDocumentPreview = (index) => {
        const { openDocumentPreview, documents } = props;
        const documentId = documents[index].id;

        openDocumentPreview(documentId);
    };

    const mapObject = (object, callback) => {
        return Object.keys(object).map((key) => {
            return callback(key, object[key]);
        });
    };

    const renderDocumentTypeError = (documentTypeId, validation) => {
        let { errors, valid } = validation;

        if (valid) {
            return false;
        }

        let { code, count, required } = errors.limit;

        let documentType = DocumentStore.getDocumentType(documentTypeId);
        let message = getDocumentValidationErrorMessage(
            required,
            count,
            documentType,
            code
        );

        return (
            <div
                className="document-type-requirements-item"
                key={documentTypeId}>
                <i className="fas fa-info-circle" />
                &nbsp;
                {message}
            </div>
        );
    };

    const renderRequirements = () => {
        let { setup, documentTypes } = props.documentValidation;

        if (documentTypes.valid && setup.valid) {
            return false;
        }

        return (
            <div className="documents-requirements">
                {documentTypes.valid === false && (
                    <div className="document-type-requirements">
                        {mapObject(
                            documentTypes.items,
                            renderDocumentTypeError
                        )}
                    </div>
                )}

                {setup.valid === false &&
                    documentTypes.valid &&
                    mapObject(setup.errors, (key, error) => (
                        <div key={key}>
                            <i className="fas fa-info-circle" />{' '}
                            {i18n(error.message)}
                        </div>
                    ))}
            </div>
        );
    };

    const handleChangeDocumentOrder = (index: number, newIndex: number) => {
        DocumentActions.reorderDocument(index, newIndex);
    };

    const {
        casefile,
        documents,
        availableDocumentTypes,
        linkParams,
        uploadedDocuments,
        saveAsDraftButton,
        savingAsDraft,
        sending,
    } = props;

    const isXBRLUploadEnabled = LaunchDarkly.variation(Flags.ENABLE_XBRL);

    const isNewCaseFileUIEnabled = LaunchDarkly.variation(
        Flags.ENABLE_NEW_UI_CASE_FILE_CREATION
    );

    const acceptedFileTypes = isXBRLUploadEnabled
        ? IXBRL_FILE_TYPE
        : DEFAULT_FILE_TYPE;

    const handleUploadClick = () => {
        document.getElementById('fileupload')?.click();
    };

    return (
        <div className="casefile-process-v2 medium">
            <div className="header">
                <h1>
                    {i18n`Add some documents to the mix`}
                    <span className="dot">.</span>
                </h1>
                <p>{i18n`Upload the documents and attachments that will be sent out to the recipients`}</p>
            </div>
            <div className="body form-v2 w-full max-w-3xl">
                {isNewCaseFileUIEnabled ? (
                    <>
                        <div className="mb-2">
                            {isXBRLUploadEnabled && showBanner && (
                                <UIInlineMessage
                                    variant="success"
                                    hideCloseButton={false}
                                    onClickClose={() =>
                                        handleBetaBannerShow(false)
                                    }
                                    title={i18n`XBRLBetaBanner.title`}
                                    description={i18n`XBRLBetaBanner.subtitle`}>
                                    <div slot="button" className="mt-2">
                                        <VegaReactButton
                                            priority="primary"
                                            size="xs"
                                            type="success"
                                            onClick={onBetaButtonClick}
                                            leftIcon={
                                                <RocketLaunchIcon className="h-5"></RocketLaunchIcon>
                                            }>
                                            {i18n`XBRLBetaBanner.tryButton`}
                                        </VegaReactButton>
                                    </div>
                                </UIInlineMessage>
                            )}
                        </div>
                        {LaunchDarkly.variation(
                            Flags.ACTIVATION_DATE_PER_ROUND
                        ) && <CasefileRoundsFlowPreview casefile={casefile} />}

                        <div className="casefile-process-body-header flex items-center justify-between w-full">
                            {LaunchDarkly.variation(
                                Flags.ACTIVATION_DATE_PER_ROUND
                            ) && (
                                <CasefileRoundsFlowPreview
                                    casefile={casefile}
                                />
                            )}
                            <div className="flex gap-4 items-center justify-start flex-col-reverse sm:flex-row">
                                <span className="count text-neutral-600 font-semibold">
                                    {uploadedDocuments.length ===
                                    documents.length
                                        ? `${documents.length} ${
                                              documents.length === 1
                                                  ? i18n`document`
                                                  : i18n`documents`
                                          }`
                                        : i18n`Uploaded ${
                                              uploadedDocuments.length
                                          } / ${documents.length} ${
                                              documents.length === 1
                                                  ? i18n`document`
                                                  : i18n`documents`
                                          }`}
                                </span>

                                {isXBRLUploadEnabled && isBeta && (
                                    <UILozenge type="solid" variant="success">
                                        {i18n('Beta')}
                                    </UILozenge>
                                )}
                            </div>

                            <div className="float-right flex items-center justify-center gap-4 flex-col sm:flex-row">
                                {isXBRLUploadEnabled && isBeta && (
                                    <VegaReactButton
                                        priority="secondary"
                                        type="neutral"
                                        onClick={() => handleBetaVersion(false)}
                                        leftIcon={
                                            <ArrowUturnLeftIcon className="h-6 shrink-0" />
                                        }>
                                        {i18n('XBRLBetaBanner.exitButton')}
                                    </VegaReactButton>
                                )}
                                <label className="file-input mt-0">
                                    <input
                                        type="file"
                                        id="fileupload"
                                        name="files[]"
                                        className="file"
                                        accept={acceptedFileTypes}
                                        onChange={onFileInputChange}
                                        onClick={(event) =>
                                            ((
                                                event.target as HTMLInputElement
                                            ).value = '')
                                        }
                                        multiple={true}
                                    />
                                    <VegaReactButton
                                        priority="primary"
                                        type="neutral"
                                        onClick={handleUploadClick}
                                        leftIcon={
                                            <ArrowUpTrayIcon className="h-6 shrink-0" />
                                        }>
                                        {i18n('Upload file')}
                                    </VegaReactButton>
                                </label>
                            </div>
                        </div>
                        <div className="w-full my-2">
                            <div className="h-[1px] bg-neutral-100"></div>
                        </div>
                        <div className="mb-4 text-neutral-600">
                            {isXBRLUploadEnabled && isBeta && (
                                <div className="inline">
                                    {i18n('Supported file formats:')}
                                    <span className="font-semibold mx-1">
                                        PDF, iXBRL.
                                    </span>
                                </div>
                            )}
                            {i18n('Max file size:')}
                            <span className="font-semibold ml-1">
                                {MAX_FILE_SIZE} MB.
                            </span>
                        </div>
                    </>
                ) : (
                    <>
                        {LaunchDarkly.variation(
                            Flags.ACTIVATION_DATE_PER_ROUND
                        ) && <CasefileRoundsFlowPreview casefile={casefile} />}

                        <div className="casefile-process-body-header flex items-center justify-between w-full">
                            <div>
                                <label className="file-input">
                                    <input
                                        type="file"
                                        id="fileupload"
                                        name="files[]"
                                        className="file"
                                        accept={acceptedFileTypes}
                                        onChange={onFileInputChange}
                                        onClick={(event) =>
                                            ((
                                                event.target as HTMLInputElement
                                            ).value = '')
                                        }
                                        multiple={true}
                                    />
                                    <Button
                                        theme="blue"
                                        variant="text"
                                        renderAsSpan
                                        icon="far fa-plus-circle"
                                        renderIconLeft={true}>
                                        {i18n('Upload document')}
                                    </Button>
                                </label>
                            </div>

                            <div className="pull-right inline-flex gap-4">
                                <span className="count">
                                    {uploadedDocuments.length ===
                                    documents.length
                                        ? `${documents.length} ${
                                              documents.length === 1
                                                  ? i18n`document`
                                                  : i18n`documents`
                                          }`
                                        : i18n`Uploaded ${
                                              uploadedDocuments.length
                                          } / ${documents.length} ${
                                              documents.length === 1
                                                  ? i18n`document`
                                                  : i18n`documents`
                                          }`}
                                </span>
                            </div>
                        </div>
                    </>
                )}

                <DocumentDropZone
                    availableDocumentTypes={availableDocumentTypes}
                    documents={documents}
                    onAddFileLinkClick={activateFileAddHandler}
                    onChangeDocumentOrder={handleChangeDocumentOrder}
                    onDrop={openDocumentImport}
                    onEdit={openDocumentEdit}
                    onPreview={onDocumentPreview}
                    acceptedFileTypes={acceptedFileTypes}
                />

                {renderRequirements()}
            </div>

            <CasefileFooterNavigation
                currentRoute="documents"
                saveAsDraftButton={saveAsDraftButton}
                linkParams={linkParams}
                linkTo="casefile-recipients"
                backLink="casefile2-details"
                savingAsDraft={savingAsDraft}
                sending={sending}
            />
        </div>
    );
}
