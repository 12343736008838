import { i18n } from 'Language';
import React, { useCallback, useEffect, useState } from 'react';
import { ClientsMainContainer } from './ClientsMainContainer';
import InputValidation from 'Common/components/InputValidation';
import { TextInput } from 'Common/components';
import Button from 'Common/components/Button';
import {
    resetLastSavedClient,
    saveNewClient,
    clientsState,
    resetNewClientCreation,
    fetchClientById,
    resetCurrentClient,
    updateClient,
    deleteClient,
} from './redux';
import { notify } from 'react-notify-toast';
import { useAppDispatch, useAppSelector } from 'Store';
import { UserEntity as User } from 'types/User';
import {
    isCustomerAdministrator,
    isPenneoAdministrator,
} from 'Common/utils/userPermissions';
import AccessDenied from 'Common/components/AccessDenied';
import { ReactRouter } from 'types/Router';
import LaunchDarkly, { Flags } from 'Common/LaunchDarkly';
import { Client } from './types';
import { modal } from 'Common/components/Common/Modal';
import { EditableClientProps } from './redux/utils';
import { isEqual } from 'lodash';

type Props = {
    user: User;
    router: ReactRouter;
    params: {
        clientId: Client['id'];
    };
};

export const ClientsEditor = ({
    user,
    router,
    params: { clientId },
}: Props) => {
    const isAdmin =
        isPenneoAdministrator(user) || isCustomerAdministrator(user);

    const initialClientData: EditableClientProps = {
        name: '',
    };

    const [isDirty, setIsDirty] = useState(false);
    const [newClientData, setNewClientData] =
        useState<EditableClientProps>(initialClientData);
    const dispatch = useAppDispatch();
    const [lastUpdated, setLastUpdated] = useState<number>();
    const {
        lastSavedClient,
        loading,
        error,
        currentClient,
        lastDeletedClient,
    } = useAppSelector(clientsState);

    /**
     * newClientData is a centralized data source for the payload
     * of Client data, whether it is to create a new one
     * or to edit an existing one.
     * This method will update the specific prop of that object
     * and maintain the rest.
     */
    const payloadBuilder = useCallback(
        (newValue: Partial<EditableClientProps>) => {
            const latestValues = {
                ...newClientData,
                ...newValue,
            };

            setNewClientData(latestValues);

            /**
             * We determine if the form is considered 'dirty'
             * depending if we are editing an existing client
             * or creating a new one, as different rules
             * would apply.
             */
            if (!currentClient) {
                setIsDirty(true);

                return;
            }

            /**
             * This neat little trick creates a new object
             * with only the properties we need to compare
             */
            const initialProps = (({
                name,
                reference,
            }: Client): EditableClientProps => ({
                name,
                reference,
            }))(currentClient);

            // we now compare both objects
            const areEqual = isEqual(latestValues, initialProps);

            setIsDirty(!areEqual);
        },
        [newClientData, currentClient]
    );

    useEffect(() => {
        // access control
        if (!LaunchDarkly.variation(Flags.CLIENTS)) {
            router.push('/404');
        }

        //cleanup
        return () => {
            /**
             * We make sure to clear the last saved client
             * when we unmount the component, so we
             * won't show the success message more than once
             */
            dispatch(resetLastSavedClient());

            /**
             * Clear the current client
             * when we unmount the component
             */
            dispatch(resetCurrentClient());
        };
    }, []);

    useEffect(() => {
        /**
         * We check if there's a new client creation request
         * as a search param. If so, we set it as the initial
         * name value.
         * Regardless, we ALWAYS cleanup the client creation store
         * when we are in this page.
         */
        if (router.location.query?.name) {
            payloadBuilder({ name: router.location.query.name });
        }

        dispatch(resetNewClientCreation());
    }, [router.location.query?.name]);

    useEffect(() => {
        if (!clientId) {
            return;
        }

        dispatch(fetchClientById(clientId));
    }, [clientId]);

    useEffect(() => {
        if (!currentClient) {
            return;
        }

        /**
         * If we have a client ID and have fetched the client's data
         * we then prefill the form with it
         */
        const { name, reference, updated } = currentClient;

        payloadBuilder({ name, reference });
        setLastUpdated(updated);
    }, [currentClient]);

    useEffect(() => {
        if (error && !loading) {
            notify.show(
                <span>{i18n('An error occurred, please try again')}</span>,
                'error',
                6000
            );

            return;
        }

        if (lastSavedClient) {
            notify.show(
                <span>{i18n`${lastSavedClient.name} was saved successfully`}</span>,
                'success',
                3000
            );

            // reset to a blank client creation form
            setNewClientData(initialClientData);
            setIsDirty(false);
        }
    }, [lastSavedClient, error, loading]);

    useEffect(() => {
        if (!lastUpdated || !currentClient) {
            return;
        }

        if (currentClient.updated !== lastUpdated) {
            notify.show(
                <span>{i18n`The changes were saved`}</span>,
                'success',
                3000
            );
        }
    }, [currentClient, lastUpdated]);

    useEffect(() => {
        if (!lastDeletedClient) {
            return;
        }

        router.push('/clients');
    }, [lastDeletedClient]);

    /**
     * CTAs
     */

    // Editing
    const saveChanges = useCallback(() => {
        // If there are no changes, we skip saving
        if (!isDirty) {
            return;
        }

        dispatch(updateClient(clientId, newClientData));

        setIsDirty(false);
    }, [clientId, newClientData, isDirty]);

    const confirmDelete = useCallback(() => {
        if (!currentClient) {
            return;
        }

        const config = {
            title: i18n`Delete client`,
            body: (
                <p>
                    {i18n`Are you sure you want to delete ${currentClient.name}?`}
                    <br />
                    <br />
                    <span className="text-error">
                        <i className="fa fa-warning" />
                        &nbsp;{i18n`This action cannot be undone`}
                    </span>
                </p>
            ),
            buttons: (
                <>
                    <Button
                        type="reset"
                        onClick={modal.hide}>{i18n`Cancel`}</Button>
                    <Button
                        type="submit"
                        theme="red"
                        autoFocus
                        onClick={() => {
                            modal.hide();
                            dispatch(deleteClient(currentClient as Client));
                        }}>
                        {i18n`Delete client`}
                    </Button>
                </>
            ),
        };

        modal.show(config);
    }, [currentClient]);

    // Creating
    const saveClient = useCallback(
        () => dispatch(saveNewClient(newClientData)),
        [newClientData]
    );

    const { name, reference } = newClientData;

    return isAdmin ? (
        <ClientsMainContainer
            title={clientId ? 'Edit client' : 'Create new client'}
            center>
            <div className="clients-editor">
                <p>{clientId ? i18n`Edit client` : i18n`Create new client`}</p>
                <div className="clients-editor-form">
                    <div className="clients-editor-form-field">
                        <InputValidation
                            triggers={name}
                            rules={[
                                {
                                    error: {
                                        message: i18n`Company name cannot be empty`,
                                    },
                                    test: () =>
                                        (name === '' || name.trim() === '') &&
                                        isDirty,
                                },
                            ]}>
                            <TextInput
                                label={i18n`Company name`}
                                name="title"
                                value={name}
                                placeholder={i18n`Enter company name`}
                                required={true}
                                autoComplete="off"
                                onChange={(value: string) =>
                                    payloadBuilder({ name: value })
                                }
                            />
                        </InputValidation>
                    </div>
                    <div className="clients-editor-form-field">
                        <TextInput
                            label={`${i18n`Reference ID`} ${i18n`(optional)`}`}
                            name="reference"
                            value={reference}
                            placeholder={i18n`Enter reference ID`}
                            autoComplete="off"
                            onChange={(value: string) =>
                                payloadBuilder({ reference: value })
                            }
                        />
                    </div>
                </div>
                <div className="clients-editor-form-footer">
                    {clientId && (
                        <Button
                            className="mr"
                            theme="red"
                            variant="outline"
                            onClick={confirmDelete}>
                            {i18n`Delete client`}
                        </Button>
                    )}

                    <Button
                        theme="blue"
                        onClick={() =>
                            clientId ? saveChanges() : saveClient()
                        }
                        disabled={
                            !name.length ||
                            name.trim() === '' ||
                            !isDirty ||
                            loading
                        }>
                        {clientId ? i18n`Save changes` : i18n`Save as a client`}
                    </Button>
                </div>
            </div>
        </ClientsMainContainer>
    ) : (
        <AccessDenied />
    );
};
