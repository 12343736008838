import { SigningAPI } from 'Api/ApiClient';

type Success<T> = { data: T; error: null };
type Failure<E> = { data: null; error: E };
type Result<T, E = { status: number }> = Success<T> | Failure<E>;

export async function tryCatch<T, E = { status: number }>(
    promise: Promise<T>
): Promise<Result<T, E>> {
    try {
        const data = await promise;

        return { data, error: null };
    } catch (error) {
        return { data: null, error: error as E };
    }
}

export type InvitationDetails = {
    id: number;
    isExpired: boolean;
    folder: {
        id: number;
        folderName: string;
    };
};

export type InvitationAcceptResponse = {
    userId: number;
    shared: boolean;
    type: string;
    sdkClassName: string;
    id: number;
    title: string;
};

export const fetchInvitationDetails = (challengeKey: string) => {
    return tryCatch<InvitationDetails>(
        SigningAPI.get(`/folders/invitations/${challengeKey}/details`)
    );
};

export const acceptFolderInvitation = (challengeKey: string) => {
    return tryCatch<InvitationAcceptResponse>(
        SigningAPI.post(`/folders/invitations/${challengeKey}/accept`)
    );
};
