import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';
import CredentialManagementTabs from './CredentialManagementTabs';
import UserStore from '../../stores/UserStore';
import UserActions from '../../actions/UserActionCreators';
import RequestStore from '../../stores/RequestStore';
import CredentialStore from '../../stores/CredentialStore';
import CredentialActions from '../../actions/CredentialActionCreators';
import { i18n } from 'Language';
import { UserEntity as User, Credentials } from 'types/User';
import { TabbedRoute } from 'types/TabbedRoute';
import { CreateLink } from 'types/Router';

const TABS: TabbedRoute[] = [
    {
        path: 'user-details',
        tab: {
            title: 'User details',
        },
    },
    {
        path: 'user-auth-methods',
        tab: {
            title: 'Reset credentials',
        },
    },
];

type Props = {
    children: JSX.Element;
    params: {
        customerId: string;
        userId: string;
    };
    route: {
        createLink: CreateLink;
    };
};

type State = {
    currentUser: any;
    user: User | null;
    credentials: Credentials | null;
    requestStatus: string | null;
};

export default class CredentialsManagement extends React.Component<
    Props,
    State
> {
    static contextTypes = {
        router: PropTypes.object.isRequired,
    };

    state: State = {
        currentUser: null,
        user: null,
        credentials: null,
        requestStatus: null,
    };

    componentWillMount() {
        UserStore.addChangeListener(this.onChange);
        CredentialStore.addChangeListener(this.onChange);
        RequestStore.addChangeListener(this.onChange);
        this.loadData();
    }

    componentWillUnmount() {
        UserStore.removeChangeListener(this.onChange);
        CredentialStore.removeChangeListener(this.onChange);
        RequestStore.removeChangeListener(this.onChange);
    }

    loadData = () => {
        const { userId, customerId } = this.props.params;

        UserActions.fetchUser(userId, customerId);
        CredentialActions.fetchUserCredentials(userId, customerId);

        UserActions.fetchCurrentUser();
    };

    onChange = () => {
        const { userId } = this.props.params;

        this.setState({
            currentUser: UserStore.getCurrentUser(),
            user: UserStore.getUser(userId),
            credentials: CredentialStore.getUserCredentials(userId),
            requestStatus: RequestStore.getRequestStatus(),
        });
    };

    renderContainer = (Component: JSX.Element) => {
        const props = {
            user: this.state.user,
            credentials: this.state.credentials,
            customerId: this.state.user?.customerIds?.[0],
            params: this.props.params,
            requestStatus: this.state.requestStatus,
        };

        return React.cloneElement(Component, props);
    };

    render() {
        const { userId, customerId } = this.props.params;
        const { credentials, currentUser, user } = this.state;

        const isLoaded = !!(credentials && currentUser && user && user.id);

        return (
            <div className="penneo-auth">
                <Helmet>
                    <title>{i18n('Profile')}</title>
                </Helmet>

                <div className="white-container">
                    <h3 className="title">{i18n('Edit user details')}</h3>
                    {isLoaded ? (
                        <div>
                            <CredentialManagementTabs
                                options={TABS}
                                userId={Number(userId)}
                                customerId={
                                    !!customerId ? Number(customerId) : null
                                }
                                createLink={this.props.route.createLink}>
                                {this.renderContainer(this.props.children)}
                            </CredentialManagementTabs>
                        </div>
                    ) : (
                        <div>
                            <br />
                            <br />
                            <div className="text-center text-medium">
                                <h1>
                                    <i className="far fa-sync fa-lg fa-spin"></i>
                                    <p>{i18n`Loading...`}</p>
                                </h1>
                            </div>
                            <br />
                            <br />
                        </div>
                    )}
                </div>
            </div>
        );
    }
}
