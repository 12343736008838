import { memoize } from 'lodash';
import { AppThunk } from 'Store';
import { SigningAPI } from 'Api';
import { UserEntity, UserRoles } from 'types/User';
import { Contact } from 'types/Contact';
import {
    CONTACT_PICKER_FAILURE,
    CONTACT_PICKER_REQUEST,
    CONTACT_PICKER_SUCCESS,
} from './action-types';
import { normalize } from './utils';

/**
 * Returns a list of the given customer's users
 *
 * It is possible to request only the users with a specific role
 */
export type RequestUserListParams = {
    customerId: number;
    roles?: UserRoles[];
    searchPhrase?: string;
    resultsLimit?: number;
};

export const requestUserList =
    ({
        customerId,
        roles = [UserRoles.USER, UserRoles.CUSTOMER_ADMINISTRATOR],
        searchPhrase,
        resultsLimit,
    }: RequestUserListParams): AppThunk =>
    async (dispatch, _, { api }) => {
        dispatch({ type: CONTACT_PICKER_REQUEST });

        const queryParams = {
            roles: roles.join(','),
            per_page: resultsLimit,
            search_phrase: searchPhrase,
        };

        try {
            let allUsers;

            const result = await api.SigningAPI.get(
                `/customers/${customerId}/users`,
                queryParams,
                {
                    paginate: !!resultsLimit,
                }
            );

            const { data: users, link, count } = result;

            // NOTE: if the query is not paginated, we use 'result' directly instead of 'users'
            allUsers = users ?? result;

            dispatch({
                type: CONTACT_PICKER_SUCCESS,
                payload: {
                    byIds: normalize(allUsers),
                    queryMeta: { count, link },
                },
            });
        } catch (e) {
            dispatch({
                type: CONTACT_PICKER_FAILURE,
                payload: e,
            });
        }
    };

/**
 * Returns a list of sharing candidates for a specified folder
 * @number folderId
 * @string searchPhrase
 */
export const requestShareCandidatesList =
    (
        folderId: number,
        searchPhrase: string,
        resultsLimit: number = 0
    ): AppThunk =>
    async (dispatch, _, { api }) => {
        const queryParams = {
            search_phrase: searchPhrase,
            per_page: resultsLimit,
        };

        try {
            const result = await api.SigningAPI.get(
                `/folders/${folderId}/share-user-candidates`,
                queryParams,
                {
                    paginate: resultsLimit > 0,
                }
            );

            const { data: contacts } = result;

            return dispatch({
                type: CONTACT_PICKER_SUCCESS,
                payload: {
                    byIds: normalize(contacts ?? result),
                },
            });
        } catch (e) {
            console.error('Could not fetch sharing candidates', e);

            return dispatch({
                type: CONTACT_PICKER_FAILURE,
                payload: e,
            });
        }
    };

/**
 * Returns a list including both the customer's users and the contacts in the
 * contact book. Accepts an optional 'query' parameter for fetching a filtered list.
 */
export const requestContactList =
    (customerId: number, query?: string, limit?: number): AppThunk =>
    async (dispatch) => {
        dispatch({ type: CONTACT_PICKER_REQUEST });

        try {
            const [contactsResp, usersResp] = await Promise.all([
                getContacts(query, limit),
                memoGetUsers(customerId),
            ]);

            const externalContacts = contactsResp.map((x) => ({
                ...x,
                isExternal: true,
            }));

            /**
             * We add name in the user object because it has to work with the
             * RecipientModalAdd filter.
             */
            const users = usersResp.map((x) => ({
                ...x,
                name: x.fullName,
            }));

            /**
             * In some cases the BE is returning users without a email
             * that's why we're filtering
             */
            const contacts = [...externalContacts, ...users]
                .filter((x) => x.email)
                .slice(0, limit); //putting a slice on the data as it may be cached and reused

            dispatch({
                type: CONTACT_PICKER_SUCCESS,
                payload: { byIds: normalize(contacts) },
            });
        } catch (e) {
            dispatch({
                type: CONTACT_PICKER_FAILURE,
                payload: e,
            });
        }
    };

export const getContacts = (
    query?: string | null,
    per_page: number = 21
): Promise<Contact[]> => {
    let contactsEndpoint = '/contacts';

    const queryURIEncoded = query && encodeURIComponent(query);

    if (queryURIEncoded) {
        contactsEndpoint += `?fullSearch=${queryURIEncoded}&per_page=${per_page}`;
    }

    return SigningAPI.get(contactsEndpoint);
};

const getUsers = (customerId): Promise<UserEntity[]> =>
    SigningAPI.get(`/customers/${customerId}/users`);
const memoGetUsers: typeof getUsers = memoize(getUsers);
