import {
    OpenIDCertificate,
    OpenIDObj,
    OpenIDSubjectIdentifiers,
    OpenIDToken,
} from 'OpenID/redux/types';

import { OIDs } from 'OpenID/Constants';
import * as Eident from './eident';

export type Token = OpenIDToken & {
    amr: 'be_cardreader';
    birthdate: string;
    card_expiry_date: string;
    card_number: string;
    citizenship: string;
    family_name: string;
    given_name: string;
    name: string;
    place_of_birth: string;
};

export type SubjectIdentifiers = OpenIDSubjectIdentifiers & {
    [OIDs.OPENID_FULL_NAME_V2]: string;
};

export type Certificate = OpenIDCertificate<SubjectIdentifiers>;

export const service: OpenIDObj<Token, Certificate> =
    Eident.makeEIdentCertProcessor(Eident.EIDENT_AMRS.EID_BELGIUM_BE);
