import PropTypes from 'prop-types';
import React from 'react';
import CasefileStore from '../../stores/CasefileStore';
import URIActions from '../../actions/URIActionCreators';
import { i18n } from 'Language';
import { SearchInput } from 'Common/components';
import Tooltip from 'Common/components/Tooltip';
import lodash from 'lodash';
import { LANGUAGES } from 'Language/Constants';
import Button from 'Common/components/Button';

export default class CasefileTemplateSelector extends React.Component {
    static propTypes = {
        templates: PropTypes.object,
        deleting: PropTypes.bool,
    };

    static defaultProps = {
        template: {},
    };

    state = {
        selected: null,
        promptDelete: null,
        filter: null,
    };

    mapObject = (object, callback) => {
        return Object.keys(object).map((key) => {
            return callback(key, object[key]);
        });
    };

    toggleActiveTemplate = (templateId) => {
        // Activate template. If it's currently selected, deselect it.
        this.setState({
            selected: this.state.selected === templateId ? null : templateId,
            promptDelete: null, // deactivate delete prompt
        });
    };

    unselectTemplates = () => {
        this.setState({ selected: null });
    };

    componentDidUpdate(prevProps) {
        // Update filtered results on new template collection
        if (this.props.templates !== prevProps.templates) {
            this.onFilterChange();
        }

        // Will delete
        if (!prevProps.deleting && this.props.deleting) {
            return this.unselectTemplates();
        }

        // Deleting is over
        if (prevProps.deleting && !this.props.deleting) {
            return this.promptDelete(null);
        }
    }

    promptDelete = (templateId) => {
        this.setState({ promptDelete: templateId });
    };

    deleteTemplate = (templateId) => {
        URIActions.deleteCasefileTemplate(this.props.templates, templateId);
    };

    renderDocuments = (templateData) => {
        let { templateId, documents } = templateData;

        if (!documents || documents.length === 0) {
            return false;
        }

        return (
            <div>
                <h2>{i18n`Documents`}</h2>
                <ul className="documents-list">
                    {documents.map((d, i) => (
                        <li key={i}>
                            <i className="text-error far fa-file-pdf" />
                            &nbsp;
                            {d.name}
                            <br />
                            {CasefileStore.getDocumentType(
                                templateId,
                                d.typeId
                            ) && (
                                <ul className="tags">
                                    <li>
                                        {
                                            CasefileStore.getDocumentType(
                                                templateId,
                                                d.typeId
                                            ).name
                                        }
                                    </li>
                                </ul>
                            )}
                        </li>
                    ))}
                </ul>
            </div>
        );
    };

    renderTags = (tags) => {
        let [tag1, tag2, ...rest] = tags;

        return (
            <ul className="tags">
                {tag1 && <li>{tag1}</li>}
                {tag2 && <li>{tag2}</li>}
                {rest.length > 0 && (
                    <li>
                        <Tooltip
                            showArrow={true}
                            text={
                                <div>
                                    {rest.map((tag, i) => (
                                        <div key={i}>- {tag}</div>
                                    ))}
                                </div>
                            }>
                            <span>+ {rest.length}</span>
                        </Tooltip>
                    </li>
                )}
            </ul>
        );
    };

    loadTemplate = (template) => {
        URIActions.loadCasefileTemplate(template.data);
    };

    renderSigners = (templateData) => {
        let { templateId, signers } = templateData;

        if (!signers || signers.length === 0) {
            return false;
        }

        return (
            <div>
                <h2>{i18n`Signers`}</h2>
                <ul className="signer-list">
                    {signers.map((s, i) => (
                        <li key={i}>
                            <span className="name">{s.name}</span>

                            <ul className="signer-icons">
                                {(s.onBehalfOf || s.ssn || s.vatin) && (
                                    <li>
                                        <Tooltip
                                            showArrow={true}
                                            text={
                                                <div className="signer-details">
                                                    {s.onBehalfOf && (
                                                        <span className="signer-details-on-behalf-of">
                                                            {i18n`On Behalf Of`}
                                                            :<br />
                                                            {s.onBehalfOf}
                                                        </span>
                                                    )}

                                                    {s.ssn && (
                                                        <span className="signer-details-ssn">
                                                            {i18n`SSN`}:&nbsp;
                                                            {s.ssn}
                                                        </span>
                                                    )}

                                                    {s.vatid && (
                                                        <span className="signer-details-vatid">
                                                            {i18n`VAT Id`}
                                                            :&nbsp;{s.vatid}
                                                        </span>
                                                    )}
                                                </div>
                                            }>
                                            <i className="far fa-address-card" />
                                        </Tooltip>
                                    </li>
                                )}

                                {s.enableTouch && (
                                    <li>
                                        <Tooltip
                                            showArrow={true}
                                            text={i18n`Touch enabled`}>
                                            <i className="far fa-hand-point-up" />
                                        </Tooltip>
                                    </li>
                                )}

                                {s.accessControl && (
                                    <li>
                                        <Tooltip
                                            showArrow={true}
                                            text={i18n`Sensitive Data`}>
                                            <i className="fa fa-lock" />
                                        </Tooltip>
                                    </li>
                                )}

                                {s.reminderInterval &&
                                    s.reminderInterval > 0 && (
                                        <li>
                                            <Tooltip
                                                showArrow={true}
                                                text={
                                                    <span>
                                                        {i18n`Reminder emails every ${s.reminderInterval} days`}
                                                    </span>
                                                }>
                                                <i className="far fa-clock" />
                                            </Tooltip>
                                        </li>
                                    )}
                            </ul>

                            <br />
                            <span className="email">{s.email}</span>
                            <br />

                            {s.roles &&
                                this.renderTags(
                                    s.roles.map((r) =>
                                        CasefileStore.getSignerRole(
                                            templateId,
                                            r
                                        )
                                    )
                                )}
                        </li>
                    ))}
                </ul>
            </div>
        );
    };

    renderCopyRecipients = (templateData) => {
        let { copyRecipients } = templateData;

        if (!copyRecipients || copyRecipients.length === 0) {
            return false;
        }

        return (
            <div>
                <h2>{i18n`Copy Recipients`}</h2>
                <ul className="signer-list">
                    {copyRecipients.map((c, i) => (
                        <li key={i}>
                            <span className="name">{c.name}</span>
                            <br />
                            <span className="email">{c.email}</span>
                        </li>
                    ))}
                </ul>
            </div>
        );
    };

    onFilterChange = (value = '') => {
        let { templates } = this.props;

        let data = lodash.cloneDeep(templates);

        for (let key in data) {
            if (!data.hasOwnProperty(key)) {
                continue;
            }

            // Remove everything that doesn't match by name
            if (
                data[key].name.toLowerCase().indexOf(value.toLowerCase()) === -1
            ) {
                delete data[key];
            }
        }

        this.setState({
            filter: value,
            results: data,
            selected: null,
            promptDelete: null,
        });
    };

    getCountText = () => {
        let { results, filter } = this.state;
        let { templates } = this.props;

        let totalCount = (templates && Object.keys(templates).length) || 0;
        let resultCount = (results && Object.keys(results).length) || 0;
        let hasFilter = filter && filter.length > 0;

        if (totalCount === 0) {
            return 'No templates';
        }

        if (hasFilter && resultCount < totalCount) {
            return `Showing ${resultCount} of ${totalCount} Templates`;
        }

        return (
            totalCount +
            ' ' +
            (totalCount > 0 ? i18n`Templates` : i18n`Template`)
        );
    };

    renderTemplateRow = (template, active, templateId) => {
        let { data, name } = template;

        // If this row is being deleted
        let isDeleting =
            templateId === this.state.promptDelete && this.props.deleting;
        let caseType = CasefileStore.getCaseTypeById(template.data.templateId);

        return (
            <div
                className={'template-row' + (isDeleting ? ' deleting' : '')}
                key={templateId}>
                <div className="left-block">
                    <span className="title">{name}</span>
                    {data.templateId ? (
                        <span className="casefile-type">
                            {caseType ? (
                                <span>
                                    <i className="fa fa-tag" />
                                    &nbsp;
                                    {caseType.name}
                                </span>
                            ) : (
                                <span className="text-warning">
                                    <i className="fas fa-exclamation-triangle" />
                                    &nbsp;
                                    {i18n`This case file type is no longer available`}
                                </span>
                            )}
                        </span>
                    ) : (
                        <span className="casefile-type">
                            {i18n`No case file type selected`}
                        </span>
                    )}
                </div>

                <div className="right-block">
                    <Button
                        theme="blue"
                        variant="outline"
                        icon="far fa-check"
                        onClick={this.loadTemplate.bind(null, template)}>
                        {i18n`Use Template`}
                    </Button>
                </div>

                <div className="template-footer">
                    <ul className="counters">
                        {data.signers && (
                            <li>
                                {data.signers.length} {i18n`Signers`}
                            </li>
                        )}
                        {data.documents && (
                            <li>
                                {data.documents.length} {i18n`Documents`}
                            </li>
                        )}
                        {data.copyRecipients && (
                            <li>
                                {data.copyRecipients.length}{' '}
                                {i18n`Copy Recipients`}
                            </li>
                        )}
                        {/* Don't collapse line if there are no elements in list */}
                        <span>&nbsp;</span>
                    </ul>
                    <span
                        className="details-link"
                        onClick={this.toggleActiveTemplate.bind(
                            null,
                            templateId
                        )}>
                        {!active ? (
                            <span>
                                <i className="far fa-plus-square" />
                                &nbsp;
                                {i18n`View more details`}
                            </span>
                        ) : (
                            <span>
                                <i className="far fa-minus-square" />
                                &nbsp;
                                {i18n`Hide details`}
                            </span>
                        )}
                    </span>
                </div>

                {active && this.renderTemplateDetails(template, templateId)}
            </div>
        );
    };

    renderTemplateDetails = (template, templateId) => {
        let { data } = template;

        if (templateId === this.state.promptDelete) {
            return (
                <div className="additional-details">
                    <div className="dashed-line"></div>
                    <div className="text-center">
                        <h3>{i18n`Are you sure you want to delete this template?`}</h3>
                        <p className="text-warning">
                            <i className="fa fa-warning" />
                            &nbsp;
                            {i18n`This action cannot be undone`}
                        </p>
                        <Button
                            className="mr"
                            onClick={this.promptDelete.bind(null, null)}>
                            {i18n`Cancel`}
                        </Button>
                        <Button
                            theme="red"
                            onClick={this.deleteTemplate.bind(
                                null,
                                templateId
                            )}>
                            {i18n`Yes, delete`}
                        </Button>
                    </div>
                </div>
            );
        }

        let caseType = CasefileStore.getCaseTypeById(template.data.templateId);

        return (
            <div className="additional-details">
                <div className="dashed-line"></div>

                {!caseType && (
                    <div className="text-center box-gray">
                        <p>
                            <i className="fas fa-exclamation-triangle" />
                            &nbsp;
                            {i18n`The case file type used to create this template is no longer
                                available and information about signer roles and document
                                types may be missing. You can still use the rest of the
                                data in this template`}
                        </p>
                    </div>
                )}

                <div className="column detail-column">
                    {/* Signers */}
                    {this.renderSigners(data)}

                    {/* Documents */}
                    {this.renderDocuments(data)}

                    {/* Copy Recipients */}
                    {this.renderCopyRecipients(data)}
                </div>

                <div className="column settings-column">
                    <h2>{i18n`Title`}</h2>
                    <p>{data.name || '--'}</p>

                    {data.language && (
                        <div>
                            <h2>{i18n`Language`}</h2>
                            <p>
                                <img src={LANGUAGES[data.language].flag} />
                                &nbsp;
                                {LANGUAGES[data.language].name}
                            </p>
                        </div>
                    )}

                    <h2>{i18n`Folder`}</h2>
                    <p>
                        <i className="fa fa-folder" />
                        &nbsp;
                        {CasefileStore.getFolder(data.folderId)
                            ? CasefileStore.getFolder(data.folderId).title
                            : data.folderId}
                    </p>

                    {(data.sensitiveData || data.signOnMeeting) && (
                        <div>
                            <h2>{i18n`Settings`}</h2>
                            <div className="settings-list">
                                {data.sensitiveData && (
                                    <div>
                                        <i className="fa fa-lock"></i>
                                        &nbsp;
                                        {i18n`Sensitive Data`}
                                    </div>
                                )}
                                {data.signOnMeeting && (
                                    <div>
                                        <i className="fa fa-group"></i>
                                        &nbsp;&nbsp;
                                        {i18n`Sign at the meeting`}
                                    </div>
                                )}
                            </div>
                        </div>
                    )}

                    {data.reference && (
                        <div>
                            <h2>{i18n`Reference`}</h2>
                            <p>{data.reference}</p>
                        </div>
                    )}

                    {data.messageTemplate && (
                        <div>
                            <h2>{i18n`Message Template`}</h2>
                            <p>{data.messageTemplate}</p>
                        </div>
                    )}
                </div>

                {(data.messageSubject || data.messageText) && (
                    <div className="custom-message">
                        <h2>{i18n`Custom Message`}</h2>

                        <div className="custom-message-wrapper">
                            <p>
                                <b>{i18n`Subject:`}</b>&nbsp;
                                {data.messageSubject}
                            </p>
                            <p style={{ whiteSpace: 'pre-line' }}>
                                {data.messageText}
                            </p>
                        </div>
                    </div>
                )}

                <div className="template-details-footer">
                    <Button
                        theme="red"
                        className="pull-left"
                        variant="outline"
                        icon="far fa-times"
                        onClick={this.promptDelete.bind(null, templateId)}>
                        {i18n`Delete`}
                    </Button>

                    <Button
                        theme="gray"
                        variant="outline"
                        className="mr"
                        icon="far fa-minus"
                        onClick={this.toggleActiveTemplate.bind(
                            null,
                            templateId
                        )}>
                        {i18n`Hide details`}
                    </Button>

                    <Button
                        icon="far fa-check"
                        theme="blue"
                        onClick={this.loadTemplate.bind(null, template)}>
                        {i18n`Use Template`}
                    </Button>
                </div>
            </div>
        );
    };

    renderTemplateList = (templates) => {
        let { selected, filter, results } = this.state;

        let data = results || templates;

        let totalCount = (templates && Object.keys(templates).length) || 0;
        let resultCount = (results && Object.keys(results).length) || 0;
        let hasFilter = filter && filter.length > 0;

        if (totalCount === 0) {
            return (
                <div>
                    <h2>{i18n`You haven't created any templates`}</h2>
                    <p>
                        {i18n`You can create a template by selecting
                        ${(
                            <span>
                                {i18n`Advanced options`}&nbsp;
                                <i className="fa fa-cog" />
                            </span>
                        )}
                        >
                        ${(
                            <span>
                                {i18n`Save as template`}&nbsp;
                                <i className="fa fa-bookmark" />
                            </span>
                        )}`}
                    </p>
                </div>
            );
        }

        if (resultCount === 0 && hasFilter) {
            return (
                <div>
                    <h2>{i18n`No results found for "${filter}"`}</h2>
                    <a onClick={() => this.onFilterChange()}>
                        {i18n`Clear search`} <i className="fa fa-times"></i>
                    </a>
                </div>
            );
        }

        return this.mapObject(data, (id, template) => {
            return this.renderTemplateRow(template, selected === id, id);
        });
    };

    render() {
        let { templates } = this.props;
        let { filter } = this.state;

        return (
            <div>
                <h3 className="template-modal-title">
                    <i className="fa fa-bookmark"></i>
                    {i18n`Templates`}
                </h3>

                <SearchInput
                    resultText={this.getCountText()}
                    value={filter}
                    onChange={this.onFilterChange}
                    placeholder={i18n`Type to filter templates`}
                />

                <div className="template-list">
                    {this.renderTemplateList(templates)}
                </div>
            </div>
        );
    }
}
