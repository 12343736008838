import { SigningAPI } from 'Api';
import React, { useState, useEffect } from 'react';

type CountdownProps = {
    minutesToCount: number;
    callbackOnEnd?: Function;
};

export const Countdown = ({
    minutesToCount,
    callbackOnEnd,
}: CountdownProps) => {
    const [countDown, setCountDown] = useState(0);
    const [runTimer, setRunTimer] = useState(true);
    const [timerId, setTimerId] = useState<number>();

    useEffect(() => {
        if (runTimer) {
            setCountDown(60 * minutesToCount);
            !timerId &&
                setTimerId(
                    setInterval(() => {
                        setCountDown((countDown) => countDown - 1);
                    }, 1000) as unknown as number
                );
        } else {
            clearInterval(timerId);
        }

        return () => clearInterval(timerId);
    }, [runTimer, timerId, minutesToCount]);

    useEffect(() => {
        if (countDown < 0 && runTimer) {
            setRunTimer(false);
            setCountDown(0);

            if (callbackOnEnd) {
                callbackOnEnd();
            }
        }
    }, [countDown, runTimer]);

    const seconds = String(countDown % 60).padStart(2, '0');
    const minutes = String(Math.floor(countDown / 60)).padStart(2, '0');

    return (
        <span className="countdown">
            {minutes}:{seconds}
        </span>
    );
};

/**
 * Request an email change
 * @param newEmail - The email to send the confirmation code to
 */
export const changeEmailRequest = async (
    newEmail: string,
    userId: number
): Promise<any> => {
    try {
        const response = await SigningAPI.post(`/users/change-email`, {
            newEmail,
            userId,
        });

        return response;
    } catch (error) {
        throw new Error();
    }
};

/**
 * Complete an email change
 * @param code - The confirmation code sent to the new email
 */
export const completeChangeEmailRequest = async (
    code: string,
    userId: number
): Promise<any> => {
    try {
        const response = await SigningAPI.post(`/users/change-email/complete`, {
            code,
            userId,
        });

        return response;
    } catch (error) {
        throw new Error();
    }
};
