import PropTypes from 'prop-types';
import React from 'react';
import { i18n } from 'Language';
import DocumentActions from '../../actions/DocumentActionCreators';
import { DragSource, DropTarget } from 'react-dnd';

import ClickDropdown from 'Common/components/Common/ClickDropdown';

class DocumentListItem extends React.Component {
    static propTypes = {
        connectDragSource: PropTypes.func.isRequired,
        connectDragPreview: PropTypes.func.isRequired,
        connectDropTarget: PropTypes.func.isRequired,
        file: PropTypes.object,
        index: PropTypes.number,
        remove: PropTypes.func,
        open: PropTypes.func,
        updateDocumentType: PropTypes.func,
        availableDocumentTypes: PropTypes.array,
    };

    changeDocumentType = (event) => {
        let { index } = this.props;
        let { value } = event.target;

        let data = {
            documentTypeId: Number(value),
        };

        DocumentActions.updateDocumentRole(index, data);
    };

    changeDocumentOrder = (index, newIndex) => {
        DocumentActions.reorderDocument(index, newIndex);
    };

    changeDocumentName = (event) => {
        let { index } = this.props;
        let { value } = event.target;

        let data = {
            name: value,
        };

        DocumentActions.editDocument(index, data);
    };

    renderDocumentOptions = () => {
        let { index, remove, open, file } = this.props;

        return (
            <div className="click-dropdown-list">
                <ul>
                    {file.localPath && (
                        <li onClick={open.bind(null, index)}>
                            <i className="fa fa-external-link" />
                            {i18n`Preview`}
                        </li>
                    )}

                    {/*
                    <li><i className="fa fa-plus"/>Add file</li>
                    <li><i className="fa fa-exchange"/>Change file</li>
                    */}
                    <li onClick={remove.bind(null, index)}>
                        <i className="fa fa-times" />
                        {i18n`Delete`}
                    </li>
                </ul>
            </div>
        );
    };

    render() {
        let { file, index, availableDocumentTypes } = this.props;

        // react-dnd
        let { connectDragSource, connectDragPreview, connectDropTarget } =
            this.props;

        // Create a dragging container.
        return connectDragPreview(
            connectDropTarget(
                <li className="dralt" ref={(node) => (this.node = node)}>
                    {connectDragSource(
                        <span className="drag-handle">
                            <span className="order">{index + 1}</span>
                            <i className="fa fa-grip fa-rotate-90"></i>
                        </span>
                    )}

                    <div className="igrp">
                        <input
                            type="text"
                            className="match-parent"
                            value={file.name}
                            onChange={this.changeDocumentName}
                        />
                        <select
                            className="match-parent"
                            onChange={this.changeDocumentType}
                            value={file.documentTypeId}>
                            <option
                                value={-1}
                                disabled>{i18n`Select Type`}</option>

                            {availableDocumentTypes.map((type) => (
                                <option value={type.id} key={type.id}>
                                    {type.name}
                                </option>
                            ))}
                        </select>
                    </div>

                    <span className="options-button">
                        <ClickDropdown
                            placement="bottom left"
                            offset={-20}
                            content={this.renderDocumentOptions()}>
                            <i className="fa fa-cog"></i>
                        </ClickDropdown>
                    </span>
                </li>
            )
        );
    }
}

// Row has two behaviours: it is a source and a target, because
// it is able to be dragged and to be reordered.

// Source callbacks:
const source = {
    // returns an object with useful properties when dragging event is over
    beginDrag(props) {
        return {
            index: props.index,
        };
    },
};

// Target callbacks:
const target = {
    hover(props, monitor, component) {
        const dragIndex = monitor.getItem().index;
        const hoverIndex = props.index;

        // prevents replacing items with themselves
        if (dragIndex === hoverIndex) {
            return;
        }

        // Determine rectangle on screen
        const hoverBoundingRect = component.node.getBoundingClientRect();

        // Get vertical middle
        const hoverMiddleYDown =
            hoverBoundingRect.top - hoverBoundingRect.bottom;
        const hoverMiddleYUp = hoverBoundingRect.bottom - hoverBoundingRect.top;

        // Determine mouse position
        const clientOffset = monitor.getClientOffset();

        // Get pixels to the top
        const hoverClientY = clientOffset.y - hoverBoundingRect.top;

        // Only perform the move when the mouse has crossed the border of the other card

        // Dragging downwards
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleYDown) {
            return;
        }

        // Dragging upwards
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleYUp) {
            return;
        }

        // Perform reorder
        component.changeDocumentOrder(dragIndex, hoverIndex);

        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        monitor.getItem().index = hoverIndex;
    },
};

// Connect the callbacks
let Target = DropTarget('DocumentListItem', target, (connect) => ({
    connectDropTarget: connect.dropTarget(),
}))(DocumentListItem);

Target = DragSource('DocumentListItem', source, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
}))(Target);

export default Target;
