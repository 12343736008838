import React, { useCallback, useState } from 'react';
import { Checkbox, NumberInput } from 'Common/components';
import { Recipient } from './RecipientModalExtended';
import { i18n } from 'Language';
import { useAppSelector } from 'Store';
import { CaseFileEntity } from 'types/CaseFile';
import HelpTooltip from 'Common/components/HelpTooltip';
import { InsecureSigningMethod } from 'types/Signer';
import { INSECURE_SIGNING_METHODS } from 'EID/Constants';
import launchDarkly, { Flags } from 'Common/LaunchDarkly';
import { PersonIdentifierType } from '../../../EID/types';

type Props = {
    casefile: CaseFileEntity;
    recipient: Recipient;
    showTouchSignatureOptions: boolean;
    handleChange: (key: string, value: any) => void;
    handleInsecureMethods: (value: string, add?: boolean) => void;
};

export const RecipientSettingsEditor = ({
    casefile,
    recipient,
    showTouchSignatureOptions,
    handleChange,
    handleInsecureMethods,
}: Props) => {
    const { data: customer } = useAppSelector(
        (state) => state.customer.details
    );
    const [viewSes, setViewSes] = useState(false);

    /**
     * With the Recipient Restrictions enabled, SES won't be disabled
     * when selecting Access control and SMS validation
     */
    const allowSESwithSmsAccessControl =
        launchDarkly.variation(Flags.IMPROVED_RECIPIENT_SETTINGS) &&
        recipient.ssnType === PersonIdentifierType.SMS &&
        recipient.accessControl;

    /**
     * Only filter out insecure methods if flag is on,
     * there's an array of methods to choose from and this array is not empty
     */
    const shouldFilterInsecureMethods = useCallback(
        () =>
            !!launchDarkly.variation(Flags.SPLIT_INSECURE_SIGNING_METHODS) &&
            !!customer.allowedSimpleSigningMethods.length,
        [customer]
    );

    const { sensitiveData } = casefile;
    const { allowedSimpleSigningMethods } = customer;

    return (
        <div className="recipient-settings-editor">
            {showTouchSignatureOptions && (
                <div className="ses-options mb">
                    <p
                        className="underline-link inline-block align-top"
                        onClick={() => setViewSes(!viewSes)}>
                        <span>
                            {i18n`Allow Simple Electronic Signatures (SES)`}
                            &nbsp;
                            {!viewSes ? (
                                <i className="far fa-chevron-down"></i>
                            ) : (
                                <i className="far fa-chevron-up"></i>
                            )}
                        </span>
                    </p>

                    {viewSes && (
                        <>
                            {(recipient?.insecureSigningMethods &&
                                INSECURE_SIGNING_METHODS.map((method) => {
                                    const show = shouldFilterInsecureMethods()
                                        ? (
                                              allowedSimpleSigningMethods as InsecureSigningMethod[]
                                          )?.includes(
                                              method.credentialType() as InsecureSigningMethod
                                          )
                                        : true;

                                    return (
                                        show && (
                                            <Checkbox
                                                key={method.credentialType()}
                                                disabled={
                                                    sensitiveData &&
                                                    !allowSESwithSmsAccessControl
                                                }
                                                checked={
                                                    recipient!
                                                        .insecureSigningMethods &&
                                                    recipient!.insecureSigningMethods?.includes(
                                                        method.credentialType() as InsecureSigningMethod
                                                    )
                                                }
                                                onChange={(value) => {
                                                    handleInsecureMethods(
                                                        method.credentialType() as InsecureSigningMethod,
                                                        value
                                                    );
                                                }}
                                                data-testid={`touch-${method.credentialType()}`}
                                                label={
                                                    <span>
                                                        {i18n(
                                                            method.extendedInfo
                                                                ?.title
                                                        )}
                                                        &nbsp;
                                                        <HelpTooltip
                                                            width={260}
                                                            text={i18n(
                                                                method
                                                                    .extendedInfo
                                                                    ?.tooltip
                                                            )}
                                                        />
                                                    </span>
                                                }
                                            />
                                        )
                                    );
                                })) ?? (
                                <Checkbox
                                    disabled={
                                        sensitiveData &&
                                        !allowSESwithSmsAccessControl
                                    }
                                    checked={recipient.enableInsecureSigning}
                                    onChange={(value) =>
                                        handleChange(
                                            'enableInsecureSigning',
                                            value
                                        )
                                    }
                                    label={
                                        <span>
                                            {i18n`Allow touch signatures`}
                                            &nbsp;
                                            <HelpTooltip
                                                width={260}
                                                text={i18n`Allow the signer to draw a hand-written signature using a touch screen or a mouse. Electronic ID signatures are more secure than touch signatures due to the fact that they allow Penneo to verify the identity of the signer.`}
                                            />
                                        </span>
                                    }
                                />
                            )}
                        </>
                    )}
                </div>
            )}

            <div className="reminders-editor mb">
                <label className="label">{i18n`Reminders`}</label>
                <span>
                    {i18n`Send reminders every
    ${(
        <NumberInput
            min={0}
            max={365}
            onChange={(value) => handleChange('reminderInterval', value)}
            value={recipient.reminderInterval}
        />
    )}
    days`}
                </span>
            </div>
        </div>
    );
};
