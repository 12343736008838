import React, { useCallback, useEffect, useState } from 'react';
import { Recipient } from './RecipientModalExtended';
import { SSNs, images } from 'Constants';
import SmsInput from 'Common/components/SmsInput';
import { i18n } from 'Language';
import { RadioButton, TextInput } from 'Common/components';
import InputValidation from 'Common/components/InputValidation';
import SsnInput from 'Common/components/SsnInput';
import { SignerValidationRule } from 'types/Signer';
import { aggregateSSN } from 'utils';
import { useAppSelector } from 'Store';
import { CustomerSigningMethod } from 'types/Customer';
import { RecipientViewingRestrictionsEditor } from './RecipientViewingRestrictionsEditor';
import { PersonIdentifierType } from '../../../EID/types';

export type ValidationType = 'none' | 'ssn' | 'vatin' | 'sms';

type Props = {
    isRegisteredLetter: boolean;
    sensitiveData: boolean;
    recipient: Recipient;
    handleChange: (key: string, value: any) => void;
    handleAccessControlChange: (value: boolean) => void;
    handleSSNChange: (value: string, ssnType?: string) => void;
    forceDisabled?: 'accessControl' | 'all';
    handleValidationTypeChange?: (value: ValidationType) => void;
};

function getRecipientValidationType(recipient: Recipient): ValidationType {
    let validationType: ValidationType = 'none';

    if (recipient.vatin) {
        validationType = 'vatin';
    } else if (recipient.ssnType === PersonIdentifierType.SMS) {
        validationType = 'sms';
    } else if (
        !!recipient.ssnType &&
        recipient.ssnType !== PersonIdentifierType.Legacy
    ) {
        validationType = 'ssn';
    }

    return validationType;
}

export const RecipientRestrictions = ({
    isRegisteredLetter,
    sensitiveData,
    recipient,
    handleChange,
    handleAccessControlChange,
    handleValidationTypeChange,
    handleSSNChange,
    forceDisabled,
}: Props) => {
    const [validationType, setValidationType] = useState<ValidationType>(
        getRecipientValidationType(recipient)
    );
    const { data: customer } = useAppSelector(
        (state) => state.customer.details
    );

    const toggleValidationType = useCallback(
        (type: ValidationType) => {
            // Prepare/reset recipient data
            handleSSNChange('', type === 'sms' ? 'sms' : SSNs.LEGACY_SSN.id);
            handleChange('vatin', null);

            setValidationType(type);
        },
        [handleChange]
    );

    useEffect(() => {
        handleValidationTypeChange &&
            handleValidationTypeChange(validationType);
    }, [validationType]);

    const { allowedSigningMethods } = customer;

    return (
        <div className="recipient-restrictions pt-4 mt-4 border-solid border-0 border-t border-neutral-100">
            <label>
                {isRegisteredLetter
                    ? i18n`Restrict who can view the documents with`
                    : i18n`Restrict who can sign the documents`}
            </label>
            <div className="bg-neutral-100 p-4 px-6 mb-4">
                <div className="restrictions-selector">
                    <RadioButton
                        className="inline-block align-top pb0 mr-5"
                        disabled={forceDisabled === 'all'}
                        checked={validationType === 'none'}
                        onChange={(checked) =>
                            checked && toggleValidationType('none')
                        }
                        name={`selector-none`}
                        label={
                            isRegisteredLetter
                                ? i18n`Any eID`
                                : i18n`Not restricted`
                        }
                    />
                    &nbsp;&nbsp;
                    <RadioButton
                        className="inline-block align-top pb0 mr-5"
                        disabled={forceDisabled === 'all'}
                        checked={
                            validationType === 'ssn' ||
                            validationType === 'vatin'
                        }
                        onChange={(checked) =>
                            checked && toggleValidationType('ssn')
                        }
                        name={`selector-eid`}
                        label={
                            isRegisteredLetter
                                ? i18n`A specific eID`
                                : i18n`eID`
                        }
                    />
                    {allowedSigningMethods.includes(
                        CustomerSigningMethod.SMS
                    ) && (
                        <>
                            &nbsp;&nbsp;
                            <RadioButton
                                className="inline-block align-top pb0"
                                disabled={forceDisabled === 'all'}
                                checked={validationType === 'sms'}
                                onChange={(checked) =>
                                    checked && toggleValidationType('sms')
                                }
                                name={`document-type`}
                                label={i18n`SMS`}
                            />
                        </>
                    )}
                </div>
                {(validationType === 'ssn' || validationType === 'vatin') && (
                    <div className="restrictions-eid my-4">
                        <label>{i18n`Validate the signer's identity with`}</label>
                        <RadioButton
                            className="inline-block align-top"
                            disabled={forceDisabled === 'all'}
                            checked={validationType === 'ssn'}
                            onChange={(checked) =>
                                checked && toggleValidationType('ssn')
                            }
                            name={`document-type`}
                            label={i18n`Social Security Number`}
                        />
                        &nbsp;&nbsp;
                        <RadioButton
                            className="inline-block align-top"
                            disabled={forceDisabled === 'all'}
                            checked={validationType === 'vatin'}
                            onChange={(checked) =>
                                checked && toggleValidationType('vatin')
                            }
                            name={`document-type`}
                            label={i18n`VAT Identification Number/ CVR`}
                        />
                    </div>
                )}
                {validationType === 'ssn' && (
                    <InputValidation
                        triggers={aggregateSSN(
                            recipient.ssn,
                            recipient.ssnType
                        )}
                        rules={[
                            {
                                error: (recipient?.validation?.ssn ||
                                    recipient?.validation
                                        ?.ssnCountry) as SignerValidationRule,
                                test: () => !!recipient.ssn,
                            },
                        ]}>
                        <SsnInput
                            value={recipient.ssn}
                            ssnType={recipient.ssnType}
                            placeholder={i18n`Type SSN`}
                            onChange={({ value, ssnType }) =>
                                handleSSNChange(value, ssnType)
                            }
                            disabled={forceDisabled === 'all'}
                        />
                    </InputValidation>
                )}
                {validationType === 'vatin' && (
                    <div>
                        <TextInput
                            value={recipient.vatin}
                            className="match-parent"
                            placeholder={i18n`Type VATIN`}
                            onChange={(value) => handleChange('vatin', value)}
                            disabled={forceDisabled === 'all'}
                        />
                        <div
                            className="text-small"
                            style={{ marginTop: '4px' }}>
                            <img src={`${images}/flags/dk.gif`} />
                            &nbsp;
                            {i18n`Only available for Danish CVR numbers`}
                        </div>
                    </div>
                )}
                {validationType === 'sms' && (
                    <div className="my-4">
                        <SmsInput
                            value={recipient.ssn?.toString() ?? ''}
                            inputClass="match-parent"
                            placeholder={i18n`Type phone number`}
                            onChange={(value) => handleSSNChange(value, 'sms')}
                            disabled={forceDisabled === 'all'}
                        />
                    </div>
                )}
            </div>
            {!isRegisteredLetter && (
                <RecipientViewingRestrictionsEditor
                    sensitiveData={sensitiveData}
                    accessControl={recipient.accessControl}
                    validationType={validationType}
                    handleAccessControlChange={handleAccessControlChange}
                    forceDisabled={forceDisabled}
                />
            )}
        </div>
    );
};
