import React from 'react';
import PropTypes from 'prop-types';
import { i18n } from 'Language';
import { connect } from 'react-redux';
import { ReduxState, AppDispatch } from 'Store';
import { RegisteredLetterRecipient } from 'RegisteredLetter/types';
import TooltipRequired from 'Common/components/TooltipRequired';
import './registered-letter-recipient-list.scss';

import Recipients from 'Common/components/Recipients';
import { Router } from 'react-router';

type AppContextType = {
    router: Router;
};

type Props = {
    dispatch: AppDispatch;
    recipients: RegisteredLetterRecipient[];
    sensitiveData: boolean;
};

type State = {
    hasError: boolean;
};

class RegisteredLetterRecipientList extends React.Component<Props, State> {
    static contextTypes = {
        router: PropTypes.object,
    };

    context!: AppContextType;

    constructor(props: Props) {
        super(props);
        this.state = {
            hasError: false,
        };
    }

    render() {
        const { recipients } = this.props;

        return (
            <div className="regletter-recipient-list-container">
                <Recipients
                    header={
                        <h4 className="section-header recipients-title">
                            {i18n`Recipients`}
                            <TooltipRequired
                                text={i18n`At least one recipient is required`}
                            />
                        </h4>
                    }
                    recipients={recipients}
                    editRecipient={(index) => {
                        let { router } = this.context;

                        router?.push({
                            name: 'registered-letter-recipient-edit',
                            params: {
                                index,
                            },
                        });
                    }}
                    addRecipient={() => {
                        let { router } = this.context;

                        router?.push({
                            name: 'registered-letter-recipient-add',
                        });
                    }}
                    registeredLetter
                />
            </div>
        );
    }
}

export default connect((state: ReduxState) => ({
    recipients: state.registeredLetter.instance.recipients,
    sensitiveData: state.registeredLetter.instance.sensitiveData,
}))(RegisteredLetterRecipientList);
