import React, { useContext, useEffect, useState } from 'react';
import WorkflowDetailStore from '../../stores/WorkflowDetailStore';
import WorkflowDetailActions from '../../actions/WorkflowDetailActionCreators';
import { i18n } from 'Language';
import { Link } from 'react-router';
import { TrashIcon, PencilIcon } from '@heroicons/react/24/outline';
import WorkflowActionCreators from 'Forms/actions/WorkflowActionCreators';
import { ReactRouter } from 'types/Router';
import { ToastContext } from 'Common/components/ToastContext';
import WithRouter from '../WithRouterHOC';
import { CheckCircleIcon } from '@heroicons/react/20/solid';
import WorkflowStore from 'Forms/stores/WorkflowStore';
import DeleteTemplateModal from './DeleteWorkflowModal';
import { Button } from '@penneo/vega-ui-react';

interface WorkflowTemplateDetailsProps {
    params: {
        workflowId: string;
    };
    router: ReactRouter;
}

interface WorkflowData {
    title: string;
    stateMachine: string;
    userData: {
        initial: {
            form: {
                prototypeId: string;
            };
        };
    };
    createdAt: string;
    updatedAt: string;
}

function WorkflowTemplateDetails({
    params,
    router,
}: WorkflowTemplateDetailsProps) {
    const { addToast } =
        useContext(ToastContext) ??
        (() => {
            throw new Error('ToastContext not found');
        })();

    const [workflowTemplateDetails, setWorkflowTemplateDetails] =
        useState<WorkflowData | null>(null);
    const [isDeleting, setIsDeleting] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const { workflowId } = params;

    useEffect(() => {
        WorkflowDetailStore.addChangeListener(onDetailChange);

        loadData();

        return () => {
            WorkflowDetailStore.removeChangeListener(onDetailChange);
        };
    }, [workflowId]);

    useEffect(() => {
        const onDeleteSuccess = () => {
            setIsDeleting(false);
            setShowDeleteModal(false);

            router.push({
                name: 'form-templates',
            });

            addToast(
                undefined,
                i18n`toast.formTemplateDeleteSuccess`,
                'accent',
                10000,
                i18n`toast.cancelButton`,
                undefined,
                undefined,
                CheckCircleIcon
            );
        };
        const onDeleteError = () => {
            setIsDeleting(false);
            setShowDeleteModal(false);

            addToast(
                `${i18n('toast.formTemplateDeleteError')} ${i18n(
                    'toast.generalError.tryAgain'
                )}`,
                i18n`toast.generalError.title`,
                'danger',
                10000,
                i18n`toast.cancelButton`
            );
        };

        WorkflowStore.addEventListener(
            WorkflowStore.Events.WORKFLOW_DELETED,
            onDeleteSuccess
        );
        WorkflowStore.addEventListener(
            WorkflowStore.Events.WORKFLOW_DELETE_FAILED,
            onDeleteError
        );

        return () => {
            WorkflowStore.removeEventListener(
                WorkflowStore.Events.WORKFLOW_DELETED,
                onDeleteSuccess
            );
            WorkflowStore.removeEventListener(
                WorkflowStore.Events.WORKFLOW_DELETE_FAILED,
                onDeleteError
            );
        };
    }, [workflowId]);

    const loadData = () => {
        WorkflowDetailActions.loadById(workflowId);
    };

    const onDetailChange = () => {
        setWorkflowTemplateDetails(
            WorkflowDetailStore.getAll()[workflowId].workflow
        );
    };

    const onDeleteButton = () => {
        setShowDeleteModal(true);
    };

    const editFormTemplate = () => {
        const formId =
            workflowTemplateDetails?.userData.initial.form.prototypeId;

        formId &&
            router.push({
                name: 'form-editor',
                params: {
                    formId: formId,
                    workflowId: workflowId,
                },
            });
    };
    const onDeleteModalHide = () => {
        setShowDeleteModal(false);
    };

    const onDeleteModalSubmit = () => {
        setIsDeleting(true);
        WorkflowActionCreators.deleteWorkflow(params.workflowId);
    };

    return (
        <>
            <div className="white-container">
                <h3 className="title">{i18n('Workflow template details')}</h3>
                <div className="content">
                    <Link to="form-templates">
                        <i className="fa fa-list"></i>
                        &nbsp;{i18n('Go back to the list')}
                    </Link>

                    <div className="panel panel-default">
                        <div className="panel-body">
                            <ul>
                                <li>
                                    <h3>{i18n('Title:')}</h3>&nbsp;
                                    {workflowTemplateDetails?.title}
                                </li>
                                <li>
                                    <h3>{i18n('Type of workflow:')}</h3>
                                    &nbsp;
                                    {workflowTemplateDetails?.stateMachine ===
                                    'simple_form'
                                        ? i18n('Mass Send')
                                        : i18n('Public link')}
                                </li>
                                <li>
                                    <h3>{i18n('Form template ID:')}</h3>
                                    &nbsp;
                                    {
                                        workflowTemplateDetails?.userData
                                            .initial.form.prototypeId
                                    }
                                </li>
                                <li>
                                    <h3>{i18n('Created at:')}</h3>&nbsp;
                                    {workflowTemplateDetails?.createdAt}
                                </li>
                                <li>
                                    <h3>{i18n('Last update:')}</h3>&nbsp;
                                    {workflowTemplateDetails?.updatedAt}
                                </li>
                            </ul>
                            <br />
                            <br />
                            <div className="flex space-x-3 justify-end">
                                <Button
                                    priority="secondary"
                                    type="neutral"
                                    onClick={editFormTemplate}
                                    leftIcon={
                                        <PencilIcon className="w-5 h-5"></PencilIcon>
                                    }>
                                    {i18n('Edit')}
                                </Button>

                                <Button
                                    priority="secondary"
                                    type="danger"
                                    onClick={onDeleteButton}
                                    leftIcon={
                                        <TrashIcon className="w-5 h-5"></TrashIcon>
                                    }>
                                    {i18n('Delete')}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="text-neutral-900">
                {showDeleteModal && (
                    <DeleteTemplateModal
                        handleCloseModal={onDeleteModalHide}
                        submitDelete={onDeleteModalSubmit}
                        isDeleting={isDeleting}
                    />
                )}
            </div>
        </>
    );
}
export default WithRouter(WorkflowTemplateDetails);
