import React from 'react';
import SignerStore from 'Casefiles/stores/SignerStore';
import { SignerRole } from 'types/SignerRole';
import Card from 'Common/components/Card';
import CasefileSignerRolesList from 'Casefiles/components/casefiles2/CasefileSignerRolesList';
import { i18n } from 'Language';
import lodash from 'lodash';

import {
    RecipientSigner,
    RecipientCopy,
    RecipientSignerRole,
} from 'types/Recipient';
import { RegisteredLetterRecipient } from 'RegisteredLetter/types';
import './recipient-list-item.scss';
import { Flags } from 'types/LaunchDarkly';
import launchDarkly from 'Common/LaunchDarkly';
import { ColorLegendCircle } from 'Casefiles/components/casefiles2/utils';

import { connect } from 'react-redux';
import { ReduxState } from 'Store';
import type { NewCasefileState } from 'Casefiles/redux/newCaseFile/types';
import classNames from 'classnames';
import Button from 'Common/components/Button';
import Flag from 'Common/components/Flag';
import { LANGUAGES } from 'Language/Constants';

type RecipientProp =
    | RegisteredLetterRecipient
    | RecipientSigner
    | RecipientCopy;

type Props = {
    index: number;
    edit?: (
        index: number | null | undefined,
        recipient: RecipientProp
    ) => void | Function;
    recipient: RecipientProp;
    summary?: boolean;
    emailMessages: NewCasefileState['emailMessages'];
    registeredLetter?: boolean;
    casefileLanguage?: string;
};

class RecipientListItem extends React.Component<Props> {
    static defaultProps = {
        edit: () => {},
    };

    getRole = (roleId) => {
        let role = SignerStore.getSignerRole(roleId);

        if (role) {
            return role.role;
        }
    };

    getCustomMessage = (recipient: RecipientSigner) => {
        if (!launchDarkly.variation(Flags.SIGNER_SPECIFIC_EMAILS)) {
            return null;
        }

        if (!recipient.tempId) {
            return null;
        }

        const { emailMessages } = this.props;

        if (!emailMessages.custom.length) {
            return null;
        }

        const index = emailMessages.custom.findIndex((email) => {
            const test = email.recipients.find((emailRecipient) => {
                return emailRecipient.tempId === recipient?.tempId;
            });

            return test?.tempId === recipient?.tempId;
        });

        const isCustomMessage = index >= 0;

        const text = isCustomMessage
            ? `${i18n`Custom message`} ${index + 1}`
            : i18n`General message`;

        return <ColorLegendCircle index={index} text={text} />;
    };

    printRoles = () => {
        const { recipient } = this.props;
        const roles: RecipientSignerRole[] = (
            recipient as RecipientSigner
        ).role.map((role: any): RecipientSignerRole => {
            const { customName, activeAt } = role;
            const originalName = this.getRole(role.id);

            return {
                role: originalName,
                ...(customName && { customName }),
                ...(activeAt && { activeAt }),
            };
        });

        return (
            <CasefileSignerRolesList roles={roles as unknown as SignerRole[]} />
        );
    };

    printOnBehalfOf = () => {
        const { recipient } = this.props;
        const behalf = (recipient as RecipientSigner)?.onBehalfOf;

        return (
            behalf && (
                <div className="recipient-list-item-behalf">
                    {i18n`On behalf of`} {behalf}
                </div>
            )
        );
    };

    printAccess = (recipient: RecipientProp, accessControlEnabled: boolean) => {
        const { registeredLetter } = this.props;

        return (recipient as RecipientSigner).type === 'signer' ? (
            <span
                className={classNames('recipient-list-item-access', {
                    'text-small': registeredLetter,
                })}>
                {accessControlEnabled ? (
                    <span>
                        {i18n`Recipient validation on`}{' '}
                        <i className="far fa-lock text-blue" />
                    </span>
                ) : (
                    <span>
                        {i18n`Recipient validation off`}{' '}
                        <i className="far fa-lock-open text-blue" />
                    </span>
                )}
            </span>
        ) : null;
    };

    handleEdit = (e) => {
        e?.preventDefault();
        this.props.edit?.(this.props.index, this.props.recipient);
    };

    render() {
        let { recipient, registeredLetter } = this.props;

        const accessControlEnabled =
            (recipient as RecipientSigner).accessControl ||
            (recipient as RecipientSigner).ssn ||
            (recipient as RecipientSigner).vatin;

        const language = recipient.language || this.props.casefileLanguage;

        return (
            <Card theme="blue">
                <>
                    <div className="recipient-list-item-wrapper">
                        <div className="recipient-list-item-main">
                            <span className="recipient-list-item-name">
                                {recipient.name}
                            </span>
                            <span className="recipient-list-item-email">
                                <i className="far fa-envelope text-small" />
                                &nbsp;
                                {recipient.email}
                            </span>

                            {language && (
                                <div className="ml-1 inline-flex">
                                    <Flag
                                        src={
                                            LANGUAGES[language as string]
                                                .svgFlag
                                        }
                                        label={
                                            LANGUAGES[language as string].name
                                        }
                                    />
                                </div>
                            )}

                            {this.printOnBehalfOf()}

                            {registeredLetter &&
                                this.printAccess(
                                    recipient,
                                    !!accessControlEnabled
                                )}

                            {!registeredLetter &&
                                ((recipient as RecipientSigner).type ===
                                    'signer' ||
                                    !(
                                        recipient as
                                            | RecipientSigner
                                            | RecipientCopy
                                    ).type) &&
                                ((recipient as RecipientSigner)?.role?.length >
                                0
                                    ? this.printRoles()
                                    : lodash.has(recipient, 'role') && (
                                          <div
                                              style={{
                                                  fontSize: '90%',
                                                  marginTop: '0.25em',
                                              }}>
                                              <i className="fas fa-exclamation-triangle text-warning" />
                                              &nbsp;
                                              {i18n`You must select a role for this recipient`}
                                          </div>
                                      ))}
                        </div>
                        <span className="recipient-list-item-type text-small text-right">
                            {!registeredLetter &&
                                this.printAccess(
                                    recipient,
                                    !!accessControlEnabled
                                )}

                            {(recipient as RecipientCopy).type ===
                                'copyrecipient' && (
                                <span>
                                    {i18n`Receives a copy of signed documents`}
                                    &nbsp;
                                    <i className="far fa-copy text-blue" />
                                </span>
                            )}
                            {this.getCustomMessage(
                                recipient as RecipientSigner
                            )}

                            {this.props.edit && (
                                <span>
                                    <Button
                                        variant={
                                            registeredLetter ? 'solid' : 'text'
                                        }
                                        theme="blue"
                                        onClick={
                                            this.handleEdit
                                        }>{i18n`Edit`}</Button>
                                </span>
                            )}
                        </span>
                    </div>
                </>
            </Card>
        );
    }
}

export default connect((state: ReduxState) => ({
    emailMessages: state.newCasefile.emailMessages,
}))(RecipientListItem);
