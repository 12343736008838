import { Variant } from '@penneo/vega-ui/dist/components/_common/types';
import { UtilityState } from 'DocumentServices/Common/Types';
import { i18n } from 'Language';

export const unixTimeToUTC = (signTime: number): string => {
    const date = new Date(signTime * 1000);
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const day = String(date.getUTCDate()).padStart(2, '0');
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const seconds = String(date.getUTCSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds} UTC`;
};

export const validationTypeText = (type: string): string => {
    switch (type) {
        case 'ssn':
            return i18n`validatorWasSSNValidated`;
        case 'sms':
            return i18n`validatorWasSMSValidated`;
        case 'vatin':
            return i18n`validatorWasVATINValidated`;
        default:
            return '';
    }
};

export const signatureTypeName = (type: string) => {
    const map = {
        nemid: i18n`Danish NemID`,
        bankid_se: i18n`Swedish BankID`,
        bankid_no: i18n`Norwegian BankID`,
        penneo: 'Penneo OpenID',
        penneo_v2: 'Penneo QES',
        'qes.itsme.be': 'itsme® Sign (QES)',
    };

    return map[type] || 'undetermined';
};

export const resolveProperties = (utilityState: UtilityState) => {
    let description: string | undefined = undefined;
    let hasError = false;
    let variant: Variant = 'accent';
    let icon = 'pdf.svg',
        inlineDescription = '';
    let showInlineMessage = false;
    let inlineTitle = '';

    if (utilityState === UtilityState.UPLOADING) {
        description = 'Uploading...';
    } else if (
        utilityState === UtilityState.UPLOAD_ERROR ||
        utilityState === UtilityState.NOT_PENNEO_DOCUMENT
    ) {
        hasError = true;
        description = i18n`validatorInvalid`;
        inlineTitle = i18n`validatorNotPenneoDocumentTitle`;
        variant = 'danger';
        icon = 'pdf-error.svg';
        inlineDescription = i18n`validatorNotPenneoDocument`;
        showInlineMessage = true;
    } else if (utilityState === UtilityState.UPLOAD_SUCCESS) {
        variant = 'success';
        inlineDescription = i18n`validatorValid`;
        showInlineMessage = false;
        description = i18n`validatorValid`;
        variant = 'success';
    } else if (utilityState === UtilityState.VALIDATION_ERROR) {
        inlineDescription = i18n`validatorInvalid`;
        hasError = true;
        showInlineMessage = false;
        description = i18n`validatorInvalid`;
        variant = 'danger';
    }

    return {
        description,
        inlineDescription,
        inlineTitle,
        variant,
        icon,
        showInlineMessage,
        hasError,
    };
};
