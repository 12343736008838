import lodash from 'lodash';
import React, { PropsWithChildren } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'react-router';
import { i18n } from 'Language';
import { TabbedRoute } from 'types/TabbedRoute';
import { Router } from 'react-router';

type AppContextType = {
    router: Router;
};

export type Props = PropsWithChildren<{
    /**
     * The react router routes from which the tabs list will be built
     */
    routes: TabbedRoute[];
}>;

/**
 * Tabs component based on react-router v3
 *
 * **Please note**: It is necessary to clone the component being rendered by the
 * current route (`this.props.children`) via `React.cloneElement()` before
 * passing it as a child, see @example
 *
 * @example
 *   const routes = [
 *     // ...
 *   ];
 *
 *   <RouterV3Tabs routes={route}>
 *     {React.cloneElement(this.props.children)}
 *   </RouterV3Tabs>
 */
class RouterV3Tabs extends React.Component<Props> {
    static contextTypes = {
        router: PropTypes.object,
    };

    context!: AppContextType;

    renderNavItem = (tabbedRoute: TabbedRoute, index: number): JSX.Element => {
        const { tab, ...route } = tabbedRoute;
        const { title, onClick } = tab;
        const isActive = this.isRouteActive(route);

        return (
            <li key={index}>
                <Link
                    to={route}
                    onClick={onClick}
                    className={classnames({ active: isActive })}>
                    {typeof title === 'string' ? i18n(title) : title}
                </Link>
            </li>
        );
    };

    getActiveRoute(): TabbedRoute | undefined {
        const { routes } = this.props;
        const { router } = this.context;

        /**
         * We check if any of the tabs is forced to be active.
         * If not, we continue checking for normally active routes.
         */

        const forcedActive = routes.find((route) => route?.params?.forceActive);

        if (forcedActive) {
            return forcedActive;
        }

        const activeRoutes = routes.filter((route) => router.isActive(route));

        // Return most nested matched route.
        return lodash.last(activeRoutes);
    }

    isRouteActive(route: Omit<TabbedRoute, 'tab'>): boolean {
        const activeRoute = this.getActiveRoute();

        if (!activeRoute) {
            return false;
        }

        return lodash.isEqual(
            {
                name: route.name,
                params: route.params,
            },
            {
                name: activeRoute.name,
                params: activeRoute.params,
            }
        );
    }

    render() {
        const { routes, children } = this.props;
        const activeRoute = this.getActiveRoute();

        return (
            <div className="tabs-layout">
                <div className="tab-header">
                    <ul>{routes.map(this.renderNavItem)}</ul>
                </div>
                <div
                    className={classnames('tab-layout-content', {
                        'no-padding':
                            activeRoute && !!activeRoute.tab.noPadding,
                    })}>
                    {children}
                </div>
            </div>
        );
    }
}

export default RouterV3Tabs;
