import launchDarkly, { Flags } from 'Common/LaunchDarkly';

/**
 * Method to detect the timestamp provider to use during the signing process.
 */
export const getTimestampProvider = (): 'penneo' | 'intesi' => {
    return launchDarkly.variation(Flags.USE_PENNEO_TIMESTAMP)
        ? 'penneo'
        : 'intesi';
};

export const isPenneoTimestampProvider = (): boolean => {
    return launchDarkly.variation(Flags.USE_PENNEO_TIMESTAMP);
};
