import { Dispatcher } from 'Core';
import { SigningAPI } from 'Api';
import Constants from '../Constants';
import SignerStore from '../stores/SignerStore';
import CasefileStore from '../stores/CasefileStore';
import lodash from 'lodash';
import uniqid from 'uniqid';
import { SignerRole } from 'types/SignerRole';
import { SSNs } from 'Constants';

const SignerActionCreators = {
    // *************************************************************************
    // GET REQUESTS
    // *************************************************************************
    fetchSignerSSN(casefileId, signerId) {
        return SigningAPI.get(`/casefiles/${casefileId}/signers/${signerId}`)
            .then((signer) => {
                return signer.socialSecurityNumberPlain;
            })
            .catch((error) => {
                console.log("Failed to fetch signer's ssn: ", error);
            });
    },

    /**
     * Gets the roles of the given signer of the given case file
     *
     * The difference between the two possible types of the return value
     * is due to the fact that this action is used both by the desktop app and
     * the new web app, and thus it needs to be backward-compatible with the former
     *
     * @param {number} casefileId
     * @param {number} signerId
     * @param {boolean} [includeCustomRoleName]
     * @return {Promise} depending on `includeCustomRoleName`, it resolves to
     *   either an array of the roles ids, or to an array of objects containing
     *   both the id and the custom name (if any) of the role
     */
    fetchSignerRoles(casefileId, signerId, includeCustomRoleName = false) {
        return SigningAPI.get(
            `/casefiles/${casefileId}/signers/${signerId}/signertypes`
        )
            .then((roles) =>
                roles.map((role) =>
                    includeCustomRoleName
                        ? {
                              id: role.signerTypeId,
                              customName: role.role,
                              activeAt: role?.activeAt,
                          }
                        : role.signerTypeId
                )
            )
            .catch((error) => {
                console.log("Failed to fetch signer's roles: ", error);
            });
    },

    fetchSignerEventLog(casefileId, signerId) {
        return SigningAPI.get(
            `/casefiles/${casefileId}/signers/${signerId}/log`
        ).catch((error) => {
            console.log("Failed to fetch signer's event log: ", error);
        });
    },

    // *************************************************************************
    // DELETE REQUESTS
    // *************************************************************************
    deleteSigner(casefileId, signerId) {
        return SigningAPI.delete(
            `/casefiles/${casefileId}/signers/${signerId}`
        );
    },

    // *************************************************************************
    // View Actions
    // *************************************************************************
    updateSignerRules() {
        Dispatcher.handleViewAction({
            type: Constants.ActionTypes.SIGNER_RULES_UPDATED,
        });
    },

    updateSigners(signers) {
        Dispatcher.handleViewAction({
            type: Constants.ActionTypes.SIGNERS_CHANGED,
            signers: signers,
        });
    },

    updateSigner(index, data) {
        Dispatcher.handleViewAction({
            type: Constants.ActionTypes.SIGNER_CHANGED,
            index: index,
            data: data,
        });
    },

    async clearSigners() {
        Dispatcher.handleViewAction({
            type: Constants.ActionTypes.SIGNERS_CLEARED,
        });
    },

    addSigner(signer = {}) {
        Dispatcher.handleViewAction({
            type: Constants.ActionTypes.SIGNER_ADDED,
            data: signer,
        });
    },

    removeSigner(index, force = false) {
        Dispatcher.handleViewAction({
            type: Constants.ActionTypes.SIGNER_REMOVED,
            index: index,
            force: force,
        });
    },

    updateSignerRole(index, payload) {
        Dispatcher.handleViewAction({
            type: Constants.ActionTypes.UPDATED_SIGNER_ROLE,
            index: index,
            role: payload,
        });
    },

    updateSignerRoles(signerRoles: SignerRole[] = []) {
        Dispatcher.handleViewAction({
            type: Constants.ActionTypes.SIGNER_ROLES_UPDATED,
            roles: signerRoles,
        });
    },

    resetSignerRoles() {
        Dispatcher.handleViewAction({
            type: Constants.ActionTypes.SIGNER_ROLES_RESET,
        });
    },

    async clearStore() {
        Dispatcher.handleViewAction({
            type: Constants.ActionTypes.CLEAR_SIGNER_STORE,
        });
    },

    removeCopyRecipientsV2() {
        Dispatcher.handleViewAction({
            type: Constants.ActionTypes.REMOVE_COPY_RECIPIENTS_V2,
        });
    },

    setDefaultReminderInterval(reminderInterval) {
        Dispatcher.handleViewAction({
            type: Constants.ActionTypes.REMINDER_INTERVAL_SET,
            reminderInterval: reminderInterval,
        });
    },

    // *************************************************************************
    // URI loaded signers data
    // *************************************************************************
    async URIUpdateSignerStore(signers) {
        let sensitiveData = CasefileStore.getSensitiveDataFlag();
        let _signers = [];

        if (!signers || signers.length === 0) {
            this.updateSigners(_signers);

            return;
        }

        _signers = signers.map((signer) => {
            let roles = [];
            let isPrivate = sensitiveData ? sensitiveData : signer.isPrivate;

            if (signer.roles) {
                roles = signer.roles.map((role) => {
                    // If the role contains an ID, it's a role coming from the JSON Schema V3.0.0 and needs to be
                    // handled differently. This is to support custom role names incoming from integrations.
                    // @see: https://penneo.atlassian.net/browse/PN-727
                    // @todo: this function should take a 'symmetric' payload no matter what URI version is used
                    if (role.id) {
                        return {
                            id: role.id,
                            name: role.customName,
                            enabled: true,
                        };
                    }

                    return {
                        id: role,
                        enabled: true,
                    };
                });
            }

            let template = SignerStore.getSignerTemplate();

            let newSigner: any = {
                name: signer.name,
                email: signer.email,
                onBehalfOf: signer.onBehalfOf,
                ssn: signer.ssn,
                ssnType: signer.ssnType ?? SSNs.LEGACY_SSN.id,
                vatin: signer.vatin || signer.vatid,
                enableInsecureSigning: signer.enableInsecureSigning,
                accessControl: isPrivate ?? template.accessControl,
                reminderInterval: isNumeric(signer.reminderInterval)
                    ? signer.reminderInterval
                    : template.reminderInterval,
                role: roles,
                eventLog: signer.eventLog && signer.eventLog,
                type: signer.type,
                status: signer.status,
                tempId: signer.tempId ?? uniqid(),
            };

            if (signer.id) {
                newSigner.id = signer.id;
            }

            // Remove undefined values
            return lodash.omitBy(newSigner, lodash.isUndefined);
        });

        this.updateSigners(_signers);
    },

    populateStore(signers) {
        let sensitiveData = CasefileStore.getSensitiveDataFlag();
        let _signers = [];

        if (!signers || signers.length === 0) {
            this.updateSigners(_signers);

            return;
        }

        _signers = signers.map((signer) => {
            let roles: any[] = [];
            let isPrivate = sensitiveData
                ? sensitiveData
                : signer.accessControl;

            if (signer.roles) {
                roles = signer.roles.map((_role_) => {
                    let role: any = {
                        enabled: true,
                    };

                    // Each item in `signer.roles` can be either an
                    // integer or an object
                    // @see `fetchSignerRoles()`
                    if (lodash.isObject(_role_)) {
                        const { id, customName, activeAt } = _role_;

                        role = {
                            ...role,
                            id,
                            ...(customName && { customName }),
                            ...(activeAt && { activeAt }),
                        };
                    } else {
                        role.id = _role_;
                    }

                    return role;
                });
            }

            let template = SignerStore.getSignerTemplate();

            let newSigner: any = {
                name: signer.name,
                email: signer.email,
                onBehalfOf: signer.onBehalfOf,
                ssn: signer.ssn,
                ssnType: signer.ssnType ?? SSNs.LEGACY_SSN.id,
                vatin: signer.vatin || signer.vatid,
                enableInsecureSigning: signer.enableInsecureSigning,
                insecureSigningMethods: signer.insecureSigningMethods,
                accessControl: isPrivate ?? template.accessControl,
                reminderInterval: isNumeric(signer.reminderInterval)
                    ? signer.reminderInterval
                    : template.reminderInterval,
                role: roles,
                eventLog: signer.eventLog && signer.eventLog,
                type: signer.type,
                status: signer.status,
                storeAsContact: signer.storeAsContact,
                tempId: signer.tempId,
                signingRequestId: signer.signingRequestId,
                language: signer.language,
            };

            if (signer.id) {
                newSigner.id = signer.id;
            }

            // Remove undefined values
            return lodash.omitBy(newSigner, lodash.isUndefined);
        });

        this.updateSigners(_signers);
    },
};

function isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
}

export default SignerActionCreators;
