import PropTypes from 'prop-types';
import React from 'react';
import TooltipRequired from 'Common/components/TooltipRequired';
import classnames from 'classnames';
import { InformationCircleIcon } from '@heroicons/react/20/solid';

export default class TextInput extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        onChange: PropTypes.func,
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        name: PropTypes.string,
        inline: PropTypes.bool,
        required: PropTypes.bool,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        disabled: PropTypes.bool,
        error: PropTypes.string,
    };

    static defaultProps = {
        value: '',
        error: '',
    };

    handleChange = (event) => {
        event.preventDefault();

        const { value } = event.target;

        this.props.onChange(value, event);
    };

    render() {
        // HTML Properties
        let { value, name, required, inline, error } = this.props;

        // Data
        let { label } = this.props;

        const className = classnames('input', this.props.className, {
            'inline-block': inline,
        });

        return (
            <div className={className}>
                {label && (
                    <label>
                        {label}
                        {required && <TooltipRequired />}
                    </label>
                )}

                <input
                    type="text"
                    {...this.props}
                    onChange={this.handleChange}
                    name={name}
                    value={value || ''}
                    required={required}
                    aria-invalid={!!error}
                    className={
                        error
                            ? 'border-danger-700 hover:border-secondary-500 focus:border-secondary-500'
                            : ''
                    }
                />

                {error && (
                    <span className="text-xs text-danger-700 inline-flex gap-x-2.5 px-2.5 py-1">
                        <InformationCircleIcon className="h-4 w-4 inline-block flex-shrink-0"></InformationCircleIcon>
                        <span>{error}</span>
                    </span>
                )}
            </div>
        );
    }
}
