import React, { useEffect, useMemo, useState } from 'react';
import { i18n } from 'Language';
import { modal } from 'Common/components/Common/Modal';
import Button from 'Common/components/Button';
import { Checkbox } from 'Common/components';

import './recipient-suggestion-modal.scss';

import { Client, Recipient } from 'AuditingAccounting/types';

type Props = {
    client: Client;
    onSubmit: (selectedRecipients: Recipient[]) => void;
};

const RecipientSuggestionModal: React.FC<Props> = ({ client, onSubmit }) => {
    const [selectedRecipients, setSelectedRecipients] = useState<Recipient[]>(
        []
    );
    const [isAllSelected, setIsAllSelected] = useState(false);

    useEffect(() => {
        setIsAllSelected(selectedRecipients.length === client.persons.length);
    }, [client, selectedRecipients]);

    const selectRecipientToggle = (recipient: Recipient) => {
        if (isRecipientSelected(recipient)) {
            const newSelectedRecipients = selectedRecipients.filter(
                (selectedRecipient) => selectedRecipient.name !== recipient.name
            );

            setSelectedRecipients(newSelectedRecipients);
        } else {
            const newSelectedRecipients = [...selectedRecipients];

            newSelectedRecipients.push(recipient);

            setSelectedRecipients(newSelectedRecipients);
        }
    };

    const isRecipientSelected = useMemo(
        () =>
            (recipient: Recipient): boolean => {
                return selectedRecipients.some(
                    (selectedRecipient) =>
                        selectedRecipient.name === recipient.name
                );
            },
        [selectedRecipients]
    );

    const allRecipientsToggle = () => {
        isAllSelected
            ? setSelectedRecipients([])
            : setSelectedRecipients(client.persons);

        setIsAllSelected(!isAllSelected);
    };

    const handleSubmit = () => {
        onSubmit(selectedRecipients);
        modal.hide();
    };

    const renderRecipientRoles = (recipient: Recipient): string => {
        return recipient.roles.map((role) => role.toLowerCase()).join(', ');
    };

    return (
        <div className="casefile-modal-v2 recipient-suggestion-modal">
            <h3 className="casefile-modal-v2-title">
                <div className="close far fa-times" onClick={modal.hide} />
            </h3>
            <h1>{client.name}</h1>
            {client.signatoryText ? (
                <>
                    <h4>{i18n`Signatory text`}</h4>
                    <i>"{client.signatoryText}"</i>
                </>
            ) : (
                i18n`Select recipients you want to use and complete information about them in the next step.`
            )}
            <div className="recipient-container">
                <div className="recipient-header">
                    <Checkbox
                        className="select-all-checkbox"
                        checked={isAllSelected}
                        onChange={() => allRecipientsToggle()}
                    />
                    <div className="recipient-header-name">{i18n`Name`}</div>
                    <div className="recipient-header-roles">{i18n`Roles in the company`}</div>
                </div>

                <div className="recipient-list">
                    {client.persons.map((recipient, index) => (
                        <div
                            className="recipient"
                            key={index}
                            // onClick={() => selectRecipientToggle(recipient)}
                        >
                            <Checkbox
                                className="recipient-checkbox"
                                checked={isRecipientSelected(recipient)}
                                onChange={() =>
                                    selectRecipientToggle(recipient)
                                }
                            />
                            <div className="recipient-name">
                                {recipient.name}
                            </div>
                            <div className="recipient-roles">
                                {renderRecipientRoles(recipient)}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="footer form-v2">
                <div className="footer-content pull-right">
                    <Button
                        theme="blue"
                        variant="outline"
                        onClick={modal.hide}
                        className="mr">
                        {i18n`Cancel`}
                    </Button>
                    <Button
                        theme="green"
                        variant="solid"
                        disabled={selectedRecipients.length <= 0}
                        onClick={handleSubmit}>
                        {i18n`Select`}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default RecipientSuggestionModal;
