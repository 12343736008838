import PropTypes from 'prop-types';
import React from 'react';
import { i18n } from 'Language';
import { cloneDeep, uniqBy } from 'lodash';

import { notify } from 'react-notify-toast';

import CustomerStore from '../../Auth/stores/CustomerStore';
import TeamStore from '../stores/TeamStore';
import TeamActions from '../actions/TeamActionCreators';

import Team from './Team';
import Loader from 'Common/components/Common/Loader';
import Widget from 'Common/components/Widget';
import WidgetHeader from 'Common/components/WidgetHeader';
import WidgetBody from 'Common/components/WidgetBody';
import Button from 'Common/components/Button';
import { Helmet } from 'react-helmet';

export default class TeamManagement extends React.Component {
    static contextTypes = {
        router: PropTypes.object.isRequired,
    };

    static propTypes = {
        children: PropTypes.object,
        params: PropTypes.object,
    };

    state = {
        customer: '',
        teams: [],
        availableUsers: [],
        loading: false,
        error: null,
        query: '',
    };

    componentDidMount = () => {
        TeamStore.addChangeListener(this.onChange);
        this.loadData();
    };

    componentWillUnmount = () => {
        TeamStore.removeChangeListener(this.onChange);
    };

    loadData = () => {
        TeamActions.fetchTeams();
    };

    onChange = () => {
        let { customerId } = this.props.params;

        this.setState({
            customer: CustomerStore.getCustomer(customerId),
            teams: TeamStore.getTeams(),
            availableUsers: TeamStore.getAvailableUsers(),
            loading: TeamStore.isFetching(),
            error: TeamStore.getError(),
        });
    };

    // Edit team button callback
    onEditTeam = (team) => {
        let { router } = this.context;
        let { customerId } = this.props.params;

        router.push({
            name: 'company-team-details',
            params: {
                customerId: customerId,
                teamId: team.id,
            },
        });
    };

    // Create team button callback
    onCreateTeam = () => {
        let { router } = this.context;
        let { customerId } = this.props.params;

        router.push({
            name: 'company-create-team',
            params: {
                customerId: customerId,
            },
        });
    };

    filterTeams = () => {
        let { query } = this.state;
        let teams = cloneDeep(this.state.teams);

        query = query.trim().toLowerCase();

        return teams.filter((team) => {
            return (
                (team.name && team.name.toLowerCase().indexOf(query) > -1) ||
                (team.description &&
                    team.description.toLowerCase().indexOf(query) > -1)
            );
        });
    };

    formatTeams = (filteredTeams) => {
        let { teams } = this.state;

        if (this.state.query !== '') {
            filteredTeams.forEach((team) => (team.filtered = true));
        }

        // Sort teams to parent teams and child teams
        let parentTeams = filteredTeams.filter((team) => !team.parentId);
        let childTeams = filteredTeams.filter((team) => team.parentId);

        // Find parent teams of the child teams even if they were not in the filter results
        childTeams.forEach((team) =>
            parentTeams.push(teams.find((obj) => obj.id === team.parentId))
        );
        // Dedupe parent teams
        parentTeams = uniqBy(parentTeams, 'id');

        // Map child teams to matching parent teams
        parentTeams.forEach((team) => {
            team.children = [];
            childTeams.forEach(
                (child) =>
                    team.id === child.parentId && team.children.push(child)
            );
        });

        return parentTeams;
    };

    searchChange = (event) => {
        this.setState({
            query: event.target.value,
        });
    };

    render() {
        let { customer, query, loading, error } = this.state;
        let filteredTeams = this.filterTeams();
        let formatedTeams = this.formatTeams(filteredTeams);

        let noTeams = filteredTeams.length === 0 && query === '' && !loading;
        let noSearchResults =
            filteredTeams.length === 0 && !loading && query !== '';

        if (error) {
            notify.show(error, 'error');
        }

        return (
            <div className="team-management">
                <Widget size="medium">
                    <Helmet>
                        <title>{i18n('Teams')}</title>
                    </Helmet>
                    <WidgetHeader>
                        <h1>
                            {i18n`Teams`}
                            <span className="dot">.</span>
                        </h1>
                        <p>
                            {i18n`Here you can administer all of the teams
                            in the ${customer.name} organization.`}
                        </p>
                    </WidgetHeader>
                    <WidgetBody>
                        <div className="team-management-header">
                            <span className="search-bar">
                                <input
                                    type="text"
                                    value={query}
                                    style={{ width: 200 }}
                                    onChange={this.searchChange}
                                    placeholder={i18n('Type to filter')}
                                />
                            </span>
                            <span className="team-management-header-counter">
                                {filteredTeams.length}&nbsp;
                                {filteredTeams.length === 1
                                    ? i18n`Result`
                                    : i18n`Results`}
                            </span>

                            <Button
                                theme="blue"
                                variant="outline"
                                className="pull-right"
                                icon="far fa-plus-circle"
                                onClick={this.onCreateTeam}
                                renderIconLeft={true}>
                                {i18n`New team`}
                            </Button>
                        </div>

                        <div>
                            {loading ? (
                                <Loader />
                            ) : (
                                formatedTeams.map((team, key) => (
                                    <Team
                                        key={key}
                                        team={team}
                                        editButtonVisible={true}
                                        onEditButton={this.onEditTeam}
                                    />
                                ))
                            )}

                            {noSearchResults && (
                                <h2>{i18n`Could not find any results for "${query}" entry.`}</h2>
                            )}

                            {noTeams && <h2>{i18n`There are no teams.`}</h2>}
                        </div>
                    </WidgetBody>
                </Widget>
            </div>
        );
    }
}
