import React from 'react';
import CasefileStore from '../../stores/CasefileStore';
import { modal } from 'Common/components/Common/Modal';
import FolderSelectorModal from './FolderSelectorModal';
import CasefileActions from 'Casefiles/actions/CasefilesActionCreators';
import { i18n } from 'Language';
import Button from 'Common/components/Button';
import Analytics from 'Common/Analytics';
import { SimpleFolderEntity } from 'types/Folder';

type Props = {
    folders: SimpleFolderEntity[];
    selectedFolder: number;
    action: string;
    handleFolderChange: (folderId: number) => void;
};

type State = {
    title: null | string;
};

export default class CasefileFolderSelector extends React.Component<
    Props,
    State
> {
    static defaultProps = {
        folders: [],
    };

    state: State = {
        title: null,
    };

    getActiveFolder = () => {
        let { selectedFolder } = this.props;

        return CasefileStore.getFolder(selectedFolder);
    };

    handleCreateFolder = () => {
        let { title } = this.state;

        if (!title) {
            return false;
        }

        CasefileActions.createFolder(title);
    };

    handleChange = (event) => {
        let { value } = event.target;

        this.setState({ title: value });
    };

    openFolderSelector = () => {
        let config = {
            component: (
                <FolderSelectorModal
                    folders={this.props.folders}
                    handleFolderChange={this.props.handleFolderChange}
                    active={this.getActiveFolder()}
                />
            ),
            preventClose: false,
        };

        modal.show(config);

        Analytics.track('casefile - open folder selection modal');
    };

    render() {
        let activeFolder = this.getActiveFolder();
        let loading = this.props.folders.length === 0 || !activeFolder;

        return (
            <div
                onClick={this.openFolderSelector}
                className="casefile-folder-selector-bar">
                <span className="folder-title">
                    {loading ? (
                        <span className="text-medium">
                            <i className="far fa-sync fa-spin"></i>&nbsp;
                            <span>{i18n`Loading...`}</span>
                        </span>
                    ) : (
                        <span>
                            <i className="far fa-folder"></i>&nbsp;
                            <span>{activeFolder.title}</span>
                        </span>
                    )}
                </span>

                <Button
                    theme="blue"
                    variant="outline"
                    className="pull-right"
                    disabled={loading}>
                    {i18n`Change`}
                </Button>
            </div>
        );
    }
}
