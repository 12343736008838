import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import { AuthStore } from 'Auth';
import {
    requestContactList,
    requestShareCandidatesList,
    requestUserList,
} from 'Casefiles/redux/contacts/actions';
import { denormalize } from 'Casefiles/redux/contacts/utils';
import { ReduxState } from 'Store';
import Autocomplete, {
    Props as AutocompleteProps,
} from 'Common/components/Autocomplete';
import { Props as AutoCompleteProps } from 'Common/components/Autocomplete/Autocomplete';
import { getUserAccess } from 'Casefiles/utils';

type Suggestions = 'all' | 'users';

export type Props = Omit<
    AutocompleteProps,
    'data' | 'renderItem' | 'filterAutocomplete'
> &
    ({
        /**
         * Callback to execute once the contacts have been fetched
         */
        onContactsFetched?: Function;
        /**
         * Filter which type of contacts should be included.
         * TODO: Add option to only include external contacts
         */
        inputDisabled?: boolean;
        onBlurHandler?: (inputValue: AutoCompleteProps['value']) => void;
    } & (
        | {
              filterSuggestions?: Suggestions;
          }
        | {
              filterSuggestions: 'share-candidates';
              folderId: number;
          }
    ) & {
            errorInputValidation?: string;
        });

export const CHAR_THRESHOLD = 2;
export const DEBOUNCE_DELAY = 250;
export const RESULTS_LIMIT = 5;

/**
 * A contact lookup that asynchronously fetches, via redux, a list
 * of contacts filtered by what the user has typed. The prop 'filterSuggestions'
 * can be used to exclude external contacts.
 *
 * It accepts all the props of `<Autocomplete />` except for
 * `data`, `renderItem`, and `filterAutocomplete`
 *
 * @example
 * <AutocompleteContacts
 *     label="A contact lookup"
 *     value={aContactEmail}
 *     onChange={handleChange}
 *     onSelect={handleSelect}
 *     onContactsFetched={handleContactsFetched}
 * />
 */
const AutocompleteContacts: React.FunctionComponent<Props> = (props) => {
    const {
        onChange,
        onContactsFetched,
        filterSuggestions = 'all',
        onBlurHandler,
        errorInputValidation,
        ...restOfProps
    } = props;

    const dispatch = useDispatch();
    let contacts = useSelector((state: ReduxState) =>
        denormalize(state.contactPicker.byIds)
    );

    useEffect(() => {
        return () => {
            contacts = [];
        };
    }, []);

    const updateContactList = useCallback(
        async (value: string) => {
            if (value.length >= CHAR_THRESHOLD) {
                const [customerId] = AuthStore.getUser().customerIds;

                if (filterSuggestions === 'share-candidates') {
                    const { folderId } = restOfProps as Props & {
                        folderId: number;
                    };

                    await dispatch(
                        requestShareCandidatesList(
                            folderId,
                            value,
                            RESULTS_LIMIT
                        )
                    );

                    if (onContactsFetched) {
                        await onContactsFetched();
                    }

                    return;
                }

                if (filterSuggestions === 'users') {
                    console.log(
                        'updateContactList  >filterSuggestions === users clause'
                    );
                    await dispatch(
                        requestUserList({
                            customerId,
                            searchPhrase: value.trim(),
                            resultsLimit: RESULTS_LIMIT,
                        })
                    );

                    if (onContactsFetched) {
                        await onContactsFetched();
                    }

                    return;
                }

                await dispatch(
                    requestContactList(customerId, value, RESULTS_LIMIT)
                );
            }

            if (onContactsFetched) {
                await onContactsFetched();
            }
        },
        [dispatch, onContactsFetched, filterSuggestions]
    );

    const debouncedUpdateContactList = useMemo(
        () => debounce(updateContactList, DEBOUNCE_DELAY),
        [updateContactList]
    );

    const onChangeWrapper = (value: string) => {
        onChange(value);
        const { isSignersArchive } = getUserAccess();

        if (!isSignersArchive) {
            debouncedUpdateContactList(value);
        }
    };

    const handleRenderAutocompleteItem = ({
        name,
        fullName,
        email,
        onBehalfOf,
    }) => (
        <div data-testid={email}>
            <div className="name">{name || fullName}</div>
            {onBehalfOf && (
                <span
                    style={{
                        display: 'block',
                    }}>
                    <i className="far fa-briefcase text-small" /> {onBehalfOf}
                </span>
            )}
            <i className="far fa-envelope text-small" />{' '}
            <strong>{email}</strong>
        </div>
    );

    const handleAutocompleteFilter =
        (text: string) =>
        ({ name, fullName, email, onBehalfOf }) => {
            return (
                (name || fullName || '').toLowerCase().includes(text) ||
                email.toLowerCase().includes(text) ||
                (onBehalfOf || '').toLowerCase().includes(text)
            );
        };

    return (
        <Autocomplete
            onBlurHandler={onBlurHandler}
            onChange={onChangeWrapper}
            renderItem={handleRenderAutocompleteItem}
            filterAutocomplete={handleAutocompleteFilter}
            data={contacts}
            errorInputValidation={errorInputValidation}
            {...restOfProps}
        />
    );
};

export default AutocompleteContacts;
