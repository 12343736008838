import React, { useState, useEffect } from 'react';
import { notify } from 'react-notify-toast';

// Common components / API
import Loader from 'Common/components/Common/Loader';
import { i18n, TranslationActions } from 'Language';
import type { Languages } from 'Language/Constants';

import { NewSignerAPI } from 'Api/ApiClient';
import { setSignerLanguage } from './utils';

// Finalized / Signing components
import SigningFail from './AfterSigning/SigningFail';
import { SigningHeader } from './SigningHeader';
import { SignFinalizedFooter } from './SignFinalizedFooter';

import { SigningDocument } from 'types/SigningProcess';
import SigningCasefileDocumentPreview from './SigningCasefileDocumentPreview';

export type Props = {
    params: {
        challengeKey: string;
    };
};

const SignFinalized = ({ params: { challengeKey } }: Props) => {
    const [documentDataFetching, setDocumentDataFetching] =
        useState<boolean>(false);
    const [documentData, setDocumentData] = useState<SigningDocument[] | null>(
        null
    );
    const [error, setError] = useState(false);
    const [currentDocId, setCurrentDocId] = useState<number | null>(null);

    const [_, setUserSelectedLanguage] = useState(''); // eslint-disable-line

    async function changeLanguage(languageCode: Languages) {
        await TranslationActions.changeLanguageView(languageCode);

        setSignerLanguage(languageCode);

        setUserSelectedLanguage(languageCode);
    }

    const changeDocumentId = (id: number) => {
        setCurrentDocId(id);
    };

    useEffect(() => {
        if (error) {
            return;
        }

        if (!documentData && !documentDataFetching) {
            setDocumentDataFetching(true);
            (async () => {
                try {
                    const data = await NewSignerAPI.get(
                        `/signing-process/${challengeKey}/finalized`
                    );

                    setDocumentData(data);
                    setCurrentDocId((data?.[0] as SigningDocument)?.id);
                    setDocumentDataFetching(false);
                } catch (error) {
                    if (error.status === (403 || 401 || 404)) {
                        const url = window.location.href.replace(
                            '/finalized',
                            ''
                        );

                        // If the user is not able to fetch the data, they are not authenticated / verified, lets get them back!
                        // 404 is for if the challenge key is gone
                        window.location.assign(url);

                        return;
                    }

                    // Something very awful have happened, and we are not able to redirect the user!
                    setDocumentDataFetching(false);
                    setError(true);

                    notify.show(
                        <span>
                            {i18n('An error occurred, please try again')}
                        </span>,
                        'error',
                        6000
                    );
                }
            })();
        }
    }, [documentData, error]);

    if (error) {
        return <SigningFail challengeKey={challengeKey} />;
    }

    if (documentDataFetching || !documentData || !currentDocId) {
        return (
            <>
                <br />
                <br />
                <Loader />
            </>
        );
    }

    /**
     * TEST:
     * These are just specifically set for us to test that we can traverse documents on this page if multiple are sent
     */
    let testProps;

    if (process.env.JEST_WORKER_ID) {
        testProps = process.env.JEST_WORKER_ID !== undefined && {
            'data-testid': 'document-content',
            'data-document-id': currentDocId,
        };
    }

    return (
        <>
            <SigningHeader
                changeLanguage={changeLanguage}
                currentDocId={currentDocId}
                challengeKey={challengeKey}
                isValidatedCasefile={true}
                finalized={true}
            />
            <div className="signing-container">
                <div className="signing-content" {...testProps}>
                    <SigningCasefileDocumentPreview
                        challengeKey={challengeKey}
                        id={currentDocId}
                        uploadedDocuments={documentData}
                    />
                </div>
            </div>
            {documentData && (
                <SignFinalizedFooter
                    currentDocId={currentDocId}
                    uploadedDocuments={documentData as SigningDocument[]}
                    changeDocument={changeDocumentId}
                />
            )}
        </>
    );
};

export default SignFinalized;
