import * as ImagesFlags from './images/flags';
import * as ImagesLogos from './images/logos';

import { EIDMethod, LoginMethod } from './index';

import {
    CompleteEIDMethod,
    eidDefaults,
    identifiesBy,
    isLoginMethod,
    isSigningMethod,
    OpenIDMethod,
    PersonIdentifierType,
    SigningMethod,
} from './types';

import launchDarkly, { Flags } from '../Common/LaunchDarkly';

export const ANY_MITID_DENMARK: OpenIDMethod = {
    ...eidDefaults,
    type: 'any.mitid.dk',
    credentialType: () => 'mitid.dk',
    title: 'MitID',
    extendedTitle: 'MitID Denmark with CPR confirmation',
    openidUrlId: 'mitid.dk',
    logo: ImagesLogos.MitIDLogo,
    openid: true,
    flag: ImagesLogos.DKFlag,
    newFlag: ImagesFlags.dk,
    login: false,
    sign: false,
    canSetupUser: () => true,
    canIdentifyBy: identifiesBy([]),
};

/**
 * We need this config that copies ANY_MITID_DENMARK and changes the title
 * becuase we needed to change the title when used in the context of NAP, but not other places
 */
export const ANY_MITID_DENMARK_HIDDEN_NAME_VARIANT: OpenIDMethod = {
    ...ANY_MITID_DENMARK,
    extendedTitle: 'MitID Denmark without visible name',
};

export const MITID_PERSONAL_NO_CPR_STEP_DENMARK: OpenIDMethod<CompleteEIDMethod> =
    {
        ...eidDefaults,
        type: 'personal.sign.no-cpr-step.mitid.dk',
        credentialType: () => 'mitid.dk',
        title: 'MitID',
        extendedTitle: 'MitID Denmark',
        logo: ImagesLogos.MitIDLogo,
        openid: true,
        flag: ImagesLogos.DKFlag,
        newFlag: ImagesFlags.dk,
        login: true,
        sign: true,
        canSetupUser: () => false,
        canIdentifyBy: identifiesBy([
            PersonIdentifierType.DanishCPR,
            PersonIdentifierType.Legacy,
            PersonIdentifierType.SMS,
        ]),
        configurationInfo: {
            logo: ImagesLogos.MitIDConfigLogo,
        },
    };

const MITID_ERHVERV_DENMARK: OpenIDMethod<CompleteEIDMethod> = {
    ...eidDefaults,
    type: 'nemlogin.sign.mitid.dk',
    credentialType: () => 'mitid.dk',
    title: 'MitID Erhverv',
    extendedTitle: 'MitID Erhverv Denmark',
    logo: ImagesLogos.MitIDErhvervLogo,
    openid: true,
    flag: ImagesLogos.DKFlag,
    newFlag: ImagesFlags.dk,
    login: true,
    sign: true,
    canSetupUser: () => false,
    canIdentifyBy: identifiesBy([
        PersonIdentifierType.VATIN,
        PersonIdentifierType.Legacy,
        PersonIdentifierType.SMS,
    ]),
};

const BANKID_NORWAY: CompleteEIDMethod = {
    ...eidDefaults,
    type: 'bankid_no',
    typeAlias: 'bankidno',
    title: 'BankID NO',
    extendedTitle: 'BankID Norway',
    logo: ImagesLogos.BankIDNOLogo,
    flag: ImagesLogos.NOFlag,
    newFlag: ImagesFlags.no,
    login: true,
    sign: true,
    canIdentifyBy: identifiesBy([
        PersonIdentifierType.NorwegianNIN,
        PersonIdentifierType.Legacy,
        PersonIdentifierType.SMS,
    ]),
    paths: {
        login: 'bankid-no-login',
        validate_id: 'bankid-no-validate-id',
        activate: 'activate-bankid-no',
        add: 'account-user-add-bankid-no',
    },
    availability: {
        idValidation: () =>
            !launchDarkly.variation(Flags.BANKID_NORWAY_EIDENT_ENABLED),
        login: () =>
            !launchDarkly.variation(Flags.BANKID_NORWAY_EIDENT_ENABLED),
        setupUser: () =>
            !launchDarkly.variation(Flags.BANKID_NORWAY_EIDENT_ENABLED),
        sign: () => !launchDarkly.variation(Flags.BANKID_NORWAY_EIDENT_ENABLED),
        configureForSign: () =>
            !launchDarkly.variation(Flags.BANKID_NORWAY_EIDENT_ENABLED),
    },
    configurationInfo: {
        logo: ImagesLogos.BankIDNOConfigLogo,
    },
};

const FINNISH_TRUST_NETWORK: OpenIDMethod<CompleteEIDMethod> = {
    ...eidDefaults,
    type: 'ftn.fi',
    title: 'FTN',
    extendedTitle: 'Finnish Trust Network',
    logo: ImagesLogos.FTNLogo,
    flag: ImagesLogos.FIFlag,
    newFlag: ImagesFlags.fi,
    openid: true,
    login: true,
    sign: true,
    canIdentifyBy: identifiesBy([
        PersonIdentifierType.FinnishPIC,
        PersonIdentifierType.Legacy,
        PersonIdentifierType.SMS,
    ]),
    configurationInfo: {
        logo: ImagesFlags.fi,
    },
};

const NETS_ID_VERIFIER: OpenIDMethod<SigningMethod> = {
    ...eidDefaults,
    type: 'passport-reader.eident.dk',
    credentialType: () => 'passport-reader.eident.dk',
    title: 'Nets ID Verifier',
    extendedTitle: 'Nets ID Verifier',
    logo: ImagesLogos.NetsIdVerifierLogo,
    newFlag: ImagesFlags.netsIdv,
    openid: true,
    login: false,
    sign: true,
    canIdentifyBy: identifiesBy([PersonIdentifierType.SMS]),
    canSetupUser: () => false,
    configurationInfo: {
        logo: ImagesLogos.NetsIdVerifierLogo,
        description: 'Allows signing with National ID document',
    },
};

const EID_BELGIUM_BE: OpenIDMethod<CompleteEIDMethod> = {
    ...eidDefaults,
    type: 'eid.belgium.be',
    title: '.beID',
    extendedTitle: '.beID',
    logo: ImagesLogos.EIDBelgiumBELogo,
    flag: ImagesLogos.BEFlag,
    newFlag: ImagesFlags.be,
    openid: true,
    login: true,
    sign: true,
    canIdentifyBy: identifiesBy([
        PersonIdentifierType.Legacy,
        PersonIdentifierType.SMS,
    ]),
    configurationInfo: {
        logo: ImagesLogos.EIDBelgiumBELogo,
    },
};

const VERIMI_DE: OpenIDMethod<CompleteEIDMethod> = {
    ...eidDefaults,
    type: 'verimi.de',
    title: 'Verimi',
    extendedTitle: 'Verimi',
    logo: ImagesLogos.VerimiLogo,
    flag: ImagesLogos.DEFlag,
    newFlag: ImagesFlags.de,
    openid: true,
    login: true,
    sign: true,
    availability: {
        sign: 'use-experimental-eid-flag-flag',
        login: 'use-experimental-eid-flag-flag',
        configureForSign: 'use-experimental-eid-flag-flag',
        configureForLogin: 'use-experimental-eid-flag-flag',
    },
    configurationInfo: {
        logo: ImagesLogos.VerimiLogo,
    },
};

/**
 * BankID Sweden, but serverd through Net's E-Ident platform as an OpenID Connect solution.
 */
const BANKID_SWEDEN_EIDENT: OpenIDMethod<CompleteEIDMethod> = {
    ...eidDefaults,
    type: 'bankid.se',
    credentialType: () => 'bankid_se',
    typeAlias: 'bankidse',
    title: 'BankID SE',
    extendedTitle: 'BankID Sweden',
    logo: ImagesLogos.BankIDSELogo,
    flag: ImagesLogos.SEFlag,
    newFlag: ImagesFlags.se,
    openid: true,
    login: true,
    sign: true,
    canIdentifyBy: identifiesBy([
        PersonIdentifierType.SwedishPIN,
        PersonIdentifierType.Legacy,
        PersonIdentifierType.SMS,
    ]),
    configurationInfo: {
        logo: ImagesLogos.BankIDSELogo,
    },
};

/**
 * BankID Norway, served through Net's E-Ident platform as an OpenID Connect solution.
 */
export const BANKID_NORWAY_EIDENT: OpenIDMethod<CompleteEIDMethod> = {
    ...eidDefaults,
    type: 'biometric.bankid.no',
    credentialType: () => 'bankid_no',
    typeAlias: 'bankidno_eident',
    title: 'BankID NO',
    extendedTitle: 'BankID Norway',
    logo: ImagesLogos.BankIDNOPurpleLogo,
    flag: ImagesLogos.NOFlag,
    newFlag: ImagesFlags.no,
    openid: true,
    login: true,
    sign: true,
    canIdentifyBy: identifiesBy([
        PersonIdentifierType.NorwegianNIN,
        PersonIdentifierType.Legacy,
        PersonIdentifierType.SMS,
    ]),
    availability: {
        idValidation: () =>
            launchDarkly.variation(Flags.BANKID_NORWAY_EIDENT_ENABLED),
        login: () => launchDarkly.variation(Flags.BANKID_NORWAY_EIDENT_ENABLED),
        setupUser: () =>
            launchDarkly.variation(Flags.BANKID_NORWAY_EIDENT_ENABLED),
        sign: () => launchDarkly.variation(Flags.BANKID_NORWAY_EIDENT_ENABLED),
        configureForSign: () =>
            launchDarkly.variation(Flags.BANKID_NORWAY_EIDENT_ENABLED),
    },
    configurationInfo: {
        logo: ImagesLogos.BankIDNOPurpleLogo,
    },
};

export const ITSME_BELGIUM_QES: SigningMethod = {
    ...eidDefaults,
    type: 'qes.itsme.be',
    credentialType: () => 'itsme.be',
    title: 'itsme® (QES)',
    extendedTitle: 'itsme® (QES)',
    logo: ImagesLogos.ItsMeLogo,
    newFlag: ImagesFlags.itsmesign,
    login: false,
    sign: true,
    canIdentifyBy: identifiesBy([PersonIdentifierType.SMS]),
    configurationInfo: {
        logo: ImagesLogos.ItsMeLogo,
    },
};

export const ITSME_BELGIUM_CONNECT: OpenIDMethod<LoginMethod> = {
    openid: true,
    ...eidDefaults,
    type: 'connect.itsme.be',
    credentialType: () => 'itsme.be',
    title: 'itsme®',
    extendedTitle: 'itsme®',
    logo: ImagesLogos.ItsMeLogo,
    newFlag: ImagesFlags.itsmesign,
    login: true,
    sign: false,
    canIdentifyBy: identifiesBy([PersonIdentifierType.Legacy]),
};

const GOOGLE_OPENID: OpenIDMethod<CompleteEIDMethod> = {
    ...eidDefaults,
    type: 'google',
    title: 'Google',
    logo: ImagesLogos.GoogleLogo,
    newFlag: ImagesFlags.google,
    availability: {
        sign: 'use-experimental-eid-flag-flag',
        configureForSign: 'use-experimental-eid-flag-flag',
    },
    openid: true,
    login: true,
    sign: true,
    configurationInfo: {
        logo: ImagesLogos.GoogleLogo,
    },
};

const MICROSOFT_OPENID: OpenIDMethod<LoginMethod> = {
    ...eidDefaults,
    type: 'microsoft',
    title: 'Microsoft',
    logo: ImagesLogos.MicrosoftLogo,
    openid: true,
    login: true,
    sign: false,
};

/**
 * Touch sign is an exception, as it is technically not an EID. This is why it is not
 * included in the EID_METHODS list, but is included in SIGNING_METHODS.
 */
const TOUCH_SIGN: SigningMethod = {
    ...eidDefaults,
    type: 'image',
    title: 'Touch Signature',
    newFlag: ImagesFlags.touch,
    login: false,
    sign: true,
};

/**
 * Touch methods broken down into three individual ones
 */
const DRAW_SIGN: SigningMethod = {
    ...eidDefaults,
    type: 'draw',
    title: 'Draw signature',
    newFlag: ImagesFlags.draw,
    login: false,
    sign: true,
    canIdentifyBy: identifiesBy([PersonIdentifierType.SMS]),
    extendedInfo: {
        title: `Allow to draw signature`,
        tooltip: `Allow the signer to produce a Simple Electronic Signature by drawing their signature with their touch screen or mouse.`,
    },
    configurationInfo: {
        logo: ImagesLogos.drawConfigLogo,
        title: `Allow to draw signature`,
        tooltip: `Allow the signer to produce a Simple Electronic Signature by drawing their signature with their touch screen or mouse.`,
    },
};

const IMAGE_SIGN: SigningMethod = {
    ...eidDefaults,
    type: 'image',
    title: 'Upload image',
    extendedTitle: 'Upload your signature picture',
    newFlag: ImagesFlags.image,
    login: false,
    sign: true,
    canIdentifyBy: identifiesBy([PersonIdentifierType.SMS]),
    extendedInfo: {
        title: `Allow to upload an image as signature`,
        tooltip: `Allow the signer to produce a Simple Electronic Signature by uploading a picture of their handwritten signature. This signature method is accepted by the Danish Business Authorities. It's enabled automatically for Danish MitID users with name and address protection, regardless of whether the box is ticked.`,
    },
    configurationInfo: {
        logo: ImagesLogos.imageConfigLogo,
        title: `Allow to upload an image as signature`,
        tooltip: `Allow the signer to produce a Simple Electronic Signature by uploading a picture of their handwritten signature. This signature method is accepted by the Danish Business Authorities.`,
    },
};

const TEXT_SIGN: SigningMethod = {
    ...eidDefaults,
    type: 'text',
    title: 'Text input',
    extendedTitle: 'Type your name',
    newFlag: ImagesFlags.text,
    login: false,
    sign: true,
    canIdentifyBy: identifiesBy([PersonIdentifierType.SMS]),
    extendedInfo: {
        title: `Allow to use text input as signature`,
        tooltip: `Allow the signer to produce a Simple Electronic Signature by typing their name as plain text.`,
    },
    configurationInfo: {
        logo: ImagesLogos.textConfigLogo,
        title: `Allow to use text input as signature`,
        tooltip: `Allow the signer to produce a Simple Electronic Signature by typing their name as plain text.`,
    },
};

/**
 * Due to the current implementation in SigningValidationMethod.tsx,
 * the "sign:true" parameter is required for the method to be displayed on the Validation page.
 * TODO: When adding new validation-only methods, the above-mentioned implementation should be refactored.
 */
export const SMS: SigningMethod = {
    ...eidDefaults,
    type: 'sms',
    title: 'SMS',
    logo: ImagesLogos.sms,
    login: true,
    sign: true,
    availability: {
        sign: launchDarkly.variation(Flags.SMS_VERIFICATION),
        login: launchDarkly.variation(Flags.SMS_VERIFICATION),
        configureForSign: launchDarkly.variation(Flags.SMS_VERIFICATION),
    },
    canIdentifyBy: identifiesBy([PersonIdentifierType.SMS]),
    configurationInfo: {
        logo: ImagesLogos.smsConfigLogo,
        title: `Enabling SMS`,
        tooltip: `By enabling SMS you instruct Penneo to process the phone numbers of signers each time the creator of a casefile adds a phone number to the recipient information. The purpose is to restrict access to the documents. Please contact compliance@penneo.com for an updated Data Processing Agreement.`,
    },
};

/**
 * Electronic ID capabilities we have.
 */
export const EID_METHODS: Array<EIDMethod> = [
    ANY_MITID_DENMARK,
    MITID_PERSONAL_NO_CPR_STEP_DENMARK,
    MITID_ERHVERV_DENMARK,
    BANKID_SWEDEN_EIDENT,
    BANKID_NORWAY_EIDENT,
    BANKID_NORWAY,
    FINNISH_TRUST_NETWORK,
    ITSME_BELGIUM_QES,
    ITSME_BELGIUM_CONNECT,
    EID_BELGIUM_BE,
    VERIMI_DE,
    NETS_ID_VERIFIER,
    GOOGLE_OPENID,
    MICROSOFT_OPENID,
    SMS,
];

/**
 * Platforms usable for login.
 *
 * Not all platforms are usable for login, for example: Touch is sign only.
 *
 * We add sms here so that it will show up in the validation methods list, but also only when ssnType is SMS.
 */
export const LOGIN_METHODS: LoginMethod[] = EID_METHODS.filter(isLoginMethod);

/**
 * Platform usable for user setup.
 */
export const USER_SETUP_METHODS: EIDMethod[] = EID_METHODS.filter((method) =>
    method.canSetupUser()
);

/**
 * Platforms usable for signing.
 *
 * Not all platforms are usable for signing, for example Microsoft is only usable for login.
 * Additionally, there are some signing platforms that are not EID methods, namely Touch sign.
 *
 * We add sms here so that it will show up in the validation methods list, but only when ssnType is SMS.
 */
export const SIGNING_METHODS: SigningMethod[] = EID_METHODS.filter(
    isSigningMethod
).concat([TOUCH_SIGN]);

/**
 * Insecure methods for signing.
 * These methods are matched with customer preferences
 * and are not meant to be used if TOUCH_SIGN is disabled
 */
export const INSECURE_SIGNING_METHODS: SigningMethod[] = [
    DRAW_SIGN,
    IMAGE_SIGN,
    TEXT_SIGN,
];

export const ALLOWED_NAP_SIGNING_METHODS: SigningMethod[] = [
    NETS_ID_VERIFIER,
    ITSME_BELGIUM_QES,
];
