import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router';
import { env } from 'Constants';
import { i18n } from 'Language';
import { ProfileSelectorDropDown } from 'Common/components/ProfileSelector/ProfileSelectorDropDown';
import { ArrowRightEndOnRectangleIcon } from '@heroicons/react/24/outline';
import AuthStore from 'Auth/stores/AuthStore';
import launchDarkly, { Flags } from 'Common/LaunchDarkly';
import Analytics from 'Common/Analytics';
import routingUtils from 'utils/routing';
import { Button } from '@penneo/vega-ui-react';

export default class UserDropdown extends React.Component {
    static propTypes = {
        items: PropTypes.array.isRequired,
        user: PropTypes.object.isRequired,
        closeDropdown: PropTypes.func,
        open: PropTypes.bool,
    };

    static defaultProps = {
        items: [],
    };

    getInitials = (fullName) => {
        if (!fullName) {
            return false;
        }

        let names = fullName.split(' ');

        let initials = '';

        if (names.length === 1) {
            initials += names[0][0];
        }

        if (names.length > 1) {
            initials += names[0][0] + names[1][0];
        }

        return initials;
    };

    onClick = () => {
        if (env.platform === 'desktop') {
            let electron = window.require('electron');

            // If via Electron layer, tell electron when user logged out
            electron.ipcRenderer.send('logged-out');
        }

        this.props.closeDropdown();
    };

    onLogout = () => {
        Analytics.track('log out', { origin: 'user dropdown' });

        window.location.href = routingUtils.getFullPath('/logout');
    };

    renderItemList = () => {
        const { user } = this.props;
        const details = AuthStore.getAuthDetails();

        const hasMultipleProfiles = details.userCount > 1;

        const userSwitcherEnabled = launchDarkly.variation(
            Flags.USER_SWITCHER_ENABLED
        );

        const isWebApplication = env.platform === 'web';

        let items = [
            {
                to: 'profile',
                icon: 'user-circle',
                show: isWebApplication,
                label: 'My profile',
            },
            {
                icon: 'exchange',
                label: 'Switch accounts',
                show: userSwitcherEnabled && hasMultipleProfiles,
                component: (
                    <ProfileSelectorDropDown
                        className="dropdown-element-label"
                        user={this.props.user}>
                        <span>
                            <i className={`far fa-exchange`} />
                            &nbsp;
                            {i18n`Switch accounts`}
                        </span>
                    </ProfileSelectorDropDown>
                ),
            },
            {
                to: 'company-settings',
                icon: 'building',
                show:
                    isWebApplication &&
                    (user.role === 'administrator' || user.admin),
                label: 'My company',
            },
        ];

        items = items.filter((item) => !!item.show);

        return items.map((item, index) => {
            return (
                <li className="dropdown-element" key={index}>
                    {item.component ? (
                        item.component
                    ) : (
                        <Link to={item.to} onClick={this.props.closeDropdown}>
                            <div className="dropdown-element-label">
                                <i className={`far fa-${item.icon}`} />
                                &nbsp;
                                {i18n(item.label)}
                            </div>
                        </Link>
                    )}
                </li>
            );
        });
    };

    render() {
        const { user } = this.props;
        const initials = this.getInitials(user.fullName);

        return (
            <div className="user-dropdown form-v2">
                <div>
                    <span>
                        <figure
                            style={{ background: '#E1F1FF', color: '#086AFA' }}
                            className="avatar"
                            aria-label={`${i18n`User Avatar`}: ${initials}`}>
                            <span aria-hidden={true}>{initials}</span>
                        </figure>
                        <span className="name">{user.fullName}</span>
                    </span>
                    <nav
                        id={'user-menu'}
                        aria-label={i18n`User menu`}
                        className="dropdown-content">
                        <ul>
                            {this.renderItemList()}

                            <li className="dropdown-element">
                                <Button
                                    priority="secondary"
                                    fullWidth
                                    type={'neutral'}
                                    ariaLabel={`${i18n`Logout`} ${
                                        user.fullName
                                    }`}
                                    onClick={this.onLogout}
                                    rightIcon={
                                        <ArrowRightEndOnRectangleIcon className="w-[24px] h-[24px]" />
                                    }>
                                    {i18n`Logout`}
                                </Button>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        );
    }
}
