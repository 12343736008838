import { AppThunk } from 'Store';
import { EmailTemplate, TemplateType } from 'types/EmailTemplates';
import { RecipientSigner } from 'types/Recipient';
import {
    EmailMessage,
    NewCasefileState,
} from 'Casefiles/redux/newCaseFile/types';
import {
    CASEFILE_EMAIL_MESSAGE_UPDATED,
    CASEFILE_EMAIL_TEMPLATE_UPDATED,
    EDIT_EMAIL_BODY,
    EDIT_EMAIL_SUBJECT,
    NEW_CUSTOM_EMAIL_MESSAGE,
    REMOVE_CUSTOM_EMAIL_MESSAGE,
    REMOVE_RECIPIENT_FROM_ALL_MESSAGES,
    RESET_CASEFILE_EMAIL_TEMPLATES,
    SET_CUSTOM_EMAIL_RECIPIENTS,
    SET_INITIAL_EMAIL_MESSAGES,
} from './action-types';

export const addCustomEmailMessage = (message: EmailMessage) => ({
    type: NEW_CUSTOM_EMAIL_MESSAGE,
    payload: message,
});

export const editEmailBody =
    (body: string, type: TemplateType, customMessageIndex?: number): AppThunk =>
    (dispatch) => {
        dispatch({
            type: EDIT_EMAIL_BODY,
            payload: { body, type, customMessageIndex },
        });
    };

export const editEmailSubject =
    (
        subject: string,
        type: TemplateType,
        customMessageIndex?: number
    ): AppThunk =>
    (dispatch) => {
        dispatch({
            type: EDIT_EMAIL_SUBJECT,
            payload: { subject, type, customMessageIndex },
        });
    };

export const removeCustomEmailMessage =
    (index: number): AppThunk =>
    (dispatch) => {
        dispatch({ type: REMOVE_CUSTOM_EMAIL_MESSAGE, payload: { index } });
    };

export const resetEmailTemplates = () => ({
    type: RESET_CASEFILE_EMAIL_TEMPLATES,
});

export const removeRecipientFromAllMessages = (tempId: string) => ({
    type: REMOVE_RECIPIENT_FROM_ALL_MESSAGES,
    payload: { tempId },
});

export const setCustomEmailRecipients =
    (index: number, recipients: RecipientSigner[]): AppThunk =>
    (dispatch) => {
        dispatch({
            type: SET_CUSTOM_EMAIL_RECIPIENTS,
            payload: { index, recipients },
        });
    };

export const setInitialEmailTemplates = (initialMessages: {
    generalMessages: NewCasefileState['emailMessages']['general']['messages'];
    customMessages: {
        messages: NewCasefileState['emailMessages']['custom'][number]['messages'];
        recipients: NewCasefileState['emailMessages']['custom'][number]['recipients'];
    }[];
}) => ({
    type: SET_INITIAL_EMAIL_MESSAGES,
    payload: initialMessages,
});

export const updateEmailMessageWithTemplate =
    (
        type: TemplateType,
        template: EmailTemplate,
        customMessageIndex?: number
    ): AppThunk =>
    (dispatch) => {
        return dispatch({
            type: CASEFILE_EMAIL_MESSAGE_UPDATED,
            payload: { type, template, customMessageIndex },
        });
    };

export const updateEmailTemplate =
    (type: TemplateType, template: EmailTemplate): AppThunk =>
    (dispatch) => {
        dispatch({
            type: CASEFILE_EMAIL_TEMPLATE_UPDATED,
            payload: { type, template },
        });
    };
