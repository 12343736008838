import React from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { translate } from 'Language';

export default class PresharedErrorPage extends React.Component {
    render() {
        return (
            <div className="error-page">
                <div className="content">
                    <TransitionGroup
                        className="redirectionWidget"
                        appear={true}>
                        <CSSTransition
                            timeout={{ enter: 500, appear: 500, exit: 300 }}>
                            <div
                                key="error-container"
                                className="error-container">
                                <div className="bar info"></div>
                                <div className="document-stack-icon">
                                    <div className="document-image">
                                        <i className="fa fa-unlink"></i>
                                    </div>
                                    <div className="lines"></div>

                                    <div className="page"></div>
                                    <div className="page page-2"></div>
                                    <div className="page page-3"></div>
                                </div>
                                <TransitionGroup
                                    className="redirectionWidget"
                                    appear={true}>
                                    <CSSTransition
                                        timeout={{
                                            enter: 500,
                                            appear: 500,
                                            exit: 300,
                                        }}>
                                        <div key="0">
                                            <h2 className="title">
                                                {translate(
                                                    'The link you used is invalid'
                                                )}
                                            </h2>
                                            <h3 className="subtitle">
                                                {translate(
                                                    'If you think this may be an error'
                                                )}{' '}
                                                <br />
                                                {translate(
                                                    'please contact support.'
                                                )}
                                            </h3>
                                        </div>
                                    </CSSTransition>
                                </TransitionGroup>
                            </div>
                        </CSSTransition>
                    </TransitionGroup>
                </div>
            </div>
        );
    }
}
