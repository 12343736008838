import React, { ReactElement } from 'react';
import moment, { Moment } from 'moment';
import analytics from 'Common/Analytics';
import {
    AutocompleteContacts,
    Checkbox,
    NumberInput,
    RadioButton,
    TextInput,
} from 'Common/components';
import SignerActions from 'Casefiles/actions/SignerActionCreators';
import { images, SSNs } from 'Constants';
import Constants from '../../Constants';
import { AppDispatch, ReduxState } from 'Store';
import { modal } from 'Common/components/Common/Modal';
import lodash from 'lodash';
import Message from 'Common/components/Message';
import { getContacts } from 'Casefiles/redux/contacts/actions';
import { removeRecipientFromAllMessages } from 'Casefiles/redux/newCaseFile/actions';
import { SSN } from 'types/SSN';
import { validateSigner } from 'Casefiles/utils/casefileValidation';
import InputValidation from 'Common/components/InputValidation';
import { i18n } from 'Language';
import { connect } from 'react-redux';
import { CustomerSettings, UserSettings } from 'Settings/redux/types';
import classnames from 'classnames';
import HelpTooltip from 'Common/components/HelpTooltip';
import Button from 'Common/components/Button';
import SsnInput from 'Common/components/SsnInput';
import { UserEntity as User } from 'types/User';
import { CustomerEntity, CustomerSigningMethod } from 'types/Customer';
import {
    InsecureSigningMethod,
    SignerValidation,
    SignerValidationRule,
} from 'types/Signer';
import { RecipientSignerRole, RecipientType } from 'types/Recipient';
import DocumentStore from 'Casefiles/stores/DocumentStore';
import LaunchDarkly from 'Common/LaunchDarkly';
import launchDarkly from 'Common/LaunchDarkly';
import { Flags } from 'types/LaunchDarkly';
import { RolesActivationDateModify } from 'Casefiles/components/casefiles2/RolesActivationDateModify';
import { emptySigner } from 'Casefiles/stores/SignerStore';

import './recipient-modal.scss';
import { INSECURE_SIGNING_METHODS } from 'EID/Constants';
import { insecureSigningMethodsDefault, PersonIdentifierType } from 'EID/types';
import Accordion from 'Common/components/Accordion';
import SmsInput from 'Common/components/SmsInput';
import { aggregateSSN } from 'utils';
import { parsePhoneNumber } from '../../../Common/components/SmsInput/utils';

type ValidationType = 'ssn' | 'vatin' | 'sms';

export type Recipient = {
    accessControl: boolean;
    // A null value indicates that the validations shouldn't be run yet.
    email: string | null;
    enableInsecureSigning: boolean;
    // A null value indicates that the validations shouldn't be run yet.
    name: string | null;
    reminderInterval: number;
    role: RecipientSignerRole[];
    storeAsContact: boolean;
    type: RecipientType;
    insecureSigningMethods?: InsecureSigningMethod[];
    onBehalfOf?: string;
    ssn?: number | string;
    ssnType?: SSN['id'] | 'sms';
    vatin?: number;
    tempId?: string;
    validation?: SignerValidation;
};

export type State = {
    signerIsMeToggled: boolean;
    advanced: boolean;
    promptDelete: boolean;
    recipient: Recipient;
    securityOptionsVisible: boolean;
    isCustomRoleNamesFeatureEnabled: boolean;
    isCustomActivationDateEnabled: boolean;
    validationType: ValidationType;
    inputFields: any;
    showStoreAsContactOption: boolean;
};

export type Props = {
    index?: number | null;
    recipient?: Recipient;
    availableSignerTypes: any[];
    casefile: any;
    dispatch: AppDispatch;
    user: User;
    userSettings: UserSettings;
    reminderInterval: number;
    customer: CustomerEntity;
    customerSettings: CustomerSettings;
};

export class RecipientModal extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        // If we are editing a signer and it has already custom role names,
        // automatically enable the custom role names section
        const { recipient } = this.props;
        const isCustomRoleNamesFeatureEnabled =
            !!recipient &&
            recipient.role.length > 0 &&
            recipient.role.some((role) => !!role.customName);

        this.state = {
            signerIsMeToggled: false,
            advanced: false,
            securityOptionsVisible: false,
            showStoreAsContactOption: false,
            isCustomRoleNamesFeatureEnabled,
            isCustomActivationDateEnabled: false,
            promptDelete: false,
            validationType: this.setValidationType(props),
            recipient: this.initRecipientData(),
            inputFields: {
                name: {
                    isFocused: false,
                },
                email: {
                    isFocused: false,
                },
                onBehalfOf: {
                    isFocused: false,
                },
            },
        };
    }

    setValidationType(props: Props) {
        if (!props?.recipient) {
            return 'ssn';
        }

        if (props.recipient.vatin) {
            return 'vatin';
        }

        if (props.recipient.ssnType === 'sms') {
            return 'sms';
        }

        return 'ssn';
    }

    async componentDidMount() {
        const { recipient, user } = this.props;

        // If editing a signer that has the same name & email as the logged in
        // user; toggle the signer is me option.
        if (
            recipient?.name === user?.fullName &&
            recipient?.email === user?.email
        ) {
            this.setState({ signerIsMeToggled: true });
        }

        // If editing a signer, we need to check whether to display the
        // "store as contact" option
        if (
            recipient?.name &&
            recipient?.email &&
            recipient?.type === 'signer'
        ) {
            await this.updateStoreAsContactOption();
        }

        // If editing a signer and signer already has a customized activation date
        // set on a role, enable it in the gui.
        const hasRecipientRoleActivationDate = recipient?.role.find((role) => {
            return role.activeAt;
        });

        if (hasRecipientRoleActivationDate) {
            this.setState({ isCustomActivationDateEnabled: true });
        }
    }

    initRecipientData() {
        let {
            recipient,
            customer: { allowedSimpleSigningMethods, usersCanStoreContacts },
        } = this.props;

        /**
         * Is the customer enabling insecure methods at all?
         * The customer details endpoint will return an empty allowedSimpleSigningMethods array
         * if "touch" is disabled or if all the SES have been disabled
         */
        const shouldAllowInsecureMethods = !!allowedSimpleSigningMethods.length;

        /**
         * We init the default Insecure Methods with the list of methods enabled by the customer
         * or all of them, if the flag is off
         */
        const defaultInsecureMethods = launchDarkly.variation(
            Flags.SPLIT_INSECURE_SIGNING_METHODS
        )
            ? allowedSimpleSigningMethods
            : insecureSigningMethodsDefault;

        /**
         * If the user already has insecure methods (saved in the draft), we use those.
         * If there's no list but enableInsecureSigning is enabled, we use ALL methods
         * (but if the flag is on we filter them by the customer setting.)
         * NOTE: if insecure signing ("Touch") is disabled by the customer (SES array is empty)
         * we automatically remove evertyhing regarding insecure signing
         */
        let insecureSigningMethods = [] as InsecureSigningMethod[];

        if (shouldAllowInsecureMethods) {
            insecureSigningMethods =
                recipient?.insecureSigningMethods ??
                ((recipient?.enableInsecureSigning && defaultInsecureMethods) ||
                    []);

            /**
             * If the flag is on, we have to make sure that
             * we remove customer disabled methods from the stored ones
             * (if we are dealing with a draft)
             */
            if (
                launchDarkly.variation(Flags.SPLIT_INSECURE_SIGNING_METHODS) &&
                recipient?.insecureSigningMethods?.length
            ) {
                const cleanedUpInsecureList = insecureSigningMethods.filter(
                    (method) => defaultInsecureMethods.includes(method)
                );

                insecureSigningMethods = cleanedUpInsecureList;
            }
        }

        if (recipient) {
            recipient = {
                ...recipient,
                insecureSigningMethods,
                ...(!shouldAllowInsecureMethods && {
                    enableInsecureSigning: false,
                }),
            };

            return lodash.cloneDeep(recipient);
        }

        const {
            userSettings: { storeNewContactsByDefault },
        } = this.props;

        recipient = {
            name: null,
            email: null,
            onBehalfOf: undefined,
            type: RecipientType.Signer,
            role: [],
            ssn: undefined,
            vatin: undefined,
            accessControl: this.props.casefile.sensitiveData,
            reminderInterval: this.props.reminderInterval,
            enableInsecureSigning:
                (!!recipient &&
                    !!(recipient as Recipient)?.enableInsecureSigning) ??
                false, // Have to keep typescript in check by modifying the expected Recipient type
            insecureSigningMethods,
            storeAsContact:
                usersCanStoreContacts === false
                    ? false
                    : storeNewContactsByDefault.active,
        };

        return recipient;
    }

    handleChange = (key, value) => {
        let { recipient } = this.state;

        if (key === 'email') {
            this.updateStoreAsContactOption();
        }

        recipient[key] = value;
        this.setState({ recipient: recipient });
    };

    handleInsecureMethods = (value, add = true) => {
        let { recipient } = this.state;
        let insecureSigningMethods;

        insecureSigningMethods =
            (
                (recipient?.insecureSigningMethods as InsecureSigningMethod[]) ??
                []
            )
                ?.filter((m) => m !== value)
                .concat(add ? [value] : []) ?? [];
        this.setState({
            recipient: {
                ...recipient,
                insecureSigningMethods,
                enableInsecureSigning:
                    (insecureSigningMethods &&
                        insecureSigningMethods?.length > 0) ??
                    false, //If the user chooses no methods we also untoggle insecureMethods
            },
        });
    };

    handleAccessControlChange = (value: boolean) => {
        let { recipient } = this.state;

        this.setState({
            recipient: {
                ...recipient,
                accessControl: value,
                ...(!value && {
                    enableInsecureSigning:
                        [
                            ...((recipient?.insecureSigningMethods as InsecureSigningMethod[]) ??
                                []),
                        ].length > 0 ??
                        recipient.enableInsecureSigning ??
                        false,
                }),
                ...(value && { enableInsecureSigning: false }), // reset insecure signing if access control enabled
            },
        });
    };

    handleSSNChange = (value, ssnType = SSNs.LEGACY_SSN.id) => {
        const { recipient } = this.state;

        recipient.ssn = value;
        recipient.ssnType = ssnType;

        /**
         * We trigger the signer validation right before updating
         * the current recipient, in order to get SSN validation in place
         */
        const { casefile } = this.props;
        const { errors } = validateSigner(recipient, casefile);

        recipient.validation = errors;

        this.setState({ recipient: recipient });
    };

    handleRoleChange = (roleData, enabled) => {
        let { role } = this.state.recipient;
        let index = lodash.findIndex(role, { id: roleData.id });

        if (index !== -1) {
            role.splice(index, 1); // Remove Role
        } else {
            role.push({
                id: roleData.id,
                enabled: enabled,
            }); // Add Role
        }

        this.setState({ recipient: this.state.recipient });
    };

    /**
     * Updates portion of selected role with the given id.
     * If value is undefined, property is removed from the role data
     */
    updateRoleCustomData = (
        id: number,
        key: string,
        value?: string | number
    ) => {
        const { recipient } = this.state;
        const { role: selectedRoles } = this.state.recipient;
        const index = lodash.findIndex(selectedRoles, { id });

        selectedRoles[index] = {
            ...selectedRoles[index],
            [key]: value,
        };

        /**
         * We allowed setting the new value, even if it's undefined. This is to, fx.
         * let the user remove a previous value completely and leave the field empty.
         * Afterwards, if it's undefined, we remove the whole property
         */
        !value && delete selectedRoles[index][key];

        this.setState({ recipient });
    };

    /**
     * Updates the custom role name of the selected role with the given id.
     * We check if the value is an empty string. If so, we then remove the customName property
     */
    handleCustomRoleNameChange = (id: number, customName: string) =>
        this.updateRoleCustomData(
            id,
            'customName',
            customName?.trim().length > 0 ? customName : undefined
        );

    handleSubmit = (event) => {
        event.preventDefault();

        const {
            isCustomActivationDateEnabled,
            isCustomRoleNamesFeatureEnabled,
            recipient,
            signerIsMeToggled,
        } = this.state;

        // If the custom role names feature was disabled, any custom name
        // gets removed before storing the signer's data
        if (!isCustomRoleNamesFeatureEnabled) {
            const { role: selectedRoles } = recipient;

            recipient.role = this.stripCustomRoleNames(selectedRoles);
        }

        // Clear specifically set role activation dates if the feature is disabled
        if (!isCustomActivationDateEnabled) {
            recipient.role = recipient.role.map((role) => {
                if (role.activeAt) {
                    delete role.activeAt;
                }

                return role;
            });
        }

        if (this.props.index !== null) {
            // If there's an index, update existing signer.
            SignerActions.updateSigner(this.props.index, this.state.recipient);
        } else {
            if (signerIsMeToggled) {
                analytics.track('Recipient saved using "Signer is me" feature');
            }

            SignerActions.addSigner(this.state.recipient);
        }

        modal.hide();
    };

    /**
     * Removes the custom name from each of the given selected roles
     */
    stripCustomRoleNames = (selectedRoles: any[]): any[] => {
        return selectedRoles.map((selectedRole) => {
            const { customName, ...rest } = selectedRole;

            return rest;
        });
    };

    // Returns true if recipient has role
    isRoleSelected = (roleId: number) => {
        let { role } = this.state.recipient;

        return lodash.findIndex(role, { id: roleId }) !== -1;
    };

    toggleAdvanced = () => {
        //Enabling all options but only toggling insecure signing if you toggle advanced options (making this untoggleable by untoggling)

        this.setState(
            {
                advanced: !this.state.advanced,
            },
            () => {
                this.setState({
                    recipient: {
                        ...this.state.recipient,
                        ...{
                            enableInsecureSigning:
                                (!this.state.recipient.accessControl &&
                                    this.state.recipient
                                        ?.insecureSigningMethods &&
                                    this.state.recipient?.insecureSigningMethods
                                        ?.length > 0) ??
                                this.state.recipient.enableInsecureSigning ??
                                false,
                        },
                    },
                });
            }
        );
    };

    toggleCustomizeRoleNamesFeature = () => {
        this.setState((prevState) => ({
            isCustomRoleNamesFeatureEnabled:
                !prevState.isCustomRoleNamesFeatureEnabled,
        }));
    };

    toggleCustomizeActivationDateFeature = () => {
        this.setState(
            (prevState) => ({
                isCustomActivationDateEnabled:
                    !prevState.isCustomActivationDateEnabled,
            }),
            () => {
                if (this.state.isCustomActivationDateEnabled) {
                    analytics.track('Recipient activation date enabled');
                }
            }
        );
    };

    handleRecipientTypeChange = (value: RecipientType) => {
        const { recipient } = this.state;

        this.setState({
            recipient: { ...recipient, type: value },
            signerIsMeToggled: false,
        });
    };

    handleStoreAsContactChange = (value: boolean) => {
        analytics.track('Used "store as a contact" option');

        this.setState((prevState) => ({
            recipient: {
                ...prevState.recipient,
                storeAsContact: value,
            },
        }));
    };

    toggleValidationType = (type: ValidationType) => {
        if (type === 'ssn' || type === 'sms') {
            this.handleChange('vatin', null); // Clear VATIN
        }

        if (type === 'vatin') {
            this.handleChange('ssn', null); // Clear SSN
        }

        if (type === 'sms') {
            this.handleChange('ssn', null);
            this.handleChange('ssnType', 'sms');
        }

        if (type === 'ssn' && this.state.recipient.ssnType === 'sms') {
            this.handleChange('ssnType', null);
            this.handleChange('ssn', null);
        }

        this.setState({ validationType: type });
    };

    renderCustomRolesSection = () => {
        const allRoles = this.props.availableSignerTypes;
        const { recipient } = this.state;

        // Order the roles by their original name
        const orderedList = lodash.sortBy(
            recipient.role,
            (selectedRole) =>
                allRoles.find((role) => role.id === selectedRole.id).role
        );

        return (
            <div className="recipient-custom-roles mb">
                <table>
                    <thead>
                        <tr>
                            <th>{i18n`Original signer role`}</th>
                            <th>{i18n`Customized signer role`}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {orderedList.map((selectedRole) => (
                            <tr key={selectedRole.id}>
                                <td>
                                    <label
                                        className="recipient-custom-role-label"
                                        htmlFor={selectedRole.id}>
                                        {
                                            allRoles.find(
                                                (role) =>
                                                    role.id === selectedRole.id
                                            ).role
                                        }
                                    </label>
                                </td>
                                <td>
                                    <TextInput
                                        id={selectedRole.id}
                                        value={selectedRole.customName || ''}
                                        placeholder={i18n`Custom signer role`}
                                        onChange={(value) =>
                                            this.handleCustomRoleNameChange(
                                                selectedRole.id,
                                                value
                                            )
                                        }
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    };

    onActivationDateChange = (date: Moment, roleId?: number) => {
        const { recipient } = this.state;
        const unixActivationDate = date.unix();
        const existingRecipientRoles = [...recipient.role];
        const roleToModify = existingRecipientRoles.find((role) => {
            return role.id === roleId;
        });

        if (!roleToModify) return;

        roleToModify.activeAt = unixActivationDate;

        this.setState({
            recipient: { ...recipient, role: existingRecipientRoles },
        });

        analytics.track('Recipient activation date changed');
    };

    activationDateDisabledStartDate = (dateToPotentiallyDisable: Moment) => {
        if (moment().isSameOrBefore(dateToPotentiallyDisable)) {
            return false;
        }

        return true;
    };

    handleClearActiveAtDateButton = (roleId?: number) => {
        const { recipient } = this.state;

        if (!recipient) return;

        const existingRecipientRoles = [...recipient.role];
        const roleToModify = existingRecipientRoles.find((role) => {
            return role.id === roleId;
        });

        if (!roleToModify) return;

        delete roleToModify.activeAt;

        this.setState({
            recipient: { ...recipient, role: [...existingRecipientRoles] },
        });
    };

    renderSignerForm = () => {
        let roles = this.props.availableSignerTypes;
        let { sensitiveData } = this.props.casefile;
        const {
            recipient,
            advanced,
            securityOptionsVisible,
            isCustomRoleNamesFeatureEnabled,
            isCustomActivationDateEnabled,
            validationType,
        } = this.state;

        const {
            customer: { allowedSigningMethods, allowedSimpleSigningMethods },
        } = this.props;

        // The toggle for the custom role names feature is visible only
        // if at least one role is selected
        const isCustomRoleNamesFeatureToggleVisible = recipient.role.length > 0;
        // The custom role names section is visible only if
        // the feature is enabled AND at least one role is selected
        const isCustomRoleNamesFeatureVisible =
            isCustomRoleNamesFeatureEnabled && recipient.role.length > 0;

        const isActivationDateToggleVisible =
            LaunchDarkly.variation(
                Flags.ROLE_SPECIFIC_ACTIVATION_DATE_ENABLED
            ) && recipient.role.length > 0;

        const showTouchSignatureOptions = this.showTouchSignatureOptions();

        /**
         * Only filter out insecure methods if flag is on,
         * there's an array of methods to choose from and this array is not empty
         */
        const shouldFilterInsecureMethods =
            !!launchDarkly.variation(Flags.SPLIT_INSECURE_SIGNING_METHODS) &&
            !!allowedSimpleSigningMethods.length;

        return (
            <div className="signer-form">
                <label>
                    {i18n`Signing roles`}&nbsp;
                    <HelpTooltip
                        text={i18n`Select the role of the signer in the process. This determines the documents that this recipient has access to view and sign`}
                    />
                </label>
                <ul className="box-gray checkbox-group recipient-modal-role-list">
                    {roles.map((role) => (
                        <Checkbox
                            key={role.id}
                            className="v2 checkbox"
                            checked={this.isRoleSelected(role.id)}
                            onChange={(checked) =>
                                this.handleRoleChange(role, checked)
                            }
                            label={role.role}
                        />
                    ))}

                    {roles.length === 0 && (
                        <div className="text-center">
                            <p>{i18n`Please, add a signable document before choosing roles`}</p>
                        </div>
                    )}
                </ul>

                <div className="custom-role-and-activation-date">
                    {isActivationDateToggleVisible && (
                        <div>
                            <Checkbox
                                data-testid="activation-date-checkbox"
                                label={i18n`Set activation date`}
                                checked={isCustomActivationDateEnabled}
                                onChange={
                                    this.toggleCustomizeActivationDateFeature
                                }
                            />
                            <HelpTooltip
                                text={i18n`Set a future activation date for this signer`}
                            />
                        </div>
                    )}

                    {isCustomRoleNamesFeatureToggleVisible && (
                        <div>
                            <Checkbox
                                data-testid="recipient-custom-roles-toggle"
                                label={i18n`Customize role names`}
                                checked={isCustomRoleNamesFeatureEnabled}
                                onChange={this.toggleCustomizeRoleNamesFeature}
                            />
                            <HelpTooltip
                                text={i18n`Customize the names of the selected roles. The original role name will be used if you don't customize it.`}
                            />
                        </div>
                    )}
                </div>

                {isCustomActivationDateEnabled &&
                    isActivationDateToggleVisible && (
                        <RolesActivationDateModify
                            availableRoles={this.props.availableSignerTypes}
                            handleClearActiveAtDateButton={
                                this.handleClearActiveAtDateButton
                            }
                            onActivationDateChange={this.onActivationDateChange}
                            recipient={recipient}
                        />
                    )}

                {isCustomRoleNamesFeatureVisible &&
                    this.renderCustomRolesSection()}

                <div
                    className={classnames('collapsing-box', {
                        collapsed: !securityOptionsVisible,
                    })}>
                    <div
                        className="collapsing-box-header"
                        onClick={() =>
                            this.setState({
                                securityOptionsVisible: !securityOptionsVisible,
                            })
                        }>
                        <span className="collapsing-box-header-title">
                            {i18n`Security and privacy`}{' '}
                            <i className="far fa-lock"></i>
                        </span>

                        <div className="pull-right">
                            {securityOptionsVisible ? (
                                <span>
                                    {i18n`Collapse`}
                                    &nbsp;
                                    <i className="fas fa-chevron-up"></i>
                                </span>
                            ) : (
                                <span>
                                    {i18n`Expand`}
                                    &nbsp;
                                    <i className="fas fa-chevron-down"></i>
                                </span>
                            )}
                        </div>
                    </div>

                    <div className="collapsing-box-content">
                        <label>
                            {i18n`Access control`}&nbsp;
                            <HelpTooltip
                                text={i18n`This will require the signer to identify using an Electronic ID before being allowed to view the documents. You can further restrict access by adding validation below.`}
                            />
                        </label>
                        {sensitiveData ? (
                            <Message type="info">
                                <span>
                                    {i18n`Access control is enabled for this signer because this case contains sensitive data`}
                                    .&nbsp;
                                    <a
                                        className="penneo-message-link"
                                        href="https://penneo.force.com/support/s/article/How-Access-Control-works"
                                        target="_blank"
                                        rel="noopener noreferrer">
                                        {i18n`Learn more`}&nbsp;
                                        <i className="far fa-external-link-alt"></i>
                                    </a>
                                </span>
                            </Message>
                        ) : (
                            <Checkbox
                                className="mb"
                                disabled={sensitiveData}
                                checked={recipient.accessControl}
                                onChange={(value) =>
                                    this.handleAccessControlChange(value)
                                }
                                label={i18n`Limit access to the documents using validation`}
                            />
                        )}
                        <label>
                            {i18n`Validate identity`}&nbsp;
                            <HelpTooltip
                                text={i18n`If enabled, Penneo will ensure that only the person or company with an Electronic ID that matches the social security number or VAT identification can view and sign the documents.`}
                            />
                        </label>
                        <span
                            className="text-small"
                            style={{ position: 'relative', top: '2px' }}>
                            {i18n`With:`}
                        </span>
                        &nbsp;
                        <RadioButton
                            className="inline-block align-top mt0"
                            checked={validationType === 'ssn'}
                            onChange={(checked) =>
                                checked && this.toggleValidationType('ssn')
                            }
                            name="document-type"
                            label={i18n`Social Security Number`}
                        />
                        &nbsp;&nbsp;
                        <RadioButton
                            className="inline-block align-top mt0"
                            checked={validationType === 'vatin'}
                            onChange={(checked) =>
                                checked && this.toggleValidationType('vatin')
                            }
                            name="document-type"
                            label={i18n`VATIN`}
                        />
                        {allowedSigningMethods.includes(
                            CustomerSigningMethod.SMS
                        ) && (
                            <span>
                                &nbsp;&nbsp;
                                <RadioButton
                                    className="inline-block align-top mt0"
                                    checked={validationType === 'sms'}
                                    onChange={(checked) =>
                                        checked &&
                                        this.toggleValidationType('sms')
                                    }
                                    name="document-type"
                                    label={i18n`SMS`}
                                />
                            </span>
                        )}
                        {validationType === 'ssn' && (
                            <InputValidation
                                triggers={aggregateSSN(
                                    recipient.ssn,
                                    recipient.ssnType
                                )}
                                rules={[
                                    {
                                        error: (recipient?.validation?.ssn ||
                                            recipient?.validation
                                                ?.ssnCountry) as SignerValidationRule,
                                        test: () => !!recipient.ssn,
                                    },
                                ]}>
                                <SsnInput
                                    value={recipient.ssn}
                                    ssnType={recipient.ssnType}
                                    placeholder={
                                        i18n`Type SSN` + ' ' + i18n`(Optional)`
                                    }
                                    onChange={({ value, ssnType }) =>
                                        this.handleSSNChange(value, ssnType)
                                    }
                                />
                            </InputValidation>
                        )}
                        {validationType === 'vatin' && (
                            <div>
                                <TextInput
                                    value={recipient.vatin}
                                    className="match-parent"
                                    placeholder={
                                        i18n`Type VATIN` +
                                        ' ' +
                                        i18n`(Optional)`
                                    }
                                    onChange={(value) =>
                                        this.handleChange('vatin', value)
                                    }
                                />
                                <div
                                    className="text-small"
                                    style={{ marginTop: '4px' }}>
                                    <img src={`${images}/flags/dk.gif`} />
                                    &nbsp;
                                    {i18n`Only available for Danish CVR numbers`}
                                </div>
                            </div>
                        )}
                        {validationType === 'sms' && (
                            <div>
                                <SmsInput
                                    value={recipient.ssn?.toString() ?? ''}
                                    inputClass="match-parent"
                                    placeholder={i18n`Type phone number`}
                                    onChange={(value) =>
                                        this.handleChange('ssn', value)
                                    }
                                />
                            </div>
                        )}
                    </div>
                </div>

                {/* Advanced Options */}

                <div className="advanced-options mb">
                    <p
                        className="underline-link inline-block align-top"
                        onClick={this.toggleAdvanced}>
                        {!advanced ? (
                            <span>
                                {i18n`Show advanced options`}&nbsp;
                                <i className="far fa-chevron-down"></i>
                            </span>
                        ) : (
                            <span>
                                {i18n`Hide advanced options`}&nbsp;
                                <i className="far fa-chevron-up"></i>
                            </span>
                        )}
                    </p>

                    {advanced && (
                        <div className="box-gray">
                            {showTouchSignatureOptions && (
                                <>
                                    <label className="label">
                                        {i18n`Signing options`}
                                    </label>
                                    {(recipient?.insecureSigningMethods &&
                                        INSECURE_SIGNING_METHODS.map(
                                            (method) => {
                                                const show =
                                                    shouldFilterInsecureMethods
                                                        ? (
                                                              allowedSimpleSigningMethods as InsecureSigningMethod[]
                                                          )?.includes(
                                                              method.credentialType() as InsecureSigningMethod
                                                          )
                                                        : true;

                                                return (
                                                    show && (
                                                        <Checkbox
                                                            key={method.credentialType()}
                                                            disabled={
                                                                !!sensitiveData
                                                            }
                                                            checked={
                                                                recipient!
                                                                    .insecureSigningMethods &&
                                                                recipient!.insecureSigningMethods?.includes(
                                                                    method.credentialType() as InsecureSigningMethod
                                                                )
                                                            }
                                                            onChange={(
                                                                value
                                                            ) => {
                                                                this.handleInsecureMethods(
                                                                    method.credentialType() as InsecureSigningMethod,
                                                                    value
                                                                );
                                                            }}
                                                            data-testid={`touch-${method.credentialType()}`}
                                                            label={
                                                                <span>
                                                                    {i18n(
                                                                        method
                                                                            .extendedInfo
                                                                            ?.title
                                                                    )}
                                                                    &nbsp;
                                                                    <HelpTooltip
                                                                        width={
                                                                            260
                                                                        }
                                                                        text={i18n(
                                                                            method
                                                                                .extendedInfo
                                                                                ?.tooltip
                                                                        )}
                                                                    />
                                                                </span>
                                                            }
                                                        />
                                                    )
                                                );
                                            }
                                        )) ?? (
                                        <Checkbox
                                            disabled={!!sensitiveData}
                                            checked={
                                                recipient.enableInsecureSigning
                                            }
                                            onChange={(value) =>
                                                this.handleChange(
                                                    'enableInsecureSigning',
                                                    value
                                                )
                                            }
                                            label={
                                                <span>
                                                    {i18n`Allow touch signatures`}
                                                    &nbsp;
                                                    <HelpTooltip
                                                        width={260}
                                                        text={i18n`Allow the signer to draw a hand-written signature using a touch screen or a mouse. Electronic ID signatures are more secure than touch signatures due to the fact that they allow Penneo to verify the identity of the signer.`}
                                                    />
                                                </span>
                                            }
                                        />
                                    )}
                                </>
                            )}

                            <label className="label">
                                {i18n`Automatic reminders`}
                            </label>
                            <span>
                                {i18n`Send reminders every
                                ${(
                                    <NumberInput
                                        min={0}
                                        max={365}
                                        onChange={(value) =>
                                            this.handleChange(
                                                'reminderInterval',
                                                value
                                            )
                                        }
                                        value={recipient.reminderInterval}
                                    />
                                )}
                                days`}
                            </span>
                        </div>
                    )}
                </div>
            </div>
        );
    };

    renderDelete = () => {
        let { recipient } = this.state;

        return (
            <div className="casefile-modal-v2 form-v2">
                <h3 className="casefile-modal-v2-title">
                    {i18n`Remove Recipient`}

                    <div className="close" onClick={modal.hide}>
                        <div className="far fa-times"></div>
                    </div>
                </h3>

                <label className="text-center">{i18n`You are about to delete`}</label>

                <div className="mt box-gray">
                    <h4>{recipient.name}</h4>
                    <p>{recipient.email}</p>
                </div>

                <label className="text-center">{i18n`Are you sure?`}</label>

                <div className="mt">
                    <Button theme="gray" onClick={this.cancelDelete}>
                        {i18n`No, cancel`}
                    </Button>

                    <Button
                        theme="red"
                        icon="far fa-times-circle"
                        className="pull-right"
                        onClick={this.onDelete}>
                        {i18n`Yes, delete`}
                    </Button>
                </div>
            </div>
        );
    };

    onDelete = () => {
        SignerActions.removeSigner(this.props.index, true); // force delete last signer

        const recipientTempId = this.props.recipient?.tempId;

        if (LaunchDarkly.variation(Flags.SIGNER_SPECIFIC_EMAILS)) {
            if (!recipientTempId) {
                console.error("Unexpected behavior: 'tempId' is undefined");

                return;
            }

            // Remove this recipient from all custom messages
            this.props.dispatch(
                removeRecipientFromAllMessages(recipientTempId)
            );
        }

        modal.hide();
    };

    promptDelete = () => {
        this.setState({ promptDelete: true });
    };

    cancelDelete = () => {
        this.setState({ promptDelete: false });
    };

    toggleFieldFocusState = (key: string, value: boolean) => {
        const { inputFields } = this.state;

        inputFields[key].isFocused = value;
        this.setState({ inputFields });
    };

    handleOnAutocompleteSelect = ({ name, email, onBehalfOf }) => {
        this.setState(
            {
                recipient: Object.assign({}, this.state.recipient, {
                    name,
                    email,
                    onBehalfOf,
                }),
                signerIsMeToggled: false,
            },
            async () => {
                await this.updateStoreAsContactOption();
            }
        );
    };

    handleSignerIsMeToggle = (isChecked: boolean) => {
        const { user } = this.props;

        if (isChecked) {
            this.setState({
                signerIsMeToggled: true,
                recipient: {
                    ...(lodash.cloneDeep(emptySigner) as typeof emptySigner),
                    reminderInterval: this.props.reminderInterval,
                    type: RecipientType.Signer,
                    storeAsContact: false,
                    name: user.fullName,
                    email: user.email,
                },
                isCustomRoleNamesFeatureEnabled: false,
                isCustomActivationDateEnabled: false,
                showStoreAsContactOption: false,
            });
        }

        if (!isChecked) {
            this.setState({
                signerIsMeToggled: false,
                recipient: {
                    ...(lodash.cloneDeep(emptySigner) as typeof emptySigner),
                    reminderInterval: this.props.reminderInterval,
                    type: RecipientType.Signer,
                    storeAsContact: false,
                },
                isCustomRoleNamesFeatureEnabled: false,
                isCustomActivationDateEnabled: false,
                showStoreAsContactOption: false,
            });
        }
    };

    renderSignerIsMeCheckbox = () => {
        const { signerIsMeToggled } = this.state;

        return (
            <div>
                <Checkbox
                    label={i18n`This signer is me`}
                    name="signer-is-me"
                    data-testid="signer-is-me"
                    checked={signerIsMeToggled}
                    onChange={this.handleSignerIsMeToggle}
                />
            </div>
        );
    };

    renderInputField = (options: {
        placeholder: string;
        label: string;
        required: boolean;
        autoFocus: boolean;
        type: 'name' | 'email' | 'onBehalfOf';
    }) => {
        const { type } = options;
        const { recipient, signerIsMeToggled } = this.state;
        const { errors } = validateSigner(recipient, this.props.casefile);
        const inputShouldBeDisabled =
            (type === 'name' || type === 'email') && signerIsMeToggled;

        return (
            <InputValidation
                triggers={[
                    recipient[options.type],
                    this.state.inputFields[options.type].isFocused,
                ]}
                rules={[
                    {
                        error: errors[options.type],
                        test: () =>
                            recipient[options.type] !== null &&
                            !this.state.inputFields[options.type].isFocused,
                    },
                ]}>
                {type === 'name' && (
                    <AutocompleteContacts
                        label={i18n(lodash.capitalize(options.label))}
                        value={recipient[options.type]}
                        onChange={(value: string) =>
                            this.handleChange(options.type, value)
                        }
                        onSelect={this.handleOnAutocompleteSelect}
                        onFocus={(value: boolean) =>
                            this.toggleFieldFocusState(options.type, value)
                        }
                        onContactsFetched={this.updateStoreAsContactOption}
                        placeholder={i18n(options.placeholder)}
                        autoFocus={options.autoFocus}
                        required={options.required}
                        extractKey={(item) => `${item.id}_${item.email}`}
                        inputDisabled={inputShouldBeDisabled}
                    />
                )}
                {type !== 'name' && (
                    <TextInput
                        label={i18n(lodash.capitalize(options.label))}
                        value={recipient[options.type]}
                        onChange={(value: string) =>
                            this.handleChange(options.type, value)
                        }
                        onFocus={(value: boolean) =>
                            this.toggleFieldFocusState(options.type, value)
                        }
                        placeholder={i18n(options.placeholder)}
                        autoFocus={options.autoFocus}
                        required={options.required}
                        disabled={inputShouldBeDisabled}
                    />
                )}
            </InputValidation>
        );
    };

    /**
     * Determines the change of visibility and/or value of the option
     */
    updateStoreAsContactOption = async () => {
        const { showStoreAsContactOption: isCurrentlyVisible } = this.state;
        const shouldItBeVisible =
            await this.shouldStoreAsContactOptionBeVisible();
        const isVisibilityUnchanged = isCurrentlyVisible === shouldItBeVisible;

        if (isVisibilityUnchanged) {
            return;
        }

        const { userSettings } = this.props;
        const { recipient: currentRecipient } = this.state;

        const isGoingToBeInvisible = isCurrentlyVisible && !shouldItBeVisible;
        const recipient = isGoingToBeInvisible
            ? {
                  // When turning invisible, reset back to the default value
                  ...currentRecipient,
                  storeAsContact: userSettings.storeNewContactsByDefault.active,
              }
            : currentRecipient;

        this.setState({
            showStoreAsContactOption: shouldItBeVisible,
            recipient,
        });
    };

    async shouldStoreAsContactOptionBeVisible() {
        const { recipient, signerIsMeToggled } = this.state;
        const { customer } = this.props;

        if (!customer.usersCanStoreContacts) {
            return false;
        }

        if (!recipient.name) {
            return false;
        }

        if (signerIsMeToggled) {
            return false;
        }

        const { casefile } = this.props;
        const {
            errors: { email: emailError },
        } = validateSigner(recipient, casefile);
        const isEmailValid = !emailError;

        if (!isEmailValid) {
            return false;
        }

        const contacts = await getContacts(recipient.email);
        const doesEmailBelongToExistingContact = contacts.find(
            ({ email }) => email === recipient.email
        );

        if (doesEmailBelongToExistingContact) {
            return false;
        }

        return true;
    }

    showTouchSignatureOptions() {
        const {
            customer: { allowedSimpleSigningMethods },
        } = this.props;

        const touchSignEnabledForCustomer =
            !!allowedSimpleSigningMethods.length;
        const { sensitiveData } = this.props.casefile;
        const { recipient } = this.state;

        return (
            (recipient.enableInsecureSigning || // always show it if checked, so the user has a chance to uncheck it
                (touchSignEnabledForCustomer && !sensitiveData)) &&
            !recipient.accessControl // user cannot use touch signature if access control is enabled
        );
    }

    renderRecipientFileSizeWarning = (): ReactElement => {
        const message = i18n`Please note! The total size of documents exceeds ${Constants.MAX_DOCS_SIZE_FOR_COPY_EMAIL} which means we can’t send a copy of the finalized document in the email`;

        return (
            <Message type="warning">
                <span>{message}</span>
            </Message>
        );
    };

    phoneNumberIsValid = () => {
        const { recipient } = this.state;

        if (recipient.ssnType === PersonIdentifierType.SMS) {
            return recipient.ssn && parsePhoneNumber(recipient.ssn as string);
        }

        return true;
    };

    render() {
        const { recipient, showStoreAsContactOption } = this.state;
        const { sensitiveData } = this.props.casefile;
        const isEditing = this.props.index !== null;

        if (this.state.promptDelete) {
            return this.renderDelete();
        }

        return (
            <div className="casefile-modal-v2">
                <h3 className="casefile-modal-v2-title">
                    {isEditing ? i18n`Edit recipient` : i18n`Add new recipient`}

                    <div className="close" onClick={modal.hide}>
                        <div className="far fa-times"></div>
                    </div>
                </h3>

                <div className="form-v2">
                    <label>{i18n`This recipient will`}</label>
                    <RadioButton
                        className="inline-block align-top mt0"
                        checked={recipient.type === RecipientType.Signer}
                        disabled={sensitiveData}
                        onChange={(checked) =>
                            checked &&
                            this.handleRecipientTypeChange(RecipientType.Signer)
                        }
                        label={i18n`Sign documents`}
                    />
                    &nbsp;&nbsp;
                    <RadioButton
                        className="inline-block align-top mt0"
                        checked={recipient.type === RecipientType.CopyRecipient}
                        disabled={sensitiveData}
                        onChange={(checked) =>
                            checked &&
                            this.handleRecipientTypeChange(
                                RecipientType.CopyRecipient
                            )
                        }
                        label={i18n`Receive a copy`}
                    />
                    <Accordion
                        label={
                            recipient.type === RecipientType.Signer
                                ? i18n`Signer`
                                : i18n`Recipient`
                        }
                        defaultState={true}
                        children={
                            <div>
                                {recipient.type === RecipientType.Signer &&
                                    this.renderSignerIsMeCheckbox()}
                                {this.renderInputField({
                                    placeholder: i18n`Add a recipient name`,
                                    label: i18n`Name`,
                                    required: true,
                                    autoFocus: true,
                                    type: 'name',
                                })}
                                {this.renderInputField({
                                    placeholder: i18n`Add a recipient email`,
                                    label: i18n`Email`,
                                    required: true,
                                    autoFocus: false,
                                    type: 'email',
                                })}

                                {recipient.type === RecipientType.Signer &&
                                    this.renderInputField({
                                        placeholder: i18n`On behalf of`,
                                        label: i18n`On behalf of`,
                                        required: false,
                                        autoFocus: false,
                                        type: 'onBehalfOf',
                                    })}
                            </div>
                        }
                    />
                    {showStoreAsContactOption && (
                        <div className="mt">
                            <Checkbox
                                label={i18n`Store in contact book`}
                                name="save-as-contact"
                                data-testid="save-as-contact"
                                checked={recipient.storeAsContact}
                                onChange={this.handleStoreAsContactChange}
                            />
                        </div>
                    )}
                    {recipient.type === 'signer' ? (
                        this.renderSignerForm()
                    ) : (
                        <>
                            {DocumentStore.isDocsSizeOverCopyEmailThreshold() &&
                                this.renderRecipientFileSizeWarning()}
                            <br />
                        </>
                    )}
                    <div
                        className={
                            'footer-content form-v2 ' +
                            (isEditing ? 'text-right' : 'text-center')
                        }>
                        {isEditing && (
                            <Button
                                theme="gray"
                                variant="outline"
                                className="pull-left"
                                onClick={this.promptDelete}>
                                {i18n`Delete`}
                            </Button>
                        )}

                        <Button
                            theme="blue"
                            disabled={
                                !recipient.name ||
                                !recipient.email ||
                                !!recipient?.validation?.ssn ||
                                !!recipient?.validation?.ssnCountry ||
                                !this.phoneNumberIsValid()
                            }
                            onClick={this.handleSubmit}>
                            {isEditing
                                ? i18n`Save changes`
                                : i18n`Add recipient`}
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect((state: ReduxState) => ({
    reminderInterval: state.settings.data.customer.casefile.reminderInterval,
    userSettings: state.settings.data.user,
    customer: state.customer.details.data,
    customerSettings: state.settings.data.customer,
}))(RecipientModal);
