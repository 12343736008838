import { ReactRouter } from 'types/Router';

export const extractChallengeKeyFromUrl = (
    redirectUrl?: string
): string | undefined => {
    if (!redirectUrl || !redirectUrl.includes('/folder/invite/show/')) {
        return undefined;
    }

    const urlParts = redirectUrl.split('/');
    const index = urlParts.length - 1;

    return urlParts[index];
};

export const navigateToProfileSelect = (
    router: ReactRouter,
    challengeKey: string,
    folderName?: string
) => {
    const profileSelectRoute = {
        name: 'profile-select',
        query: {
            redirect: `/folder/invite/show/${challengeKey}`,
        },
        state: { folderName },
    };

    router.replace(profileSelectRoute);
};

export const navigateToErrorPage = (
    router: ReactRouter,
    challengeKey: string,
    errorType: 'expired' | 'error'
) => {
    const routeName =
        errorType === 'expired'
            ? 'accept-folder-share-not-found'
            : 'accept-folder-share-error';

    router.replace({
        name: routeName,
        params: { challengeKey },
    });
};

export const navigateToArchive = (router: ReactRouter) => {
    router.replace('archive');
};
