import { PublicValidatorApi } from 'Api/ApiClient';
import { CancelToken } from 'axios';
import analytics from 'Common/Analytics';
import { UtilityState } from 'DocumentServices/Common/Types';
import React, {
    createContext,
    ReactNode,
    useContext,
    useRef,
    useState,
} from 'react';
import { ValidatorResponse } from '../Models/ValidatorResponse';
import { unixTimeToUTC } from '../Utils/Utils';

type State = {
    caseDocument: File | null;
    utilityState: UtilityState;
    downloadUrl?: string;
    validatorResponse?: ValidatorResponse | undefined;
    uploadTime: string;
};

const defaultState: State = {
    caseDocument: null,
    utilityState: UtilityState.IDLE,
    downloadUrl: undefined,
    validatorResponse: undefined,
    uploadTime: '',
};

const resolveErrorCode = (errorCode: number) => {
    switch (errorCode) {
        case 400:
            return UtilityState.NOT_PENNEO_DOCUMENT;
        default:
            return UtilityState.UPLOAD_ERROR;
    }
};

const timeOut = 10000;

const validatorPDFUploadMultipart = async (
    file: File,
    cancelToken?: CancelToken
): Promise<ValidatorResponse> => {
    const formData = new FormData();

    formData.append('file', file);

    const response = await PublicValidatorApi.post(
        '/public/api/v1/validate-document',
        formData,
        {
            headers: {
                'content-type': 'multipart/form-data',
            },
            cancelToken,
            raw: true,
        }
    );

    return response.data;
};

interface ValidatorContextProps {
    state: State;
    setState: React.Dispatch<React.SetStateAction<State>>;
    trackAction: (action: string, description?: string) => void;
    handleUpload: (file: File) => Promise<ValidatorResponse | undefined>;
    reset: () => void;
    onFileInputChange: (
        e: React.ChangeEvent<HTMLInputElement>
    ) => Promise<void>;
    activateFileAddHandler: () => void;
}

const ValidatorContext = createContext<ValidatorContextProps | undefined>(
    undefined
);

export function ValidatorProvider({ children }: { children: ReactNode }) {
    const [state, setState] = useState<State>(defaultState);
    const timeoutIdRef = useRef<number | NodeJS.Timeout | null>(null);

    function trackAction(action: string, description?: string) {
        analytics.track(`validator-service - ${action}`, {
            description: description || 'not specified',
        });
    }

    async function handleUpload(file: File) {
        try {
            const response = await validatorPDFUploadMultipart(file);

            if (
                !response.document.signatureFound ||
                !response.document.signatureValid
            ) {
                setState((prev) => ({
                    ...prev,
                    utilityState: UtilityState.VALIDATION_ERROR,
                }));
            } else {
                setState((prev) => ({
                    ...prev,
                    utilityState: UtilityState.UPLOAD_SUCCESS,
                }));
            }

            return response;
        } catch (error) {
            setState((prev) => ({
                ...prev,
                utilityState: UtilityState.UPLOAD_ERROR,
            }));
        }
    }

    function reset() {
        if (timeoutIdRef.current) {
            clearTimeout(timeoutIdRef.current as number);
        }

        setState({ ...defaultState });
    }

    function activateFileAddHandler() {
        const fileUpload = document.getElementById('fileupload') as HTMLElement;

        fileUpload.click();
        trackAction('upload-document - clicked');
    }

    async function onFileInputChange(
        event: React.ChangeEvent<HTMLInputElement>
    ) {
        if (!event.target.files) return;

        trackAction('file selected for upload');

        const files = Array.from(event.target.files || []);

        if (files.length > 0) {
            setState((prev) => ({
                ...prev,
                utilityState: UtilityState.UPLOADING,
                caseDocument: files[0],
            }));

            const now = new Date();

            try {
                const response = await handleUpload(files[0]);

                setState((prev) => ({
                    ...prev,
                    validatorResponse: response,
                    uploadTime: unixTimeToUTC(Math.floor(now.getTime() / 1000)),
                }));
            } catch (error: any) {
                const decoder = new TextDecoder('utf-8');
                const jsonString = decoder.decode(error.data);

                error.data = JSON.parse(jsonString);

                setState((prev) => ({
                    ...prev,
                    utilityState: resolveErrorCode(error.data.status),
                }));

                if (timeoutIdRef.current) {
                    clearTimeout(timeoutIdRef.current as number);
                }

                timeoutIdRef.current = setTimeout(() => {
                    reset();
                }, timeOut);
            }
        }
    }
    const value: ValidatorContextProps = {
        state,
        setState,
        trackAction,
        handleUpload,
        reset,
        onFileInputChange,
        activateFileAddHandler,
    };

    return (
        <ValidatorContext.Provider value={value}>
            {children}
        </ValidatorContext.Provider>
    );
}

export function useValidator() {
    const context = useContext(ValidatorContext);

    if (!context) {
        throw new Error('useValidator must be used within a ValidatorProvider');
    }

    return context;
}
