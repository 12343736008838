import PropTypes from 'prop-types';
import React from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import Constants, { Routes, StorageKeys } from 'Constants';
import { translate } from 'Language';
import { storage } from 'Core';
import Button from 'Common/components/Button';
import { Router } from 'react-router';

type AppContextType = {
    router: Router;
};

export default class SessionErrorPage extends React.Component {
    static contextTypes = {
        router: PropTypes.object,
    };

    context!: AppContextType;

    state = {
        timer: 6,
        progress: 100,
        redirecting: false,
        render: false,
    };

    interval?: ReturnType<typeof setInterval> | null = null;

    constructor(props) {
        super(props);
        this.redirect = this.redirect.bind(this);
    }

    updateProgress = () => {
        const initialTime = 6;
        let { timer } = this.state;

        let progress = ((timer - 1) / initialTime) * 100;

        this.setState({ progress: progress });
    };

    decreaseTimer = () => {
        let { timer } = this.state;

        this.setState({ timer: timer - 1 });
    };

    componentDidMount() {
        // If the token / refresh token are not present in local storage. Redirect
        // to login page without displaying the session expiry countdown.
        if (!storage.get(Constants.PENNEO_TOKEN_KEY)) {
            this.redirect();

            return;
        }

        this.initCountdown();
    }

    initCountdown = () => {
        this.setState({ render: true });

        if (storage.get(StorageKeys.DEBUG)) {
            console.warn('Automatic redirection is disabled in debug mode');

            return;
        }

        this.tick();
    };

    componentWillUnmount() {
        if (!this.interval) {
            return;
        }

        clearInterval(this.interval);
    }

    redirect() {
        this.setState({ redirecting: true });
        const { router } = this.context;

        const { PENNEO_TOKEN_KEY, PENNEO_REFRESH_KEY } = Constants;

        if (storage.get(PENNEO_TOKEN_KEY) || storage.get(PENNEO_REFRESH_KEY)) {
            storage.clear(PENNEO_TOKEN_KEY);
            storage.clear(PENNEO_REFRESH_KEY);
        }

        if (window.location.pathname && window.location.pathname !== '/') {
            const pageInfo = {
                name: 'login-route',
                query: {
                    redirect: window.location.href,
                },
            };

            if (!router) {
                // This is just in case the router context is lost (should not happen)
                // but it also works as a beautiful test case

                window.location.assign(
                    `${Routes.login}?redirect=${encodeURIComponent(
                        window.location.href
                    )}`
                );

                return;
            }

            router.push(pageInfo);

            return;
        }

        window.location.href = Routes.logout;
    }

    handleClick = (event) => {
        event.preventDefault();
        this.setState({ timer: 0, progress: 0 });
        this.redirect();
    };

    tick = () => {
        this.interval = setInterval(() => {
            let { timer } = this.state;

            this.decreaseTimer();
            this.updateProgress();

            if (timer === 1) {
                if (!this.interval) {
                    this.redirect();

                    return;
                }

                clearInterval(this.interval);
                this.redirect();
            }
        }, 1000);
    };

    render() {
        let { progress, timer, redirecting, render } = this.state;

        if (!render) {
            return false;
        }

        return (
            <div className="error-page">
                <div className="content">
                    <TransitionGroup
                        className="redirectionWidget"
                        appear={true}>
                        <CSSTransition
                            timeout={{ enter: 500, appear: 500, exit: 300 }}>
                            <div
                                key="error-container"
                                className="error-container">
                                <div className="progress-bar">
                                    <div
                                        className={
                                            'progress' +
                                            (redirecting ? ' fast' : '')
                                        }
                                        style={{ width: progress + '%' }}></div>
                                </div>
                                <br />
                                <div className="document-stack-icon">
                                    <div className="document-image">
                                        <i className="far fa-clock"></i>
                                    </div>
                                    <div className="lines"></div>

                                    <div className="page"></div>
                                    <div className="page page-2"></div>
                                    <div className="page page-3"></div>
                                </div>
                                <TransitionGroup
                                    className="redirectionWidget"
                                    appear={true}>
                                    <CSSTransition
                                        timeout={{
                                            enter: 500,
                                            appear: 500,
                                            exit: 300,
                                        }}>
                                        <div key="0">
                                            {!redirecting ? (
                                                <div>
                                                    <h2 className="title">
                                                        {translate(
                                                            `Your session expired`
                                                        )}
                                                    </h2>
                                                    <p className="subtitle">
                                                        {translate(
                                                            'You will be redirected to Log In in'
                                                        )}
                                                        <span>
                                                            &nbsp;{timer}&nbsp;
                                                        </span>
                                                        {translate('seconds')}
                                                    </p>

                                                    <Button
                                                        theme="red"
                                                        onClick={
                                                            this.handleClick
                                                        }>
                                                        {translate(
                                                            'Redirect To Login'
                                                        )}
                                                    </Button>
                                                </div>
                                            ) : (
                                                <h2 className="title">
                                                    {translate(
                                                        'Redirecting...'
                                                    )}
                                                </h2>
                                            )}
                                        </div>
                                    </CSSTransition>
                                </TransitionGroup>
                            </div>
                        </CSSTransition>
                    </TransitionGroup>
                </div>
            </div>
        );
    }
}
