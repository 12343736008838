import React from 'react';
import { LanguageDropdown } from 'Language';
import UserDropdown from './UserDropdown';
import Constants, { env } from 'Constants';
import StatusNotification from 'Common/components/Common/StatusNotification';
import DashboardMenuItem from './DashboardMenuItem';
import LogoHeader from 'resources/images/logo-header.png';
import { Link } from 'react-router';
import { UserEntity as User } from 'types/User';
import { i18n } from 'Language';
import { LogoSvg } from '../Logo';
import BurgerMenuButton from './BurgerMenuButton';
import { useFetchIncidents } from 'Common/hooks/useFetchIncidents';

type Props = {
    user: User;
    branding: any;
    toggleCollapse: () => void;
    isCollapsed?: boolean;
    displaySigner?: boolean;
    disableUserOptions?: boolean;
    disableLink?: boolean;
};

const DashboardHeader: React.FunctionComponent<Props> = (props) => {
    const {
        user,
        branding,
        disableUserOptions,
        disableLink,
        displaySigner,
        isCollapsed,
    } = props;
    const logo = branding.imageUrl || LogoHeader;

    const isDesktopApp = env.platform === 'desktop';
    const incidents = useFetchIncidents();

    const dashboardV2 = !isDesktopApp;

    const backgroundColor = displaySigner
        ? '#F5F9FF'
        : branding.backgroundColor;

    const CustomLinkHeader = () => {
        if (displaySigner) {
            return (
                <div className="ml-16 text-[#086AFA] w-[131px]">
                    <LogoSvg />
                </div>
            );
        }

        return (
            <Link
                to={'dashboard'}
                style={{ backgroundImage: `url(${logo})` }}
                aria-label={`Dashboard`}
            />
        );
    };

    return (
        <nav
            aria-label={i18n`Dashboard header`}
            className="penneo-dashboard-header">
            {displaySigner && (
                <BurgerMenuButton
                    onClick={props.toggleCollapse}
                    isCollapsed={isCollapsed}
                    showTooltip
                    displaySigner={true}
                />
            )}

            <div
                className="dashboard-header-sidebar penneo-header-branding"
                style={
                    displaySigner
                        ? {
                              background: backgroundColor,
                              boxShadow: 'inset 0 -1px 0 0 rgba(0, 0, 0, 0.1)',
                          }
                        : { background: backgroundColor }
                }>
                <div className="logo">
                    {dashboardV2 || displaySigner ? (
                        <CustomLinkHeader />
                    ) : (
                        <a
                            href={
                                disableLink
                                    ? undefined
                                    : Constants.PENNEO_ORIGIN + '/archive'
                            }
                            style={{ backgroundImage: `url(${logo})` }}></a>
                    )}
                </div>
            </div>
            <div
                className="dashboard-header-content"
                style={displaySigner ? { background: '#F5F9FF' } : {}}>
                <div className="dashboard-menu">
                    <StatusNotification
                        offset={-30}
                        incidents={incidents ?? []}
                    />
                    {isDesktopApp && (
                        <DashboardMenuItem
                            controls={'language-menu'}
                            name={i18n`Language menu`}
                            branding={branding}>
                            <LanguageDropdown persist />
                        </DashboardMenuItem>
                    )}
                    <DashboardMenuItem
                        controls={'user-menu'}
                        name={i18n`User menu`}
                        branding={branding}>
                        <UserDropdown
                            user={user}
                            items={
                                disableUserOptions ? [] : Constants.userOptions
                            }
                        />
                    </DashboardMenuItem>
                </div>
            </div>
        </nav>
    );
};

export default DashboardHeader;
