import React from 'react';

import SignerStore from 'Casefiles/stores/SignerStore';
import Recipients from 'Common/components/Recipients';
import { getSignerValidationErrorMessage } from 'Casefiles/utils/casefileValidation';

import { UserEntity } from 'types/User';
import { CaseFileEntity } from 'types/CaseFile';
import { DocumentType } from 'types/Document';
import { SignerValidation } from 'types/DataValidation';
import { SignerRole } from 'types/SignerRole';

// Modals
import { modal } from 'Common/components/Common/Modal';
import { Recipient } from './RecipientModal';

import { i18n } from 'Language';

import LaunchDarkly, { Flags } from 'Common/LaunchDarkly';
import { RecipientSuggestionButton } from 'AuditingAccounting';
import { RecipientCopy, RecipientSigner } from 'types/Recipient';
import { RegisteredLetterRecipient } from 'RegisteredLetter/types';
import EmailMessages from './EmailMessages';
import RecipientModalExtended from './RecipientModalExtended';
import { CasefileRoundsFlowPreview } from './CasefileRounds';
import CasefileFooterNavigation from './CasefileFooterNavigation';

type Props = {
    user: UserEntity;
    casefile: CaseFileEntity;
    recipients: (RecipientSigner | RecipientCopy)[];
    signerValidation: SignerValidation;
    availableSignerTypes: SignerRole[];
    selectedDocumentTypes: DocumentType[];
    linkParams: any;
    saveAsDraftButton: Function;
    savingAsDraft: boolean;
    sending: boolean;
};

export default class CasefileRecipientStep extends React.Component<Props> {
    state = {
        editMessages: false,
    };

    openRecipientModal = (
        index?: number | null,
        recipient?: RegisteredLetterRecipient | RecipientSigner | RecipientCopy
    ) => {
        const { casefile, availableSignerTypes } = this.props;

        const config = {
            component: (
                <RecipientModalExtended
                    casefile={casefile}
                    availableSignerTypes={availableSignerTypes}
                    index={index}
                    user={this.props.user}
                    recipient={recipient as unknown as Recipient}
                />
            ),
            preventClose: false,
        };

        modal.show(config);
    };

    mapObject = (object, callback) => {
        return Object.keys(object).map((key) => {
            return callback(key, object[key]);
        });
    };

    renderTypeError = (signerTypeId, validation) => {
        let { errors, valid } = validation;

        if (valid) {
            return false;
        }

        let { code, count, required } = errors.limit;

        let signerType = SignerStore.getSignerRole(Number(signerTypeId));

        if (!signerType) {
            return false;
        }

        let message = getSignerValidationErrorMessage(
            required,
            count,
            signerType,
            code
        );

        // Display document type limit errors
        return (
            <div className="document-type-requirements-item" key={signerTypeId}>
                <i className="fas fa-info-circle" />
                &nbsp;
                {message}
            </div>
        );
    };

    renderRequirements = () => {
        let { setup, signerTypes } = this.props.signerValidation;

        if (setup.valid && signerTypes.valid) {
            return false;
        }

        return (
            <div className="documents-requirements">
                {setup.valid === false && (
                    <div>
                        {this.mapObject(setup.errors, (key, error) => (
                            <div key={key}>
                                <i className="fas fa-info-circle" />{' '}
                                {i18n(error.message)}
                            </div>
                        ))}
                    </div>
                )}

                {signerTypes.valid === false && (
                    <div className="document-type-requirements">
                        {this.mapObject(
                            signerTypes.items,
                            this.renderTypeError
                        )}
                    </div>
                )}
            </div>
        );
    };

    render() {
        let {
            recipients,
            linkParams,
            casefile,
            availableSignerTypes,
            user,
            savingAsDraft,
            sending,
        } = this.props;

        const signers = recipients.filter((recipient) => {
            return recipient.type === 'signer';
        }) as RecipientSigner[];

        return (
            <main id="content" className="casefile-process-v2 medium">
                <header className="header">
                    <h3>
                        {i18n`Who needs to sign the documents`}
                        <span className="dot">?</span>
                    </h3>
                    <p>{i18n`Add the recipients that will sign or receive a copy of the documents
                    and select the role they will have in the signing process`}</p>
                </header>

                <article
                    className="body form-v2"
                    aria-label={i18n`Recipients for the documents`}>
                    {LaunchDarkly.variation(
                        Flags.ACTIVATION_DATE_PER_ROUND
                    ) && <CasefileRoundsFlowPreview casefile={casefile} />}

                    <Recipients
                        editRecipient={this.openRecipientModal}
                        addRecipient={this.openRecipientModal}
                        recipients={recipients}
                        casefileLanguage={casefile.language}
                        header={
                            (LaunchDarkly.variation(
                                Flags.EXPERIMENT_CLIENT_SELECTOR_ENABLED
                            ) && (
                                <RecipientSuggestionButton
                                    hasRecipients={recipients.length > 0}
                                    casefile={casefile}
                                    availableSignerTypes={availableSignerTypes}
                                    user={user}
                                />
                            )) ??
                            null
                        }
                    />

                    {/* Render Validation */}
                    {this.renderRequirements()}
                </article>

                {LaunchDarkly.variation(Flags.SIGNER_SPECIFIC_EMAILS) && (
                    <>
                        <header className="header">
                            <h3>
                                {i18n`What message do you want to send to the recipients?`}
                            </h3>
                            <p>{i18n`All recipients will receive the general message. You have the option to customize messages for different recipients if they need different information.`}</p>
                        </header>

                        <article
                            className="body form-v2"
                            aria-label={i18n`Email messages for the recipients`}>
                            <EmailMessages
                                casefileLanguage={this.props.casefile.language}
                                recipients={signers}
                            />
                        </article>
                    </>
                )}

                <CasefileFooterNavigation
                    currentRoute="recipients"
                    saveAsDraftButton={this.props.saveAsDraftButton}
                    linkParams={linkParams}
                    linkTo="casefile-review"
                    backLink="casefile-documents"
                    savingAsDraft={savingAsDraft}
                    sending={sending}
                    ariaLabel={i18n`Recipient step actions`}
                />
            </main>
        );
    }
}
