import React from 'react';
import CredentialActions from '../../actions/CredentialActionCreators';
import RequestStore from '../../stores/RequestStore';
import Loader from 'Common/components/Common/Loader';
import Button from 'Common/components/Button';
import CredentialStore from '../../stores/CredentialStore';
import { i18n } from 'Language';
import lodash from 'lodash';
import { AUTH_METHODS, AuthMethod } from 'Constants';
import { LOGIN_METHODS, LoginMethod } from 'EID';
import { UserEntity as User } from 'types/User';

type Props = {
    user: User;
    requestStatus: string;
};

export default class ManageUserCredentials extends React.Component<Props> {
    componentWillMount() {
        RequestStore.clearRequestStatus();
    }

    handleSendLoginRequest = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        event.preventDefault();
        CredentialActions.createCredentialRequest(this.props.user.id, 'all');
    };

    resetStatus = () => {
        RequestStore.clearRequestStatus();
    };

    renderCredentialsList = () => {
        const { user } = this.props;

        //Generate new array, to not modify the memory pointer of the CredentialStore (we should use immer perhaps in this instance)
        const userCredentials: string[] = CredentialStore.getUserCredentials(
            user.id
        ).allowed.slice();

        lodash.pull(userCredentials, 'api');

        const supportedMethods = (
            AUTH_METHODS as (AuthMethod | LoginMethod)[]
        ).concat(LOGIN_METHODS);

        type FilteredCredential = {
            key: string;
            title: string;
        };

        const filteredCredentials: FilteredCredential[] =
            userCredentials.reduce<FilteredCredential[]>((acc, credential) => {
                const matchedMethod = supportedMethods.find(
                    (method) => method.credentialType() === credential
                );

                if (matchedMethod) {
                    acc.push({ key: credential, title: matchedMethod.title });
                }

                return acc;
            }, []);

        return (
            <ul className="credentials-list">
                {filteredCredentials.map(({ key, title }) => (
                    <li key={key}>
                        <i className="fa fa-circle" />
                        &nbsp;
                        {i18n(title)}
                    </li>
                ))}
            </ul>
        );
    };

    renderDefaultContent = () => {
        const { user } = this.props;

        //Generate new array, to not modify the memory pointer of the CredentialStore (we should use immer perhaps in this instance)
        const userCredentials: string[] = CredentialStore.getUserCredentials(
            user.id
        ).allowed.slice();

        lodash.pull(userCredentials, 'api');

        return (
            <div className="form">
                <p>
                    {i18n`If ${(
                        <b>{user.fullName}</b>
                    )} has changed or forgotten any credentials
                          and cannot log in anymore. You have the ability to send a link via email
                          that will allow the configuration of a new authentication method.`}
                </p>

                <p>
                    {i18n`${(
                        <b>{user.fullName}</b>
                    )} will receive a link via email at
                          ${(
                              <b>{user.email}</b>
                          )} that leads to a page where the user
                          can manage his credentials.`}
                </p>

                <p>
                    {i18n`${(
                        <b>{user.fullName}</b>
                    )} has the following authentication methods.`}
                </p>

                {this.renderCredentialsList()}

                <Button onClick={this.handleSendLoginRequest} theme="blue">
                    {i18n('Send reset email')}
                </Button>
            </div>
        );
    };

    renderSuccess = () => {
        const { user } = this.props;

        return (
            <div>
                <h3>{i18n('Reset request sent successfully')}</h3>
                <p>
                    {i18n('An email was sent to ')}
                    <b>{user.fullName}</b>
                    {i18n(' at ')}
                    <b>{user.email}</b>
                </p>

                <Button theme="blue" onClick={this.resetStatus}>
                    {i18n('Accept')}
                </Button>
            </div>
        );
    };

    renderFailure = () => {
        return (
            <div>
                <h3>{i18n('There was a problem with your request')}</h3>
                <Button onClick={this.resetStatus}>{i18n('Retry')}</Button>
            </div>
        );
    };

    renderContent = () => {
        const { requestStatus } = this.props;

        switch (requestStatus) {
            case 'request':
                return <Loader type="dots" />;
            case 'success':
                return this.renderSuccess();
            case 'failure':
                return this.renderFailure();
            default:
                return this.renderDefaultContent();
        }
    };

    render() {
        return (
            <div className="form manage-user-credentials">
                {this.renderContent()}
            </div>
        );
    }
}
