import keyMirror from 'keymirror';

const Constants = {
    SUPPORT_URL: 'https://www.support.penneo.com/hc/en-gb/requests/new',

    // Utility Constants
    IMAGE_PATH: '/images/',
    DATE_FORMAT: 'DD/MM/YYYY',
    DATETIME_FORMAT: 'DD/MM/YYYY',

    AUTH_METHODS: {
        SESSION: 'SID',
        SECRET: 'SECRET',
        JWT: 'JWT',
        PUBLIC: 'PUBLIC',
    },

    // @link: _variables.scss
    COLORS: {
        white: '#FFFFFF',
        formHighlight: '#FFEB9B',
    },

    // event name triggered from store, listened to by views
    CHANGE_EVENT: 'change',

    // Each time you add an action, add it here... They should be past-tense
    ActionTypes: keyMirror({
        // Form Actions
        FIELD_UPDATED: null,
        FORM_SAVED: null,
        FORM_PUBLISHED: null,
        FORM_UNPUBLISHED: null,
        PUBLIC_FORM_LOADED: null,
        PUBLIC_FIELDS_LOADED: null,
        PUBLIC_MAPPINGS_LOADED: null,
        PUBLIC_FORM_DOCUMENT_LOADED: null,
        PUBLIC_FORM_IMAGES_LOADED: null,
        FORM_LOADED: null,
        DOCUMENT_LOADED: null,
        FIELDS_LOADED: null,
        MAPPINGS_LOADED: null,
        FORM_TEMPLATE_UPDATED: null,
        FORM_MAPPING_CREATED: null,
        FORM_MAPPING_DELETED: null,

        // Batch Actions
        CSV_UPLOADED: null,
        CSV_MAPPING_SAVED: null,
        MAPPED_WORKFLOW_FIELDS_LOADED: null,

        // Workflow Actions
        WORKFLOW_RESUMED: null,
        WORKFLOW_REJECT: null,
        WORKFLOW_TAGGED: null,
        WORKFLOW_UNTAGGED: null,
        WORKFLOW_FETCH_BY_ID: null,
        ATTACHMENT_UPLOADED: null,
        WORKFLOW_INSTANTIATED: null,
        WORKFLOW_INSTANTIATE_FAILED: null,
        WORKFLOW_PROTOTYPE_PUBLISHED: null,
        WORKFLOW_PROTOTYPE_UNPUBLISHED: null,
        WORKFLOW_PROTOTYPE_LOADED: null,
        WORKFLOW_PROTOTYPE_NOT_FOUND: null,
        WORKFLOW_DELETED: null,
        WORKFLOW_DELETE_FAILED: null,

        // Workflow Comment Actions
        GET_COMMENTS: null,
        CURRENT_COMMENT_UPDATED: null,
        COMMENT_ADDED: null,
        COMMENT_UPDATED: null,
        COMMENT_REMOVED: null,
        COMMENT_SAVED: null,

        // Branding Actions
        BRANDING_UPDATED: null,
        FETCH_BRANDING: null,
        FETCH_BRANDING_TOKEN: null,
        APPLY_DEFAULT_BRANDING: null,

        // Auth Actions
        TOKEN_UPDATED: null,
        TOKEN_AUTHENTICATED: null,
        AUTH_UPDATED: null,
        AUTH_BYPASSED: null,
        SESSION_AUTHENTICATED: null,
        AUTHENTICATION_FAILED: null,

        // Workflow Activity Log
        LOAD_ACTIVITY_LIST: null,
        RESET_ACTIVITY_LIST: null,

        // Workflow Attachments
        ATTACHMENTS_LOADED: null,
        ATTACHMENT_ADDED: null,
        ATTACHMENT_DOWNLOADED: null,

        // Workflow Details
        DETAIL_LIST_LOADED: null,
        DETAIL_WORKFLOW_LOADED: null,
        DETAIL_CASEFILE_STATUSES_LOADED: null,

        // Collections
        COLLECTION_ADDED: null,
        FETCH_CASEFILES_SUCCESS: null,
        FETCH_BASE64_DOCUMENT: null,
        FETCH_MESSAGE_TEMPLATES_SUCCESS: null,

        // Form Prototypes
        FORM_PROTOTYPE_LOADED: null,

        FORM_PROTOTYPE_CREATED: null,
        FORM_PROTOTYPE_CREATION_FAILURE: null,
        FORM_PROTOTYPE_UPDATED: null,
        WORKFLOW_PROTOTYPE_CREATED: null,
        WORKFLOW_PROTOTYPE_UPDATED: null,
        PROCESSING_STATE_CHANGED: null,

        // Editor View Actions
        EDITOR_SETTING_UPDATED: null,
        EDITOR_FIELD_ADDED: null,
        EDITOR_DONE: null,
    }),

    ActionSources: keyMirror({
        SERVER_ACTION: null,
        VIEW_ACTION: null,
    }),

    ItemTypes: {
        FIELD: 'field',
    },

    FieldTypes: [
        { id: 'text', name: 'Text' },
        { id: 'textarea', name: 'Multiline Text' },
        { id: 'select', name: 'Multiple Selection' },
        { id: 'email', name: 'Email' },
        { id: 'date', name: 'Date' },
        { id: 'ssn', name: 'Social Security Number' },
        { id: 'vatin', name: 'VATIN' },
    ],

    PROTECTED_FIELDS: [
        {
            type: 'text',
            name: 'RECIPIENT_NAME',
            label: 'RECIPIENT_NAME',
            required: true,
            editable: true,
            order: 1,
            metaData: {
                type: 'text',
            },
        },
        {
            type: 'text',
            label: 'RECIPIENT_EMAIL',
            name: 'RECIPIENT_EMAIL',
            required: true,
            editable: true,
            order: 2,
            metaData: {
                type: 'email',
            },
        },
    ],
};

export default Constants;
