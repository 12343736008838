import React, { useEffect, useRef, useState } from 'react';

import LaunchDarkly, { Flags } from 'Common/LaunchDarkly';
import {
    fetchEntities,
    entitiesState,
    setSelectedEntity,
    CaseFileDetailed,
    Entity,
    CompanyPerson,
} from 'Entities/redux/reducer';

import { SignerEntity } from 'types/Signer';
import { Link } from 'react-router';

import Button from 'Common/components/Button';
import { useAppSelector, useAppDispatch } from 'Store';
import Table, { Config } from 'Common/components/BaseTable/Table';
import Box from 'Common/components/Box';
import Loader from 'Common/components/Common/Loader.jsx';
import './client.scss';

type File = {
    fileId: number;
    fileData: { id: string } & Record<string, unknown>;
    recipients: SignerEntity[];
};
type ClientProps = {
    params: Record<string, string>;
};
const Client = (props: ClientProps) => {
    const dispatch = useAppDispatch();
    const { selectedEntity, entities, fetchedEntities } =
        useAppSelector(entitiesState);

    const [files, updateFiles] = useState<File[]>([]);

    const inputRef = useRef(null);

    useEffect(() => {
        if (!LaunchDarkly.variation(Flags.CLIENT_ENTITIES_ACCESS)) {
            document.location.replace('/404');
        }

        LaunchDarkly.variation(Flags.CLIENT_ENTITIES_ACCESS) &&
            !fetchedEntities &&
            dispatch(fetchEntities());
    }, []);

    useEffect(() => {
        const { params } = props;

        if (!selectedEntity && fetchedEntities && entities.length > 0) {
            const entity = entities.find(
                (company) => company.vatin === params.cvr
            );

            if (!entity) return;

            dispatch(setSelectedEntity(entity));
        }
    }, [fetchedEntities, entities]);
    const { cases }: Entity = (selectedEntity as Entity) ?? {
        cases: null,
    };

    const latestCase = cases?.reduce((prev, curr) => {
        return (prev.id < curr.id && curr) || prev;
    }, cases[0]);

    const latestCaseSigners = latestCase?.signers.map((signer) => ({
        ...signer,
        role: signer.types.map((t) => t.role).join(','),
    }));

    const allSigners = cases
        ?.reduce(
            (prev: SignerEntity[] | [], curr: CaseFileDetailed) =>
                [...prev, ...(curr?.signers as SignerEntity[])].filter(
                    (v) => v.name !== (curr as unknown as SignerEntity).name
                ),
            []
        )
        .map((signer: SignerEntity) => {
            return {
                ...signer,
                role: signer.types
                    .map((t) => {
                        return t.role;
                    })
                    .join(','),
            };
        });

    const handleFileChange = (e) => {
        const { files } = e.target;
        let i = 0;
        let fileArr = [] as File[];

        while (files.length > i) {
            const fileId = i;
            const file = {
                fileId,
                fileData: files?.[i],
                recipients: [...(latestCaseSigners as SignerEntity[])].map(
                    (signer) => ({
                        ...signer,
                        fileId,
                    })
                ),
            };

            fileArr.push(file);
            i++;
        }

        updateFiles(fileArr);
    };

    const handleDeleteRecipient = (data: { id: number; fileId: number }) => {
        const { recipients } = files.find(
            (file) => file.fileId === data.fileId
        ) ?? { recipients: null };

        if (!recipients) return;

        const copyRecipients = recipients.filter(
            (recipient) => recipient.id !== data.id
        );

        const newFiles = files.map((file) => {
            if (file.fileId !== data.fileId) {
                return file;
            }

            return { ...file, recipients: copyRecipients };
        });

        updateFiles(newFiles);
    };

    const allPersons = selectedEntity?.persons;

    const defaultProps = {
        isFetching: false,
        isLoading: false,
        config: {
            name: {
                label: 'Name',
            },
            role: {
                label: 'Role',
            },
            button: {
                label: '',
                disableSort: true,
                component: (data) => (
                    <Button
                        renderAsSpan
                        theme="red"
                        variant="outline"
                        icon="far fa-trash"
                        onClick={() => handleDeleteRecipient(data)}>
                        Delete
                    </Button>
                ),
            },
        } as Config,
        page: 1,
        dataCount: entities?.length ?? 0,
        onNext: (offset: number) => null,
        onPrev: (offset: number) => null,
        onLimitChange: (value: number) => null,
        onSortChange: (sort: string) => null,
    };

    return (
        (LaunchDarkly.variation(Flags.CLIENT_ENTITIES_ACCESS) && (
            <div className="clients-container">
                {(((fetchedEntities && !cases) || !fetchedEntities) && (
                    <Box className="clients-full">
                        {!fetchedEntities && <Loader />}
                        {fetchedEntities && !cases && (
                            <>
                                <p>No client have been selected</p>
                                <Link
                                    to={{
                                        name: 'clients',
                                    }}>
                                    Go to Clients dashboard
                                </Link>
                            </>
                        )}
                    </Box>
                )) ??
                    null}
                {(cases && fetchedEntities && (
                    <>
                        <Box className={'recipient-container'}>
                            <div className={'recipient-block'}>
                                <header>
                                    <h2>{`${selectedEntity?.name} - CVR: ${selectedEntity?.vatin}`}</h2>
                                    <p>
                                        <strong>
                                            {latestCase?.caseFileType?.name}
                                        </strong>{' '}
                                        - Baseret ud fra{' '}
                                        <em>{latestCase?.title}</em>
                                    </p>
                                </header>
                                <ul className="recipient-list">
                                    {allPersons?.map(
                                        (person: CompanyPerson) => (
                                            <li
                                                key={person.id}
                                                className={'recipient'}>
                                                {person.name}
                                                <br />
                                                <strong>
                                                    {person?.roles.join(', ')}
                                                </strong>
                                                <br />
                                                {allSigners?.find(
                                                    (signer) =>
                                                        signer.name ===
                                                        person.name
                                                )?.signingRequest?.email ?? ''}
                                            </li>
                                        )
                                    )}
                                </ul>
                            </div>
                        </Box>
                        <div className="documents-container">
                            {files && files.length > 0 && (
                                <Box className="file-container">
                                    {files?.map((file, index) => (
                                        <div
                                            key={`file-${index}`}
                                            className="file-details">
                                            <header className="file-header">
                                                <h4>
                                                    <>
                                                        <i className="icon far fa-file-pdf"></i>
                                                        {file.fileData!.name}
                                                    </>
                                                </h4>
                                                <select>
                                                    {latestCase?.documents?.map(
                                                        (document) => (
                                                            <option
                                                                key={
                                                                    document
                                                                        ?.type
                                                                        ?.id
                                                                }
                                                                value={
                                                                    document
                                                                        ?.type
                                                                        ?.id
                                                                }>
                                                                {
                                                                    document
                                                                        ?.type
                                                                        ?.name
                                                                }
                                                            </option>
                                                        )
                                                    )}
                                                </select>
                                            </header>

                                            <Table
                                                {...{
                                                    ...defaultProps,
                                                    dataSource:
                                                        file!.recipients,
                                                }}
                                            />
                                        </div>
                                    ))}
                                </Box>
                            )}
                            <input
                                type="file"
                                name="documents"
                                className="document-input"
                                multiple
                                ref={inputRef}
                                onChange={(e) => handleFileChange(e)}
                            />
                        </div>
                    </>
                )) ??
                    null}
            </div>
        )) ??
        null
    );
};

export default Client;
