import { useReducer } from 'react';
import { PublicValidatorApi } from 'Api/ApiClient';
import { Signature } from '../Models/ValidatorResponse';

type IdentityState = {
    identityCheckValue: string;
    match: boolean;
    checkedIdentity: boolean;
    status: IdentityStatus;
};

type IdentityStatus = 'idle' | 'ready' | 'checking' | 'success' | 'failure';

type Action =
    | { type: 'SET_VALUE'; payload: string }
    | { type: 'CHECK_START' }
    | { type: 'CHECK_SUCCESS'; payload: boolean }
    | { type: 'CHECK_FAILURE' };

function identityReducer(state: IdentityState, action: Action): IdentityState {
    switch (action.type) {
        case 'SET_VALUE':
            const updatedValue = action.payload;
            const isLongEnough = updatedValue.length > 9;

            return {
                ...state,
                identityCheckValue: action.payload,
                checkedIdentity: false,
                match: false,
                status: isLongEnough ? 'ready' : 'idle',
            };
        case 'CHECK_START':
            return { ...state, status: 'checking' };
        case 'CHECK_SUCCESS':
            return {
                ...state,
                status: action.payload ? 'success' : 'failure',
                match: action.payload,
                checkedIdentity: true,
            };
        case 'CHECK_FAILURE':
            return {
                ...state,
                checkedIdentity: true,
                status: 'failure',
            };
        default:
            return state;
    }
}

export function useIdentityCheck(signature: Signature) {
    const [state, dispatch] = useReducer(identityReducer, {
        identityCheckValue: '',
        match: false,
        checkedIdentity: false,
        status: 'idle',
    });

    async function checkIdentity() {
        if (state.status !== 'ready') {
            return;
        }

        dispatch({ type: 'CHECK_START' });

        try {
            const response = await PublicValidatorApi.post(
                '/public/api/v1/validate-signature',
                {
                    socialSecurityNumber: state.identityCheckValue,
                    type: signature.type,
                    openIdIssuer: signature.openIdIssuer,
                    certificateSerialNumber: signature.certificateSerialNumber,
                    serialNumber: signature.serialNumber,
                }
            );

            dispatch({ type: 'CHECK_SUCCESS', payload: response.match });
        } catch (error) {
            dispatch({ type: 'CHECK_FAILURE' });
        }
    }

    function setValue(value: string) {
        dispatch({ type: 'SET_VALUE', payload: value });
    }

    return {
        ...state,
        setValue,
        checkIdentity,
    };
}
