import React, { PropsWithChildren } from 'react';
import RouterV3Tabs from 'Common/components/RouterV3Tabs';
import { CreateLink } from 'types/Router';
import { TabbedRoute } from 'types/TabbedRoute';

type Props = PropsWithChildren<{
    userId: number;
    customerId: number | null;
    options: TabbedRoute[];
    // Support alternate link creation
    createLink?: CreateLink;
}>;

export default class CredentialManagementTabs extends React.Component<Props> {
    getRouteFromOption = (option: TabbedRoute): TabbedRoute => {
        const { path, ...rest } = option;

        const params = {
            userId: this.props.userId,
            ...(this.props.customerId && { customerId: this.props.customerId }),
        };

        const route =
            this.props.createLink && !!path
                ? this.props.createLink(path, params)
                : { name: path, params };

        // Exclude `path` property
        return { ...rest, ...route };
    };

    render() {
        const { options } = this.props;
        const routes = options.map(this.getRouteFromOption);

        return (
            <RouterV3Tabs routes={routes}>{this.props.children}</RouterV3Tabs>
        );
    }
}
