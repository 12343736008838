import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';

type Props = {
    className?: string;
};

export const Divider: FC<Props> = ({ className }: Props) => {
    return <div className={twMerge('bg-gray-100 h-0.5 w-full', className)} />;
};
