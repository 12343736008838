import PropTypes from 'prop-types';
import React from 'react';
import Modal from 'Common/components/Common/Modal';
import Notification from 'react-notify-toast';
import { StorageKeys } from 'Constants';
import { storage } from 'Core';
import assign from 'object-assign';
import { Helmet } from 'react-helmet';
import AuthActionCreators from 'Auth/actions/AuthActionCreators';

class AppContainer extends React.Component {
    static contextTypes = {
        router: PropTypes.object,
    };

    static propTypes = {
        children: PropTypes.object,
        location: PropTypes.object,
    };

    componentDidMount() {
        let { location } = this.props;

        if (!location.query) {
            return false;
        }

        if (location.query.experimental === 'true') {
            storage.set(StorageKeys.EXPERIMENTAL, true);
            this.removeQuery('experimental');
        }

        if (location.query.experimental === 'false') {
            storage.clear(StorageKeys.EXPERIMENTAL);
            this.removeQuery('experimental');
        }

        window.addEventListener('storage', this.handleTokenUpdates, false);
        window.addEventListener('storage', (event) => {
            if (event.key === StorageKeys.LOGOUT && event.newValue === 'true') {
                AuthActionCreators.endCurrentSession();
            }
        });
    }

    removeQuery = (queryKey) => {
        let query = assign({}, this.props.location.query);

        delete query[queryKey];

        let href = this.context.router.createHref({
            pathname: this.props.location.pathname,
            query: query,
        });

        window.location.replace(href);
    };

    render() {
        const notificationOptions = {
            zIndex: 1000002, // Display over header and modals
            colors: {
                // colors extracted from _variables.scss
                error: {
                    color: '#FFFFFF',
                    backgroundColor: '#E85742',
                },
                success: {
                    color: '#FFFFFF',
                    backgroundColor: '#16BC65',
                },
                warning: {
                    color: '#333333',
                    backgroundColor: '#FAD470',
                },
                info: {
                    color: '#FFFFFF',
                    backgroundColor: '#3F99F1',
                },
            },
            animationDuration: 600,
        };

        return (
            <>
                <Helmet titleTemplate="%s - Penneo" defaultTitle="Penneo">
                    <meta charSet="utf-8" />
                    <meta name="description" content="Penneo" />
                </Helmet>
                <div className="ui-v2">
                    <Notification options={notificationOptions} />{' '}
                    {/* display over header */}
                    <Modal />
                    {React.cloneElement(this.props.children)}
                </div>
            </>
        );
    }
}

export default AppContainer;
