import React, { PropsWithChildren } from 'react';

export type Props = PropsWithChildren<{
    top?: JSX.Element;
}>;

const FullScreen: React.FunctionComponent<Props> = (props) => {
    const { top, children } = props;

    return (
        <div className="fullscreen">
            {top}

            <div className="vertical-center">{children}</div>
        </div>
    );
};

export default FullScreen;
