import React from 'react';
import Constants from 'Constants';
import PropTypes from 'prop-types';
import { i18n } from 'Language';
import { Link } from 'react-router';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { ReduxState, AppDispatch } from 'Store';
import Button from 'Common/components/Button';
import { fetchKYCById } from 'KYC/redux/actions';
import { KYCListItem, KYCListItemField } from 'KYC/redux/types';
import StatusBanner from 'Casefiles/CasefileDetails/StatusBanner';
import CasefileDocuments from 'Casefiles/CasefileDetails/CasefileDocuments';
import {
    fetchCasefile,
    fetchCasefileAttachments,
} from 'Casefiles/CasefileDetails/redux/actions';
import CasefileAttachmentsSection from 'Casefiles/CasefileDetails/CasefileAttachmentsSection';
import { getKYCCasefileStatus } from 'Casefiles/CasefileDetails/statusCodes';
import { CLEAR_CASEFILE_DETAILS_REDUCER } from 'Casefiles/CasefileDetails/redux/action-types';
import { KYC_CLEAR_ERROR } from 'KYC/redux/reducer';
import Analytics from 'Common/Analytics';
import { sentry } from 'Common/SentryLogger';
import LaunchDarkly, { Flags } from 'Common/LaunchDarkly';
import AccessDenied from 'Common/components/AccessDenied';
import { showKYCDeleteModal } from 'KYC/components/KYCDeleteModal';
import LoadingData from 'Common/components/Loaders/LoadingData';
import './kyc-item-details.scss';
import { Router } from 'react-router';

type AppContextType = {
    router: Router;
};

type Props = {
    params: {
        id: number;
    };
    router: any;
    modal: JSX.Element;
    dispatch: AppDispatch;
    caseFile: ReduxState['caseFileDetails']['casefile'];
    attachments: ReduxState['caseFileDetails']['attachments'];
    breadcrumbs: any[];
    isLoaded: boolean;
    error: any;
    kyc?: KYCListItem;
};

type State = {
    hasError: boolean;
};

class KYCItemDetails extends React.Component<Props, State> {
    static contextTypes = {
        router: PropTypes.object,
    };

    context!: AppContextType;

    constructor(props: Props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        error.name = '[componentDidCatch] KYCItemDetails';
        sentry.track(error, { ...errorInfo, ...this.props });
    }

    componentDidMount() {
        const { dispatch, kyc } = this.props;

        dispatch({
            type: CLEAR_CASEFILE_DETAILS_REDUCER,
        });

        if (kyc && kyc.caseFileId) {
            this.loadCasefileDetails(kyc.caseFileId);
        }

        this.loadData();
    }

    componentWillUnmount() {
        const { dispatch } = this.props;

        dispatch({ type: CLEAR_CASEFILE_DETAILS_REDUCER });
        dispatch({ type: KYC_CLEAR_ERROR });
    }

    async loadData() {
        const { dispatch } = this.props;

        await dispatch(fetchKYCById(this.props.params.id));
    }

    componentDidUpdate(prevProps) {
        const kycLoaded = !prevProps.kyc && this.props.kyc;

        // Load casefile details / documents
        if (kycLoaded && this.props.kyc?.caseFileId) {
            this.loadCasefileDetails(this.props.kyc.caseFileId);
        }

        if (!prevProps.isLoaded && this.props.isLoaded && !this.props.error) {
            this.trackEvent('kyc details - data loaded');
        }
    }

    async loadCasefileDetails(casefileId: number) {
        const { dispatch } = this.props;

        await dispatch(fetchCasefile(casefileId));
        await dispatch(fetchCasefileAttachments(this.props.caseFile.data));
    }

    renderRecipient() {
        const kyc = this.props.kyc as KYCListItem;

        return (
            <div className="casefile-recipient pointer-events-none">
                <div className="casefile-recipient-container">
                    <div className="casefile-recipient-name">
                        {kyc.recipient.name}
                    </div>

                    <div className="casefile-recipient-email">
                        <i className="far fa-envelope text-small"></i>&nbsp;
                        {kyc.recipient.email}
                    </div>
                </div>
            </div>
        );
    }

    renderField(field: KYCListItemField) {
        switch (field.name) {
            case 'ssn':
                return (
                    <span>
                        <i className="far fa-id-badge"></i>&nbsp;
                        {i18n`Social security number`}
                    </span>
                );
            case 'address':
                return (
                    <span>
                        <i className="far fa-map-marker-alt"></i>&nbsp;
                        {i18n`Address`}
                    </span>
                );
            case 'phone':
                return (
                    <span>
                        <i className="far fa-phone"></i>&nbsp;
                        {i18n`Phone number`}
                    </span>
                );
            case 'driverLicense':
                return (
                    <span>
                        <i className="far fa-car-side"></i>&nbsp;
                        {i18n`Driver license`}
                    </span>
                );
            case 'ssnCard':
                return (
                    <span>
                        <i className="far fa-id-card"></i>&nbsp;
                        {i18n`Social security card`}
                    </span>
                );
            case 'passport':
                return (
                    <span>
                        <i className="far fa-passport"></i>&nbsp;
                        {i18n`Passport`}
                    </span>
                );
            default:
                return field.name;
        }
    }

    renderStatus() {
        const caseFileStatus = this.props.caseFile.data.status;
        const kyc = this.props.kyc as KYCListItem;

        if (!kyc.filledAt) {
            return (
                <StatusBanner
                    status={{
                        name: 'Pending',
                        color: 'yellow',
                        icon: 'far fa-paper-plane',
                        details: `Sent ${moment
                            .unix(kyc.createdAt)
                            .fromNow()} (${this.formatTimestamp(
                            kyc.createdAt
                        )})`,
                    }}
                />
            );
        }

        return <StatusBanner status={getKYCCasefileStatus(caseFileStatus)} />;
    }

    // @duplicate: CasefileDetails.tsx
    renderAttachmentsSection = () => {
        const { caseFile, attachments } = this.props;

        if (attachments.data.length) {
            const [firstSigner] = caseFile.data.signers;
            const files = attachments.data;

            return (
                <CasefileAttachmentsSection
                    title={i18n`Identity documents`}
                    signer={firstSigner}
                    files={files}
                />
            );
        }
    };

    formatTimestamp = (timestamp: number) =>
        moment.unix(timestamp).format('DD/MM/YY HH:mm');

    trackEvent = (eventName: string, extraProps: any = {}) => {
        const kyc = this.props.kyc as KYCListItem;

        Analytics.track(eventName, {
            id: kyc.id,
            caseFileId: kyc.caseFileId,
            createdAt: kyc.createdAt,
            filledAt: kyc.filledAt,
            ...extraProps,
        });
    };

    getBackLink = () => {
        const { kyc } = this.props;

        return {
            name: 'kyc-list',
            params: {
                status: kyc && kyc.filledAt ? 'filled' : 'pending',
            },
        };
    };

    render() {
        // Display an access denied page if the user doesn't have the required feature flag
        const featureFlagDisabled = !LaunchDarkly.variation(
            Flags.KYC_LIST_ENABLED
        );
        const { modal, caseFile, isLoaded, error } = this.props;

        if (featureFlagDisabled || error || this.state.hasError) {
            return (
                <AccessDenied
                    pageName={i18n`KYC Details`}
                    linkLabel={i18n`Go back`}
                    linkRoute={this.getBackLink()}
                />
            );
        }

        if (!isLoaded) {
            return <LoadingData />;
        }

        const kyc = this.props.kyc as KYCListItem;

        /**
         * The first three fields (by order) are reserved fields and internal to the process
         * and shouldn't be displayed to the user.
         * Reserved fields:
         * - {order: 1, customerName}
         * - {order: 2, recipientNameTitle}
         * - {order: 3, recipientName}
         *
         * The user editable fields are all fields ordered 4 and above that have type=text.
         * All attachments are fields with type=file
         */
        const fieldList = kyc
            ? kyc.fields.filter((f) => f.order > 3 && f.type === 'text')
            : [];
        const attachmentList = kyc
            ? kyc.fields.filter((f) => f.type === 'file')
            : [];
        const isCaseFileCompleted =
            caseFile.isLoaded && caseFile.data.status === 5;

        return (
            <div className="casefile-details">
                <Helmet>{kyc ? kyc.title : i18n`KYC Details`}</Helmet>

                <Link
                    onClick={() =>
                        this.trackEvent('kyc details - back to list')
                    }
                    className="back-link"
                    to={this.getBackLink()}>
                    <i className="fas fa-arrow-left" />
                    <div className="back-button-text">&nbsp;{i18n`Back`}</div>
                </Link>

                {!kyc.filledAt ? (
                    <Button
                        onClick={() =>
                            showKYCDeleteModal(
                                kyc,
                                () =>
                                    this.context.router.replace(
                                        this.getBackLink()
                                    ),
                                'KYC details'
                            )
                        }
                        className="pull-right"
                        theme="gray"
                        variant="outline"
                        icon="far fa-trash">
                        {i18n`Delete KYC`}
                    </Button>
                ) : (
                    <a
                        className="pull-right"
                        rel="noopener noreferrer"
                        download=""
                        href={`${Constants.PENNEO_ORIGIN}/document/pdf/${caseFile.data.documents[0].id}/attachment`}>
                        <Button
                            onClick={() =>
                                this.trackEvent('kyc details - download PDF', {
                                    documentId: caseFile.data.documents[0].id,
                                })
                            }
                            theme="blue"
                            variant="outline"
                            icon="far fa-arrow-alt-to-bottom">
                            {i18n`Download PDF`}
                        </Button>
                    </a>
                )}

                {modal && (
                    <div className="casefile-details-modal">
                        {React.cloneElement(modal, {
                            goBackRoute: {
                                name: 'kyc-item-details',
                                params: {
                                    id: this.props.params.id,
                                },
                            },
                        })}
                    </div>
                )}

                <h1 className="casefile-details-title">{kyc.title}</h1>

                {this.renderStatus()}

                {kyc.filledAt && (
                    <div className="text-semibold text-small">
                        {i18n`Created`}: {this.formatTimestamp(kyc.createdAt)}
                        {isCaseFileCompleted ? (
                            <span>
                                &nbsp;|&nbsp;
                                {i18n`Completed`}:{' '}
                                {this.formatTimestamp(caseFile.data.completed)}
                            </span>
                        ) : (
                            <span>
                                &nbsp;|&nbsp;
                                {i18n`Filled`}:{' '}
                                {this.formatTimestamp(kyc.filledAt)}
                            </span>
                        )}
                    </div>
                )}

                <h4 className="casefile-details-section-header mt">
                    {i18n`Recipient`}
                </h4>
                <div>{this.renderRecipient()}</div>

                {caseFile.isLoaded && (
                    <CasefileDocuments
                        title={i18n`KYC Confirmation`}
                        casefile={caseFile.data}
                        documents={caseFile.data.documents}
                        openDocumentDetailsRoute={{
                            name: 'kyc-document-details',
                            params: {
                                id: kyc.id,
                                documentId: caseFile.data.documents[0].id,
                            },
                        }}
                    />
                )}

                {this.renderAttachmentsSection()}

                <h4 className="casefile-details-section-header">
                    {i18n`Information Requested`}
                </h4>

                <div>
                    <ul>
                        {fieldList.map((field) => (
                            <li
                                key={field.id}
                                className="kyc-requirement-item text-semibold">
                                {this.renderField(field)}
                                {field.required && (
                                    <i
                                        style={{
                                            fontSize: '60%',
                                            position: 'relative',
                                            top: '-6px',
                                            left: '3px',
                                        }}
                                        className="fas fa-asterisk text-error"></i>
                                )}
                            </li>
                        ))}
                    </ul>
                </div>

                {this.props.attachments.data.length === 0 && (
                    <>
                        {attachmentList.length > 0 ? (
                            <>
                                <h4 className="casefile-details-section-header">
                                    {i18n`Attachments Requested`}
                                </h4>
                                <ul>
                                    {attachmentList.map((field) => (
                                        <li
                                            key={field.id}
                                            className="kyc-requirement-item text-semibold">
                                            {this.renderField(field)}
                                            {field.required && (
                                                <i
                                                    style={{
                                                        fontSize: '60%',
                                                        position: 'relative',
                                                        top: '-6px',
                                                        left: '3px',
                                                    }}
                                                    className="fas fa-asterisk text-error"></i>
                                            )}
                                        </li>
                                    ))}
                                </ul>
                            </>
                        ) : (
                            <h4 className="casefile-details-section-header">
                                {i18n`No Attachments Requested`}
                            </h4>
                        )}
                    </>
                )}
            </div>
        );
    }
}

export default connect((state: ReduxState, props: Props) => {
    const kyc = state.kyc.items.data.find(
        (kyc) => Number(props.params.id) === kyc.id
    );
    const caseFile = state.caseFileDetails.casefile;
    let isLoaded = false;

    if (kyc) {
        // If the KYC has been filled out. The loading state also has to take into account
        // the casefile being loaded before displaying the page
        if (kyc.filledAt && caseFile.isLoaded) {
            isLoaded = true;
        }

        // If the KYC is pending, there is no case file associated
        if (!kyc.filledAt) {
            isLoaded = true;
        }
    }

    const error = state.kyc.items.error || state.caseFileDetails.casefile.error;

    return {
        kyc: kyc,
        attachments: state.caseFileDetails.attachments,
        caseFile,
        isLoaded,
        error,
    };
})(KYCItemDetails);
