import React, { PropsWithChildren } from 'react';

import Fade from 'react-reveal/Fade';
import classnames from 'classnames';
import './modal.scss';
import { env } from 'Constants';

import FocusTrap from 'focus-trap-react';
type Props = PropsWithChildren<{
    onClose: Function;
    className?: string;
    title?: string | JSX.Element;
    header?: JSX.Element;
    body?: JSX.Element;
    footer?: JSX.Element;
    displayBackButton?: boolean;
    large?: boolean;
    xlarge?: boolean;
    fullscreen?: boolean;
    overflowVisible?: boolean;
    disableFocusTrap?: boolean;
    focusTrapTimeout?: number;
}>;

class Modal extends React.Component<Props> {
    componentDidMount() {
        window.addEventListener('keyup', this.handleEscKey);

        // If the modal is mounted on the signing-service embedded components, prevent scrolling on the body element
        // The signing-service's bootstrap CSS allows scrolling on the elements in the background when the modal is open
        if (env.legacy) {
            document.body.classList.add('prevent-scroll');
        }
    }

    componentWillUnmount() {
        window.removeEventListener('keyup', this.handleEscKey);

        // Remove scroll prevention class on signing-service embedded components when modal closes
        if (env.legacy) {
            document.body.classList.remove('prevent-scroll');
        }
    }

    onClose = (event: React.MouseEvent<HTMLDivElement>) => {
        event.preventDefault();
        this.props.onClose();
    };

    handleEscKey = (e: KeyboardEvent) => {
        const key = e.keyCode || e.which;

        if (key === 27) {
            this.props.onClose();
        }
    };

    render() {
        const className = classnames('penneo-modal', this.props.className, {
            fullscreen: this.props.fullscreen,
            large: this.props.large,
            xlarge: this.props.xlarge,
        });

        return (
            <FocusTrap
                active={!this.props?.disableFocusTrap}
                focusTrapOptions={{
                    checkCanFocusTrap: () => {
                        return new Promise((res) => {
                            return setTimeout(
                                res,
                                this.props?.focusTrapTimeout ?? 250
                            ); //make sure transitions are done!
                        });
                    },
                    onDeactivate: () => this.props?.onClose(),
                }}>
                <div className={className}>
                    <Fade duration={250} distance="30px" top>
                        <div className="penneo-modal-container">
                            <div
                                className="penneo-modal-actions"
                                onClick={this.onClose}>
                                <i className="far fa-times" />

                                <div className="penneo-modal-actions-shortcut">
                                    <span>esc</span>
                                </div>
                            </div>

                            <div className="penneo-modal-content">
                                <div className="penneo-modal-header">
                                    {this.props.header}

                                    {this.props.title && (
                                        <h3 className="mt0">
                                            {this.props.title}
                                        </h3>
                                    )}
                                </div>
                                <div
                                    className={classnames(
                                        'penneo-modal-body',
                                        this.props.overflowVisible &&
                                            'overflow-visible'
                                    )}>
                                    {this.props.body || this.props.children}
                                </div>
                                <div className="penneo-modal-footer">
                                    {this.props.footer}
                                </div>
                            </div>
                        </div>
                    </Fade>
                </div>
            </FocusTrap>
        );
    }
}

export default Modal;
