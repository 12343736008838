import { Button } from '@penneo/vega-ui-react';
import { i18n } from 'Language';
import React from 'react';
import { ReactRouter, Route } from 'types/Router';
import { useAppSelector } from 'Store';
import { images } from 'Constants';

type Props = {
    saveCasefileDraft: () => void;
    sendCasefile: () => void;
    router: ReactRouter;
    backRoute: Route;
    isValid: boolean;
};

const CasefileRoundsFlowActions = ({
    saveCasefileDraft,
    sendCasefile,
    router,
    backRoute,
    isValid,
}: Props) => {
    const dateErrors = useAppSelector((state) => state.rounds.dateErrors);
    const roundDateError = dateErrors.some((error) => error === true);

    return (
        <div className="casefile-round-actions-section">
            <div className="casefile-round-action mr-auto">
                <Button
                    onClick={() => router.push(backRoute)}
                    priority="tertiary"
                    type="neutral"
                    size="md"
                    leftIcon={<i className="far fa-chevron-left" />}>
                    {i18n`Back`}
                </Button>
            </div>
            <div className="casefile-round-action mr-6">
                <Button
                    onClick={() => saveCasefileDraft()}
                    priority="tertiary"
                    type="accent"
                    size="md">
                    {i18n`Save as draft`}
                </Button>
            </div>
            <div className="casefile-round-action">
                <Button
                    onClick={() => sendCasefile()}
                    priority="primary"
                    type="accent"
                    size="md"
                    isDisabled={!isValid || roundDateError}
                    leftIcon={
                        <img
                            className="inline-block pt-1"
                            src={`${images}/casefile/flow/schedule-casefile-icon.svg`}
                        />
                    }>
                    {i18n`Schedule for sending`}
                </Button>
            </div>
        </div>
    );
};

export default CasefileRoundsFlowActions;
