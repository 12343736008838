/* eslint max-len: 0 */
import lodash from 'lodash';
import React from 'react';
import { i18n } from 'Language';
import { isLegacySSN, isSSNValid } from 'utils';
import { DocumentEntity } from 'types/Document';
import { CaseFileType } from 'types/CaseFile';
import { Recipient } from 'Casefiles/components/casefiles2/RecipientModal';
import { NewCasefileState } from 'Casefiles/redux/newCaseFile/types';
import { PersonIdentifierType } from '../../EID/types';

// Casefile

let assert = {
    notEmpty: (str) =>
        (str && str.length > 0 && str.trim().length > 0) || false,
    isSelected: (option) => (option && option !== -1) || false,
    isEmailValid: (email: string) => {
        if (!email) {
            return true;
        }

        // We do not allow for emails of longer than 254 characters
        // Why?:
        // https://www.rfc-editor.org/errata_search.php?rfc=3696
        // https://www.rfc-editor.org/rfc/rfc5321#section-4.5.3.1
        // Whilst SES of course will error out, we can from the user point of view
        // stop them in doing something super bad
        const regex =
            /^(?=^.{1,254}$)[^\s@]+@[^\s@]+\.[^\s@]{1,63}(?<=[a-zA-Z0-9])$/;

        return regex.test(email.trim());
    },
    isSsnValid: (ssn, ssnType) => !ssn || isSSNValid(ssn, ssnType),
};

let validateCasefileDetails = (casefile) => {
    let { title, caseFileTypeId } = casefile;

    let rules = {
        title: [
            {
                valid: assert.notEmpty(title),
                code: 'casefile-title-required',
                message: i18n`Title cannot be empty`,
            },
        ],
        caseFileTypeId: [
            {
                valid: assert.isSelected(caseFileTypeId),
                code: 'casefile-type-required',
                message: `You must select a case file type`,
            },
        ],
    };

    return _evaluateRules(rules);
};

let validateDocumentSetup = (documents) => {
    let rules = {
        count: [
            {
                valid: documents.length > 0,
                code: 'casefile-document-count',
                message: i18n`You must add at least one document`,
            },
        ],
    };

    return _evaluateRules(rules);
};

const documentHasSigners = (
    document: DocumentEntity,
    casefileType: CaseFileType,
    recipients: Recipient[]
): boolean => {
    if (!casefileType || !document || !recipients) {
        return false;
    }

    //TODO: Picking only the first one is kind of a code smell - more complex flows may require more checks?
    const signableDocumentFromCaseFileType = casefileType.documentTypes.filter(
        (documentType) => {
            return (
                document.documentTypeId === documentType.id &&
                documentType.signerTypes?.length > 0
            );
        }
    )[0];

    /* Array with all unique selected roles */
    const allSelectedRoles = lodash.uniq(
        lodash.flatten(
            recipients.map((recipient) => {
                return recipient.role.map((role) => {
                    return role.id;
                });
            })
        )
    ) as number[];

    if (!signableDocumentFromCaseFileType) return true;

    const allDocumentSignerTypeRoles =
        signableDocumentFromCaseFileType.signerTypes.map((signerType) => {
            return signerType.id;
        });

    const documentHasSigners = allDocumentSignerTypeRoles.some((r) =>
        allSelectedRoles.includes(r)
    );

    return documentHasSigners;
};

const getAllDocumentsWithoutSigners = (documents, casefileType, recipients) => {
    const documentsWithoutSigners = documents.filter((document) => {
        return !documentHasSigners(document, casefileType, recipients);
    });

    return documentsWithoutSigners;
};

let validateSignerSetup = (signers, documents, casefileType) => {
    let rules = {
        count: [
            {
                valid: signers.length > 0,
                code: 'casefile-signer-count',
                message: i18n`You must add at least one recipient`,
            },
        ],
        missingRoles: [
            {
                valid:
                    getAllDocumentsWithoutSigners(
                        documents,
                        casefileType,
                        signers
                    ).length === 0,
                code: 'casefile-signer-missing-roles',
                message: i18n`Some documents are missing signers`,
            },
        ],
    };

    return _evaluateRules(rules);
};

let validateDocuments = (documents) => {
    let collection = {};

    documents.forEach((d, index) => {
        let { name, documentTypeId } = d;

        collection[index] = {
            id: [
                {
                    valid: d.hasOwnProperty('id'),
                    message: `Please wait for the document ${d.filename} to be uploaded`,
                },
            ],
            name: [
                {
                    valid: assert.notEmpty(name),
                    code: 'document-name-required',
                    message: `Documents can't have an empty name`,
                },
            ],
            documentTypeId: [
                {
                    valid: assert.isSelected(documentTypeId),
                    code: 'document-type-required',
                    message: `You must select a document type`,
                },
            ],
        };
    });

    return _evaluateRuleCollection(collection);
};

let validateSigners = (signers, casefile: any = {}) => {
    let collection = {};

    signers.forEach((signer, index) => {
        collection[index] = {
            name: [
                {
                    valid: assert.notEmpty(signer.name),
                    code: 'signer-name-required',
                    message: i18n`Signer's name is required`,
                },
            ],
            email: [
                {
                    valid: assert.notEmpty(signer.email),
                    code: 'signer-email-required',
                    message: i18n`Signer's email is required`,
                },
                {
                    valid: assert.isEmailValid(signer.email),
                    code: 'signer-email-invalid',
                    message: i18n`Recipient's email is not valid`,
                },
            ],
            role: [
                {
                    valid: signer.role && signer.role.length > 0,
                    message: i18n`Recipient must have a role`,
                },
            ],
            ssnCountry: [
                {
                    // If there is no SSN, there is no need to check the country
                    valid: !signer.ssn || !isLegacySSN(signer.ssnType),
                    code: 'signer-ssn-missing-country',
                    message: i18n`Social Security Number must have a country assigned`,
                },
            ],
            ssn: [
                {
                    // If no country is selected, there is no need to check if
                    // the SSN is valid (as the check on the country takes precedence)
                    valid: assert.isSsnValid(signer.ssn, signer.ssnType),
                    code: 'signer-ssn-invalid',
                    message: i18n`Social Security Number is invalid`,
                },
            ],
            accessControl: [
                {
                    valid: casefile.sensitiveData ? signer.accessControl : true,
                    code: 'signer-access-control-disabled',
                    message: i18n`Access control cannot be disabled if case file contains sensitive data`,
                },
            ],
            enableInsecureSigning: [
                {
                    valid:
                        !casefile.sensitiveData ||
                        !signer.enableInsecureSigning ||
                        (signer.accessControl && signer.ssnType === 'sms'),
                    code: 'signer-touch-disabled',
                    message: i18n`Touch signatures cannot be enabled if case file contains sensitive data`,
                },
            ],
        };
    });

    return _evaluateRuleCollection(collection);
};

let validateCcRecipients = (recipients) => {
    const ccEmailExists = (recipient) => {
        const { email } = recipient;

        return {
            valid: assert.notEmpty(email),
            message: i18n`Email cant be empty for Copy Recipient(s)`,
        };
    };

    const ccEmailValid = (recipient) => {
        const { email } = recipient;

        return {
            valid: assert.isEmailValid(email),
            message: i18n`Email is not valid for Copy Recipient(s)`,
        };
    };

    const collection = recipients.reduce((acc, recipient, index) => {
        acc[index] = {
            email: [ccEmailExists(recipient), ccEmailValid(recipient)],
        };

        return acc;
    }, {});

    return _evaluateRuleCollection(collection);
};

let validateSigner = (signer, casefile: any = {}) => {
    let rules = {
        name: [
            {
                valid: assert.notEmpty(signer.name),
                code: 'signer-name-required',
                message: `Recipient's name is required`,
            },
        ],
        email: [
            {
                valid: assert.notEmpty(
                    signer.signingRequest
                        ? signer.signingRequest.email
                        : signer.email
                ),
                code: 'signer-email-required',
                message: `Recipient's email is required`,
            },
            {
                valid: assert.isEmailValid(
                    signer.signingRequest
                        ? signer.signingRequest.email
                        : signer.email
                ),
                code: 'signer-email-invalid',
                message: `Recipient's email must be valid`,
            },
        ],
        role: [
            {
                valid: signer.role && signer.role.length > 0,
                message: 'Recipient must have a role',
            },
        ],
        ssnCountry: [
            {
                // If there is no SSN, there is no need to check the country
                valid:
                    !(signer.ssn || signer.socialSecurityNumberPlain) ||
                    !isLegacySSN(signer.ssnType),
                code: 'signer-ssn-missing-country',
                message: 'Social Security Number must have a country assigned',
            },
        ],
        ssn: [
            {
                // If no country is selected, there is no need to check if
                // the SSN is valid (as the check on the country takes precedence)
                valid:
                    isLegacySSN(signer.ssnType) ||
                    assert.isSsnValid(
                        signer.ssn
                            ? signer.ssn
                            : signer.socialSecurityNumberPlain,
                        signer.ssnType
                    ),
                code: 'signer-ssn-invalid',
                message: 'Social Security Number is invalid',
            },
        ],
        accessControl: [
            {
                valid: casefile.sensitiveData ? signer.accessControl : true,
                code: 'signer-access-control-disabled',
                message: `Access control cannot be disabled if case file contains sensitive data`,
            },
        ],
        enableInsecureSigning: [
            {
                valid:
                    !casefile.sensitiveData ||
                    signer.ssnType === PersonIdentifierType.SMS ||
                    (casefile.sensitiveData && !signer.enableInsecureSigning),
                code: 'signer-touch-disabled',
                message: `Touch signatures cannot be enabled if case file contains sensitive data`,
            },
        ],
    };

    return _evaluateRules(rules);
};

let getLimitValidation = (
    lowerLimit,
    upperLimit,
    count = 0,
    errorPrefix = ''
) => {
    // Optional
    if (lowerLimit === 0 && upperLimit === 0) {
        return {
            valid: true,
            count: count,
            code: `${errorPrefix}-limit-optional`,
        };
    }

    // Specific value
    if (
        lowerLimit !== 0 &&
        upperLimit !== 0 &&
        lowerLimit === upperLimit &&
        count !== upperLimit
    ) {
        return {
            valid: false,
            count: count,
            required: lowerLimit,
            code: `${errorPrefix}-count-required`,
        };
    }

    // Minimum Required not met
    if (lowerLimit !== 0 && count < lowerLimit) {
        return {
            valid: false,
            count: count,
            required: lowerLimit,
            code: `${errorPrefix}-lower-limit`,
        };
    }

    // Maximum Required not met
    if (upperLimit !== 0 && count > upperLimit) {
        return {
            valid: false,
            count: count,
            required: upperLimit,
            code: `${errorPrefix}-upper-limit`,
        };
    }

    return {
        valid: true,
        count: count,
        code: `${errorPrefix}-limit-success`,
    };
};

let validateDocumentTypes = (documentTypes, documents) => {
    const selectedTypes = documents.map((d) => d.documentTypeId);
    const typeCount = lodash.countBy(selectedTypes);

    let collection = {};

    documentTypes.forEach((dt) => {
        let count = typeCount[dt.id];

        collection[dt.id] = {
            limit: [
                getLimitValidation(
                    dt.lowerLimit,
                    dt.upperLimit,
                    count,
                    'documenttype'
                ),
            ],
        };
    });

    return _evaluateRuleCollection(collection);
};

let validateSignerTypes = (
    signers: any[] = [],
    availableSignerTypes: any[] = []
) => {
    // Create list of selected signer types
    let selectedTypes = [];

    signers.forEach((s) => {
        selectedTypes = selectedTypes.concat(s.role.map((r) => r.id));
    });

    // Count by id
    const typeCount = lodash.countBy(selectedTypes);

    // Create a validation collection by signer type
    let collection = {};

    availableSignerTypes.forEach((st) => {
        let count = typeCount[st.id];

        collection[st.id] = {
            limit: [
                getLimitValidation(
                    st.lowerLimit,
                    st.upperLimit,
                    count,
                    'signertype'
                ),
            ],
        };
    });

    return _evaluateRuleCollection(collection);
};

const validateEmailTemplate = (template: {
    subject: string;
    message: string;
}) => {
    const { subject, message } = template;

    const rules = {
        subject: [
            {
                valid: assert.notEmpty(subject),
                message: 'Subject cannot be empty',
            },
        ],
        content: [
            {
                valid: assert.notEmpty(message),
                message: 'Message cannot be empty',
            },
        ],
    };

    return _evaluateRules(rules);
};

// Format of validation object.
//
// {
//     valid: true | false, // overall validity of rule group
//     errors: {
//         property: {
//             message: <String>
//         }
//     }
// }

let _evaluateRules = (rules) => {
    let errors: any = {};

    // Check whether every property is valid.
    for (let key in rules) {
        // Filter unwanted keys
        if (!rules.hasOwnProperty(key)) {
            continue;
        }

        let invalid = rules[key].filter((r) => !r.valid);

        if (invalid.length > 0) {
            // Return first available error message per property.
            // Return all properties except valid, which is always false.
            errors[key] = lodash.omit(invalid[0], ['valid']);
            continue;
        }
    }

    return {
        // rules: rules, // expose detailed information about specific rules
        valid: Object.keys(errors).length === 0, // overall rule group validity.
        errors: errors,
    };
};

// Format of validation collection.
//
// {
//     valid: <Boolean>, // overall validity of rule collection
//     items: {
//         <id>: {
//             valid: <Boolean> // validity of all properties in a rule group
//             errors: ...
//         }
//     }
// }

// Nested format for collections of rules such as documents, signers,
// or other collection-based validations.
let _evaluateRuleCollection = (collection) => {
    let rules = {};
    let valid = true;

    for (let documentIndex in collection) {
        // Filter unwanted keys
        if (!collection.hasOwnProperty(documentIndex)) {
            continue;
        }

        rules[documentIndex] = _evaluateRules(collection[documentIndex]);

        if (rules[documentIndex].valid === false) {
            valid = false;
        }
    }

    return {
        valid: valid,
        items: rules,
    };
};

const getSignerValidationErrorMessage = (required, count, type, code) => {
    if (!type) {
        return;
    }

    const signerTypeSpan = <span className="text-bold">{type.role}</span>;

    return {
        'signertype-count-required':
            count === 0 ? (
                <span>
                    {i18n`${required} recipient(s) must have the role
                    ${signerTypeSpan}`}
                </span>
            ) : (
                <span>
                    {i18n`${
                        required - count
                    } more recipient(s) must have the role
                    ${signerTypeSpan}`}
                </span>
            ),
        'signertype-lower-limit': (
            <span>
                {i18n`At least ${required} recipient(s) must have the role
                ${signerTypeSpan}`}
            </span>
        ),
        'signertype-upper-limit': (
            <span>
                {i18n`You cannot assign the role ${signerTypeSpan} to more than
                ${required} recipient(s)`}
            </span>
        ),
    }[code];
};

const getDocumentValidationErrorMessage = (required, count, type, code) => {
    if (!type) {
        return;
    }

    return {
        'documenttype-count-required':
            count === 0 ? (
                <span>
                    {i18n`You must add ${required} document(s) of type
                    ${(<span className="text-bold">{type.name}</span>)}`}
                </span>
            ) : (
                <span>
                    {i18n`Add ${required - count} more document(s) of type
                    ${(<span className="text-bold">{type.name}</span>)}`}
                </span>
            ),
        'documenttype-lower-limit': (
            <span>
                {i18n`Add at least ${required} document(s) of type
                    ${(<span className="text-bold">{type.name}</span>)}`}
            </span>
        ),
        'documenttype-upper-limit': (
            <span>
                {i18n`You cannot add more than ${required} documents of type
                    ${(<span className="text-bold">{type.name}</span>)}`}
            </span>
        ),
    }[code];
};

function parseEmailMessageValidationErrors(
    emailMessages: NewCasefileState['emailMessages']
): string[] {
    let hasIssues = false;

    Object.keys(emailMessages.general.validation).forEach((key) => {
        if (!emailMessages.general.validation[key].valid) {
            hasIssues = true;
        }
    });

    emailMessages.custom.forEach((customMessage) => {
        Object.keys(customMessage.validation).forEach((key) => {
            if (!customMessage.validation[key].valid) {
                hasIssues = true;
            }
        });
    });

    if (hasIssues) {
        return [
            i18n('There is an issue with at least one of your email messages'),
        ];
    }

    return [];
}

export {
    getDocumentValidationErrorMessage,
    getSignerValidationErrorMessage,
    parseEmailMessageValidationErrors,
    validateCasefileDetails,
    validateCcRecipients,
    validateDocuments,
    validateDocumentSetup,
    validateDocumentTypes,
    validateEmailTemplate,
    validateSigner,
    validateSigners,
    validateSignerSetup,
    validateSignerTypes,
};
