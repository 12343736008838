import React, { MouseEvent } from 'react';
import { TextInput } from 'Common/components';
import CasefileStore from '../../stores/CasefileStore';
import { modal } from 'Common/components/Common/Modal';
import { i18n } from 'Language';
import Button from 'Common/components/Button';
import { connect } from 'react-redux';
import { AppDispatch } from 'Store';
import CasefileActions2 from 'Casefiles/actions/CasefilesV2ActionCreators';
import { createFolder } from 'Casefiles/Archive/redux/reducer';
import classnames from 'classnames';

type Props = {
    folders: any[];
    active: any;
    handleFolderChange: (folderId: number) => void;
    dispatch: AppDispatch;
};

type State = {
    open: any;
    selected: any;
    folders: any[];
    creatingNew: null | number | boolean;
    newFolderName: string;
};

export class FolderSelectorModal extends React.Component<Props, State> {
    static defaultProps = {
        folders: [],
    };

    state: State = {
        open: {},
        selected: this.props.active,
        folders: [],
        creatingNew: null,
        newFolderName: '',
    };

    componentDidMount() {
        if (this.props.active) {
            this.openParentFolder(this.props.active);
        }

        this.setState({ folders: CasefileStore.getFolders() });
    }

    deselect = () => {
        this.setState({ selected: null }, () => {
            this.cancelCreatingNew();
        });
    };

    isCreatingNew = (folderId) => this.state.creatingNew === folderId;

    openCreatingNew = (folderId) => this.setState({ creatingNew: folderId });

    cancelCreatingNew = () =>
        this.setState({ creatingNew: null, newFolderName: '' });

    handleNewFolderName = (newFolderName: string) =>
        this.setState({ newFolderName });

    handleOnNewFolder = async (e, folder?) => {
        e.preventDefault();

        await this.props.dispatch(
            createFolder(this.state.newFolderName, folder?.id)
        );
        await CasefileActions2.fetchFolders();
        this.setState(
            {
                folders: CasefileStore.getFolders(),
            },
            () => {
                const newFolders = this.state.folders.filter(
                    (f) =>
                        f?.parentId === folder?.id &&
                        f.title === this.state.newFolderName
                );

                const newestFolder = newFolders.sort((a, b) =>
                    a.id < b.id ? 1 : -1
                )[0];

                if (newestFolder) {
                    this.openParentFolder(newestFolder);
                    this.handleClick(newestFolder);
                }
            }
        );
        //reset and close form
        this.cancelCreatingNew();
    };

    openParentFolder = (folder) => {
        if (!folder || !folder.parentId) {
            return false;
        }

        let parent = CasefileStore.getFolder(folder.parentId);

        if (!parent) {
            return false;
        }

        let { open } = this.state;

        open[parent.id] = true;
        this.setState({ open: open });

        // Perform the same check for deeply nested children.
        this.openParentFolder(parent);
    };

    handleClick = (folder) => {
        if (!folder) return;

        let { open } = this.state;

        // Toggle open state for folder.
        // only for items that have children
        if (folder.children && folder.children.length > 0) {
            open[folder.id] = !this.isOpen(folder.id); // get open state, reverse it.
        }

        this.setState({ open: open, selected: folder });
    };

    isOpen = (folderId) => {
        let { open } = this.state;

        if (open[folderId]) {
            return open[folderId];
        }

        return false;
    };

    getFolderStateClass = (folder) => {
        if (!folder || !folder.children || folder.children.length === 0) {
            return '';
        }

        if (this.isOpen(folder.id)) {
            return 'open';
        }

        return 'closed';
    };

    isActive = (folderId) => {
        let { selected } = this.state;

        if (selected && folderId === selected.id) {
            return true;
        }

        return false;
    };

    changeFolder = () => {
        let { selected } = this.state;

        if (!selected) return;

        this.props.handleFolderChange(selected.id);
        modal.hide();
    };

    selectRootLevel = (e: MouseEvent<HTMLElement>) => {
        e.preventDefault();
        this.deselect();
    };

    renderFolderList = (folders, level = 0) => {
        return folders.map((folder) => (
            <li
                key={folder.id}
                tabIndex={-1}
                className={this.getFolderStateClass(folder)}>
                <span
                    onClick={(e) => {
                        e.stopPropagation();
                        this.handleClick(folder);
                    }}
                    className={
                        'folder-title-container ' +
                        (this.isActive(folder.id) ? 'active' : '')
                    }>
                    <span className="folder-title">
                        {folder.children &&
                            folder.children.length > 0 &&
                            this.isOpen(folder.id) && (
                                <i>
                                    <i className="fa fa-chevron-down"></i>
                                    &nbsp;
                                </i>
                            )}
                        {folder.children &&
                            folder.children.length > 0 &&
                            !this.isOpen(folder.id) && (
                                <i>
                                    <i className="fa fa-chevron-right"></i>
                                    &nbsp;
                                </i>
                            )}
                        {this.isOpen(folder.id) ? (
                            <i className="far fa-folder-open"></i>
                        ) : (
                            <i className="far fa-folder"></i>
                        )}
                        &nbsp;&nbsp;{folder.title}
                    </span>

                    {folder.children && folder.children.length > 0 && (
                        <span className="children">
                            ({folder.children.length}&nbsp;
                            {folder.children.length === 1 && `folder`}
                            {folder.children.length > 1 && `folders`})
                        </span>
                    )}
                </span>

                {this.isCreatingNew(folder.id) &&
                    this.renderNewFolderForm(folder)}

                {folder.children &&
                    folder.children.length > 0 &&
                    this.isOpen(folder.id) && (
                        <ul className={`level-${level}`}>
                            {this.renderFolderList(folder.children, level + 1)}
                        </ul>
                    )}
            </li>
        ));
    };

    renderNewFolderForm = (folder?) => (
        <>
            <div className="new-folder-placeholder">
                <span className="folder-title">
                    <i className="far fa-folder"></i>
                    {this.state.newFolderName && (
                        <>&nbsp;&nbsp;{this.state.newFolderName}</>
                    )}
                </span>
            </div>
            <form
                className={`folder-new-container`}
                onClick={(e) => e.stopPropagation()}
                onSubmit={(e) => this.handleOnNewFolder(e, folder)}>
                <TextInput
                    label={i18n('New folder')}
                    value={this.state.newFolderName}
                    onChange={this.handleNewFolderName}
                    autoFocus
                />
                <Button
                    className="default"
                    type="button"
                    onClick={this.cancelCreatingNew}>
                    {i18n('Cancel')}
                </Button>
                <Button
                    theme={'green'}
                    className="primary pull-right"
                    type="submit"
                    onClick={(e) => this.handleOnNewFolder(e, folder)}>
                    {i18n('Create')}
                </Button>
            </form>
        </>
    );

    render() {
        let { folders } = this.state;

        // if folder doesn't have a parent, it's a topmost folder
        let topLevelFolders = folders.filter((folder) => !folder.parent);

        return (
            <div className="casefile-modal-v2">
                <div className="casefile-modal-v2-title">
                    {i18n`Select a folder`}

                    <div className="close" onClick={modal.hide}>
                        <div className="far fa-times"></div>
                    </div>
                </div>

                <div className="casefile-folder-actions">
                    <Button
                        theme="blue"
                        variant="outline"
                        icon="far fa-folder-plus"
                        renderIconLeft={true}
                        onClick={() =>
                            this.openCreatingNew(
                                this.state.selected?.id || true
                            )
                        }>
                        {i18n('New folder')}
                    </Button>
                </div>

                <div>
                    <div
                        className="mt mb casefile-folder-selector box-gray"
                        onClick={this.deselect}>
                        <a
                            className={classnames('casefile-folder-root', {
                                selected: this.state.selected === null,
                            })}
                            href="#"
                            onClick={this.selectRootLevel}>
                            <i className="far fa-window" />
                            <span>{i18n`Archive`}</span>
                        </a>
                        <ul className="casefile-folder-container can-create-new">
                            {this.state.creatingNew === true && (
                                <li>{this.renderNewFolderForm()}</li>
                            )}
                            {this.renderFolderList(topLevelFolders)}
                        </ul>
                    </div>
                </div>

                <div className="footer-content form-v2">
                    <Button
                        theme="blue"
                        className="pull-right"
                        onClick={this.changeFolder}
                        disabled={
                            this.state.selected === null ||
                            this.state.creatingNew != null
                        }>
                        {i18n`Select this folder`}
                    </Button>
                </div>
            </div>
        );
    }
}

export default connect()(FolderSelectorModal);
