import { useState, useEffect, useRef } from 'react';

const useHasScrollbar = () => {
    const ref = useRef<HTMLDivElement>(null);
    const [hasScrollbar, setHasScrollbar] = useState(false);

    useEffect(() => {
        const checkScroll = () => {
            if (ref.current) {
                setHasScrollbar(
                    ref.current.scrollHeight > ref.current.clientHeight
                );
            }
        };

        const observer = new ResizeObserver(() => checkScroll());

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => observer.disconnect();
    }, []);

    return { ref, hasScrollbar };
};

export default useHasScrollbar;
