import { useEffect, useState } from 'react';

const useScrollbarWidth = () => {
    const [scrollbarWidth, setScrollbarWidth] = useState(0);

    useEffect(() => {
        const updateWidth = () => {
            const scrollDiv = document.createElement('div');

            scrollDiv.style.width = '100px';
            scrollDiv.style.height = '100px';
            scrollDiv.style.overflow = 'scroll';
            scrollDiv.style.position = 'absolute';
            scrollDiv.style.top = '-9999px';

            document.body.appendChild(scrollDiv);
            setScrollbarWidth(scrollDiv.offsetWidth - scrollDiv.clientWidth);
            document.body.removeChild(scrollDiv);
        };

        updateWidth();
        window.addEventListener('resize', updateWidth);

        return () => window.removeEventListener('resize', updateWidth);
    }, []);

    return scrollbarWidth;
};

export default useScrollbarWidth;
