import React, { useCallback } from 'react';
import { EIDMethod, OpenIDMethod } from '../types';
import openIDActions from 'Auth/actions/OpenIDActions';
import { Intent, ServiceIDs } from 'OpenID/Constants';
import { i18n } from 'Language';
import { getEnvironment } from '../../utils';
import { Environment, ProviderName } from '@penneo/eid-webcomponent';
import '@penneo/eid-webcomponent/dist/react-types';
import { getUrlId } from 'OpenID/redux/actions';

export const EIDButtonWrapper: React.FunctionComponent<{
    inline?: boolean;
    encryptedNIN?: string;
    method: OpenIDMethod | EIDMethod;
    intent: Intent;
    children?: JSX.Element;
}> = (props) => {
    const { inline, method, intent, encryptedNIN, children } = props;
    const redirectUri = `${window.location.origin}/openid/${method.type}/callback`;

    const handleInitSuccess = (event: CustomEvent) => {
        openIDActions.initSuccess(
            intent,
            method.type,
            event.detail,
            encryptedNIN
        );
    };

    const eidButton = useCallback((node) => {
        node?.addEventListener('init-success', handleInitSuccess);

        return () => {
            node?.removeEventListener('init-success', handleInitSuccess);
        };
    }, []);

    const renderContent = () => {
        if (children) {
            return <div slot="button">{children}</div>;
        }

        if (inline) {
            return (
                <li slot="button">
                    <a
                        href="#"
                        onClick={(e) => {
                            e.preventDefault();
                        }}>
                        <img src={method.newFlag} />
                        {i18n(method?.extendedTitle || method.title)}
                    </a>
                </li>
            );
        }

        return (
            <div slot="button">
                <div id={method.type} className="item-content">
                    {method.flag && (
                        <div className="auth-flag">
                            <img src={method.flag} />
                        </div>
                    )}
                    <div className="auth-logo">
                        <img height="120" width="120" src={method.logo} />
                    </div>
                    <div className="method-title">{i18n(method.title)}</div>
                </div>
            </div>
        );
    };

    return (
        <eid-button
            ref={eidButton}
            redirectUri={redirectUri}
            environment={getEnvironment() as unknown as Environment}
            provider={
                getUrlId(method.type as ServiceIDs, intent) as ProviderName
            }>
            {renderContent()}
        </eid-button>
    );
};
