import React from 'react';
import { i18n } from 'Language';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import Constants from 'Constants';
import { clsx } from 'clsx';
import { Button } from '@penneo/vega-ui-react';

export function InvitationStatus({
    imageSrc,
    imageAlt,
    titleKey,
    bodyKey,
    supportLink,
    isWarning,
}: {
    imageSrc: string;
    imageAlt: string;
    titleKey: string;
    bodyKey: string;
    supportLink?: boolean;
    isWarning?: boolean;
}): JSX.Element {
    return (
        <div className="w-full h-full bg-white md:p-9 py-3 px-6 flex justify-center">
            <div className="inline-flex flex-col items-center gap-4 md:w-3/5 md:max-w-2xl">
                <img alt={imageAlt} src={imageSrc} height="50" width="50" />
                <div
                    className={clsx(
                        isWarning ? 'text-neutral-900' : 'text-secondary-600',
                        'text-2xl font-semibold text-wrap text-center'
                    )}>
                    {i18n(titleKey)}
                </div>
                <div className="text-neutral-600 text-md text-wrap text-center">
                    {i18n(bodyKey)}
                </div>
                {supportLink && (
                    <div>
                        <Button
                            priority="tertiary"
                            size="md"
                            role="link"
                            onClick={() => {
                                window.open(Constants.sites.support);
                            }}
                            rightIcon={
                                <ArrowTopRightOnSquareIcon className="h-6 w-6" />
                            }>
                            <span className="underline">{i18n`Contact Support`}</span>
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
}
