import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ClaAPIProxyPrototype } from 'Api';
import { AppThunk, ReduxState } from 'Store';
import { Client, Recipient, ClientDetails } from '../types';

interface ClientState {
    clientsList: Client[];
    selectedRecipients: Recipient[];
    addedRecipientsCount: number;
    selectedClient?: Client;
}

export const initialState: ClientState = {
    clientsList: [],
    selectedRecipients: [],
    addedRecipientsCount: 0,
};

const clientSlice = createSlice({
    name: 'client',
    initialState,
    reducers: {
        setClientsList: (state, action: PayloadAction<Client[]>) => {
            state.clientsList = action.payload;
        },
        setSelectedClient: (
            state,
            action: PayloadAction<Client | undefined>
        ) => {
            state.selectedClient = action.payload;
        },
        setClientDetails: (state, action: PayloadAction<ClientDetails>) => {
            if (!state.selectedClient) {
                return;
            }

            state.selectedClient = {
                ...state.selectedClient,
                ...action.payload,
            };
        },
        setSelectedRecipients: (state, action: PayloadAction<Recipient[]>) => {
            state.selectedRecipients = action.payload;
        },
        setAddedRecipientsCount: (state, action: PayloadAction<number>) => {
            state.addedRecipientsCount += action.payload;
        },
        resetClientState: () => initialState,
    },
});

export const {
    setClientsList,
    setSelectedClient,
    setClientDetails,
    setSelectedRecipients,
    setAddedRecipientsCount,
    resetClientState,
} = clientSlice.actions;

export const lookupClients =
    (query: string): AppThunk =>
    async (dispatch) => {
        if (!query) {
            return;
        }

        try {
            const params = new URLSearchParams({ q: query });
            const data: Client[] = await ClaAPIProxyPrototype.get(
                `/cla/lookup`,
                params
            );

            // We default the country code to 'DK' until we support additional countries.
            // TODO: When we support additional countries, update the country code accordingly.
            const formatedClients: Client[] = data.map((client) => {
                return {
                    ...client,
                    countryCode: 'DK',
                    persons: [],
                };
            });

            dispatch(setClientsList(formatedClients));
        } catch (error) {
            console.log('error', error);
        }
    };

export const fetchClientDetails =
    (vatin: string): AppThunk =>
    async (dispatch) => {
        try {
            const params = new URLSearchParams({ vatin });
            const data: ClientDetails = await ClaAPIProxyPrototype.get(
                `/cla/details`,
                params
            );

            dispatch(setClientDetails(data));
        } catch (error) {
            console.log('error', error);
        }
    };

export const clearSelectedClient = (): AppThunk => (dispatch) => {
    dispatch(setSelectedClient());
};

/**
 * State selectors
 */
export const clientsListSelector = (state: ReduxState) =>
    state.auditingAccounting.clientsList;

export const selectedClientSelector = (state: ReduxState) =>
    state.auditingAccounting.selectedClient;

export const selectedRecipientsSelector = (state: ReduxState) =>
    state.auditingAccounting.selectedRecipients;

export const clientReducerStateSelector = (state: ReduxState) =>
    state.auditingAccounting;

export default clientSlice.reducer;
