import PropTypes from 'prop-types';
import React from 'react';
import createReactClass from 'create-react-class';
import FormStore from '../../stores/FormStore';
import Constants from 'Constants';
import Hammer from 'hammerjs';
import DocumentDrop from './DocumentDrop';

// Global Animation Frame
let reqAnimationFrame = (function () {
    return (
        window[Hammer.prefixed(window, 'requestAnimationFrame')] ||
        function (callback) {
            window.setTimeout(callback, 1000 / 60);
        }
    );
})();

let mc;
let START_X;
let START_Y;
let ticking = false;
let transform;
let initZoom = 1;
let delta = {
    pan: {
        x: 0,
        y: 0,
    },
};

export default createReactClass({
    propTypes: {
        doc: PropTypes.array,
        pages: PropTypes.array,
        fields: PropTypes.array,
        mapping: PropTypes.array,
        getPageURL: PropTypes.func,
        disableEvent: PropTypes.func,
        getCoords: PropTypes.func,
        updateField: PropTypes.func,
        highlightedField: PropTypes.number,
        zoom: PropTypes.number,
        scalableStyle: PropTypes.object,
        viewerStyle: PropTypes.object,
        editMode: PropTypes.bool,
        dimensions: PropTypes.object,
        toolbar: PropTypes.object,
        disableEdit: PropTypes.bool,
        timestamp: PropTypes.number,
        save: PropTypes.func.isRequired,
        formId: PropTypes.string.isRequired,
        editorSettings: PropTypes.object,
    },

    getDefaultProps() {
        return {
            editMode: false,
            disableEdit: false,
        };
    },

    getInitialState() {
        return {
            scalableStyle: {},
            viewerStyle: {},
            hammerListener: null,
            items: [],
        };
    },

    componentDidMount() {
        this.mounted = true;
        window.addEventListener('resize', this.handleResize);

        // Add Multitouch Handlers
        if (!this.props.editMode) {
            this.attachHammer();

            // Set the Initial Size and position of elements. (for touch events)
            this.resetElement();
        }
    },

    componentWillUnmount() {
        this.mounted = false;
        window.removeEventListener('resize', this.handleResize);
    },

    addItem(name) {
        let clone = this.state.items.slice(0);

        clone.push(name);
        this.setState({ items: clone });
    },

    /**
     * Listens to Browser Resizing events.
     * Sets viewportWidth state, which tracks the current browser width.
     */
    handleResize() {
        this.updateDimensions();
    },

    getPageURL(page) {
        let { timestamp } = this.props;

        return Constants.PENNEO_ORIGIN + '/form' + page + '?t=' + timestamp;
    },

    getCoords(event) {
        event.preventDefault();
        // Get DOM Object Bounds
        // let rect = event.target.getBoundingClientRect();

        // Calculate Percentage of position within target
        // (Pointer position - Target Position / Target Width * 100)
        // let posX = ((event.clientX - rect.left) / rect.width) * 100;
        // let posY = (((event.clientY - rect.top) / rect.height) * 100);

        let middlepoint = {
            x: event.clientX,
            y: event.clientY - 1,
        };

        this.setState({ middlepoint: middlepoint });
    },

    /**
     * Attaches Hammer.js events to page scalable element.
     */
    attachHammer() {
        let content = document.getElementById('scalable');

        mc = new Hammer.Manager(content);

        START_X = 0;
        START_Y = 0;

        mc.add(new Hammer.Pan({ threshold: 0, pointers: 0 }));
        mc.add(new Hammer.Pinch({ threshold: 0 })).recognizeWith(mc.get('pan'));

        mc.on('panstart panmove', this.onPan);
        mc.on('pinchstart pinchmove', this.onPinch);
        mc.on('tap', this.onPinch);

        mc.on('hammer.input', this.persistPosition);
    },

    /**
     * Updates the position and zoom level of the scalable element
     * after the last touch event.
     */
    persistPosition(event) {
        if (event.isFinal) {
            this.resetElement(true);
        }
    },

    /**
     * Updates translate X and Y axis based on delta amounts.
     * @param event Hammer.js Pan Event.
     */
    onPan(event) {
        transform.translate = {
            x: START_X + event.deltaX,
            y: START_Y + event.deltaY,
        };

        delta.pan.x = event.deltaX;
        delta.pan.y = event.deltaY;

        this.requestElementUpdate();
    },

    /**
     * Updates Zoom level based on pinch scale.
     * @param event Hammer.js Pinch Event.
     */
    onPinch(event) {
        if (event.type === 'pinchstart') {
            initZoom = transform.scale || 1;
        }

        transform.scale = initZoom * event.scale;

        this.requestElementUpdate();
    },

    /**
     * Updates the scalable element with transform3d and scaled3d
     * based on touch gestures
     */
    updateElementTransform() {
        if (transform.scale < 1) {
            transform.scale = 1;
        }

        if (transform.scale === 1) {
            transform.translate.x = 0;
        }

        let style = {
            transform:
                'scale3d(' +
                transform.scale +
                ', ' +
                transform.scale +
                ', 1) ' +
                'translate3d(' +
                transform.translate.x +
                'px, ' +
                transform.translate.y +
                'px, 0) ',
            transformOrigin: '0 0',
        };

        if (this.mounted) {
            this.setState({ scalableStyle: style });
        }

        ticking = false;
    },

    /**
     * Calls the RequestAnimationFrame to perform a style update
     */
    requestElementUpdate() {
        if (!ticking) {
            reqAnimationFrame(this.updateElementTransform);
            ticking = true;
        }
    },

    /**
     * Sets the initial position and zoom level of the page content.
     * @param {boolean} inputEnd toggles full reset or persisting of current settings.
     */
    resetElement(inputEnd) {
        if (inputEnd) {
            if (transform.scale === 1) {
                this.snapEdges();
            }

            initZoom = transform.scale;
            START_X = transform.translate.x;
            START_Y = transform.translate.y;

            return;
        }

        transform = {
            translate: { x: START_X, y: START_Y },
            scale: 1,
        };

        this.requestElementUpdate();
    },

    /**
     * Snaps the page back into bounds after dragging outside of bounding box.
     * @todo This function doesn't always behave properly.
     * Replace with a real boundary overflow prevention
     *
     * Updates Page Container Style.
     */
    snapEdges() {
        // check edges
        let viewer = document.getElementById('viewer').getBoundingClientRect();
        let content = document
            .getElementById('scalable')
            .getBoundingClientRect();

        let topOffset = viewer.top - content.top;

        if (topOffset < 0) {
            transform.translate.y += topOffset;
        }

        let style = {
            transform:
                'translate3d(' +
                transform.translate.x +
                'px, ' +
                transform.translate.y +
                'px, 0) ' +
                'scale3d(' +
                transform.scale +
                ', ' +
                transform.scale +
                ', 1)',
            transition: 'transform 0.2s ease',
        };

        this.setState({ scalableStyle: style });
    },

    updateDimensions() {
        let elements = document.querySelectorAll('.page img');

        for (let i = 0; i < elements.length; i++) {
            this.updatePageDimension(i + 1, elements[i]);
        }
    },

    updatePageDimension(pageNumber, element) {
        let dimensions = {
            height: element.height,
            width: element.width,
            naturalHeight: element.naturalHeight,
            naturalWidth: element.naturalWidth,
        };

        FormStore.updateDocumentDimensions(pageNumber, dimensions);
    },

    pageLoadHandler(pageNumber, event) {
        let element = event.target;

        this.updatePageDimension(pageNumber, element);
    },

    render() {
        let { pages, mapping, fields, dimensions, highlightedField, save } =
            this.props;
        let {
            getCoords,
            doc,
            editMode,
            zoom,
            updateField,
            disableEdit,
            formId,
        } = this.props;
        let { viewerStyle, scalableStyle } = this.state;

        const viewerId = 'viewer';

        return (
            <div className="document-forms-viewer-wrapper large">
                {this.props.toolbar}
                <div
                    id={viewerId}
                    className={
                        'document-forms-viewer' +
                        (this.props.toolbar ? ' -toolbar' : ' no-toolbar')
                    }
                    style={viewerStyle}>
                    <div
                        id="scalable"
                        className="scalable"
                        style={scalableStyle}>
                        <div>
                            {pages.map((page, index) => (
                                <div
                                    className="page"
                                    id={'page' + index}
                                    key={index}>
                                    <img
                                        className="page-content"
                                        id="page-content"
                                        src={this.getPageURL(page)}
                                        onLoad={this.pageLoadHandler.bind(
                                            this,
                                            index + 1
                                        )}
                                    />

                                    <DocumentDrop
                                        index={index}
                                        mapping={mapping}
                                        fields={fields}
                                        dimensions={dimensions}
                                        getCoords={getCoords}
                                        doc={doc}
                                        editMode={editMode}
                                        zoom={zoom}
                                        updateField={updateField}
                                        disableEdit={disableEdit}
                                        editorSettings={
                                            this.props.editorSettings
                                        }
                                        highlightedField={highlightedField}
                                        save={save}
                                        formId={formId}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        );
    },
});
