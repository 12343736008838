import { AuthAPI, PublicAuthAPI, PublicSigningAPI } from 'Api';
import AuthActions from 'Auth/actions/AuthActionCreators';
import analytics from 'Common/Analytics';
import { sentry } from 'Common/SentryLogger';
import Constants, { StorageKeys } from 'Constants';
import { EID_METHODS, EIDMethod, isOpenIDMethod, SIGNING_METHODS } from 'EID';
import { Languages } from 'Language/Constants';
import { Intent, ServiceIDs } from 'OpenID/Constants';
import * as OpenIdErrors from 'OpenID/Errors';
import {
    OpenIdLocalStorageState,
    OpenIdMetadata,
    SignatureData,
    SignerCertificateData,
} from 'OpenID/redux/types';
import { fetchCACertificate } from 'OpenID/utils/ca';
import { verifyCertificate } from 'OpenID/utils/certificate';
import { generateCSR } from 'OpenID/utils/CertificateRequestWrapper';
import {
    fetchSignableDocuments,
    verifyDocumentHash,
} from 'OpenID/utils/documents';
import { createKeyPair } from 'OpenID/utils/keypair';
import {
    getOpenIdState,
    storeOpenIdIntentState,
    updateOpenIdState,
} from 'OpenID/utils/openIdState';
import { createSignature } from 'OpenID/utils/signature';
import {
    fetchSignText,
    getDocumentDigestsFromSignText,
    getXMLSignatureDocument,
} from 'OpenID/utils/signText';
import { isTemporaryStorageEnabled } from 'OpenID/utils/temporalStorage';
import { signingData, signingRedirectUrl, V2Validation } from 'Signing/utils';
import { AppThunk, GetState } from 'Store';
import {
    convertUTF16ToUTF8,
    getChallengeKeyFromSignUrl,
    getEnvironment,
} from 'utils';
import { getCrypto } from '../utils/crypto';
import * as Actions from './action-types';
import launchDarkly, { Flags } from '../../Common/LaunchDarkly';
import {
    ApiAuthClient,
    Environments,
    isEIDWebComponentProvider,
    ProviderName,
    TokenType,
} from '@penneo/eid-webcomponent';
import { storage } from 'Core';
import { ANY_MITID_DENMARK, INSECURE_SIGNING_METHODS } from 'EID/Constants';
import { PublicSigningAPIWithAuthHeader } from 'Api/ApiClient';
import { signingStages } from '../../types/SigningProcess';
import { recordIdToken } from '../utils/recordIdToken';
import { getUserAccess } from 'Casefiles/utils';
import { getTimestampProvider } from '../../Common/utils/timestamp';

const getMethod = (
    methodId: string,
    intent: Intent = Intent.LOGIN
): EIDMethod | undefined => {
    if (intent === Intent.SIGN) {
        return [...SIGNING_METHODS, ...INSECURE_SIGNING_METHODS].find(
            (method) => method.type === methodId
        );
    }

    return EID_METHODS.find((method) => method.type === methodId);
};

const getMethodName = (methodId: ServiceIDs) => {
    const method = getMethod(methodId);

    if (method) {
        return method.title;
    }

    return 'Unknown';
};

export const getUrlId = (methodId: ServiceIDs, intent: Intent): string => {
    const method = getMethod(methodId, intent);

    if (!method) {
        throw new Error(`Unable to find the specified EID method ${methodId}`);
    }

    if (isOpenIDMethod(method) && method.openidUrlId) {
        return typeof method.openidUrlId === 'function'
            ? method.openidUrlId(intent)
            : method.openidUrlId;
    }

    return method.type;
};

export const init =
    (
        serviceId: ServiceIDs,
        intent: Intent,
        metadata: OpenIdMetadata = {},
        language: Languages = Languages.EN,
        encryptedNIN?: string
    ): AppThunk =>
    async (dispatch) => {
        dispatch({ type: Actions.OPENID_INIT_REQUEST });

        const payload = {
            language: language,
            redirectUri: `${window.location.origin}/openid/${serviceId}/callback`,
        };

        try {
            const response = await PublicAuthAPI.post(
                `/openid/${getUrlId(serviceId, intent)}/init`,
                payload
            );

            dispatch({
                type: Actions.OPENID_INIT_SUCCESS,
                payload: response.uri,
            });

            // Save data about user's intent
            storeOpenIdIntentState(
                {
                    serviceId: serviceId,
                    intent: intent,
                    csrfToken: response.csrfToken,
                    encryptedNIN,
                },
                metadata
            );

            // Redirect to OpenID service
            window.location.href = response.uri;
        } catch (error) {
            dispatch({
                type: Actions.OPENID_INIT_FAILURE,
                payload: OpenIdErrors.createOpenIDError(
                    error,
                    OpenIdErrors.ERROR_OPENID_001
                ),
            });
        }
    };

const track = (
    serviceId: ServiceIDs,
    eventName: string,
    eventProps: any = {}
) => {
    analytics.track(eventName, {
        method: `OpenID - ${getMethodName(serviceId)}`,
        ...eventProps,
    });
};

let apiAuthClient;

export const collect = (
    serviceId: ServiceIDs,
    intent: Intent,
    code: string,
    state: string
): AppThunk => {
    return async (dispatch) => {
        try {
            const openIdState = getOpenIdState();

            if (!openIdState) {
                return dispatch({
                    type: Actions.OPENID_COLLECT_FAILURE,
                    payload: OpenIdErrors.ERROR_OPENID_010,
                });
            }

            const payload = {
                code: code,
                state: state,
                csrfToken: openIdState.csrfToken,
                redirectUri: `${Constants.PENNEO_ORIGIN}/openid/${serviceId}/callback`,
                intent,
                encryptedNIN: openIdState.encryptedNIN,
            };

            apiAuthClient = new ApiAuthClient(
                payload.redirectUri,
                getUrlId(serviceId, intent) as ProviderName,
                getEnvironment() as unknown as keyof typeof Environments
            );

            dispatch({ type: Actions.OPENID_COLLECT_REQUEST });
            track(serviceId, `OpenID - process ${intent} intent`);

            if (intent === Intent.ADD || intent === Intent.ACTIVATION) {
                await add(serviceId, payload);
            }

            if (intent === Intent.SIGN || intent === Intent.NAP) {
                let openIdToken = await getOpenIdToken(
                    serviceId,
                    intent,
                    TokenType.ID_TOKEN,
                    payload
                );

                updateOpenIdState({
                    openIdToken,
                });
            }

            if (intent === Intent.LOGIN) {
                await login(serviceId, payload);

                const { isSignersArchive } = getUserAccess();

                track(serviceId, 'log in success', { isSignersArchive });
            }

            if (intent === Intent.VALIDATE_ID) {
                await validateId(serviceId, payload);
            }

            storage.clear(StorageKeys.ENCRYPTED_NIN);
            track(serviceId, 'log in (legacy)');
            dispatch({ type: Actions.OPENID_COLLECT_SUCCESS });
        } catch (error) {
            const trackMessage = `OpenID - ${intent} error`;

            track(serviceId, trackMessage);

            let errorCode: OpenIdErrors.OpenIDClientError;

            // Use a different error code per intent type
            switch (intent) {
                case Intent.ADD:
                case Intent.ACTIVATION:
                    errorCode = OpenIdErrors.ERROR_OPENID_004;
                    break;
                case Intent.LOGIN:
                    errorCode = OpenIdErrors.ERROR_OPENID_005;
                    break;
                case Intent.VALIDATE_ID:
                    errorCode = OpenIdErrors.ERROR_OPENID_015;
                    break;
                case Intent.NAP:
                case Intent.SIGN:
                    errorCode = OpenIdErrors.ERROR_OPENID_006;
                    break;
                default:
                    errorCode = OpenIdErrors.ERROR_OPENID_007;
                    break;
            }

            sentry.track(
                error instanceof Error ? error : new Error(trackMessage),
                {
                    serviceId,
                    errorCode,
                }
            );

            dispatch({
                type: Actions.OPENID_COLLECT_FAILURE,
                payload: OpenIdErrors.createOpenIDError(error, errorCode),
            });
        }
    };
};

const add = async (serviceId: ServiceIDs, payload) => {
    const idToken = await getOpenIdToken(
        serviceId,
        Intent.ADD,
        TokenType.ID_TOKEN,
        payload
    );

    return PublicAuthAPI.post(
        `/openid/${getUrlId(serviceId, Intent.ADD)}/credentials`,
        { idToken }
    );
};

const login = (serviceId: ServiceIDs, payload: { [key: string]: any }) => {
    return AuthActions.authenticate(serviceId as string, payload, {
        propagateError: true,
    });
};

const validateId = async (serviceId: ServiceIDs, payload: any) => {
    const { validation_token } = await getOpenIdToken(
        serviceId,
        Intent.VALIDATE_ID,
        TokenType.VALIDATION_TOKEN,
        payload
    );

    /**
     * Saving validation token for V2 validation (in localStorage)
     */
    V2Validation.set(validation_token);

    await validateSession(validation_token);
};

const validateSession = async (validationToken: string) => {
    const successUrl = getOpenIdState()?.successUrl;

    if (!successUrl) {
        throw new Error('Redirect url missing');
    }

    const challengeKey = getChallengeKeyFromSignUrl(successUrl);

    if (!challengeKey) {
        throw new Error('Challenge key missing');
    }

    await PublicSigningAPI.post(`/validation-session/${challengeKey}`, {
        validationToken,
    });
};

export const cacheOpenIdIntent =
    (payload: OpenIdLocalStorageState): AppThunk =>
    (dispatch) => {
        dispatch({ type: Actions.OPENID_CACHE_INTENT, payload });
    };

export const resetState = (): AppThunk => (dispatch) => {
    dispatch({ type: Actions.OPENID_RESET_STATE });
};

export const initSign =
    (challengeKey: string, language: Languages = Languages.EN): AppThunk =>
    async (dispatch, getState) => {
        dispatch({ type: Actions.OPENID_SIGN_INIT_REQUEST });

        try {
            const openIdState = getState().openId.intent.data;

            if (!openIdState) {
                throw new Error("Couldn't read OpenID state");
            }

            const { serviceId, openIdToken } = openIdState;

            if (!openIdToken) {
                throw new Error('There was a problem with authentication');
            }

            recordIdToken(challengeKey, openIdToken);

            const [signText, signableDocuments, signerCertificate] =
                await Promise.all([
                    fetchSignText(challengeKey, language),
                    fetchSignableDocuments(challengeKey),
                    createSignerCertificate(openIdToken, serviceId),
                ]);

            // Extract digest from sign text to compare if hashes of the documents match
            const documentDigests = getDocumentDigestsFromSignText(
                signText.xml.xmlData
            );

            /**
             * The fetched signable documents includes all signable documents, not just the current round.
             * Filter it to match the list of document digests.
             */
            const signableDocsCurrentRound = signableDocuments.filter(
                ({ documentId }) => !!documentDigests[documentId]
            );

            // Verify signable document hashes with sign data's digest.
            await Promise.all(
                signableDocsCurrentRound.map((doc) => {
                    const { digest } = documentDigests[doc.documentId];

                    return verifyDocumentHash(challengeKey, doc.id, digest);
                })
            );

            const { certificate, keyPair, trustChain } =
                signerCertificate as SignerCertificateData;

            dispatch({
                type: Actions.OPENID_SIGN_INIT_SUCCESS,
                payload: {
                    signText,
                    documents: signableDocuments,
                    certificate,
                    keyPair,
                    trustChain,
                },
            });
        } catch (error) {
            const openIdState = getState().openId.intent.data;

            track(
                openIdState.serviceId,
                `OpenID - sign init error`,
                error.data
            );

            const openIdError =
                error.status === 403
                    ? OpenIdErrors.ERROR_OPENID_016
                    : OpenIdErrors.ERROR_OPENID_009;

            sentry.track(error, {
                serviceId: openIdState.serviceId,
                code: openIdError.code,
            });

            dispatch({
                type: Actions.OPENID_SIGN_INIT_FAILURE,
                payload: error.handled
                    ? error
                    : OpenIdErrors.createOpenIDError(error, openIdError),
            });

            if (openIdError === OpenIdErrors.ERROR_OPENID_009) {
                V2Validation.clear();
            }
        }
    };

const createSignerCertificate = async (
    idToken: string,
    serviceId: ServiceIDs
): Promise<SignerCertificateData> => {
    try {
        const keyPair = await createKeyPair();

        const csr = await generateCSR(idToken, keyPair);
        const { certificate, trustChain } = await fetchCACertificate(csr);

        const isCertValid = await verifyCertificate(certificate, trustChain);

        if (!isCertValid) {
            throw new Error(
                'The certificate trust chain could not be validated'
            );
        }

        return {
            keyPair,
            certificate,
            trustChain,
        };
    } catch (error) {
        if (error.handled) {
            throw error;
        }

        sentry.track(error, {
            serviceId: serviceId,
            code: OpenIdErrors.ERROR_OPENID_008.code,
        });

        throw OpenIdErrors.createOpenIDError(
            error,
            OpenIdErrors.ERROR_OPENID_008
        );
    }
};

const prepareSignature = async (getState: GetState) => {
    const { certificate, signText, trustChain, keyPair } =
        getState().openId.sign.data;
    const { openIdToken, encryptedNIN } = getState().openId.intent.data;
    const validationToken = V2Validation.get();

    const xmlDataString = new XMLSerializer().serializeToString(
        getXMLSignatureDocument(signText.xml)
    );
    const clientSignature = await createSignature(
        certificate,
        keyPair,
        trustChain,
        signText
    );

    const { toBase64 } = getCrypto();

    return PublicAuthAPI.post('/v2/penneo/signatures', {
        signature: toBase64(convertUTF16ToUTF8(clientSignature)),
        signedData: toBase64(convertUTF16ToUTF8(xmlDataString)),
        idToken: openIdToken,
        validationToken: validationToken,
        options: {
            encryptedNIN: encryptedNIN,
        },
    });
};

export const prepareSignatureEutl = (
    signedXmlData: string,
    idToken: string,
    serviceId: ServiceIDs
) => {
    return PublicAuthAPI.post(
        `/v2/penneov2/${getUrlId(serviceId, Intent.SIGN)}/signatures`,
        {
            signature: btoa(signedXmlData),
            idToken,
        }
    );
};

export const finalizeSigningEutl =
    (
        challengeKey,
        storageOptions: any = {},
        newSignature: SignatureData | null = null,
        serviceId: ServiceIDs
    ): AppThunk =>
    async (dispatch, getState) => {
        dispatch({ type: Actions.OPENID_SIGN_REQUEST });

        try {
            const signatureData = newSignature;

            if (!signatureData?.token || !signatureData?.signature) {
                dispatch({
                    type: Actions.OPENID_SIGN_FAILURE,
                    payload: {
                        message:
                            'Signature Data does not include signature or token',
                        details:
                            'The signature data provided, does not include a proper signature or token key',
                    },
                });
            }

            const payload = {
                challengeKey,
                token: signatureData?.token,
                signature: signatureData?.signature,
                signedData: null,
                ...storageOptions,
            };

            const { successUrl } = await PublicSigningAPI.post(
                `/sign/penneov2/${getUrlId(serviceId, Intent.SIGN)}/signatures`,
                payload
            );

            const openIdState = getState().openId.intent.data;

            analytics.track('sign success', {
                method: `OpenID - ${openIdState.serviceId}`,
                disposable: storageOptions.disposable,
            });
            // Redirect to the success page when posting the signature successfully.
            const url = await signingRedirectUrl(
                signingStages.signed,
                challengeKey
            );

            window.location.href = successUrl || url;
        } catch (error) {
            const openIdState = getState().openId.intent.data;

            analytics.track('sign error', {
                method: `OpenID - ${openIdState.serviceId}`,
            });

            sentry.track(error, {
                serviceId: openIdState.serviceId,
                code: OpenIdErrors.ERROR_OPENID_012.code,
            });

            dispatch({
                type: Actions.OPENID_SIGN_FAILURE,
                payload: error.handled
                    ? error
                    : OpenIdErrors.createOpenIDError(
                          error,
                          OpenIdErrors.ERROR_OPENID_014
                      ),
            });
        }
    };

export const finalizeSigning =
    (
        challengeKey,
        storageOptions: any = {},
        newSignature: SignatureData | null = null
    ): AppThunk =>
    async (dispatch, getState) => {
        dispatch({ type: Actions.OPENID_SIGN_REQUEST });

        try {
            const signatureData =
                newSignature || getState().openId.sign.data.signatureData;

            const [signature, signedData] = signatureData.signature;

            const payload = {
                challengeKey,
                token: signatureData.token,
                signature,
                signedData,
                ...storageOptions,
                timestampProvider: getTimestampProvider(),
            };

            const { successUrl } = await PublicSigningAPIWithAuthHeader.post(
                '/sign/penneo/signatures',
                payload
            );

            const openIdState = getState().openId.intent.data;

            analytics.track('sign success', {
                method: `OpenID - ${openIdState.serviceId}`,
                disposable: storageOptions.disposable,
            });
            // Redirect to the success page when posting the signature successfully.
            const url = await signingRedirectUrl(
                signingStages.signed,
                challengeKey
            );
            const localData = signingData.get(challengeKey);

            if (localData) {
                signingData.set(challengeKey, {
                    ...localData,
                    ...{ outcome: 'signed' },
                });
            }

            window.location.href = successUrl || url;
        } catch (error) {
            const openIdState = getState().openId.intent.data;

            analytics.track('sign error', {
                method: `OpenID - ${openIdState.serviceId}`,
            });

            sentry.track(error, {
                serviceId: openIdState.serviceId,
                code: OpenIdErrors.ERROR_OPENID_012.code,
            });

            dispatch({
                type: Actions.OPENID_SIGN_FAILURE,
                payload: error.handled
                    ? error
                    : OpenIdErrors.createOpenIDError(
                          error,
                          OpenIdErrors.ERROR_OPENID_014
                      ),
            });
        }
    };

export const startSigning =
    (challengeKey: string): AppThunk =>
    async (dispatch, getState) => {
        dispatch({ type: Actions.OPENID_SIGN_REQUEST });

        try {
            const temporaryStorageEnabled = await isTemporaryStorageEnabled(
                challengeKey
            );
            const signatureData = await prepareSignature(getState);

            dispatch({
                type: Actions.OPENID_SIGN_DATA_FETCH_SUCCESS,
                payload: signatureData,
            });

            // If temporary storage is enabled for this signing process, instead of finalizing the signing process,
            // a page where the user can choose storage options for the signed documents will be shown.
            if (temporaryStorageEnabled) {
                const openIdState = getState().openId.intent.data;

                const method = getMethod(openIdState.serviceId);
                const signingMethodSupportsLogin = method?.login;

                // It's possible to sign with a signing method which doesn't support login in the MitID NAP flow.
                // In this scenario, the documents can be saved to the MitID user identified in the validation token.
                const validationProvider = V2Validation.getProvider();
                const validationMethodSupportsLogin =
                    validationProvider === ANY_MITID_DENMARK.credentialType();

                if (
                    signingMethodSupportsLogin ||
                    validationMethodSupportsLogin
                ) {
                    return dispatch({
                        type: Actions.OPENID_DISPLAY_STORAGE_OPTIONS,
                    });
                }
            }

            dispatch(finalizeSigning(challengeKey));
        } catch (error) {
            const openIdState = getState().openId.intent.data;

            sentry.track(error, {
                serviceId: openIdState.serviceId,
                code: OpenIdErrors.ERROR_OPENID_012.code,
            });
            dispatch({
                type: Actions.OPENID_SIGN_FAILURE,
                payload: error.handled
                    ? error
                    : OpenIdErrors.createOpenIDError(
                          error,
                          OpenIdErrors.ERROR_OPENID_012
                      ),
            });
        }
    };

export const enableMitId = (): AppThunk => async () =>
    AuthAPI.post('/mitid/enable-login');

//  TODO: Remove this method and refactor code/tests that is using this, when EIDWebComponent is tested in staging/production
export const shouldUseEIDWebcomponent = (serviceId: ServiceIDs): boolean => {
    return (
        launchDarkly.variation(Flags.USE_EID_WEBCOMPONENT) &&
        isEIDWebComponentProvider(serviceId)
    );
};

export const getOpenIdToken = async (
    serviceId: ServiceIDs,
    intent: Intent,
    tokenType: TokenType,
    payload: any
): Promise<any> => {
    if (shouldUseEIDWebcomponent(getUrlId(serviceId, intent) as ServiceIDs)) {
        return await apiAuthClient.callCollectEndpoint(tokenType, payload);
    }

    const idToken = await PublicAuthAPI.post(
        `/openid/${getUrlId(serviceId, intent)}/collect`,
        payload
    );

    if (intent !== Intent.VALIDATE_ID) {
        return idToken;
    }

    payload.encryptedNIN = storage.get(StorageKeys.ENCRYPTED_NIN);

    // Yes, this is okay for the implementation.
    // As we only use this method for either the id token or the validation token.
    // we can keep it like this for now. This will be removed once we have validated that the eid-web-component is working properly.
    return await PublicAuthAPI.post(
        `/openid/${getUrlId(serviceId, intent)}/validation-token`,
        { idToken, ...payload }
    );
};
