import React from 'react';
import { i18n } from 'Language';
import { debug } from 'Core';
import classnames from 'classnames';
import Fade from 'react-reveal/Fade';
import LoadingData from 'Common/components/Loaders/LoadingData';
import './table.scss';
import Button from 'Common/components/Button';

/**
 * TODO: we should remove the classes styles from
 * the table and create our own.
 */

type ConfigBase = {
    /**
     * Text to display as column header
     */
    label?: string;
    /**
     * Class applied to th (column header) element
     */
    thClassName?: string;
    /**
     * Class applied to all cells in the column
     */
    tdClassName?: string;
    /**
     * Disables sort functionality for column. (Disables click handlers and hides arrow up/down icons)
     */
    disableSort?: boolean;
    /**
     * Disables bubbling events on this column (fx. to prevent triggering row event handlers enabled via triggerClick option)
     */
    disableBubbling?: boolean;
    /**
     * Disables placeholder load animation while data is being fetched
     */
    disableAnimation?: boolean;
    /**
     * Override column header with a React component (Overrides label)
     */
    thComponent?: () => JSX.Element | string | number;
    /**
     * React component to render all cells in column (Defaults to rendering the value in plain text if not provided)
     */
    component?: (item: any) => JSX.Element | string | number;
};

export type Config = {
    [key: string]: ConfigBase;
};

export enum TableLabelSort {
    Asc = 'asc',
    Desc = 'desc',
}

export type Props = {
    className?: string;
    config: Config;
    dataSource: any[];
    limit?: number;
    sort?: string;
    page?: number;
    dataCount?: number;
    onNext?: (offset: number) => any;
    onPrev?: (offset: number) => any;
    onLimitChange?: (value: number) => any;
    onSortChange?: (sort: string) => any;
    onPageChange?: (page: number) => any;
    extractId?: (item: any) => string | number;
    hidePagination?: boolean;
    headComponent?: JSX.Element | string;
    internalPagination?: boolean;
    emptyTableComponent?: JSX.Element;
    isLoading?: boolean;
    hasError?: boolean;
    triggerClick?: boolean;
};

class Table extends React.Component<Props> {
    handlePrev = () => {
        const { page, onPrev } = this.props;

        if (page) {
            const prevOffset = page > 0 ? page - 1 : 0;

            return onPrev && onPrev(prevOffset);
        }

        if (onPrev) {
            debug.log(
                'Table error: you have define onPrev but not the page property'
            );
        }
    };

    handleOnNext = () => {
        const { page, onNext } = this.props;

        if (page) {
            return onNext && onNext(page + 1);
        }

        if (onNext) {
            debug.log(
                'Table error: you have define onNext but not the page property'
            );
        }
    };

    handleOnThClick = (label: string) => () => {
        const { sort, onSortChange } = this.props;
        const sortBy = sort === label ? `-${label}` : label;

        if (onSortChange) {
            onSortChange(sortBy);
        }
    };

    handleDataSource = (props: Props) => {
        const { page, limit, dataSource, hidePagination, internalPagination } =
            props;

        if (hidePagination) {
            return dataSource;
        }

        if (page && limit && internalPagination) {
            const startOffset = (page - 1) * limit;
            const endOffset = page ? page * limit : limit;

            return dataSource.slice(startOffset, endOffset);
        }

        return dataSource;
    };

    handleOnPageChange = (e: React.ChangeEvent<HTMLSelectElement>) =>
        this.props.onPageChange &&
        this.props.onPageChange(Number(e.target.value));

    handleOnLimitChange = (e: React.ChangeEvent<HTMLSelectElement>) =>
        this.props.onLimitChange &&
        this.props.onLimitChange(Number(e.target.value.replace(/\D/g, '')));

    handleTableThRender = (config: ConfigBase) => {
        if (config.thComponent && typeof config.thComponent === 'function') {
            return config.thComponent();
        } else if (config.label) {
            return config.label;
        }
    };

    renderCell(
        columnKey,
        item,
        rowIndex,
        duration = 200,
        randomRowDelay,
        columnIndex
    ) {
        const { config, isLoading } = this.props;
        const itemId = this.getItemId(item);
        const column = config[columnKey];

        const randomWidth = Math.floor(Math.random() * (70 - 80)) + 70;

        const content = column.component
            ? column.component(item)
            : item[columnKey];
        const loading = isLoading && columnKey !== 'checkbox';
        const delay = duration === 0 ? 0 : 30 * rowIndex;
        const bubblingCatch = column.disableBubbling
            ? (event: React.MouseEvent) => event.stopPropagation()
            : undefined;

        return (
            <td
                key={`${itemId}-${columnKey}`}
                className={classnames(column.tdClassName, {
                    'pointer-events-none': isLoading,
                })}
                onClick={bubblingCatch}>
                {column.disableAnimation ? (
                    <div className="table-cell-content">{content}</div>
                ) : (
                    <>
                        <Fade
                            key={`${itemId}-${columnKey}`}
                            duration={duration}
                            delay={delay}
                            force>
                            <div className="table-cell-content">
                                <Fade
                                    duration={duration / 2}
                                    delay={delay}
                                    when={!loading}>
                                    {content}
                                </Fade>
                            </div>
                        </Fade>

                        <Fade
                            duration={duration}
                            when={loading}
                            delay={delay}
                            mountOnEnter
                            unmountOnExit
                            force>
                            <div className="table-cell-loading-container">
                                <div
                                    className={`table-cell-loading`}
                                    style={{
                                        width: `${randomWidth}%`,
                                        animationDelay:
                                            -(randomRowDelay * 10) + 'ms',
                                    }}>
                                    <div
                                        className="table-cell-loading-glare"
                                        style={{
                                            animationDelay:
                                                -(columnIndex * 300) + 'ms',
                                        }}></div>
                                </div>
                            </div>
                        </Fade>
                    </>
                )}
            </td>
        );
    }

    renderRowColumns(item, rowIndex, randomRowDelay) {
        const columns = Object.keys(this.props.config);

        return columns.map((columnKey, index) =>
            this.renderCell(
                columnKey,
                item,
                rowIndex,
                index === 0 ? 0 : 600,
                randomRowDelay,
                index
            )
        );
    }

    getItemId(item) {
        const { extractId } = this.props;

        return extractId ? extractId(item) : item.id;
    }

    renderRow(item, rowIndex: number) {
        const itemId = this.getItemId(item);
        const randomRowDelay = Math.floor(Math.random() * (80 - 100)) + 80;
        const triggerFn = this.props.triggerClick
            ? () => document.getElementById(`link-${itemId}`)?.click()
            : undefined;

        return (
            <tr key={itemId} onClick={triggerFn}>
                {this.renderRowColumns(item, rowIndex, randomRowDelay)}
            </tr>
        );
    }

    render() {
        const {
            config,
            limit,
            page,
            dataSource,
            hidePagination,
            dataCount,
            headComponent,
            emptyTableComponent,
            isLoading,
            hasError,
        } = this.props;

        const perPageRanges = [10, 25, 50, 100];
        const data = this.handleDataSource(this.props);

        return (
            <div
                className={classnames(
                    'data-table-container ui-v2',
                    this.props.className
                )}>
                {headComponent && headComponent}
                <table>
                    <thead>
                        <tr>
                            {Object.keys(config).map((columnId) => (
                                <th
                                    key={columnId}
                                    className={classnames(
                                        config[columnId].thClassName
                                    )}
                                    onClick={
                                        config[columnId].disableSort
                                            ? () => ({})
                                            : this.handleOnThClick(columnId)
                                    }>
                                    <span
                                        className={classnames(
                                            'data-table-th-content',
                                            {
                                                'sort-enabled':
                                                    !config[columnId]
                                                        .disableSort,
                                            }
                                        )}>
                                        {this.handleTableThRender(
                                            config[columnId]
                                        )}

                                        {!config[columnId].disableSort && (
                                            <div className="th-sort">
                                                {this.props.sort === columnId ||
                                                this.props.sort ===
                                                    '-' + columnId ? (
                                                    <i
                                                        className={`th-icon fas fa-sort-${
                                                            this.props.sort ===
                                                            columnId
                                                                ? 'up'
                                                                : 'down'
                                                        }`}
                                                    />
                                                ) : (
                                                    <i className="th-icon disabled fas fa-sort"></i>
                                                )}
                                            </div>
                                        )}
                                    </span>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((item, index) => this.renderRow(item, index))}
                    </tbody>
                </table>

                <div className="empty-table">
                    {isLoading && (
                        <div
                            className={classnames({
                                'empty-table-body': !dataSource.length,
                                'loading-data': dataSource.length,
                            })}>
                            <LoadingData />
                        </div>
                    )}

                    {hasError && !isLoading && (
                        <div
                            className={classnames({
                                'empty-table-body': !dataSource.length,
                                'loading-data': dataSource.length,
                            })}>
                            <div>
                                <i className="far fa-lg fa-exclamation-triangle"></i>
                            </div>
                            <h3>{i18n`There was an error retrieving this data. Please try again`}</h3>
                        </div>
                    )}

                    {!dataSource.length &&
                        emptyTableComponent &&
                        !isLoading &&
                        !hasError &&
                        emptyTableComponent}
                </div>

                {!hidePagination && (
                    <div className="table-pagination">
                        {limit && (
                            <select
                                value={limit}
                                className="limit pull-left"
                                onChange={this.handleOnLimitChange}>
                                {perPageRanges.map((x) => (
                                    <option value={x} key={x}>
                                        {x}
                                    </option>
                                ))}
                            </select>
                        )}
                        {!!page && !!limit && !!dataCount && (
                            <span>
                                <Button
                                    className="prev mr-2"
                                    theme="blue"
                                    variant="text"
                                    onClick={this.handlePrev}
                                    disabled={(page - 1) * limit === 0}
                                    data-testid="button-prev">
                                    <i className="button-icon far fa-arrow-left"></i>
                                    {i18n('Previous')}
                                </Button>
                                <span>
                                    {i18n`Page ${(
                                        <select
                                            onChange={this.handleOnPageChange}
                                            className="table-current-page"
                                            value={page}>
                                            {Array.from(
                                                {
                                                    length: Math.ceil(
                                                        dataCount / limit
                                                    ),
                                                },
                                                (_, k) => k + 1
                                            ).map((x) => (
                                                <option key={x} value={x}>
                                                    {x}
                                                </option>
                                            ))}
                                        </select>
                                    )} of ${Math.ceil(dataCount / limit)}`}
                                </span>
                                <Button
                                    theme="blue"
                                    variant="text"
                                    className="next ml-2"
                                    onClick={this.handleOnNext}
                                    icon="far fa-arrow-right"
                                    disabled={dataCount <= page * limit}
                                    data-testid="button-next">
                                    {i18n('Next')}
                                </Button>
                            </span>
                        )}
                    </div>
                )}
            </div>
        );
    }
}

export default Table;
