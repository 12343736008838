import PropTypes from 'prop-types';
import React from 'react';
import DocumentNavigator from '../Form/DocumentNavigator.jsx';
import DocumentMapping from '../Form/DocumentMapping.jsx';
import InputSelector from './InputSelector.jsx';
import FormStore from '../../stores/FormStore';
import WorkflowStore from '../../stores/WorkflowStore';
import FormActions from '../../actions/FormActionCreators';
import WorkflowActions from '../../actions/WorkflowActionCreators';
import Modal, { modal } from '../../../Common/components/Common/Modal';
import HTML5Backend from 'react-dnd-html5-backend';
import { DragDropContext } from 'react-dnd';
import { translate } from 'Language';
import Analytics from 'Common/Analytics';

let timestamp;

export default DragDropContext(HTML5Backend)(
    class extends React.Component {
        static propTypes = {
            params: PropTypes.object,
        };

        static contextTypes = {
            router: PropTypes.object.isRequired,
        };

        state = {
            doc: [],
            pages: [],
            fields: [],
            mapping: [],
            viewportWidth: null,
            middlepoint: null,
            editorSettings: FormStore.getEditorSettings(),
            dimensions: {},
        };

        componentDidMount() {
            // Prevent body scrolling, for mobile compatibility.
            document.body.classList.add('no-scroll');

            // Attach Event Listeners
            window.addEventListener('resize', this.handleResize);
            FormStore.addChangeListener(this.onFormChange);
            FormStore.addEventListener(
                'EDITOR_SETTING_UPDATED',
                this.onSettingUpdate
            );
            FormStore.addEventListener('EDITOR_FIELD_ADDED', this.onFieldAdded);
            FormStore.addEventListener('EDITOR_DONE', this.onDone);
            WorkflowStore.addChangeListener(this.onWorkflowChange);

            timestamp = new Date().getTime();

            // Load Initial set of data.
            this.loadWorkflowData();
        }

        componentWillUpdate(nextProps, nextState) {
            if (!this.state.workflow && nextState.workflow) {
                let formId =
                    nextState.workflow.userData.initial.form.prototypeId;

                this.loadFormData(formId);
            }
        }

        componentWillUnmount() {
            document.body.classList.remove('no-scroll');

            window.removeEventListener('resize', this.handleResize);
            FormStore.removeChangeListener(this.onFormChange);
            FormStore.removeEventListener(
                'EDITOR_SETTING_UPDATED',
                this.onSettingUpdate
            );
            FormStore.removeEventListener(
                'EDITOR_FIELD_ADDED',
                this.onFieldAdded
            );
            FormStore.removeEventListener('EDITOR_DONE', this.onDone);
            WorkflowStore.removeChangeListener(this.onWorkflowChange);
        }

        onWorkflowChange = () => {
            this.setState({
                workflow: WorkflowStore.getCurrentWorkflowPrototype(),
            });
        };

        /**
         * Sends setting changes to analytics services
         * @param {object} setting
         */
        onSettingUpdate = (setting) => {
            const [label, value] = Object.entries(setting)[0];

            Analytics.track('form mapping editor - setting changed', {
                label,
                value,
                formId: this.props.params.formId,
            });
        };

        /**
         * Sends field addition to analytics services
         */
        onFieldAdded = () => {
            Analytics.track('form mapping editor - field added', {
                formId: this.props.params.formId,
            });
        };

        /**
         * Tracks the event via the analytics services and redirects
         * the user to the form templates list
         */
        onDone = () => {
            const { router } = this.context;

            Analytics.track('form mapping editor - done', {
                formId: this.props.params.formId,
            });

            router.push({
                name: 'form-templates',
            });
        };

        /**
         * Listens to Store Change event emisions, and updates all
         * states acccording to the latest store contents.
         */
        onFormChange = () => {
            this.setState({
                doc: FormStore.getFormDocument(),
                form: FormStore.getCurrentForm(),
                pages: FormStore.getFormDocumentImages(),
                fields: FormStore.getFormFields(),
                mapping: FormStore.getFormFieldsMapping(),
                dimensions: FormStore.getDocumentDimensions(),
                editorSettings: FormStore.getEditorSettings(),
            });
        };

        /**
         * Updates value on field state and propagates the value to all mapped fields with
         * the same field ID
         * @param field {object} field state object containing field value and id
         * @param event {value}  field value
         */
        updateField = (fieldId, value) => {
            FormActions.updateField(fieldId, value);
        };

        loadWorkflowData = () => {
            let workflowId = this.props.params.workflowId;

            WorkflowActions.getWorkflow(workflowId);
        };

        loadFormData = (formId) => {
            FormActions.fetchForm(formId);
            FormActions.fetchFields(formId);
            FormActions.fetchDocument(formId);
            FormActions.fetchMappings(formId);

            this.setState({ viewportWidth: window.innerWidth });
        };

        /**
         * Listens to Browser Resizing events.
         * Sets viewportWidth state, which tracks the current browser width.
         */
        handleResize = () => {
            this.setState({ viewportWidth: window.innerWidth });

            if (window.innerWidth > 480 && this.state.currentView === 1) {
                this.setState({ currentView: 2 });
            }
        };

        /**
         * Currently, the server returns mappings with object keys such as [documentId, fieldId]
         * but expects POST and PUT with the same keys named as [document, field].
         * This function prepares the data for PUT and POSTS. This function is temporary and it
         * should go away when the server expects input and output consistently
         *
         * @return {array} Array of mappings with data ready for server input.
         */
        cleanMappings = (mappings) => {
            const fieldKey = {
                from: 'documentId',
                to: 'document',
            };

            const documentKey = {
                from: 'fieldId',
                to: 'field',
            };

            mappings.forEach((mapping) => {
                mapping[fieldKey.to] = mapping[fieldKey.from];
                mapping[documentKey.to] = mapping[documentKey.from];
                delete mapping[fieldKey.from];
                delete mapping[documentKey.from];
            });

            return mappings;
        };

        editFormTemplate = () => {
            let { router } = this.context;
            let { workflowId, formId } = this.props.params;

            router.push({
                name: 'form-editor',
                params: {
                    formId: formId,
                    workflowId: workflowId,
                },
            });
        };

        /**
         * Calls ActionCreator to save all the fields in the form
         * @param event {onClick} event on the form submit button.
         */
        saveFormTemplateOnClick = (event) => {
            event.preventDefault();

            let config = {
                title: translate('Please Wait'),
                body: (
                    <div className="loader-wrapper">
                        <div className="loader"></div>
                    </div>
                ),
                preventClose: true,
            };

            modal.show(config);

            let { formId } = this.props.params;
            let { mapping } = this.state;

            let serverMapping = [];
            let editorMapping = [];

            mapping.forEach((fieldMap) => {
                if (fieldMap.hasOwnProperty('id')) {
                    serverMapping.push(fieldMap);
                } else {
                    editorMapping.push(fieldMap);
                }
            });

            let saveEditorMapping = Promise.resolve();

            if (editorMapping.length > 0) {
                saveEditorMapping = this.saveEditorMapping(
                    editorMapping,
                    formId
                );
            }

            let updateServerMapping = Promise.resolve();

            if (serverMapping.length > 0) {
                updateServerMapping = this.updateServerMapping(
                    serverMapping,
                    formId
                );
            }

            Promise.all([saveEditorMapping, updateServerMapping]).then(() => {
                modal.hide();
            });
        };

        /**
         * Calls ActionCreator to save all the fields in the form
         * @param event {onClick} event on the form submit button.
         */
        saveFormTemplate = () => {
            let { formId } = this.props.params;
            let { mapping } = this.state;

            let serverMapping = [];

            mapping.forEach((fieldMap) => {
                if (fieldMap.hasOwnProperty('id')) {
                    serverMapping.push(fieldMap);
                }
            });

            let updateServerMapping = Promise.resolve();

            if (serverMapping.length > 0) {
                updateServerMapping = this.updateServerMapping(
                    serverMapping,
                    formId
                );
            }

            // saveEditorMapping,
            Promise.all([updateServerMapping]).then(() => {});
        };

        saveEditorMapping = (mapping, formId) => {
            return FormStore.saveFormFieldMappings(formId, mapping);
        };

        updateServerMapping = (mapping, formId) => {
            return FormStore.updateFormFieldMappings(formId, mapping);
        };

        redirectToPreview = (id) => {
            this.context.router.transitionTo('form', { id: id });
        };

        // Convert Mapping Pixels to Percentages
        convertMappingPosition = (mapping, width, height) => {
            let computedMapping = [];

            for (let i = 0; i < mapping.length; i++) {
                mapping[i].x = (mapping[i].x + 2) / width;
                mapping[i].y = (mapping[i].y + 2) / height;
                mapping[i].width = (mapping[i].width - 4) / width;
                mapping[i].height = (mapping[i].height - 4) / height;
                computedMapping.push(mapping[i]);
            }

            return computedMapping;
        };

        /**
         * Cap digits to avoid errors in PDF Service with mis-mapped fields
         * @return {Array} contains positions with a limited amount of precision digits.
         */
        limitFloatingPointDecimals = (mapping) => {
            const digits = 5;
            let cappedDecimalsMapping = [];

            for (let i = 0; i < mapping.length; i++) {
                mapping[i].x = mapping[i].x.toFixed(digits);
                mapping[i].y = mapping[i].y.toFixed(digits);
                mapping[i].width = mapping[i].width.toFixed(digits);
                mapping[i].height = mapping[i].height.toFixed(digits);

                cappedDecimalsMapping.push(mapping[i]);
            }

            return cappedDecimalsMapping;
        };

        /**
         * Updates state to mark field as highlighted
         * @param field {object} field state object containing field value and id
         * @param event {event} onFocus event, activated by active fields in the form sidebar
         */
        highlightFieldHandler = (field, event) => {
            event?.preventDefault();

            let fields = this.state.fields;

            for (let i in fields) {
                if (fields[i].id === field.id) {
                    fields[i].isHighlighted = true;
                }
            }

            this.setState({ fields: fields });
        };

        /**
         * Updates state to remove flag that marks a field as highlighted.
         * @param field {object} field state object containing field value and id
         * @param event {event} onFocus event, activated by active fields in the form sidebar
         */
        removeHighlightHandler = (field, event) => {
            event.preventDefault();

            let fields = this.state.fields;

            for (let i in fields) {
                if (fields[i].id === field.id) {
                    fields[i].isHighlighted = false;
                }
            }

            this.setState({ fields: fields });
        };

        render() {
            let formId = this.props.params.formId;
            let { form, doc, fields, mapping, scalableStyle, viewerStyle } =
                this.state;
            let { pages, dimensions } = this.state;
            const viewerId = 'viewer';

            return (
                <div key={formId} className="document-forms-wrapper">
                    <Modal />
                    <div className="document-forms-editor">
                        <DocumentNavigator
                            pages={pages}
                            form={form}
                            viewerId={viewerId}
                            getPageContent={this.getPageContent}
                            timestamp={timestamp}
                        />

                        <DocumentMapping
                            doc={doc}
                            pages={pages}
                            fields={fields}
                            mapping={mapping}
                            disableEvent={this.disableEvent}
                            getCoords={this.getCoords}
                            updateField={this.updateField}
                            dimensions={dimensions}
                            scalableStyle={scalableStyle}
                            viewerStyle={viewerStyle}
                            editMode={true}
                            timestamp={timestamp}
                            save={this.saveFormTemplate}
                            formId={this.props.params.formId}
                            editorSettings={this.state.editorSettings}
                        />

                        <InputSelector
                            fields={fields}
                            save={this.saveFormTemplateOnClick}
                            edit={this.editFormTemplate}
                        />
                    </div>
                </div>
            );
        }
    }
);
