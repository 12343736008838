import axios from 'axios';
import debounce from 'lodash/debounce';

import Constants from 'Constants';
export interface QRSSClientImplementation {
    wsClient?: WebSocket;
    // translateIdToken: (idToken: string) => Promise<string>;
    connect: (wsUri: string) => Promise<unknown>;
    sign: (
        translatedIdToken: string,
        dataToBeSigned: string,
        processEmit?: (message: QRSSState) => void
    ) => Promise<QRSSSignatureMessage['signedData']>;
}
export const enum QRSSState {
    SIGNING_PROCESS_INITIATED = 'SIGNING_PROCESS_INITIATED',
    SIGNATURE_ACTIVATION_DATA_REQUESTED = 'SIGNATURE_ACTIVATION_DATA_REQUESTED',
    SIGNATURE_ACTIVATION_DATA_RECEIVED = 'SIGNATURE_ACTIVATION_DATA_RECEIVED',
    SIGNATURE_PRODUCED = 'SIGNATURE_PRODUCED',
}

export type QRSSMessage = {
    type: QRSSState;
    message: Record<string, string | number>;
};

export type QRSSSignatureMessage = {
    sessionId: string;
    signedData: string;
};

// Type-Safe message factory to generate QRSSMessage(s)
const messageFactory = ({ type, message }: QRSSMessage): string =>
    JSON.stringify({ type, message });

export class QRSSClient implements QRSSClientImplementation {
    wsClient = undefined;
    connect = async (wsUri: string): Promise<void> => {
        return new Promise((res, rej) => {
            try {
                //TODO: This feels dirty lets figure out how to do it
                (this.wsClient as unknown as WebSocket) = new WebSocket(
                    (wsUri.startsWith('ws') && wsUri) || `ws://${wsUri}`
                );

                (this.wsClient as unknown as WebSocket).onopen = () => {
                    res(undefined);
                };

                (this.wsClient as unknown as WebSocket).onclose = () => {
                    console.error('Client closed connection prematurely!');
                    rej('Client closed connection');
                };
            } catch (e) {
                if (process.env.NODE_ENV !== 'production') {
                    console.error(
                        '(.connect)',
                        'The WSClient connection could not be established, please verify your connection/proxy to the S Endpoint and connection URI'
                    );
                }

                rej(e);
            }
        });
    };

    sign = async (
        translatedIdToken: string,
        dataToBeSigned: string,
        processEmit?: (message: QRSSState) => void
    ): Promise<QRSSSignatureMessage['signedData']> => {
        const ws = this.wsClient as unknown as WebSocket;
        let timer: any;

        return new Promise(async (res, rej) => {
            const setTimer = (
                timeout: number,
                errorMessage: string = 'Unknown sign error'
            ) => {
                timer && timer?.cancel();

                if (timeout > -1) {
                    timer = debounce(() => rej(errorMessage), timeout);
                }
            };

            if (!ws) {
                if (process.env.NODE_ENV !== 'production') {
                    console.error(
                        '(.sign)',
                        'Instantiate the WSClient connection with a .connect function call'
                    );
                }

                return rej('WSClient not instantiated');
            }

            if (!ws.OPEN) {
                if (process.env.NODE_ENV !== 'production') {
                    console.error(
                        '(.sign)',
                        'The WSClient connection could not be established, please verify your connection/proxy to the WS Endpoint and connection URI'
                    );
                }

                return rej('WSClient not connected');
            }

            // Initial message that kicks off the flow
            const initMessage: QRSSMessage = {
                type: QRSSState.SIGNING_PROCESS_INITIATED,
                message: {
                    auth: translatedIdToken,
                    dtbs: btoa(dataToBeSigned),
                },
            };

            ws.send(messageFactory(initMessage));

            ws.onmessage = async (receivedMessage: MessageEvent) => {
                const { data: socketData } = receivedMessage;
                const { type, message } = JSON.parse(socketData);

                processEmit?.(type);
                switch (type) {
                    case QRSSState.SIGNATURE_ACTIVATION_DATA_REQUESTED:
                        const { userId, keyId, dtbsHash, sessionId } = message;
                        const sadService = `https://${Constants.QRSS_PROXY_ENDPOINT}/v1/sad/sign`;

                        const sadData = await axios
                            .post(sadService, {
                                userId,
                                keyId,
                                dtbs: dtbsHash,
                                idToken: translatedIdToken,
                                sessionId,
                            })
                            .then((response) => response.data)
                            .catch(() => {
                                setTimer(-1);
                                rej(
                                    'An error occurred signing the Signature Activation Data'
                                );
                                ws.close();
                            });
                        const { sad } = sadData;

                        const sentData = {
                            type: QRSSState.SIGNATURE_ACTIVATION_DATA_RECEIVED,
                            message: {
                                sessionId: message.sessionId,
                                activationData: sad,
                            },
                        };

                        const msg = messageFactory(sentData);

                        ws.send(msg);
                        setTimer(5000);
                        break;
                    case QRSSState.SIGNATURE_PRODUCED:
                        if (!message.signedData) {
                            setTimer(-1);
                            rej('Could not sign document data');

                            return;
                        }

                        setTimer(-1); // Exit rejection early

                        return Promise.resolve(res(message.signedData));
                    default:
                        setTimer(-1);
                        rej(`No resolver found for ${type}`);
                        ws.close();
                }
            };

            ws.onclose = () => {
                rej('Unexpected Closure of Socket Connection');
            };
            setTimer(5000);

            return 'Unknown clause';
        });
    };
}

export default QRSSClient;
