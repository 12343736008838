import React from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { isBefore, isAfter, isSameDay, addMinutes, addDays } from 'date-fns';
import moment from 'moment';
import { enUS, sv, nb, da, fr, nl } from 'date-fns/locale';
import { i18n, TranslationStore } from 'Language';

type Props = {
    type: 'sendAt' | 'expireAt';
    sendAtDate: any;
    expireAtDate: any;
    sendAtPlaceholder?: string;
    expireAtPlaceholder?: string;
    onChange: (date: any) => void;
    enabled: boolean;
    clearExpireDate: () => void;
};

const normalizeDate = (date: any): Date | null => {
    if (!date) return null;

    return moment.isMoment(date) ? date.toDate() : date;
};

const formatDateForConsumer = (date: Date): any => {
    return moment(date);
};

const TimeStamp: React.FC<Props> = ({
    type,
    sendAtDate,
    expireAtDate,
    sendAtPlaceholder,
    expireAtPlaceholder,
    onChange,
    enabled,
    clearExpireDate,
}) => {
    const normalizedSendAtDate = normalizeDate(sendAtDate);
    const normalizedExpireAtDate = normalizeDate(expireAtDate);

    const getCalendarLanguage = React.useMemo(() => {
        const languageCode = TranslationStore.getLanguage();

        switch (languageCode) {
            case 'da':
                return da;
            case 'no':
                return nb;
            case 'sv':
                return sv;
            case 'fr':
                return fr;
            case 'nl':
                return nl;
            default:
                return enUS;
        }
    }, []);

    const onDateChange = (date: Date | null) => {
        if (!date) return;

        let newDate = date;

        if (normalizedSendAtDate && type === 'sendAt') {
            if (
                normalizedExpireAtDate &&
                (isSameDay(newDate, normalizedExpireAtDate) ||
                    isAfter(newDate, normalizedExpireAtDate))
            ) {
                clearExpireDate();
            }

            if (
                isSameDay(newDate, new Date()) &&
                isBefore(newDate, new Date())
            ) {
                newDate = addMinutes(new Date(), 1);
            }
        } else {
            if (
                normalizedSendAtDate &&
                (isSameDay(newDate, normalizedSendAtDate) ||
                    isBefore(newDate, normalizedSendAtDate))
            ) {
                newDate = addDays(normalizedSendAtDate, 1);
            }
        }

        onChange(formatDateForConsumer(newDate));
    };

    const filterPassedTime = (time) => {
        let currentDate = new Date();
        const selectedDate = new Date(time);

        return currentDate.getTime() < selectedDate.getTime();
    };

    return (
        <div className="time-stamp horizontal-stamp">
            <div className="time-stamp-date-picker">
                {type === 'sendAt' ? (
                    <ReactDatePicker
                        selected={normalizedSendAtDate}
                        onChange={onDateChange}
                        disabled={!enabled}
                        minDate={new Date()}
                        locale={getCalendarLanguage}
                        showTimeSelect
                        timeCaption={i18n('timeCaption')}
                        timeFormat="HH:mm"
                        timeIntervals={15}
                        dateFormat="dd-MM-yyyy HH:mm"
                        placeholderText={i18n(sendAtPlaceholder)}
                        filterTime={filterPassedTime}
                    />
                ) : (
                    <ReactDatePicker
                        selected={normalizedExpireAtDate}
                        onChange={onDateChange}
                        disabled={!enabled}
                        minDate={
                            normalizedSendAtDate
                                ? addDays(normalizedSendAtDate, 1)
                                : new Date()
                        }
                        locale={getCalendarLanguage}
                        timeCaption={i18n('timeCaption')}
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={15}
                        dateFormat="dd-MM-yyyy HH:mm"
                        placeholderText={i18n(expireAtPlaceholder)}
                        filterTime={filterPassedTime}
                    />
                )}
            </div>
        </div>
    );
};

export default TimeStamp;
