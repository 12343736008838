import React from 'react';
import { images } from 'Constants';
import UITooltip from 'Common/components/Vega/Tooltip';
import UILoadingSpinner from 'Common/components/Vega/LoadingSpinner';
import {
    XMarkIcon,
    CheckCircleIcon,
    ExclamationTriangleIcon,
} from '@heroicons/react/20/solid';
import UIInlineMessage from 'Common/components/Vega/InlineMessage';
import { UtilityState } from 'DocumentServices/Common/Types';
import { Variant } from '@penneo/vega-ui/dist/components/_common/types';
import { Button } from '@penneo/vega-ui-react';
import clsx from 'clsx';

const resolveInlineDescription = (utilityState: UtilityState) => {
    switch (utilityState) {
        case 'notPenneoDocument':
            return 'This file does not contain Penneo signatures or has invalid signatures. Please try a different document';
        case 'validationError':
            return 'File format not supported. Please verify the format and try again';
        default:
            return 'Formatting failed. Please try again';
    }
};
const resolveProperties = (utilityState: UtilityState) => {
    let description: string | undefined = undefined;
    let hasError = false;
    let variant: Variant = 'accent';
    let icon = 'pdf.svg',
        inlineDescription = '';
    let showInlineMessage = false;

    if (utilityState === UtilityState.UPLOADING) {
        description = 'Formatting in progress';
    } else if (
        utilityState === UtilityState.UPLOAD_ERROR ||
        utilityState === UtilityState.NOT_PENNEO_DOCUMENT ||
        utilityState === UtilityState.VALIDATION_ERROR
    ) {
        hasError = true;
        description = 'Formatting failed';
        variant = 'danger';
        icon = 'pdf-error.svg';
        inlineDescription = resolveInlineDescription(utilityState);
        showInlineMessage = true;
    } else if (utilityState === UtilityState.UPLOAD_SUCCESS) {
        variant = 'success';
        inlineDescription = 'Document formatted successfully';
        showInlineMessage = false;
        description = 'Formatting complete';
    } else if (utilityState === UtilityState.NO_ACTION_NEEDED) {
        inlineDescription =
            'No issue detected. The document has correct formatting';
        showInlineMessage = true;
    }

    return {
        description,
        inlineDescription,
        variant,
        icon,
        showInlineMessage,
        hasError,
    };
};

type Props = {
    documentState: UtilityState;
    fileName: string;
    donwloadUrl?: string;
    onReset?: () => void;
};

const DocumentFile = ({ fileName, documentState, onReset }: Props) => {
    const {
        description,
        inlineDescription,
        variant,
        icon,
        showInlineMessage,
        hasError,
    } = resolveProperties(documentState);

    return (
        <>
            <div
                className={`flex items-center bg-white rounded-[3px] border-solid border-[1px] p-4 h-[72px] ${
                    hasError
                        ? 'border-danger-700'
                        : documentState === 'uploadSuccess'
                        ? 'border-success-600'
                        : 'border-neutral-300'
                }`}>
                <div className="inline-flex items-center">
                    <img
                        className="inline-block align-middle w-[27.5px] h-[35px] flex-shrink-0"
                        src={`${images}/file-component/${icon}`}
                        alt="icon"
                    />
                    <div className="inline-block align-middle ml-2 text-[14px] text-neutral-900 font-semibold">
                        {fileName}
                    </div>
                </div>
                <div className="flex items-center ml-auto">
                    <div className="inline-flex items-center justify-between ml-8">
                        {description && (
                            <span
                                className={clsx(
                                    'ml-8',
                                    hasError && 'text-danger-700',
                                    documentState === 'uploadSuccess' &&
                                        'text-success-600'
                                )}>
                                {description}
                                {documentState === 'uploadSuccess' && (
                                    <CheckCircleIcon className="h-5 fill-success-600 inline-block align-middle ml-1 mt-[-2px]" />
                                )}
                                {hasError && (
                                    <ExclamationTriangleIcon className="h-5 fill-danger-700 inline-block align-middle ml-1 mt-[-2px]" />
                                )}
                            </span>
                        )}
                        <div className="p-2">
                            {documentState === 'uploading' && (
                                <UILoadingSpinner variant="accent" />
                            )}
                            {onReset && (
                                <>
                                    <Button
                                        onClick={onReset}
                                        type="neutral"
                                        priority="tertiary"
                                        leftIcon={
                                            <XMarkIcon className="h-6" />
                                        }></Button>
                                    <UITooltip placement="right" offset={7}>
                                        Close
                                    </UITooltip>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {showInlineMessage && (
                <div className="mt-1">
                    <UIInlineMessage
                        variant={variant}
                        description={inlineDescription}
                        hideCloseButton
                    />
                </div>
            )}
        </>
    );
};

export default DocumentFile;
