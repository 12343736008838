import React from 'react';
import { i18n } from 'Language';
import { connect } from 'react-redux';
import ActionDropDown from './ActionDropDown';
import AuthStore from 'Auth/stores/AuthStore';

import { ReduxState, AppDispatch } from 'Store';
import { denormalize } from 'Casefiles/redux/contacts/utils';
import AutocompleteContacts from 'Common/components/AutocompleteContacts';
import {
    FolderUserRights,
    FolderUserWithCustomProperty,
    getFolderShareData,
    shareFolder,
    updateUserFolderRights,
    deleteFolderInvitation,
    unshareFolder,
} from '../redux/reducer';
import {
    ACCESS_RIGHTS_LABELS,
    ACCESS_READONLY,
    PERSONAL_ARCHIVE_ROUTE_NAMES,
    ARCHIVE_ROUTE_NAMES,
} from 'Constants';
import './share.scss';
import moment from 'moment';
import LoadingData from 'Common/components/Loaders/LoadingData';
import FullScreenModal from 'Common/components/FullScreenModal';
import Button from 'Common/components/Button';
import { FolderShare } from 'types/Folder';
import { UserEntity } from 'types/User';
import { Props as AutoCompleteProps } from 'Common/components/Autocomplete/Autocomplete';
// LEGACY store - TODO refactor to Redux
import UserStore from 'Auth/stores/UserStore';
import Griddle from 'griddle-react';

import {
    CheckCircleIcon,
    InformationCircleIcon,
    UserGroupIcon,
} from '@heroicons/react/20/solid';
import { getUserAccess } from 'Casefiles/utils';
import { ToastContext, ToastContextType } from 'Common/components/ToastContext';

export type Props = {
    dispatch: AppDispatch;
    folderId: number;
    parentFolderId: number | null;
    contacts: any[];
    title: any;
    shares: FolderShare[];
    invites: any;
    isLoaded: any;
    isFetching: any;
    onShareDone: (error) => void;
    router: any;
};

type StateComp = {
    currentUser: UserEntity | null;
    tab: number;
    email: string;
    id: number;
    right: FolderUserRights;
    shares: FolderShare[];
    invites: any[];
    isLoading: boolean;
    error?: string;
};

export class Share extends React.Component<Props, StateComp> {
    declare context: ToastContextType;

    static contextType = ToastContext;

    private get addToast() {
        const toast = this.context;

        if (!toast) {
            console.error('ToastContext not found');

            return;
        }

        return toast?.addToast;
    }

    private router: any;

    constructor(props: Props) {
        super(props);
        this.router = props.router;
        this.state = {
            currentUser: null,
            email: '',
            id: 0,
            right: ACCESS_READONLY,

            tab: 0,
            shares: [],
            invites: [],
            isLoading: true,
        };
    }

    /**
     * When dismissing the modal, the user gets redirected to the
     * folder's parent, and if there isn't any then to the root folder
     */
    goBack = () => {
        const { parentFolderId } = this.props;

        const { isSignersArchive } = getUserAccess();

        const route = parentFolderId
            ? {
                  name: isSignersArchive
                      ? PERSONAL_ARCHIVE_ROUTE_NAMES.folderRoute
                      : ARCHIVE_ROUTE_NAMES.folderRoute,
                  params: {
                      folderId: parentFolderId,
                  },
              }
            : {
                  name: isSignersArchive
                      ? PERSONAL_ARCHIVE_ROUTE_NAMES.mainRoute
                      : ARCHIVE_ROUTE_NAMES.mainRoute,
              };

        this.router.push(route);
    };

    componentDidMount() {
        const { dispatch, folderId } = this.props;

        dispatch(getFolderShareData(folderId));
        this.setState({
            currentUser: UserStore.getCurrentUser(),
        });
    }

    handleOnBlurInputField = (inputValue: AutoCompleteProps['value']) => {
        const { contacts, shares } = this.props;

        const suggestions = this.suggestionDataSource(contacts, shares);

        const matchedContact = suggestions.find(
            (suggestion) => suggestion.email === (inputValue as string).trim()
        );

        if (matchedContact) {
            this.setState({
                email: matchedContact.email,
                id: matchedContact.id,
            });
        }
    };

    handleSendInvite = async () => {
        const { email, id, right } = this.state;

        if (!this.validateEmail(email.trim())) {
            this.setState({
                error: i18n('invalidEmailError'),
            });

            return;
        }

        const { folderId, dispatch, contacts } = this.props;
        const contact = contacts.find((c) => c.id === id);

        if (contact?.id === this.state.currentUser?.id) {
            return this.addToast?.(
                undefined,
                i18n`shareFolder.cannotShareWithOwnerError`,
                'danger',
                10000,
                i18n`toast.cancelButton`
            );
        }

        // If the user is a part of the organization
        if (contact && !contact.isExternal) {
            const payload = {
                user: contact.id,
                right,
            };

            this.setState({ tab: 0, email: '', id: 0 });
            await dispatch(shareFolder(folderId, payload));

            return this.addToast?.(
                undefined,
                i18n`Folder has been shared with ${contact.fullName}`,
                'accent',
                10000,
                i18n`toast.cancelButton`,
                undefined,
                undefined,
                CheckCircleIcon
            );
        }

        const payload = {
            email,
            right,
            external: true,
        };

        this.setState({ tab: 1, email: '', id: 0 });
        await dispatch(shareFolder(folderId, payload));

        return this.addToast?.(
            undefined,
            i18n`An invite has been sent to ${email}`,
            'accent',
            10000,
            i18n`toast.cancelButton`,
            undefined,
            undefined,
            CheckCircleIcon
        );
    };

    validateEmail = (email) => {
        // @duplicate regex from '/Constants'
        const regex =
            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9_-]{2,63})+$/;

        return regex.test(email.trim());
    };

    // Handle changes of invite form
    onEmailChange = (email: string) =>
        this.setState({ email, id: 0, error: undefined });
    onRightChange = (right: FolderUserRights) => this.setState({ right });

    deleteInvite = async (invite) => {
        const { folderId, dispatch } = this.props;

        await dispatch(deleteFolderInvitation(folderId, invite.id));

        this.addToast?.(
            undefined,
            i18n`Invite for ${invite.email} was deleted`,
            'accent',
            10000,
            i18n`toast.cancelButton`
        );
    };

    unshareFolder = async (share) => {
        const { folderId, dispatch } = this.props;

        await dispatch(unshareFolder(folderId, share.id));
        this.addToast?.(
            undefined,
            i18n`${share.fullName} no longer has access to this folder`,
            'accent',
            10000,
            i18n`toast.cancelButton`
        );
    };

    updateShareRights = async (share, right) => {
        const { folderId, dispatch } = this.props;

        const translatedShareLevelText = i18n(ACCESS_RIGHTS_LABELS[right].type);

        await dispatch(updateUserFolderRights(folderId, share.id, right));

        this.addToast?.(
            undefined,
            i18n`${share.fullName} can now ${translatedShareLevelText} this folder`,
            'accent',
            10000,
            i18n`toast.cancelButton`
        );
    };

    handleOnSuggestionClick = ({ email, id }: FolderUserWithCustomProperty) => {
        this.setState({ email, id });
    };

    suggestionDataSource = (
        contacts: FolderUserWithCustomProperty[],
        users: FolderShare[]
    ) => {
        if (!users.length) {
            return contacts;
        }

        return contacts.filter(
            (contact) =>
                users.filter((user) => user.email !== contact.email).length
        );
    };

    /**
     * Custom implementation of Griddle
     * to display users with access to folder
     * in a paginated list instead of a table
     */
    sharesCustomRowComponent = ({ data: share }: { data: FolderShare }) => (
        <div key={share.id} className="archive-share-members-item">
            <div className="archive-share-members-item-name">
                {share.fullName}
                <br />
                <div className="archive-share-members-item-email">
                    {share.email}
                </div>
            </div>

            <div className="archive-share-members-item-access">
                <ActionDropDown
                    onSelect={(right) => this.updateShareRights(share, right)}
                    selectedRight={share.accessRights}
                />
            </div>
            <div
                className="archive-share-members-item-remove"
                onClick={() => this.unshareFolder(share)}>
                <div className="far fa-times"></div>
            </div>
        </div>
    );

    renderSharesTable = (data: FolderShare[]) => (
        <div className="griddle-table griddle-custom-rows">
            <Griddle
                results={data}
                resultsPerPage={10}
                useGriddleStyles={false}
                nextText={i18n`Next`}
                previousText={i18n`Previous`}
                useCustomRowComponent
                customRowComponent={this.sharesCustomRowComponent}
                customRowComponentClassName={'griddle-custom-row'}
            />
        </div>
    );

    render() {
        const { tab, email, id, right } = this.state;

        const { title, shares, invites, isLoaded } = this.props;

        const { isSignersArchive } = getUserAccess();

        const customer = AuthStore.getCustomer();

        const isEmailValid = this.validateEmail(email);
        /**
         * maybe we should memorize this function.
         */
        const contacts = this.suggestionDataSource(this.props.contacts, shares);
        const contact = contacts.find((c) => c.id === id);

        return (
            <div>
                <FullScreenModal
                    displayModal={true}
                    onCancel={() => this.goBack()}
                    header={
                        isLoaded && (
                            <h1>
                                {i18n`Sharing`}&nbsp;&nbsp;
                                <span className="text-blue break-words">
                                    <i className="far fa-folder" />
                                    &nbsp;
                                    {title}
                                </span>
                            </h1>
                        )
                    }>
                    {!isLoaded ? (
                        <LoadingData />
                    ) : (
                        <div className="archive-share">
                            <div className="my-4 flex">
                                <UserGroupIcon className="h-5 mr-2 fill-secondary-700 shrink-0"></UserGroupIcon>
                                <span className="font-semibold text-neutral-600">{i18n`Members of this folder have access to all case files and documents stored in it`}</span>
                            </div>
                            <div className="text-neutral-600 mb-4 flex">
                                <InformationCircleIcon className="h-5 fill-secondary-700 mr-2 shrink-0"></InformationCircleIcon>
                                <div>
                                    <span className="mr-1 font-semibold">{i18n`Please note:`}</span>
                                    <span>{i18n`shareFolder.cannotShareWhenParentSharedText`}</span>
                                </div>
                            </div>
                            <div className="archive-share-invite-box">
                                <label>
                                    {i18n`Share folder with coworkers or external collaborators`}
                                </label>
                                <div className="archive-share-invite-box-share-inputs">
                                    <div className="archive-share-invite-box-contact-input">
                                        <AutocompleteContacts
                                            filterSuggestions="share-candidates"
                                            value={email}
                                            folderId={this.props.folderId}
                                            placeholder={i18n(
                                                'Type a name or email'
                                            )}
                                            extractKey={(item) =>
                                                `${item.id}_${item.right}`
                                            }
                                            onBlurHandler={
                                                this.handleOnBlurInputField
                                            }
                                            onChange={this.onEmailChange}
                                            onSelect={
                                                this.handleOnSuggestionClick
                                            }
                                            errorInputValidation={
                                                this.state.error
                                            }
                                        />
                                    </div>

                                    <div className="archive-share-invite-box-role-selector">
                                        <ActionDropDown
                                            onSelect={this.onRightChange}
                                            selectedRight={right}
                                        />
                                    </div>

                                    <div className="archive-share-invite-box-invite-button">
                                        {isEmailValid &&
                                        contact &&
                                        !contact.isExternal ? (
                                            <Button
                                                theme="green"
                                                icon="far fa-check"
                                                onClick={this.handleSendInvite}>
                                                {i18n`Grant access`}
                                            </Button>
                                        ) : (
                                            <Button
                                                theme="blue"
                                                icon="far fa-envelope"
                                                onClick={this.handleSendInvite}>
                                                {i18n`Share`}
                                            </Button>
                                        )}
                                    </div>
                                </div>

                                {isEmailValid &&
                                    !isSignersArchive &&
                                    !contact && (
                                        <div className="archive-share-invite-box-message">
                                            <span>
                                                <i className="text-blue far fa-info-circle" />
                                                &nbsp;
                                                {i18n`This user is not part of your organization. The invite will be delivered by email`}
                                            </span>
                                        </div>
                                    )}

                                {isEmailValid && contact && (
                                    <div className="archive-share-invite-box-message">
                                        {contact.isExternal && (
                                            <span>
                                                <i className="text-blue far fa-info-circle" />
                                                &nbsp;
                                                {i18n`This user is not part of your organization. The invite will be delivered by email`}
                                            </span>
                                        )}
                                        {!contact.isExternal && (
                                            <span>
                                                <i className="text-green far fa-check-circle" />
                                                &nbsp;
                                                {i18n`${contact.fullName} is part of ${customer.name}. You can grant access directly`}
                                            </span>
                                        )}
                                    </div>
                                )}
                            </div>

                            <br />

                            <h4 className="text-tab-headers">
                                <span
                                    className={tab === 0 ? 'active' : ''}
                                    onClick={() => this.setState({ tab: 0 })}>
                                    {i18n`Shared with`}
                                </span>
                                <span
                                    className={tab === 1 ? 'active' : ''}
                                    onClick={() => this.setState({ tab: 1 })}>
                                    {i18n`Pending invites`}
                                </span>
                            </h4>

                            {tab === 0 && (
                                <div className="archive-share-members">
                                    {!shares.length ? (
                                        <div className="archive-share-members-empty">
                                            <i className="far fa-share-alt" />
                                            <h1>{i18n`You haven't shared this folder with anyone yet`}</h1>
                                        </div>
                                    ) : (
                                        this.renderSharesTable(shares)
                                    )}
                                </div>
                            )}

                            {tab === 1 && (
                                <div className="archive-share-members">
                                    {invites.length === 0 && (
                                        <div className="archive-share-members-empty">
                                            <i className="far fa-envelope" />
                                            <h1>{i18n`You haven't sent any invites to share this folder`}</h1>
                                        </div>
                                    )}
                                    {invites.map((invite) => (
                                        <div
                                            key={invite.id}
                                            className="archive-share-members-item">
                                            <div className="archive-share-members-item-name">
                                                {invite.email} <br />
                                                <div className="archive-share-members-item-email">
                                                    {i18n`Invitation expires ${moment
                                                        .unix(invite.expireAt)
                                                        .fromNow()}`}
                                                </div>
                                            </div>
                                            <div className="archive-share-members-item-access">
                                                <ActionDropDown
                                                    readOnly={true}
                                                    onSelect={() => ({})}
                                                    selectedRight={
                                                        invite.accessRights
                                                    }
                                                />
                                            </div>
                                            <div
                                                className="archive-share-members-item-remove"
                                                onClick={() =>
                                                    this.deleteInvite(invite)
                                                }>
                                                <div className="far fa-times"></div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    )}
                </FullScreenModal>
            </div>
        );
    }
}

export default connect((state: ReduxState, props: any) => {
    const folderData =
        state.archive.folderShareData[props.params.folderId] || {};

    return {
        folderId: props.params.folderId,
        parentFolderId: folderData.parent ? folderData.parent.id : null,
        title: folderData.title,
        shares: folderData.shares || [],
        invites: folderData.invites || [],
        isFetching: folderData.isFetching || false,
        isLoaded: folderData.isLoaded || false,
        contacts: denormalize(state.contactPicker.byIds),
    };
})(Share);
