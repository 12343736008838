import PropTypes from 'prop-types';
import React from 'react';
import { i18n } from 'Language';
import classnames from 'classnames';
import Constants from '../../Constants';

import { DragSource, DropTarget } from 'react-dnd';
import InputValidation from 'Common/components/InputValidation';
import produce from 'immer';

class FormBuilderField extends React.Component {
    state = {
        drop: false,
        optionsTextField: {},
    };

    static propTypes = {
        connectDragSource: PropTypes.func.isRequired,
        connectDragPreview: PropTypes.func.isRequired,
        connectDropTarget: PropTypes.func.isRequired,

        index: PropTypes.number,
        field: PropTypes.object,
        isProtected: PropTypes.bool,
        forceValidation: PropTypes.bool,

        reorderField: PropTypes.func,
        removeField: PropTypes.func,
        updateField: PropTypes.func,
    };

    updateFieldType = (fieldIndex, event) => {
        let { value } = event.target;
        let { field } = this.props;

        field = produce(field, ({ metaData }) => {
            metaData.type = value;
        });

        this.props.updateField(field, fieldIndex);
    };

    addFieldOptions = () => {
        const fieldIndex = this.props.index;
        let { optionsTextField } = this.state;
        let { field } = this.props;

        let value = optionsTextField[fieldIndex];

        field = produce(field, ({ metaData }) => {
            if (!metaData.options) {
                metaData.options = [];
            }
        });

        // Disallow empty values.
        if (value.length === 0) {
            return false;
        }

        // Disallow repeated values.
        if (field.metaData.options.indexOf(value) !== -1) {
            return false;
        }

        field = produce(field, ({ metaData }) => {
            metaData.options.push(value);
        });

        this.props.updateField(field, fieldIndex);

        // Reset options field.
        this.setState({
            optionsTextField: {
                ...optionsTextField,
                ...(optionsTextField[fieldIndex] = ''),
            },
        });
    };

    updateOptionsTextField = (fieldIndex, event) => {
        let { optionsTextField } = this.state;

        optionsTextField[fieldIndex] = event.target.value;

        this.setState({ optionsTextField });
    };

    removeOption = (optionIndex) => {
        const fieldIndex = this.props.index;
        let { field } = this.props;

        field = produce(field, ({ metaData }) => {
            metaData.options.splice(optionIndex, 1);
        });

        this.props.updateField(field, fieldIndex);
    };

    handleOptionsEnterKey = (event) => {
        if (event.key === 'Enter') {
            this.addFieldOptions();
        }
    };

    fieldUpdateHandler = (index, event) => {
        let data = {};
        let { name, value, type } = event.target;

        // Checkbox events carry the value in .checked instead of .value
        if (type === 'checkbox') {
            data[name] = event.target.checked;
        } else {
            data[name] = value;
        }

        this.props.updateField(data, index);
    };

    getFieldType = () => {
        let { field } = this.props;

        return field.metaData.type;
    };

    render() {
        let { field, index, isProtected, forceValidation } = this.props;

        // react-dnd
        let { connectDragSource, connectDragPreview, connectDropTarget } =
            this.props;

        // Create a dragging container.
        return connectDragPreview(
            connectDropTarget(
                <div
                    className="form-builder-entry"
                    ref={(node) => (this.node = node)}>
                    {connectDragSource(
                        <div className="drag-handle">
                            <i className="far fa-grip-vertical fa-lg" />
                        </div>
                    )}

                    <div className="input-element">
                        <InputValidation
                            triggers={[field.name, forceValidation]}
                            immediate={forceValidation}
                            rules={[
                                {
                                    error: { message: i18n`Name is required` },
                                    test: () => !field.name,
                                },
                                {
                                    error: { message: i18n`Name already used` },
                                    test: () => field.isDuplicate,
                                },
                            ]}>
                            <label>
                                <span className="label">{i18n('Name')}</span>
                                <br />
                                <input
                                    type="text"
                                    placeholder={i18n('Field name')}
                                    name="name"
                                    disabled={isProtected}
                                    value={field.name}
                                    onChange={this.fieldUpdateHandler.bind(
                                        null,
                                        index
                                    )}
                                />
                            </label>
                        </InputValidation>
                    </div>

                    <div className="input-element">
                        <InputValidation
                            triggers={[field.label, forceValidation]}
                            immediate={forceValidation}
                            rules={[
                                {
                                    error: { message: i18n`Label is required` },
                                    test: () => !field.label,
                                },
                            ]}>
                            <label>
                                <span className="label">{i18n('Label')}</span>
                                <br />
                                <input
                                    type="text"
                                    placeholder={i18n('Field label')}
                                    name="label"
                                    value={field.label}
                                    onChange={this.fieldUpdateHandler.bind(
                                        null,
                                        index
                                    )}
                                />
                            </label>
                        </InputValidation>
                    </div>

                    <div className="input-element">
                        <span className="label">{i18n('Type')}</span>
                        <br />
                        <select
                            required
                            disabled={isProtected}
                            name="fieldType"
                            value={this.getFieldType() || -1}
                            onChange={this.updateFieldType.bind(null, index)}>
                            {Constants.FieldTypes.map((type) => (
                                <option key={type.id} value={type.id}>
                                    {i18n(type.name)}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="input-element">
                        <br />
                        <br />
                        <label
                            className={classnames('custom-checkbox', {
                                disabled: isProtected,
                            })}>
                            <span>{i18n('Required')}</span>
                            <input
                                name="required"
                                type="checkbox"
                                checked={field.required}
                                disabled={isProtected}
                                onChange={this.fieldUpdateHandler.bind(
                                    null,
                                    index
                                )}
                            />
                            <span className="check">
                                <i className="fas fa-check" />
                            </span>
                        </label>
                        &nbsp;
                        <label className="custom-checkbox">
                            <span>{i18n('User editable')}</span>
                            <input
                                name="editable"
                                type="checkbox"
                                checked={field.editable}
                                onChange={this.fieldUpdateHandler.bind(
                                    null,
                                    index
                                )}
                            />
                            <span className="check">
                                <i className="fas fa-check" />
                            </span>
                        </label>
                    </div>

                    {!isProtected && (
                        <div className="input-element">
                            <br />
                            <br />
                            <i
                                className="fa fa-times hover-error cursor-pointer"
                                onClick={this.props.removeField.bind(
                                    null,
                                    index
                                )}
                            />
                        </div>
                    )}

                    {field.metaData && field.metaData.type === 'select' && (
                        <div>
                            <div className="input-element mt0">
                                <span className="label">
                                    Options (
                                    {field.metaData.options
                                        ? field.metaData.options.length
                                        : 0}
                                    )
                                </span>
                                <br />
                                <div className="button-input">
                                    <input
                                        type="text"
                                        placeholder="Add an option"
                                        value={
                                            this.state.optionsTextField[
                                                index
                                            ] || ''
                                        }
                                        onChange={this.updateOptionsTextField.bind(
                                            null,
                                            index
                                        )}
                                        onKeyDown={this.handleOptionsEnterKey}
                                    />
                                    <i
                                        className="fas fa-plus-circle"
                                        onClick={this.addFieldOptions}></i>
                                </div>
                            </div>

                            <div className="multiple-selection-options">
                                {field.metaData.options &&
                                    field.metaData.options.map((option, i) => (
                                        <span
                                            className="multiple-selection-option"
                                            key={i}
                                            onClick={this.removeOption.bind(
                                                null,
                                                i
                                            )}>
                                            <span className="multiple-selection-option-number">
                                                {i + 1}
                                            </span>
                                            {option}
                                        </span>
                                    ))}
                            </div>
                        </div>
                    )}
                </div>
            )
        );
    }
}

// Row has two behaviours: it is a source and a target, because
// it is able to be dragged and to be reordered.

// Source callbacks:
const source = {
    // returns an object with useful properties when dragging event is over
    beginDrag(props) {
        return {
            index: props.index,
        };
    },
};

// Target callbacks:
const target = {
    hover(props, monitor, component) {
        const dragIndex = monitor.getItem().index;
        const hoverIndex = props.index;

        // prevents replacing items with themselves
        if (dragIndex === hoverIndex) {
            return;
        }

        // Determine rectangle on screen
        const hoverBoundingRect = component.node.getBoundingClientRect();

        // Get vertical middle
        const hoverMiddleYDown =
            hoverBoundingRect.top - hoverBoundingRect.bottom;
        const hoverMiddleYUp = hoverBoundingRect.bottom - hoverBoundingRect.top;

        // Determine mouse position
        const clientOffset = monitor.getClientOffset();

        // Get pixels to the top
        const hoverClientY = clientOffset.y - hoverBoundingRect.top;

        // Only perform the move when the mouse has crossed the border of the other card

        // Dragging downwards
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleYDown) {
            return;
        }

        // Dragging upwards
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleYUp) {
            return;
        }

        // Perform reorder
        component.props.reorderField(dragIndex, hoverIndex);

        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        monitor.getItem().index = hoverIndex;
    },
};

// Connect the callbacks
let Target = DropTarget('FormBuilderField', target, (connect) => ({
    connectDropTarget: connect.dropTarget(),
}))(FormBuilderField);

Target = DragSource('FormBuilderField', source, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
}))(Target);

export default Target;

// export default FormBuilderField;
