import React, { PropsWithChildren } from 'react';

import Fade from 'react-reveal/Fade';
import { env } from 'Constants';
import './side-panel.scss';

type Props = PropsWithChildren<{
    onClose: Function;
    className?: string;
    title?: string | JSX.Element;
    header?: JSX.Element;
    body?: JSX.Element;
    footer?: JSX.Element;
    displayBackButton?: boolean;
    large?: boolean;
    xlarge?: boolean;
    fullscreen?: boolean;
    overflowVisible?: boolean;
}>;

export class SidePanel extends React.Component<Props> {
    componentDidMount() {
        window.addEventListener('keyup', this.handleEscKey);

        // If the modal is mounted on the signing-service embedded components, prevent scrolling on the body element
        // The signing-service's bootstrap CSS allows scrolling on the elements in the background when the modal is open
        if (env.legacy) {
            document.body.classList.add('prevent-scroll');
        }
    }

    componentWillUnmount() {
        window.removeEventListener('keyup', this.handleEscKey);

        // Remove scroll prevention class on signing-service embedded components when modal closes
        if (env.legacy) {
            document.body.classList.remove('prevent-scroll');
        }
    }

    onClose = (event: React.MouseEvent<HTMLDivElement>) => {
        event.preventDefault();
        this.props.onClose();
    };

    handleEscKey = (e: KeyboardEvent) => {
        const key = e.keyCode || e.which;

        if (key === 27) {
            this.props.onClose();
        }
    };

    render() {
        return (
            <div className={'penneo-side-panel'}>
                <Fade duration={300}>
                    <div
                        className="penneo-side-panel-backdrop"
                        onClick={this.onClose}
                    />
                    <div className="penneo-side-panel-container">
                        <Fade duration={300} distance="50px" right>
                            <div className="penneo-side-panel-content">
                                {this.props.children}
                            </div>
                        </Fade>
                    </div>
                </Fade>
            </div>
        );
    }
}
