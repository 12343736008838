import React from 'react';
import ApiKeyManagementForm from '../Integration/ApiKeyManagementForm';

import IntegrationStore from '../../stores/IntegrationStore';
import IntegrationActions from '../../actions/IntegrationActionCreators';
import { UserEntity as User } from 'types/User';

import { i18n } from 'Language';

const CREDENTIALS_LOADED = 'FETCH_INTEGRATION_CREDENTIALS_SUCCESS';
const CREDENTIALS_ERROR = 'FETCH_INTEGRATION_CREDENTIALS_ERROR';
const CREDENTIALS_CREATED = 'CREATE_INTEGRATION_CREDENTIALS_SUCCESS';

export type IntegrationCredentials = {
    key: string;
    secret: string;
};

type Props = {
    user: User;
};

type State = {
    credentialsFetched: boolean;
    credentialsExist: boolean;
    creatingCredentials: boolean;
    credentials: IntegrationCredentials | null;
};

export default class ManageIntegrationCredentials extends React.Component<
    Props,
    State
> {
    state: State = {
        credentialsFetched: false,
        credentialsExist: false,
        creatingCredentials: false,
        credentials: null,
    };

    componentDidMount() {
        IntegrationStore.addChangeListener(this.onChange);
        IntegrationStore.addEventListener(
            CREDENTIALS_LOADED,
            this.onCredentialFetch
        );
        IntegrationStore.addEventListener(
            CREDENTIALS_ERROR,
            this.onCredentialFetch
        );
        IntegrationStore.addEventListener(
            CREDENTIALS_CREATED,
            this.onCredentialFetch
        );
        this.loadData();
    }

    onCredentialFetch = () => {
        this.setState({
            credentialsFetched: true,
            credentialsExist: IntegrationStore.getCredentials() ? true : false,
        });
    };

    componentWillUnmount() {
        IntegrationStore.removeChangeListener(this.onChange);
        IntegrationStore.removeEventListener(
            CREDENTIALS_LOADED,
            this.onCredentialFetch
        );
        IntegrationStore.removeEventListener(
            CREDENTIALS_ERROR,
            this.onCredentialFetch
        );
        IntegrationStore.removeEventListener(
            CREDENTIALS_CREATED,
            this.onCredentialFetch
        );
    }

    onChange = () => {
        this.setState({
            credentials: IntegrationStore.getCredentials(),
        });
    };

    componentWillUpdate(_: Props, nextState: State) {
        if (
            nextState.credentialsFetched &&
            !nextState.credentialsExist &&
            !nextState.creatingCredentials
        ) {
            this.generateKeys();
        }
    }

    generateKeys = () => {
        this.setState(
            {
                creatingCredentials: true,
            },
            () => IntegrationActions.createCredentials()
        );
    };

    loadData = () => {
        IntegrationActions.fetchCredentials();
    };

    render() {
        const { credentialsFetched, credentialsExist, credentials } =
            this.state;

        if (!credentialsFetched) {
            return (
                <div>
                    <br />
                    <br />
                    <div className="text-center text-medium">
                        <h1>
                            <i className="far fa-sync fa-lg fa-spin"></i>
                            <p>{i18n`Loading...`}</p>
                        </h1>
                    </div>
                    <br />
                    <br />
                </div>
            );
        }

        if (credentialsExist) {
            return (
                <div>
                    <ApiKeyManagementForm credentials={credentials} />
                </div>
            );
        }

        return <div>{i18n('Generating API Integration Keys...')}</div>;
    }
}
