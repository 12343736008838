import React, { SyntheticEvent } from 'react';
import { i18n } from 'Language';
import PropTypes from 'prop-types';
import Modal from 'Common/components/Modal';
import AutocompleteContacts from 'Common/components/AutocompleteContacts';
import { connect } from 'react-redux';
import {
    recipientUpdated,
    recipientAdded,
    recipientRemoved,
} from 'RegisteredLetter/redux/reducer';
import { ReduxState, AppDispatch } from 'Store';
import Button from 'Common/components/Button';
import { isSSNValid } from 'utils/ssn';
import { RegisteredLetterRecipient } from '../types';
import { RecipientRestrictions } from '../../Casefiles/components/casefiles2/RecipientRestrictions';
import { RecipientType } from '../../types/Recipient';
import { Router } from 'react-router';

type AppContextType = {
    router: Router;
};

type Props = {
    dispatch: AppDispatch;
    edit: boolean;
    params: any;
    disableFocusTrap?: boolean; // Only used for the test currently to disable the focus trap
    sensitiveData: boolean;
    index: number;
    recipient: RegisteredLetterRecipient;
};

type State = {
    recipient: RegisteredLetterRecipient;
    emailHasChanged: boolean;
};

class RegisteredLetterRecipientModal extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            recipient: props.recipient,
            emailHasChanged: false,
        };
    }

    static contextTypes = {
        router: PropTypes.object,
    };

    context!: AppContextType;

    closeModal = () => {
        const { params } = this.props;

        this.context.router.push({
            name: 'registered-letter-create',
            params,
        });
    };

    discardChanges = () => {
        this.closeModal();
    };

    handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        const { dispatch, edit } = this.props;
        const { recipient } = this.state;

        if (
            !recipient.name ||
            recipient.name.trim().length === 0 ||
            !recipient.email ||
            recipient.email.trim().length === 0
        ) {
            return false;
        }

        if (edit) {
            dispatch(
                recipientUpdated({
                    recipient: recipient,
                    index: this.props.index,
                })
            );
        } else {
            dispatch(recipientAdded(recipient));
        }

        this.closeModal();
    };

    handleDelete = (event: SyntheticEvent) => {
        event.preventDefault();
        const { dispatch } = this.props;

        dispatch(recipientRemoved(this.props.index));
        this.closeModal();
    };

    updateRecipient = (data: any) => {
        this.setState((prevState) => {
            const { recipient, emailHasChanged } = prevState;
            const newRecipient = {
                ...recipient,
                ...data,
            };

            return {
                recipient: newRecipient,
                emailHasChanged:
                    emailHasChanged || recipient.email !== newRecipient.email,
            };
        }, this.getValidationError);
    };

    renderInputField = (type: 'name' | 'email') => {
        return (
            <AutocompleteContacts
                label={i18n(`Recipient ${type}`)}
                value={this.state.recipient[type]}
                onChange={(value: string) => {
                    this.updateRecipient({ [type]: value });
                }}
                onSelect={({ name, email }) =>
                    this.updateRecipient({ name, email })
                }
                placeholder={
                    type === 'name'
                        ? i18n`Enter a name`
                        : i18n`Enter an email (example@example.com)`
                }
                required={true}
            />
        );
    };

    isEmailValid = (email) => {
        if (!email) {
            return true;
        }

        let regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9_-]{1,63})+$/;

        return regex.test(email.trim());
    };

    getValidationError = () => {
        const { recipient, emailHasChanged } = this.state;

        const ssnPlain = recipient.ssn;
        const ssnType = recipient.ssnType;

        const isSsnValid =
            !ssnPlain || ssnType === 'legacy'
                ? true
                : isSSNValid(ssnPlain as string, ssnType);

        const validationRules = {
            'Email is incorrect': !(
                emailHasChanged && !this.isEmailValid(recipient.email)
            ),
            'The SSN is invalid': isSsnValid,
            'Select a country from the drop-down': !(
                ssnPlain && ssnType === 'legacy'
            ),
        };

        return Object.keys(validationRules).filter(
            (k) => !validationRules[k]
        )?.[0];
    };

    render() {
        const { edit, sensitiveData } = this.props;
        const { recipient } = this.state;

        return (
            <Modal
                className="registered-letter-recipient-modal"
                header={
                    <h3 className="mt0">
                        {edit ? i18n`Edit recipient` : i18n`Add a recipient`}
                    </h3>
                }
                overflowVisible={true}
                disableFocusTrap={this.props.disableFocusTrap ?? false}
                onClose={this.discardChanges}>
                <form
                    className="reg-letter-recipient-form"
                    onSubmit={this.handleSubmit}>
                    {this.renderInputField('name')}
                    <br />
                    {this.renderInputField('email')}
                    <br />
                    {sensitiveData && (
                        <RecipientRestrictions
                            isRegisteredLetter={true}
                            sensitiveData={sensitiveData}
                            recipient={recipient}
                            handleChange={(key, value) =>
                                this.updateRecipient({ [key]: value })
                            }
                            handleAccessControlChange={(value: boolean) =>
                                this.updateRecipient({ accessControl: value })
                            }
                            handleSSNChange={(value, ssnType) =>
                                this.updateRecipient({
                                    ssn: value,
                                    ssnType: ssnType,
                                })
                            }
                            handleValidationTypeChange={(value) =>
                                this.updateRecipient({})
                            }
                        />
                    )}
                    <div className="recipient-ssn-validation-error mt">
                        {!!this.getValidationError() && (
                            <div className="validation-errors">
                                <i className="fas fa-exclamation-triangle" />
                                <span>{i18n(this.getValidationError())}</span>
                            </div>
                        )}
                    </div>
                    <div className="mt text-center">
                        {edit ? (
                            <div className="text-right">
                                <Button
                                    theme="green"
                                    disabled={!!this.getValidationError()}>
                                    {i18n`Save changes`}&nbsp;
                                    <i className="far fa-check" />
                                </Button>

                                <Button
                                    theme="gray"
                                    className="button-hover-red pull-left"
                                    onClick={this.handleDelete}>
                                    {i18n`Delete`}&nbsp;
                                    <i className="delete-icon far fa-times" />
                                </Button>
                            </div>
                        ) : (
                            <div className="pull-right-flex">
                                <Button
                                    data-testid={'add-recipient-modal-button'}
                                    theme="blue"
                                    disabled={!!this.getValidationError()}>
                                    {i18n`Add recipient`}
                                </Button>
                            </div>
                        )}
                    </div>
                </form>
            </Modal>
        );
    }
}

export default connect((state: ReduxState, props: Props) => {
    const { recipients, sensitiveData } = state.registeredLetter.instance;

    if (typeof props.params.index !== 'undefined' && recipients.length > 0) {
        return {
            edit: true,
            index: props.params.index,
            recipient: recipients[props.params.index],
            sensitiveData,
        };
    }

    return {
        edit: false,
        sensitiveData,
        recipient: {
            name: null,
            email: null,
            onBehalfOf: undefined,
            type: RecipientType.Signer,
            role: [],
            ssn: undefined,
            vatin: undefined,
            accessControl: sensitiveData,
            reminderInterval: 0,
            enableInsecureSigning: false,
            insecureSigningMethods: [],
            storeAsContact: false,
        },
    };
})(RegisteredLetterRecipientModal);
