import React, { PropsWithChildren } from 'react';

type Props = PropsWithChildren<{
    displayModal: boolean;
    onCancel: () => void;
    title?: string;
    header?: JSX.Element;
    body?: JSX.Element;
    footer?: JSX.Element;
    displayBackButton?: boolean;
}>;

interface FullScreenModal {
    Title: JSX.Element;
}

class FullScreenModal
    extends React.Component<Props>
    implements FullScreenModal
{
    componentDidMount() {
        window.addEventListener('keyup', this.handleKeyUp);
    }

    componentWillUnmount() {
        window.removeEventListener('keyup', this.handleKeyUp);
    }

    handleOnClickCancel = (event: React.MouseEvent<HTMLDivElement>) => {
        event.preventDefault();
        this.props.onCancel();
    };

    handleKeyUp = (e: KeyboardEvent) => {
        const key = e.keyCode || e.which;

        if (key === 27) {
            this.props.onCancel();
        }
    };

    render() {
        if (!this.props.displayModal) {
            return null;
        }

        return (
            <div className="full-screen-modal">
                <div className="full-screen-modal-backdrop">
                    <div
                        className="full-screen-modal-actions"
                        onClick={this.handleOnClickCancel}>
                        <i className="far fa-times" />

                        <div className="full-screen-modal-actions-shortcut">
                            <span>esc</span>
                        </div>
                    </div>

                    <div className="full-screen-modal-content">
                        <div className="full-screen-modal-header">
                            {this.props.header}

                            {/* @deprecated Backwards compatibility */}
                            {this.props.title && (
                                <h1 className="full-screen-modal-title">
                                    {this.props.title}
                                </h1>
                            )}
                        </div>
                        <div className="full-screen-modal-body">
                            {this.props.body || this.props.children}
                        </div>
                        <div className="full-screen-modal-footer">
                            {this.props.footer}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default FullScreenModal;
