import PropTypes from 'prop-types';
import React from 'react';
import { i18n } from 'Language';
import { Link } from 'react-router';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Button from 'Common/components/Button';
import Constants, { images } from 'Constants';

export default class CasefileAccessDenied extends React.Component {
    static propTypes = {
        user: PropTypes.object,
    };

    state = {
        size: null,
    };

    componentWillMount() {
        window.addEventListener('resize', this.updateBannerSize);
    }

    componentDidMount() {
        this.updateBannerSize();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateBannerSize);
    }

    /**
     * small, medium, large
     */
    updateBannerSize = () => {
        let width = this.banner.offsetWidth;

        if (width < 480) {
            return this.setState({
                size: 'small',
            });
        }

        if (width < 740) {
            return this.setState({
                size: 'medium',
            });
        }

        if (width > 740) {
            return this.setState({
                size: 'large',
            });
        }
    };

    renderCustomerWarning = () => {
        return (
            <div>
                <p>
                    <i className="fa fa-warning text-warning" />
                    &nbsp;
                    {i18n`This user isn't part of an organization.
                      You may be using your personal Penneo account instead
                      of the account linked to your company`}
                </p>
                <p>
                    {i18n`You can click the button below to go to the login page,
                      and try logging in with a different account`}
                </p>

                <Link to="login-route">
                    <Button theme="blue">{i18n('Go to Login Page')}</Button>
                </Link>
            </div>
        );
    };

    renderRightsWarning = () => {
        return (
            <div>
                <p>
                    <i className="fa fa-warning text-warning" />
                    &nbsp;
                    {i18n`You are not allowed to send or manage case files`}
                </p>

                <p>{i18n`To resolve this issue, contact your administrator`}</p>
                <br />
                <Link to="login-route">
                    <Button theme="blue">{i18n('Go to Login Page')}</Button>
                </Link>
            </div>
        );
    };

    renderContent = () => {
        const { user } = this.props;

        const noRights = !user.rights;
        const noCustomers = !user.customerIds || user.customerIds.length === 0;

        if (noCustomers) {
            return this.renderCustomerWarning();
        }

        if (noRights) {
            return this.renderRightsWarning();
        }
    };

    render() {
        let { user } = this.props;
        let { size } = this.state;

        return (
            <div
                className={'casefile-access-denied ' + size}
                ref={(ref) => (this.banner = ref)}>
                <TransitionGroup className="redirectionWidget" appear={true}>
                    <CSSTransition
                        timeout={{ enter: 500, appear: 500, exit: 300 }}>
                        <div>
                            <div
                                className="error-image"
                                style={{
                                    backgroundImage: `url(${images}/people/mikkel-waving.jpg)`,
                                }}>
                                <h1>403</h1>
                                <h2>{i18n`Access denied`}</h2>
                                <br />
                                <div className="overlay" />
                                <div id="locked-document">
                                    <div className="animated-document stack-1" />
                                    <div className="animated-document stack-2" />

                                    <div className="animated-document">
                                        <i className="fa fa-lock"></i>
                                        <i className="line line-1"></i>
                                        <i className="line line-2"></i>
                                        <i className="line line-3"></i>
                                        <i className="line line-4"></i>
                                    </div>
                                </div>
                            </div>
                            <div className="container" key="0">
                                <h1 style={{ fontSize: '16pt' }}>
                                    {i18n`You don't have access to see this page`}
                                </h1>
                                <h4>
                                    {i18n`You are currently logged in as`}
                                    :&nbsp;
                                    {user.fullName} ({user.email})
                                </h4>

                                {this.renderContent(user)}

                                <p className="support-link">
                                    {i18n`If you think this may be an error`}
                                    &nbsp;
                                    <a
                                        href={Constants.sites.supportRequest}
                                        target="_blank"
                                        rel="noopener noreferrer">{i18n`please contact support.`}</a>
                                </p>
                            </div>
                        </div>
                    </CSSTransition>
                </TransitionGroup>
            </div>
        );
    }
}
