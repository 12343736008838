import { Dispatcher, storage } from 'Core';
import Constants, { StorageKeys } from 'Constants';
import { PublicAuthAPI, SessionAPI } from 'Api';
import { TranslationActions, TranslationStore } from 'Language';
import { AuthActions as Actions } from '../ActionTypes';

import UserActions from './UserActionCreators';
import CustomerActions from './CustomerActionCreators';

import UserPreferenceActions from 'Auth/actions/UserPreferenceActions';
import cookie from 'react-cookie';

import launchDarkly from 'Common/LaunchDarkly';
import analytics from 'Common/Analytics';
import { sentry } from 'Common/SentryLogger';
import { intercom } from 'Common/Intercom';
import lodash from 'lodash';
import { AxiosResponse } from 'axios';
import { ERRORS } from 'Core/Errors';
import { dispatch } from 'Store';
import { fetchSettings } from 'Settings/redux/actions';
import { Intent, ServiceIDs } from '../../OpenID/Constants';
import { getUrlId, shouldUseEIDWebcomponent } from 'OpenID/redux/actions';
import { getEnvironment } from '../../utils';
import {
    ApiAuthClient,
    Environments,
    ProviderName,
    TokenType,
} from '@penneo/eid-webcomponent';
import routingUtils from 'utils/routing';

const AuthActionCreators = {
    getToken(endpoint: string, payload: any) {
        payload.refresh = true;

        return PublicAuthAPI.post(`${endpoint}?compatibility=false`, payload);
    },

    async authenticate(
        serviceId: string,
        payload: any,
        options: {
            propagateError?: boolean;
            onSuccess?: Function;
            onError?: Function;
        }
    ) {
        const inlineErrorHandler = (error) => {
            this.handleAuthenticationError(error);

            if (options.onError) {
                return options.onError(error);
            }

            if (options.propagateError) {
                throw error;
            }
        };
        const accessToken = await this.getEIDAccessToken(
            serviceId,
            payload
        ).catch((err) => {
            inlineErrorHandler(err);
            throw err;
        });

        if (!accessToken) return;

        return this.setup(accessToken)
            .then((response) => {
                this.handleAuthenticationSuccess(
                    response.token,
                    response.user,
                    response.customers,
                    response.settings
                );

                if (options.onSuccess) {
                    return options.onSuccess();
                }
            })
            .catch((error) => inlineErrorHandler(error));
    },

    async reauthenticate(data: { token: string; refreshToken: string }) {
        try {
            const response = await this.setup(data);

            this.handleAuthenticationSuccess(
                response.token,
                response.user,
                response.customers,
                response.settings
            );
        } catch (error) {
            this.handleAuthenticationError(error);
        }
    },

    async refresh(refreshToken: string) {
        const auth = await PublicAuthAPI.post('/token/refresh', {
            token: refreshToken,
        }).catch((error) => {
            this.handleAuthenticationError(error);
        });

        if (!auth) {
            return this.handleAuthenticationError(auth);
        }

        const { token } = auth;

        return this.reauthenticate({ token, refreshToken });
    },

    /* Set of required actions to initialize application */
    setup(data: { token: string; refreshToken?: string }) {
        let _user;
        let _customers;
        let _settings;

        return (
            this._storeTokens(data)
                .then(this._setLanguage)

                // Update _user shared local object
                .then(() =>
                    UserActions.fetchCurrentUser().then(
                        (user) => (_user = user)
                    )
                )

                // Get user preferences
                .then(() => UserPreferenceActions.fetchPreferences())

                // Fetch current customers
                .then(() => CustomerActions.fetchUserCustomers(_user.id))
                .then((customers) => (_customers = customers))

                .then(async () => {
                    const [customer] = _customers;

                    if (!customer) {
                        return false;
                    }

                    _settings = await dispatch(fetchSettings() as any);
                })

                // Start Server Session
                .then(this._startSession)

                // Update `remember this login method` local settings.
                .then(() => this._persistRememberMethod())

                // Identify launchdarkly to fetch user flags.
                .then(() =>
                    launchDarkly.identify(_user, _settings, _customers[0])
                )

                // Identify user tracking
                .then(() => analytics.identify(_user, _customers, _settings))

                // Initialize intercom
                .then(() => intercom.init(_user, _customers[0]))

                // Enrich error tracking
                .then(() => configureSentryScope(_user, _customers))

                // Return authenticated user and token
                .then(() => {
                    // Get token from localStorage
                    let token = storage.get(Constants.PENNEO_TOKEN_KEY);

                    // Return authentication object.
                    return {
                        token: token,
                        user: _user,
                        customers: _customers,
                        settings: _settings,
                    };
                })
        );
    },

    _storeTokens({
        token,
        refreshToken,
    }: {
        token: string;
        refreshToken?: string;
    }) {
        return new Promise((resolve) => {
            storage.set(Constants.PENNEO_TOKEN_KEY, token);

            if (refreshToken) {
                storage.set(Constants.PENNEO_REFRESH_KEY, refreshToken);
            }

            resolve(token);
        });
    },

    _startSession() {
        return SessionAPI.get('/session');
    },

    _setLanguage() {
        let didLanguageChange = TranslationStore.getLocalChangeStatus();
        let languageCode = TranslationStore.getLanguage();

        // If Language was changed during authentication
        if (didLanguageChange) {
            return TranslationActions.persistLanguage(languageCode);
        }

        return Promise.resolve();
    },

    /**
     * Performs actions to finish a session after a user logs out
     */
    async endCurrentSession() {
        try {
            // Invalidate refresh token
            if (storage.get(Constants.PENNEO_TOKEN_KEY)) {
                await PublicAuthAPI.post(`/user/logout`);
            }

            // Clear Session and JWT
            storage.clear(Constants.PENNEO_TOKEN_KEY);
            storage.clear(Constants.PENNEO_REFRESH_KEY);

            // Log out from all tabs immidiately and clear it after a short delay
            localStorage.setItem(StorageKeys.LOGOUT, 'true');

            setTimeout(() => {
                localStorage.removeItem(StorageKeys.LOGOUT);
            }, 1000);

            // Reset third party services
            launchDarkly.reset();
            intercom.shutdown();

            // End session
            cookie.remove('PHPSESSID', { path: '/' });

            // Clear login URL
            storage.clear(StorageKeys.LOGIN_URL);

            Dispatcher.handleViewAction({
                type: Actions.LOGOUT,
            });

            // Only redirect if not already on the login page
            if (!window.location.pathname.includes('/login')) {
                window.location.href = routingUtils.getFullPath('/logout');
            }
        } catch (e) {
            if (!window.location.pathname.includes('/login')) {
                window.location.href = routingUtils.getFullPath('/logout');
            }
        }
    },

    handleAuthenticationSuccess(token, user, customers, settings) {
        Dispatcher.handleServerAction({
            type: Actions.AUTHENTICATION_SUCCESS,
            token: token,
            user: user,
            customers: customers,
            settings: settings,
        });
    },

    /**
     * Detect certain specific server errors and return a custom error object.
     * Otherwise, return the unmodified error object.
     *
     * @param error - Error from API request
     * @return returns a custom error object if it matches a specific server errors, otherwise, returns the original error parameter
     */
    createError(
        error: AxiosResponse
    ): AxiosResponse | { errorCode: string; [otherKeys: string]: any } {
        switch (error.status) {
            case 403:
                // @todo: Change to Typescript optional chaining when Typescript is updated to 3.7+
                // @see: https://www.typescriptlang.org/docs/handbook/release-notes/typescript-3-7.html#optional-chaining
                const resendToken = lodash.get(
                    error,
                    'data.error.data.resendToken',
                    false
                );

                if (resendToken) {
                    return {
                        errorCode: ERRORS.AUTH_ACCOUNT_NOT_SETUP,
                        resendToken,
                    };
                }

                break;
            default:
                return error;
        }

        return error;
    },

    handleAuthenticationError(
        error: AxiosResponse & { code?: string },
        errorCode?: string
    ) {
        if (errorCode) {
            // @todo: this property was temporarily kept for backwards compatibility purposes.
            // This is used only in BootstrapingContainer -> handleAuthenticationError. to detect certain errors
            // The preferred solution is to decide on a custom error format, follow it, and return these codes as
            // a part of a custom `errorCode` generated in `createError`. Instead of modifying the <AxiosResponse> parameter
            error.code = errorCode;
        }

        Dispatcher.handleServerAction({
            type: Actions.AUTHENTICATION_FAILURE,
            error: AuthActionCreators.createError(error),
        });
    },

    clearAuthError() {
        Dispatcher.handleViewAction({
            type: Actions.CLEAR_AUTHENTICATION_ERROR,
        });
    },

    handleUnauthorized(error = { code: ERRORS.AUTH_SESSION_EXPIRED }) {
        Dispatcher.handleServerAction({
            type: Actions.UNAUTHORIZED,
            error: error,
        });
    },

    _persistRememberMethod() {
        let method = storage.get('penneo-remember-method');
        let url = this.getAllowedUrl(window.location.pathname);

        if (method) {
            if (url) {
                storage.set('penneo-remember-method', window.location.pathname);
                storage.set('penneo-remember-method-active', true);
            }
        } else {
            storage.clear('penneo-remember-method');
            storage.clear('penneo-remember-method-active');
        }

        return;
    },

    getAllowedUrl(pathname) {
        const allowed = ['/login/bankid_no'];

        if (allowed.indexOf(pathname) !== -1) {
            return pathname;
        }

        return false;
    },

    setLoginSuccessUrl(url) {
        Dispatcher.handleViewAction({
            type: Actions.UPDATE_LOGIN_SUCCESS_URL,
            url: url,
        });
    },

    async getEIDAccessToken(serviceId: string, payload: any) {
        if (serviceId.includes('/') || serviceId.includes('password')) {
            return await this.getToken(serviceId, payload);
        }

        const apiAuthClient = new ApiAuthClient(
            payload.redirectUri,
            getUrlId(serviceId as ServiceIDs, Intent.LOGIN) as ProviderName,
            getEnvironment() as unknown as keyof typeof Environments
        );

        if (shouldUseEIDWebcomponent(serviceId as ServiceIDs)) {
            return await apiAuthClient.callCollectEndpoint(
                TokenType.ACCESS_TOKEN,
                payload
            );
        }

        return await this.getOpenIdToken(serviceId, payload);
    },

    async getOpenIdToken(serviceId: string, payload: any) {
        if (serviceId.includes('password') || serviceId.includes('/')) {
            return await this.getToken(`${serviceId}`, payload);
        }

        let endpoint = `/openid/${getUrlId(
            serviceId as ServiceIDs,
            Intent.LOGIN
        )}`;

        const idToken = await PublicAuthAPI.post(
            `${endpoint}/collect`,
            payload
        );

        payload.idToken = idToken;

        return await this.getToken(`${endpoint}/token`, payload);
    },
};

export default AuthActionCreators;

function configureSentryScope(user, customers) {
    sentry.configureScope((scope) => {
        scope.setUser({
            id: user.id,
            role: user.role,
            customerId: lodash.get(user, 'customerIds[0]'),
            customerName: lodash.get(customers, '[0].name'),
            language: user.language,
        });
    });
}
