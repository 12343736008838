import PropTypes from 'prop-types';
import React from 'react';
import createReactClass from 'create-react-class';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

let timer;

export default createReactClass({
    propTypes: {
        message: PropTypes.string,
        type: PropTypes.string,
        timeout: PropTypes.number,
        children: PropTypes.oneOfType([
            PropTypes.string.isRequired,
            PropTypes.object.isRequired,
        ]),
        callback: PropTypes.func,
        preventClose: PropTypes.bool,
    },

    getDefaultProps() {
        return {
            type: 'normal',
            preventClose: false,
        };
    },

    getInitialState() {
        return {
            show: true,
        };
    },

    componentDidMount() {
        if (!this.props.timeout) {
            return false;
        }

        this.mounted = true;
        this.hide(this.props.timeout);
    },

    close() {
        this.setState({ show: false });

        if (this.props.callback) {
            this.props.callback();
        }
    },

    componentWillUmmount() {
        this.mounted = false;

        if (timer) {
            clearTimeout(timer);
        }
    },

    hide(timeout, force = false) {
        if (force) {
            return this.close();
        }

        timer = setTimeout(() => {
            if (this.mounted) {
                this.close();
            }
        }, timeout);
    },

    dismiss() {
        this.hide(false, true);
    },

    render() {
        let { type, message, preventClose } = this.props;
        let { show } = this.state;

        return (
            <TransitionGroup className="fadeIn" appear={true}>
                {show && (
                    <CSSTransition
                        timeout={{
                            enter: 500,
                            appear: 500,
                            exit: 300,
                        }}>
                        <div className={'info-message ' + type}>
                            {!preventClose && (
                                <div className="dismiss" onClick={this.dismiss}>
                                    <i className="fa fa-times"></i>
                                </div>
                            )}
                            {this.props.children || message}
                        </div>
                    </CSSTransition>
                )}
            </TransitionGroup>
        );
    },
});
