import PropTypes from 'prop-types';
import { i18n } from 'Language';
import React, { useCallback, useEffect, useState } from 'react';
import FormStore from '../../stores/FormStore';
import assign from 'object-assign';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { aggregateSSN, splitAggregatedSSN } from 'utils';
import { SSNs } from 'Constants';

export default class DocumentField extends React.Component {
    static propTypes = {
        index: PropTypes.number,
        field: PropTypes.object,
        mapping: PropTypes.object,
        scaling: PropTypes.number,
        dimensions: PropTypes.object,
        highlightField: PropTypes.func,
        highlightedField: PropTypes.number,
        removeHighlight: PropTypes.func,
        updateField: PropTypes.func.isRequired,
        disableEdit: PropTypes.bool,
    };

    updateDateHandler = (date) => {
        const { field } = this.props;

        if (!date) {
            return this.props.updateField(field.id, null);
        }

        return this.props.updateField(
            field.id,
            moment(date).format('DD/MM/YYYY')
        );
    };

    onBlurDatePicker = (event) => {
        const { field } = this.props;

        this.props.removeHighlight(field, event);

        const date = moment(event.target.value, 'DD/MM/YYYY', true);

        if (!date.isValid()) {
            return this.props.updateField(field.id, '');
        }

        return this.props.updateField(field.id, date.format('DD/MM/YYYY'));
    };

    /**
     * Updates value on field state and propagates the value to all mapped fields with
     * the same field ID
     * @param event {event} onChange event, activated by text change on mapped field.
     * @param value {string} The new value of the field, in case it can't be
     *      extracted from the event object directly
     */
    updateFieldHandler = (event, value = undefined) => {
        let { field } = this.props;
        const {
            type,
            value: eventValue,
            scrollHeight,
            clientHeight,
        } = event.target;

        let fieldValue = value || eventValue;

        /**
         * When the textarea's height is smaller than its scrollable surface (scrollHeight)
         * we want to subtract the text, because we do not want to enable the user to scroll the textarea
         * (giving them more real-estate) than they have visible in the finalized document
         */

        if (type === 'textarea') {
            if (clientHeight < scrollHeight) {
                var text = fieldValue.substr(0, fieldValue.length - 1);

                text = text.substr(0, 2001); //Limit input to max 2000 characters (exclusive)
                fieldValue = text;
            }
        }

        this.props.updateField(field.id, fieldValue);
    };

    /**
     * Renders field on page based on fieldMap state elements.
     * @param data {object} fieldMap object containing field mapping and field type data
     *
     * @return {DOM} Returns the JSX for the Input to be rendered by React's render() function
     */
    renderField = () => {
        let fieldJSX; // JSX Placeholder to create DOM Representation of Input to display.
        let { field, mapping, index, disableEdit, highlightedField } =
            this.props;
        let { highlightField, removeHighlight } = this.props;

        let data = FormStore.getPreviewMapping(mapping);

        let { updateFieldHandler } = this;

        // Base Style for rendered element.
        let style = {
            width: data.width + 'px',
            height: data.height + 'px',
            top: data.y + 'px',
            left: data.x + 'px',
            fontSize:
                FormStore.convertPointsToPreviewPixels(10, mapping.page) + 'px',
            // @fixme: For a yet unknown reason, the color attribute is changing the placeholder
            // text color, so this is temporarily required for toggling between placeholder and
            // actual text color.
            color: (field.value && field.value.length) > 0 ? '#222' : false,
        };

        if (disableEdit || !field.editable) {
            // Base Style for rendered element.
            const readOnlyStyle = {
                backgroundColor: 'transparent',
                color: '#000000',
                borderColor: 'transparent',
            };

            assign(style, readOnlyStyle);

            return (
                <span
                    key={index}
                    id={'field-' + field.id}
                    style={style}
                    className="input-item">
                    {field.value ? field.value : ''}
                </span>
            );
        }

        let className = 'input-item';

        // Determine Highlighted state
        let isFocused = field.id === highlightedField;

        if (isFocused) {
            className += ' focused';
        }

        // @todo: legacy forms don't have a type, and RECIPIENT_EMAIL and COSIGNER_EMAIL
        // were hardcoded fields used for email types. This will make them render
        // appropiately.
        const legacyEmailFields = ['RECIPIENT_EMAIL', 'COSIGNER_EMAIL'];

        switch (field.metaData.type) {
            case 'text':
            case 'email':
                if (legacyEmailFields.indexOf(field.name) !== -1) {
                    field.metaData.type = 'email';
                }

                fieldJSX = (
                    <input
                        key={index}
                        id={'field-' + field.id}
                        type={field.metaData.type}
                        style={style}
                        className={className}
                        value={field.value || ''}
                        data-fd={field.id}
                        data-map-id={mapping.id}
                        onChange={updateFieldHandler}
                        onFocus={highlightField.bind(null, field)}
                        onBlur={removeHighlight.bind(null, field)}></input>
                );
                break;
            case 'ssn':
                fieldJSX = (
                    <SSNDocumentField
                        key={index}
                        id={'field-' + field.id}
                        type={field.metaData.type}
                        style={style}
                        className={className}
                        value={field.value}
                        data-fd={field.id}
                        data-map-id={mapping.id}
                        onChange={updateFieldHandler}
                        onFocus={highlightField.bind(null, field)}
                        onBlur={removeHighlight.bind(null, field)}
                    />
                );
                break;
            case 'select':
                fieldJSX = (
                    <select
                        key={index}
                        id={'field-' + field.id}
                        type={field.metaData.type}
                        style={style}
                        className={className}
                        value={field.value || ''}
                        data-fd={field.id}
                        data-map-id={mapping.id}
                        onChange={updateFieldHandler}
                        onFocus={highlightField.bind(null, field)}
                        onBlur={removeHighlight.bind(null, field)}>
                        <option
                            value=""
                            disabled>{i18n`Select an option`}</option>
                        {field.metaData.options.map((option) => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>
                );
                break;
            case 'textarea':
                fieldJSX = (
                    <textarea
                        key={index}
                        id={'field-' + field.id}
                        type={field.metaData.type}
                        style={style}
                        className={className}
                        value={field.value || ''}
                        data-fd={field.id}
                        data-map-id={mapping.id}
                        onChange={updateFieldHandler}
                        onFocus={highlightField.bind(null, field)}
                        onBlur={removeHighlight.bind(null, field)}></textarea>
                );
                break;
            case 'date':
                fieldJSX = (
                    <div style={style} className="input-item field-container">
                        <DatePicker
                            className={className}
                            dateFormat="dd/MM/yyyy"
                            popperPlacement="bottom-start"
                            disabledKeyboardNavigation
                            onChangeRaw={this.updateDateHandler}
                            onChange={(date) => this.updateDateHandler(date)}
                            selected={
                                field.value
                                    ? moment(field.value, 'DD/MM/YYYY').toDate()
                                    : null
                            }
                            onFocus={() => this.props.highlightField(field)}
                            onBlur={this.onBlurDatePicker}
                        />
                    </div>
                );
                break;
            default:
                fieldJSX = (
                    <input
                        key={index}
                        id={'field-' + field.id}
                        type="text"
                        style={style}
                        className={className}
                        value={field.value || ''}
                        data-fd={field.id}
                        data-map-id={mapping.id}
                        onChange={updateFieldHandler}
                        onFocus={highlightField.bind(null, field)}
                        onBlur={removeHighlight.bind(null, field)}></input>
                );
                break;
        }

        return fieldJSX;
    };

    render() {
        let { field } = this.props;

        if (!field) {
            return false;
        }

        return this.renderField();
    }
}

/**
 * Represents an SSN value
 *
 * The SSN value passed to this field is in this format: "{type};{value}".
 *
 * We want to show only the value, so the component removes the ssn type before display.
 *
 * At the same type, when the value changes we need to send the ssn type along
 * with the new value to the `onChange` handler, so the component takes care of
 * aggregating the two pieces back together before calling the handler.
 */
const SSNDocumentField = (props) => {
    const { id, value, onChange, style, ...restOfProps } = props;
    const splitValue = splitAggregatedSSN(value);
    const [ssnType, setSsnType] = useState();

    /**
     * trigger click (hence, change event) on the input field
     * when user selects different SSN type
     *  */
    useEffect(() => document.getElementById(id).click(), [ssnType]);

    /**
     * Sizing and positioning values for different elements
     */
    const widths = { select: 2.5, input: 6 };

    // gets size
    const widthCalc = (times, unit = false) => {
        const value = (parseFloat(style.width) / 10) * times;

        return unit ? `${value}px` : value;
    };

    // gets offset for input
    const inputLeft = `${
        widthCalc(widths.select + 1.5) + parseFloat(style.left)
    }px`;

    // handle SSN locally
    const handleSsnTypeChange = (event) => {
        setSsnType(event.target.value);
    };

    // Select inout is extracted (but refactored) from the SsnInput component
    const renderTypeSelect = () => {
        const { LEGACY_SSN, ...restOfTypes } = SSNs;
        const options = [LEGACY_SSN, ...Object.values(restOfTypes)].filter(
            (v) => v.isEnabled()
        );

        return (
            <select
                id={`ssn-${id}`}
                value={ssnType || splitValue?.typeId}
                onChange={handleSsnTypeChange}
                style={{ ...style, width: widthCalc(widths.select, true) }}
                {...restOfProps}>
                {options.map(({ id, country }) => (
                    <option key={id} value={id}>
                        {id === LEGACY_SSN.id
                            ? `-- ${i18n('Country')} --`
                            : i18n(country.name)}
                    </option>
                ))}
            </select>
        );
    };

    // process new value
    const processNewValue = useCallback(() => {
        const type = ssnType || splitValue?.typeId;
        const newValue = aggregateSSN(document.getElementById(id).value, type);

        return newValue;
    }, [ssnType, splitValue]);

    const customOnChange = (event) => {
        onChange(event, processNewValue());

        // clear local SSN value
        setSsnType(undefined);
    };

    return (
        <>
            {' '}
            {renderTypeSelect()}
            <input
                id={id}
                value={splitValue?.ssn || ''}
                onClick={customOnChange}
                onChange={customOnChange}
                style={{
                    ...style,
                    width: widthCalc(widths.input, true),
                    left: inputLeft,
                }}
                {...restOfProps}
            />
        </>
    );
};
