import { createComponent } from '@lit/react';
import { InputText } from '@penneo/vega-ui';
import React from 'react';

const UIInputText = createComponent({
    tagName: 'vega-input-text',
    elementClass: InputText,
    react: React,
});

export default UIInputText;
