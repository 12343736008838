import React from 'react';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/20/solid';
import { ValidatorResponse } from '../Models/ValidatorResponse';
import UILozenge from 'Common/components/Vega/Lozenge';
import { DocumentIcon } from '@heroicons/react/24/outline';
import { unixTimeToUTC } from '../Utils/Utils';
import { i18n } from 'Language';
import { Divider } from 'Common/components/Divider/Divider';
import '../Styles/styles.css';

const DocumentSection = ({
    response,
    fileName,
}: {
    response: ValidatorResponse;
    fileName: string;
}) => {
    return (
        <div
            aria-label={i18n`validatorDocumentValidationResults`}
            className="flex flex-col gap-4 w-full">
            <div className="flex flex-wrap gap-2">
                {response.document.signatureFound &&
                response.document.signatureValid ? (
                    <div className="flex flex-col gap-1">
                        <UILozenge type="solid" variant="success">
                            <CheckCircleIcon className="w-4 align-middle inline-block shrink-0" />
                            <span className="ml-2">{i18n`validatorDocumentIsValid`}</span>
                        </UILozenge>
                        <UILozenge type="solid" variant="success">
                            <CheckCircleIcon className="w-4 align-middle inline-block shrink-0" />
                            <span className="ml-2">
                                {i18n`validatorDocumentContentHasNotBeenChanged`}
                            </span>
                        </UILozenge>
                    </div>
                ) : (
                    <div className="flex flex-col gap-1">
                        <UILozenge type="solid" variant="danger">
                            <XCircleIcon className="w-4 align-middle inline-block" />
                            <span className="ml-2">
                                {i18n`validatorDocumentIsInvalid`}
                            </span>
                        </UILozenge>
                    </div>
                )}
            </div>
            <div className="h-6 justify-center items-center gap-2 inline-flex">
                <DocumentIcon className="w-6 h-6 align-middle inline-block shrink-0" />
                <div className="grow shrink basis-0 text-gray-900 text-base font-semibold leading-tight">
                    {i18n`validatorDocumentDetails`}:
                </div>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-[max-content,1fr] gap-y-1 gap-x-9">
                <div className="label">{i18n`File name`}:</div>
                <div className="value">{fileName}</div>
                <div className="label">{i18n`Document key`}:</div>
                <div className="value">{response.document.documentKey}</div>
                <div className="label">{i18n`validatorTimestamp`}:</div>
                <div className="value">
                    {unixTimeToUTC(response.document.signTime)}
                </div>
            </div>
            <Divider className="h-px mt-2 bg-gray-200" />
        </div>
    );
};

export default DocumentSection;
