import { TEMPLATE_ENTITY_KIND, TEMPLATE_ENTITY_NAME } from 'Constants';

export type TemplateEntityKind =
    (typeof TEMPLATE_ENTITY_KIND)[keyof typeof TEMPLATE_ENTITY_KIND];
export type TemplateEntityName =
    (typeof TEMPLATE_ENTITY_NAME)[keyof typeof TEMPLATE_ENTITY_NAME];

export enum TemplateType {
    SIGNER = 'signing_request',
    COMPLETED = 'completed',
    REMINDER = 'reminder',
    COPY_RECIPIENT = 'carbon_copy_recipient',
    INITIAL = 'initial',
}

export enum EmailTemplateGroup {
    CASEFILE = 'casefile',
    KYC = 'kyc',
    REGISTERED_LETTER = 'registeredLetter',
}

export interface EmailTemplate {
    id: number | null;
    title: string | null;
    subject: string;
    message: string;
    default: boolean;
    customerId?: number;
    custom?: boolean;
    userId?: number;
    type?: TemplateType;
}

export type DefaultEmailTemplate = EmailTemplate & {
    type: TemplateType;
    default: true;
};

export interface EmailTemplates {
    [type: string]: EmailTemplate;
}

export type EmailTemplateState = {
    templates: EmailTemplate[];
    isLoading: boolean;
};

export type UserEmailTemplate = {
    title: string;
    subject: string;
    message: string;
    ownedByCustomer?: boolean;
};

export const isDefaultEmailTemplate = (
    template: EmailTemplate
): template is DefaultEmailTemplate => !!template.default;

export type TemplateEntities = {
    templates: {
        content: string;
        entityRelations: {
            name: TemplateEntityName;
            ids: number[];
        }[];
        kind: TemplateEntityKind;
        uuid: string;
    }[];
};
