import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router';
import { Helmet } from 'react-helmet';
import WorkflowStore from '../../stores/WorkflowStore';
import FormStore from '../../stores/FormStore';
import WorkflowActions from '../../actions/WorkflowActionCreators';
import FormActions from '../../actions/FormActionCreators';
import { i18n } from 'Language';
import { Stores as AuthStores } from 'Auth';
import classnames from 'classnames';
import FormsUpsell from 'Marketing/FormsUpsell';
import Button from 'Common/components/Button';
import WithRouter from '../WithRouterHOC';

const sortTypes = {
    ASC: 'ASC',
    DESC: 'DESC',
};

const tableHeaders = [
    { title: 'ID', name: 'id', width: 50, sortable: true },
    { title: 'Template Name', name: 'title', width: '30%', sortable: true },
    { title: 'Actions', name: 'actions', width: 50 },
    { title: 'Public link', name: 'link', width: 200 },
];

const WorkflowTemplateList = ({ customer }) => {
    /* eslint-disable @typescript-eslint/no-unused-vars */
    const [workflows, setWorkflows] = useState([]);
    const [forms, setForms] = useState([]);
    /* eslint-enable @typescript-eslint/no-unused-vars */
    const [data, setData] = useState([]);
    const [filtered, setFiltered] = useState([]);
    const [filter, setFilter] = useState('');
    const [sort, setSort] = useState({ column: 'id', type: sortTypes.DESC });

    useEffect(() => {
        WorkflowStore.addChangeListener(onChange);
        FormStore.addChangeListener(onChange);
        loadData();

        return () => {
            WorkflowStore.removeChangeListener(onChange);
            FormStore.removeChangeListener(onChange);
        };
    }, []);

    const loadData = () => {
        WorkflowStore.loadWorkflowPrototypes();
        FormStore.loadFormPrototypes();
    };

    const onChange = () => {
        const workflowsData = WorkflowStore.getWorkflowPrototypes();
        const formsData = FormStore.getFormPrototypes();

        setWorkflows(workflowsData);
        setForms(formsData);

        if (workflowsData.length > 0) {
            const data = getData(workflowsData);

            setData(data);
            setFiltered(_filterData(filter, data));
        }
    };

    const getData = (data) => {
        return data.map((workflow) => ({
            id: workflow.id,
            title: workflow.title,
            token: workflow.token,
            formId: workflow.userData.initial.form.prototypeId,
        }));
    };

    const _filterData = (filter, data) => {
        if (!filter) return [];

        const term = filter.toLowerCase();

        return data.filter((item) =>
            Object.values(item).some(
                (value) =>
                    value && value.toString().toLowerCase().includes(term)
            )
        );
    };

    const _onFilterChange = (e) => {
        const filterBy = e.target.value.toLowerCase();

        setFilter(filterBy);

        if (!filterBy) {
            setFiltered([]);
        } else {
            setFiltered(_filterData(filterBy, data));
        }
    };

    const sortTable = (rows, sort) => {
        const sortedRows = [...rows].sort((a, b) => {
            const valueA = a[sort.column];
            const valueB = b[sort.column];
            let sortVal = valueA > valueB ? -1 : valueA < valueB ? 1 : 0;

            return sort.type === sortTypes.ASC ? -sortVal : sortVal;
        });

        return sortedRows;
    };

    const changeSortHandler = (header) => {
        if (sort.column === header.name) {
            setSort({
                ...sort,
                type:
                    sort.type === sortTypes.ASC
                        ? sortTypes.DESC
                        : sortTypes.ASC,
            });
        } else {
            setSort({ column: header.name, type: sortTypes.ASC });
        }
    };

    const createLink = (formId, workflowToken) => {
        const formToken = getFormToken(formId);

        if (!workflowToken || !formToken) return '';

        const pathname = `/public/form/${formToken}/${workflowToken}`;

        const { protocol, host } = window.location;

        const query = `?group=forms`;

        return `${protocol}//${host}${pathname}${query}`;
    };

    const getFormToken = (formId) => {
        const form = FormStore.getFormById(formId);

        return form ? form.token : '';
    };

    const handlePublish = (workflowId, formId, event) => {
        event.preventDefault();
        WorkflowActions.publish(workflowId);
        FormActions.publish(formId);
    };

    const handleUnpublish = (workflowId, event) => {
        event.preventDefault();
        WorkflowActions.unpublish(workflowId);
    };

    let tableData = filtered.length === 0 ? data : filtered;

    if (filter.length > 0) {
        tableData = filtered;
    }

    const rows = sortTable(tableData, sort);
    const user = AuthStores.UserStore.getCurrentUser();
    const { active: isCustomerActive } = customer;

    if (user.rights && user.rights.indexOf('mass-send') === -1) {
        return <FormsUpsell />;
    }

    return (
        <div className="white-container workflow-templates-list ui-v2">
            <Helmet>
                <title>{i18n`Form templates`}</title>
            </Helmet>
            <h4 className="title">{i18n('Form templates')}</h4>
            <div className="content">
                <div className="container-header pb0">
                    <div className="inline-block">
                        <span className="search-bar">
                            <input
                                type="text"
                                style={{ width: 200 }}
                                onChange={_onFilterChange}
                                placeholder={i18n('Type to filter')}
                            />
                        </span>
                        <span className="text-bold pl">
                            {rows.length === 1 ? (
                                <span>
                                    {rows.length} {i18n('template')}
                                </span>
                            ) : (
                                <span>
                                    {rows.length} {i18n('templates')}
                                </span>
                            )}
                        </span>
                    </div>
                    <Link
                        to={{
                            pathname: 'forms/template/new',
                        }}
                        className="pull-left">
                        <Button
                            icon="far fa-plus"
                            theme="blue"
                            variant="outline"
                            disabled={!isCustomerActive}
                            renderIconLeft>
                            {i18n`Create new template`}
                        </Button>
                    </Link>
                </div>
                <table className="table">
                    <thead>
                        <tr>
                            {tableHeaders.map((header, index) => (
                                <th
                                    key={index}
                                    className={classnames({
                                        sortable: header.sortable,
                                        'sort-ascending':
                                            header.name === sort.column &&
                                            sort.type === 'ASC',
                                        'sort-descending':
                                            header.name === sort.column &&
                                            sort.type === 'DESC',
                                    })}
                                    width={header.width}
                                    onClick={
                                        header.sortable
                                            ? () => changeSortHandler(header)
                                            : null
                                    }>
                                    {i18n(header.title)}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {rows.map((row) => (
                            <tr key={row.id}>
                                <td>{row.id}</td>
                                <td>
                                    <Link
                                        to={{
                                            pathname: `forms/templates/${row.id}/details`,
                                        }}>
                                        <i className="fa fa-cog fa-lg"></i>
                                    </Link>
                                    &nbsp;&nbsp;&nbsp;
                                    {i18n(row.title)}
                                </td>
                                <td>
                                    {!row.token ? (
                                        <Button
                                            theme="blue"
                                            variant="text"
                                            onClick={(e) =>
                                                handlePublish(
                                                    row.id,
                                                    row.formId,
                                                    e
                                                )
                                            }>
                                            {i18n('Make public')}
                                        </Button>
                                    ) : (
                                        <Button
                                            onClick={(e) =>
                                                handleUnpublish(row.id, e)
                                            }
                                            theme="gray"
                                            variant="text">
                                            {i18n('Revoke access')}
                                        </Button>
                                    )}
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        className="match-parent"
                                        value={createLink(
                                            row.formId,
                                            row.token
                                        )}
                                        readOnly
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

WorkflowTemplateList.propTypes = {
    customer: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
};

export default WithRouter(WorkflowTemplateList);
