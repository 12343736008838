import React from 'react';
import moment from 'moment';
import { Link } from 'react-router';
import { storage } from 'Core';
import Constants, { StorageKeys, env } from 'Constants';

import Widget from 'Common/components/Widget';
import WidgetBody from 'Common/components/WidgetBody';
import WidgetHeader from 'Common/components/WidgetHeader';
import Button from '../Button';
import './debug-information.scss';
import { UserEntity as User } from 'types/User';
import { getUserAccess } from 'Casefiles/utils';

/**
 * @see https://stackoverflow.com/a/69491367
 */
declare global {
    interface Navigator {
        msSaveBlob?: (blob: any, defaultName?: string) => boolean;
    }
}

type Props = {
    user: User;
};

const DebugInformation: React.FC<Props> = ({ user }) => {
    const version = process.env.APP_VERSION;
    const token: string = storage.get(StorageKeys.JWT_TOKEN);
    const anonymizedToken = token.substring(token.length - 15, token.length);

    const mimeType = 'text/json;charset=utf-8;';
    const anchorId = 'download-json-link';
    const fileName = `penneo_support_${moment().unix()}.json`;

    const { isCustomer } = getUserAccess();

    const data = {
        version: version,
        userId: user.id,
        userRights: user.rights,
        userRole: user.role,
        customerId: user.customerIds?.[0] || null,
        token: anonymizedToken,
        timestamp: moment().format(),
        platform: env.platform,
        isCustomer,
    };

    const formattedJSON = JSON.stringify(data, null, 2);

    const handleClick = () => {
        // Internet explorer doesn't have support for data links for download, or the download attribute in <a> tags
        // @see: https://caniuse.com/#feat=download
        // In IE we can trigger a download with the correct filename and content by using the msSaveBlob method.
        if (navigator.msSaveBlob) {
            const blob = new Blob([formattedJSON], { type: mimeType });

            return navigator.msSaveBlob(blob, fileName);
        }

        const el = document.getElementById(anchorId);

        if (el) {
            el.click();
        }
    };

    const anchorLink = `data:${mimeType},${encodeURIComponent(formattedJSON)}`;

    const isConnectedToCompany = user.customerIds?.length > 0;

    return (
        <div className="debug-information">
            <Widget>
                <WidgetHeader>
                    <h1>
                        Debug information
                        <span className="dot">.</span>
                    </h1>
                    <p>
                        Nerdy (and useful) information for our support heroes :)
                    </p>
                </WidgetHeader>
                <WidgetBody>
                    <div>
                        <div className="mb">
                            <Link
                                to="/"
                                className="text-blue text-semibold text-small">
                                <i className="far fa-arrow-left"></i>&nbsp; Go
                                back to{' '}
                                {isConnectedToCompany ? 'dashboard' : 'archive'}
                            </Link>
                            <span className="pull-right text-small mb">
                                <i className="far fa-clock"></i>&nbsp;
                                {moment().format()}
                            </span>
                        </div>

                        <div className="debug-information-content">
                            <label>User</label>
                            <div className="no-wrap">
                                <span className="tag">
                                    <span className="tag-title">ID</span>
                                    <span>{user.id}</span>
                                </span>
                                {isConnectedToCompany && (
                                    <>
                                        <span className="tag">
                                            <span className="tag-title">
                                                Role
                                            </span>
                                            <span>{user.role}</span>
                                        </span>
                                        <span className="tag">
                                            <span className="tag-title">
                                                Customer ID
                                            </span>
                                            <span>{user.customerIds[0]}</span>
                                        </span>
                                        <span className="tag">
                                            <span className="tag-title">
                                                isCustomer
                                            </span>
                                            <span>{`${isCustomer}`}</span>
                                        </span>
                                    </>
                                )}
                            </div>
                            {isConnectedToCompany && (
                                <>
                                    <label>Permissions</label>
                                    <div className="flex flex-wrap gap-y-2">
                                        <div style={{ marginTop: '-0.5em' }}>
                                            {user.rights.map((r, index) => (
                                                <span
                                                    key={index}
                                                    style={{
                                                        marginTop: '0.5em',
                                                    }}
                                                    className="tag">
                                                    {r}
                                                </span>
                                            ))}
                                        </div>
                                    </div>
                                </>
                            )}

                            <label>App Information</label>
                            <div className="flex flex-wrap gap-y-2">
                                <span className="tag">
                                    <span className="tag-title">Version</span>
                                    <span>{version}</span>
                                </span>
                                <span className="tag">
                                    <span className="tag-title">Token</span>
                                    <span>{anonymizedToken}</span>
                                </span>
                                <span className="tag">
                                    <span className="tag-title">Platform</span>
                                    <span>{env.platform}</span>
                                </span>
                            </div>
                        </div>
                        <p className="text-small">
                            <i className="far fa-info-circle" /> Get faster and
                            better help by downloading the information on this
                            page and attaching the file to your support ticket
                            at{' '}
                            <a
                                href={Constants.sites.support}
                                target="_blank"
                                rel="noopener noreferrer">
                                support.penneo.com
                            </a>
                        </p>
                        <br />
                        <div className="text-center">
                            <Button
                                onClick={handleClick}
                                icon="far fa-arrow-down"
                                theme="gray"
                                variant="outline">
                                Download as JSON
                            </Button>
                        </div>

                        {/* Hidden link to save URI data */}
                        {/* eslint jsx-a11y/anchor-has-content:0 */}
                        <a
                            id={anchorId}
                            href={anchorLink}
                            target="_blank"
                            rel="noopener noreferrer"
                            download={fileName}
                        />
                    </div>
                </WidgetBody>
            </Widget>
        </div>
    );
};

export default DebugInformation;
