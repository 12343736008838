import React from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { isBefore, isSameDay, addMinutes } from 'date-fns';
import moment, { Moment } from 'moment';
import { enUS, sv, nb, da, fr, nl, de } from 'date-fns/locale';
import { i18n, TranslationStore } from 'Language';
import Button from 'Common/components/Button';

export type Props = {
    onDateChange: (date: any, roleId?: number) => void;
    onDateClear: (roleId?: number) => void;
    activationDate?: Moment;
    roleIdToUpdate?: number;
};

const normalizeDate = (date: any): Date | null => {
    if (!date) return null;

    return moment.isMoment(date) ? date.toDate() : date;
};

const formatDateForConsumer = (date: Date): any => {
    return moment(date);
};

export function ActivationDateModify({
    activationDate,
    roleIdToUpdate,
    onDateChange,
    onDateClear,
}: Props) {
    const normalizedActivationDate = normalizeDate(activationDate);

    const getCalendarLanguage = React.useMemo(() => {
        const languageCode = TranslationStore.getLanguage();
        const languageMap = { da, no: nb, sv, fr, nl, de };

        return languageMap[languageCode] ?? enUS;
    }, []);

    const handleOnDateChange = (date: Date | null) => {
        if (!date) return;

        let newDate = date;

        if (isSameDay(newDate, new Date()) && isBefore(newDate, new Date())) {
            newDate = addMinutes(new Date(), 1);
        }

        if (!roleIdToUpdate) {
            onDateChange(formatDateForConsumer(newDate));
        } else {
            onDateChange(formatDateForConsumer(newDate), roleIdToUpdate);
        }
    };

    const handleOnDateClear = () => {
        if (!roleIdToUpdate) {
            onDateClear();
        } else {
            onDateClear(roleIdToUpdate);
        }
    };

    const filterPassedTime = (time) => {
        const currentDate = new Date();
        const selectedDate = new Date(time);

        return currentDate.getTime() < selectedDate.getTime();
    };

    return (
        <div
            data-testid="activation-date-container"
            className="activation-date-container mb">
            <div className="activation-date-picker">
                <ReactDatePicker
                    selected={normalizedActivationDate}
                    onChange={handleOnDateChange}
                    disabled={false}
                    minDate={new Date()}
                    locale={getCalendarLanguage}
                    showTimeSelect
                    timeCaption={i18n('timeCaption')}
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    dateFormat="dd-MM-yyyy HH:mm"
                    placeholderText={i18n('Select Date')}
                    filterTime={filterPassedTime}
                />
                <Button
                    className="clear-date-btn"
                    onClick={handleOnDateClear}
                    size="small"
                    variant="outline">
                    {i18n`Clear date`}
                </Button>
            </div>
        </div>
    );
}
