import React, { cloneElement, useState } from 'react';
import {
    Placement,
    autoUpdate,
    offset,
    useDismiss,
    useFloating,
    useFocus,
    useHover,
    useInteractions,
    useRole,
    safePolygon,
} from '@floating-ui/react';

type Props = {
    /** Makes the popup stay active if mouse is hovering it */
    applySafePolygon: boolean;
    children: JSX.Element;
    /** Accepts both a string or react element */
    label: React.ReactNode;
    delayClose?: number;
    delayOpen?: number;
    placement?: Placement;
    offsetAmount?: number;
};

export const Float = ({
    children,
    label,
    placement = 'top',
    offsetAmount = 10,
    applySafePolygon,
    delayClose,
    delayOpen,
}: Props) => {
    const [open, setOpen] = useState(false);

    const { refs, floatingStyles, context } = useFloating({
        placement,
        open,
        onOpenChange: setOpen,
        middleware: [offset(offsetAmount)],
        whileElementsMounted: autoUpdate,
        strategy: 'fixed',
    });

    const hover = useHover(context, {
        delay: { open: delayOpen || 0, close: delayClose || 0 },
        handleClose: applySafePolygon ? safePolygon({ buffer: 5 }) : undefined,
    });

    const focus = useFocus(context);
    const role = useRole(context, { role: 'tooltip' });
    const dismiss = useDismiss(context);

    const { getReferenceProps, getFloatingProps } = useInteractions([
        hover,
        focus,
        role,
        dismiss,
    ]);

    return (
        <>
            {cloneElement(
                children,
                getReferenceProps({ ref: refs.setReference, ...children.props })
            )}
            {open && (
                <div
                    {...getFloatingProps({
                        ref: refs.setFloating,
                        className: 'Tooltip',
                        style: {
                            ...floatingStyles,

                            pointerEvents: 'auto',
                        },
                    })}>
                    {label}
                </div>
            )}
        </>
    );
};
