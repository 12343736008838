import React from 'react';
import { i18n } from 'Language';
import PropTypes from 'prop-types';
import Modal from 'Common/components/Modal';
import AutocompleteContacts from 'Common/components/AutocompleteContacts';
import { connect } from 'react-redux';
import {
    addRecipient,
    updateRecipient,
    removeRecipient,
} from 'KYC/redux/actions';
import { ReduxState, AppDispatch } from 'Store';
import lodash from 'lodash';
// Flux
import Button from 'Common/components/Button';
import { Router } from 'react-router';

type AppContextType = {
    router: Router;
};

type Props = {
    params: any;
    dispatch: AppDispatch;
    name: string;
    email: string;
    edit: boolean;
    index: number;
};

type State = {
    name: string;
    email: string;
};

class KYCRecipientModal extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            name: props.name,
            email: props.email,
        };
    }

    static contextTypes = {
        router: PropTypes.object,
    };

    context!: AppContextType;

    closeModal = () => {
        const { params } = this.props;

        this.context.router.push({
            name: 'kyc-create',
            params,
        });
    };

    discardChanges = () => {
        this.closeModal();
    };

    handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        const { dispatch, edit } = this.props;
        const { name, email } = this.state;

        if (name.trim().length === 0 || email.trim().length === 0) {
            return false;
        }

        if (edit) {
            dispatch(updateRecipient({ name, email }, this.props.index));
        } else {
            dispatch(addRecipient({ name, email }));
        }

        this.closeModal();
    };

    handleDelete = (event) => {
        event.preventDefault();

        const { dispatch } = this.props;

        dispatch(removeRecipient(this.props.index));
        this.closeModal();
    };

    updateName = (name: string) => {
        this.setState({ name });
    };

    updateEmail = (email: string) => {
        this.setState({ email });
    };

    handleOnAutocompleteSelect = ({ name, email }) => {
        this.updateName(name);
        this.updateEmail(email);
    };

    handleChange = (key: 'name' | 'email', value: string) => {
        const method = lodash.camelCase(`update ${key}`);

        this[method](value);
    };

    renderInputField = (type: 'name' | 'email') => {
        return (
            <AutocompleteContacts
                label={i18n(`Recipient ${type}`)}
                value={this.state[type]}
                onChange={(value: string) => this.handleChange(type, value)}
                onSelect={this.handleOnAutocompleteSelect}
                placeholder={
                    type === 'name'
                        ? i18n`Enter a name`
                        : i18n`Enter an email (example@example.com)`
                }
                required={true}
            />
        );
    };

    render() {
        const { edit } = this.props;

        return (
            <Modal
                header={
                    <h3 className="mt0">
                        {edit ? i18n`Edit recipient` : i18n`Add a recipient`}
                    </h3>
                }
                overflowVisible={true}
                onClose={this.discardChanges}>
                <form onSubmit={this.handleSubmit}>
                    {this.renderInputField('name')}
                    <br />
                    {this.renderInputField('email')}

                    <div className="mt text-center">
                        {edit ? (
                            <div className="text-right">
                                <Button theme="blue" renderIconLeft={true}>
                                    {i18n`Save changes`}&nbsp;
                                    <i className="far fa-check"></i>
                                </Button>

                                <Button
                                    theme="red"
                                    className="pull-left"
                                    onClick={this.handleDelete}
                                    renderIconLeft={true}>
                                    {i18n`Delete`}&nbsp;
                                    <i className="far fa-times"></i>
                                </Button>
                            </div>
                        ) : (
                            <Button
                                theme="blue"
                                icon="far fa-plus-circle"
                                renderIconLeft={true}>
                                {i18n`Add recipient`}
                            </Button>
                        )}
                    </div>
                </form>
            </Modal>
        );
    }
}

export default connect((state: ReduxState, props: Props) => {
    const recipients = state.kyc.__new.recipients;

    if (typeof props.params.index !== 'undefined' && recipients.length > 0) {
        const recipient = recipients[props.params.index];

        return {
            edit: true,
            name: recipient.name,
            email: recipient.email,
            index: props.params.index,
        };
    }

    return {
        edit: false,
        name: '',
        email: '',
    };
})(KYCRecipientModal);
