import React from 'react';
import { get } from 'lodash';
import moment from 'moment';
import classnames from 'classnames';
import { i18n } from 'Language/i18n';
import { ReduxState } from 'Store';
import { ABUNDANT_SIGNER_STATUS } from 'Casefiles/Constants';
import { TextInput, Checkbox, NumberInput } from 'Common/components';
import FullScreenModal from 'Common/components/FullScreenModal';
import { modal } from 'Common/components/Common/Modal';
import LoadingData from 'Common/components/Loaders/LoadingData';
import Message from 'Common/components/Message';
import InputValidation from 'Common/components/InputValidation';
import { getSignerRolesFromSignatureLines } from '../../utils/rolesFromSignatureLines';
import CasefileSignerRolesList from '../../components/casefiles2/CasefileSignerRolesList';
import { getSigningRequestStatus } from '../statusCodes';
import StatusBanner from '../StatusBanner';
import { CustomerEntity } from 'types/Customer';
import { CaseFileStatus } from '../../Constants';

// Redux
import { connect } from 'react-redux';
import {
    backupDocuments,
    backupSigner,
    createSigningLink,
    deleteSigner,
    fetchSignerEventLog,
    persistSignatureLines,
    persistSigner,
    reactivateSigner,
    restoreDocuments,
    restoreSigner,
    sendSigningRequest,
    updateSigner,
} from '../redux/actions';
import { CasefileState } from '../redux/types';
import { InsecureSigningMethod, SignerEntity } from 'types/Signer';

import './signer-details.scss';
import Button from 'Common/components/Button';
import SsnInput from 'Common/components/SsnInput';
import { aggregateSSN } from 'utils';
import { getModifiedSigLinesFromCasefile } from 'Casefiles/utils/signatureLinesFromDocuments';
import HelpTooltip from 'Common/components/HelpTooltip';
import { INSECURE_SIGNING_METHODS } from 'EID/Constants';
import launchDarkly, { Flags } from 'Common/LaunchDarkly';
import { CaseFileEntity } from 'types/CaseFile';
import { DocumentEntity } from 'types/Document';
import { insecureSigningMethodsDefault, PersonIdentifierType } from 'EID/types';
import { EventLogSignerEvent } from 'types/EventLog';
import { RecipientRestrictions } from 'Casefiles/components/casefiles2/RecipientRestrictions';
import { Recipient } from 'Casefiles/components/casefiles2/RecipientModalExtended';
import { SSNs } from '../../../Constants';
import { parsePhoneNumber } from '../../../Common/components/SmsInput/utils';
import { signingFlowIsRegisteredLetter } from '../../../RegisteredLetter/utils';
import Flag from 'Common/components/Flag';
import { LANGUAGES } from 'Language/Constants';
import { ToastContext, ToastContextType } from 'Common/components/ToastContext';
import { CheckCircleIcon, CheckIcon } from '@heroicons/react/20/solid';
import { Button as VegaReactButton } from '@penneo/vega-ui-react';

type Props = {
    signer: SignerEntity;
    signerId: number;
    caseFile: CasefileState;
    caseFileId: number;
    customer: CustomerEntity;
    dispatch: any;
    params: {
        [x: string]: string;
    };
    router: any;
};

type ComponentState = {
    activeTab: number;
    promptDelete: boolean;
    showInsecureMethods?: boolean;
    isValid?: boolean;
};

const SIGNER_LOGS = {
    0: {
        name: 'request_sent',
        color: 'blue',
        icon: 'far fa-envelope',
        text: () => i18n`Signing request was sent by email to the signer`,
    },
    1: {
        name: 'request_opened',
        icon: 'far fa-eye',
        text: () => i18n`Signer read the signing request email`,
    },
    2: {
        name: 'opened',
        icon: 'far fa-envelope-open-text',
        text: () => i18n`Signer opened the case file`,
    },
    3: {
        name: 'signed',
        icon: 'far fa-pen-nib',
        text: () => i18n`Signer has signed the documents in the case file`,
    },
    4: {
        name: 'rejected',
        color: 'red',
        icon: 'far fa-ban',
        text: () => i18n`Signer rejected to sign the case file`,
    },
    5: {
        name: 'reminder_sent',
        icon: 'far fa-alarm-clock',
        text: () => i18n`Reminder to sign was sent by email`,
    },
    6: {
        name: 'undeliverable',
        color: 'red',
        icon: 'far fa-times',
        text: () => i18n`The signing request email could not be delivered`,
    },
    7: {
        name: 'request_activated',
        icon: 'far fa-calendar-check',
        text: () => i18n`Signing request was activated`,
    },
    8: {
        name: 'finalized',
        color: 'green',
        icon: 'fas fa-check',
        text: () => (
            <span>
                {i18n`Case file was processed by ${(
                    <b className="user">Penneo</b>
                )}`}{' '}
                <i className="far fa-check-circle text-green"></i>
            </span>
        ),
    },
    10: {
        name: 'signedWithImageUploadAndNAP',
        icon: 'far fa-pen-nib',
        text: () =>
            i18n`Signature completed using Image Upload as an alternative to MitID due to name and address protection`,
    },
    11: {
        name: 'transientBounce',
        color: 'orange',
        icon: 'far fa-question',
        text: () =>
            i18n`We were unable to confirm the delivery of the signing request email. This may be because the recipient's email inbox is set to out-of-office mode or is temporarily unavailable`,
    },
    20: {
        name: 'unknown',
        icon: 'far fa-question',
        text: i18n`Unknown event`,
    },
};

class SignerDetails extends React.Component<Props, ComponentState> {
    private router: any;
    declare context: ToastContextType;
    static contextType = ToastContext;

    private get addToast() {
        const toast = this.context;

        if (!toast) {
            console.error('ToastContext not found');

            return;
        }

        return toast?.addToast;
    }

    constructor(props: Props) {
        super(props);
        this.router = props.router;

        this.state = {
            activeTab: 0,
            promptDelete: false,
            showInsecureMethods: undefined,
            isValid: true,
        };
    }

    componentDidMount() {
        const { dispatch, signerId, caseFileId } = this.props;

        dispatch(backupSigner(signerId));
        dispatch(backupDocuments());
        dispatch(fetchSignerEventLog(signerId, caseFileId));

        this.setState({
            showInsecureMethods: this.showTouchSignatureOptions(),
        });
    }

    componentDidUpdate(prevProps: Readonly<Props>): void {
        if (!prevProps.signer && !this.props.signer) {
            return;
        }

        if (!prevProps.signer && this.props.signer) {
            this.setState({
                showInsecureMethods: this.showTouchSignatureOptions(),
            });

            return;
        }

        if (!prevProps.signer.isLoaded && this.props.signer.isLoaded) {
            this.setState({
                showInsecureMethods: this.showTouchSignatureOptions(),
            });

            return;
        }
    }

    handleTabChange = (tabId: number) => {
        this.setState({ activeTab: tabId });
    };

    handleInsecureMethods = (method: InsecureSigningMethod, value: boolean) => {
        const { signer } = this.props;

        const insecureSigningMethods =
            (signer.signingRequest.insecureSigningMethods ?? [])
                ?.filter((signingMethod) => signingMethod !== method)
                .concat(value ? [method] : []) ?? [];

        const payload = {
            insecureSigningMethods,
            enableInsecureSigning:
                (insecureSigningMethods &&
                    insecureSigningMethods?.length > 0) ??
                false, //If the user chooses no methods we also untoggle insecureMethods
        };

        this.updateSigner(payload);
    };

    // This method goes in pair with 'showTouchSignatureOptions' in RecipientModalExtended.tsx
    // Make sure to keep them in sync
    showTouchSignatureOptions() {
        const { signer } = this.props;

        if (!signer || !signer?.isLoaded) {
            return false;
        }

        // We show the section if there is any insecure method enabled to always allow admins to remove the option.
        if (!!signer.signingRequest.insecureSigningMethods?.length) {
            return true;
        }

        const { customer } = this.props;
        const touchSignEnabledForCustomer =
            customer.allowedSimpleSigningMethods.length > 0;

        if (!touchSignEnabledForCustomer) {
            return false;
        }

        return this.isInsecureSigningAllowed();
    }

    isInsecureSigningAllowed = () => {
        let { signer } = this.props;

        if (!signer || !signer?.isLoaded) {
            return false;
        }

        if (signer.ssnType === PersonIdentifierType.SMS) {
            return true;
        }

        return (
            !signer.signingRequest.accessControl &&
            signer.ssnType === SSNs.LEGACY_SSN.id
        );
    };

    updateSigner = async (data: any) => {
        const { dispatch, signerId } = this.props;
        let signerData = data;

        if (!this.isInsecureSigningAllowed()) {
            signerData = {
                ...signerData,
                enableInsecureSigning: false,
                insecureSigningMethods: [],
            };
        }

        await dispatch(updateSigner(signerId, signerData));
        this.setState({
            showInsecureMethods: this.showTouchSignatureOptions(),
        });
    };

    onDelete = async () => {
        const { dispatch, signerId, caseFileId } = this.props;

        try {
            await dispatch(deleteSigner(caseFileId, signerId));

            this.closeModal();
            this.addToast?.(
                undefined,
                i18n`toast.deleteSigner`,
                'accent',
                10000,
                i18n`toast.cancelButton`,
                undefined,
                undefined,
                CheckCircleIcon
            );
        } catch {
            this.addToast?.(
                `${i18n('toast.deleteSigner.error')} ${i18n(
                    'toast.generalError.tryAgain'
                )}`,
                i18n`toast.generalError.title`,
                'danger',
                10000,
                i18n`toast.cancelButton`
            );
        }
    };

    promptDelete = () => {
        this.setState({ promptDelete: true });
    };

    cancelDelete = () => {
        this.setState({ promptDelete: false });
    };

    renderDelete = () => {
        const { signer, caseFile } = this.props;
        const roles = getSignerRolesFromSignatureLines(signer, caseFile.data);

        return (
            <>
                <p>
                    {i18n`You are about to delete
                        ${(<span className="text-bold">{signer.name}</span>)}
                        who has the following roles in this casefile`}
                    :&nbsp;
                </p>
                <CasefileSignerRolesList roles={roles} trimList={false} />
                <p>{i18n`Are you sure?`}</p>
                <Button
                    variant="outline"
                    theme="gray"
                    onClick={this.cancelDelete}>
                    {i18n`No, cancel`}
                </Button>

                <Button
                    variant="outline"
                    theme="red"
                    icon="far fa-times"
                    className="ml"
                    onClick={this.onDelete}>
                    {i18n`Yes, delete`}
                </Button>
            </>
        );
    };

    reactivateSigner = async () => {
        const { dispatch, signerId, caseFileId } = this.props;

        try {
            await dispatch(reactivateSigner(caseFileId, signerId));
            this.addToast?.(
                undefined,
                i18n`toast.reactivateSigner`,
                'accent',
                10000,
                i18n`toast.cancelButton`,
                undefined,
                undefined,
                CheckCircleIcon
            );

            /**
             * We "close" the modal, as it keeps the old recipient settings.
             * Note that since we are on a fullscreen modal,
             * modal.hide() won't work
             */
            this.closeModal();
        } catch {
            this.addToast?.(
                undefined,
                i18n`Failed to reactivate signer`,
                'danger',
                10000,
                i18n`toast.cancelButton`
            );
        }
    };

    resendSigningRequest = async (from: 'actions-tab' | 'after-saving') => {
        const { dispatch, signerId, caseFileId, signer } = this.props;

        const emailToSendTo =
            from === 'actions-tab'
                ? signer.__initial.signingRequest.email
                : signer.signingRequest.email;

        try {
            await dispatch(sendSigningRequest(caseFileId, signerId));
            from === 'actions-tab' ? this.closeModal() : modal.hide();
            this.addToast?.(
                undefined,
                i18n`Signing request sent to ${emailToSendTo}`,
                'accent',
                10000,
                i18n`toast.cancelButton`,
                undefined,
                undefined,
                CheckCircleIcon
            );
        } catch {
            this.addToast?.(
                undefined,
                i18n`Failed to send signing request to ${emailToSendTo}`,
                'danger',
                10000,
                i18n`toast.cancelButton`
            );
        }
    };

    goSign = async () => {
        const { dispatch, signerId } = this.props;

        try {
            await dispatch(createSigningLink(signerId)).then(
                (link) => (window.location.href = `${link}`)
            );
        } catch (error) {
            this.addToast?.(
                undefined,
                i18n`An unexpected error occurred`,
                'danger',
                10000,
                i18n`toast.cancelButton`
            );
        }
    };

    resendRequestPrompt = () => {
        modal.show({
            body: (
                <div className="resend-request-confirm">
                    <div className="message">{i18n`Signer's email has changed, do you want to resend signing request to the new address?`}</div>
                    <div className="actions">
                        <Button className="pull-left" onClick={modal.hide}>
                            {i18n(`Don't resend`)}
                        </Button>

                        <Button
                            theme="blue"
                            className="pull-right"
                            onClick={() =>
                                this.resendSigningRequest('after-saving')
                            }
                            icon="far fa-redo-alt"
                            renderIconLeft={true}>
                            {i18n('Resend request')}
                        </Button>
                    </div>
                </div>
            ),
        });
    };

    persistSigner = async () => {
        const { dispatch, signerId, caseFileId, signer, caseFile } = this.props;
        const isSignerModified = signer.hasChanged;
        const isSignatureLinesModified =
            getModifiedSigLinesFromCasefile(signerId, caseFile.data).length > 0;

        try {
            if (isSignatureLinesModified) {
                await dispatch(persistSignatureLines(signerId));
            }

            if (isSignerModified) {
                await dispatch(persistSigner(caseFileId, signerId));
            }

            this.closeModal();

            this.addToast?.(
                undefined,
                i18n`The changes were saved`,
                'accent',
                10000,
                i18n`toast.cancelButton`,
                undefined,
                undefined,
                CheckCircleIcon
            );
        } catch (error) {
            this.addToast?.(
                undefined,
                i18n`Failed to save changes`,
                'danger',
                10000,
                i18n`toast.cancelButton`
            );
        }
    };

    saveIsEnabled = (additionalRules?: boolean) => {
        const { signer, caseFile } = this.props;

        if (caseFile.isFetching) {
            return false;
        }

        if (signer.ssnType === PersonIdentifierType.SMS) {
            return (
                signer.socialSecurityNumberPlain &&
                parsePhoneNumber(signer.socialSecurityNumberPlain as string) &&
                (additionalRules !== undefined ? additionalRules : true)
            );
        }

        return additionalRules;
    };

    closeModal = () => {
        const { params, signer, caseFile, signerId } = this.props;

        const persistedSigner = caseFile.data.signers.find(
            (signer) => signer.id === signerId
        );

        if (
            persistedSigner &&
            signer.signingRequest.email ===
                persistedSigner.signingRequest.email &&
            signer.signingRequest.email !==
                signer.__initial.signingRequest.email
        ) {
            this.resendRequestPrompt();
        }

        this.router.push({
            name: 'archive-casefile-details',
            params,
        });
    };

    discardChanges = () => {
        const { dispatch, signerId } = this.props;

        dispatch(restoreSigner(signerId));
        dispatch(restoreDocuments());
        this.closeModal();
    };

    renderMasterData() {
        const { signer, caseFile, customer } = this.props;
        const { allowedSimpleSigningMethods } = customer;
        const { showInsecureMethods, isValid } = this.state;
        const hasSensitiveData = caseFile.data.sensitiveData;
        const roles = getSignerRolesFromSignatureLines(signer, caseFile.data);

        const isDeleted = signer.signingRequest.status === 3;
        const disabled = caseFile.data.status !== 1 || isDeleted;

        const modifiedSignatureLines = getModifiedSigLinesFromCasefile(
            signer.id,
            caseFile.data
        );

        const isFormDirty =
            signer.hasChanged || modifiedSignatureLines.length > 0;

        /**
         * Only filter out insecure methods if flag is on,
         * there's an array of methods to choose from and this array is not empty
         */
        const shouldFilterInsecureMethods =
            !!launchDarkly.variation(Flags.SPLIT_INSECURE_SIGNING_METHODS) &&
            !!allowedSimpleSigningMethods?.length;

        const language = signer.language || caseFile.data.language;

        const getHelpLink = () => {
            if (language === 'da') {
                return 'https://www.support.penneo.com/hc/da/articles/20447323275421-S%C3%A5dan-fungerer-adgangskontrol';
            } else if (language === 'fr') {
                return 'https://www.support.penneo.com/hc/fr/articles/20447323275421-Le-fonctionnement-du-contr%C3%B4le-d-acc%C3%A8s';
            }

            return 'https://www.support.penneo.com/hc/en-gb/articles/20447323275421-How-Access-Control-works';
        };

        return (
            <div className="signer-details-master-data">
                <InputValidation
                    onValidation={(isValid) => {
                        this.setState({ isValid });
                    }}
                    triggers={signer.name}
                    rules={[
                        {
                            error: signer.validation && signer.validation.name,
                            test: () =>
                                signer.name !== null || signer.name !== '',
                        },
                    ]}>
                    <TextInput
                        label={i18n`Name`}
                        required={true}
                        className="input-field"
                        placeholder={i18n`Signer name`}
                        value={signer.name}
                        disabled={disabled}
                        onChange={(value: string) =>
                            this.updateSigner({ name: value })
                        }
                    />
                </InputValidation>

                <InputValidation
                    onValidation={(isValid) => {
                        this.setState({ isValid });
                    }}
                    triggers={signer.signingRequest.email}
                    rules={[
                        {
                            error: signer.validation && signer.validation.email,
                            test: () =>
                                signer.signingRequest.email !== null ||
                                signer.signingRequest.email !== '',
                        },
                    ]}>
                    <TextInput
                        label={i18n`Email`}
                        required={true}
                        className="input-field"
                        placeholder={i18n`Email address`}
                        value={signer.signingRequest.email}
                        disabled={disabled}
                        onChange={(value: string) =>
                            this.updateSigner({ email: value })
                        }
                    />
                </InputValidation>

                <TextInput
                    label={i18n`On Behalf Of`}
                    className="input-field"
                    value={signer.onBehalfOf}
                    placeholder={i18n`Company name`}
                    disabled={disabled}
                    onChange={(value: string) =>
                        this.updateSigner({ onBehalfOf: value })
                    }
                />

                <br />

                <label>{i18n`Roles`}</label>
                <CasefileSignerRolesList
                    signerId={this.props.signerId}
                    activationDateEditable={true}
                    roles={roles}
                    trimList={false}
                />

                <>
                    <label>
                        {i18n`Language`}
                        <div className="inline-flex ml-4 relative top-[0.2rem]">
                            <Flag
                                src={LANGUAGES[language as string].svgFlag}
                                label={LANGUAGES[language as string].name}
                            />
                        </div>
                    </label>
                </>

                <br />

                <label>
                    {i18n`Send reminders every
                    ${(
                        <NumberInput
                            min={0}
                            max={365}
                            disabled={disabled}
                            onChange={(value: number) =>
                                this.updateSigner({ reminderInterval: value })
                            }
                            value={
                                signer.signingRequest.reminderInterval
                                    ? signer.signingRequest.reminderInterval
                                    : 0
                            }
                        />
                    )} days`}
                </label>

                {caseFile.data.sensitiveData &&
                    signer.ssnType !== PersonIdentifierType.SMS && (
                        <Message type="info">
                            <span>
                                {i18n`Simple electronic signatures can not be enabled for this signer because this case contains sensitive data`}
                                .&nbsp;
                                <a
                                    className="penneo-message-link"
                                    href={getHelpLink()}
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    {i18n`Learn more`}&nbsp;
                                    <i className="far fa-external-link-alt"></i>
                                </a>
                            </span>
                        </Message>
                    )}

                <br />
                {INSECURE_SIGNING_METHODS.map((method) => {
                    /**
                     * We will display insecure methods ONLY if 'image' is enabled (showInsecureMethods) and,
                     * if there's a filter, if the method is included in the customer settings
                     */
                    const show = shouldFilterInsecureMethods
                        ? (
                              allowedSimpleSigningMethods as InsecureSigningMethod[]
                          )?.includes(
                              method.credentialType() as InsecureSigningMethod
                          )
                        : true;

                    return (
                        show &&
                        showInsecureMethods && (
                            <Checkbox
                                key={method.credentialType()}
                                disabled={
                                    (hasSensitiveData &&
                                        !this.signerHasSMS()) ||
                                    disabled
                                }
                                checked={
                                    signer!.signingRequest
                                        ?.insecureSigningMethods &&
                                    signer!.signingRequest.insecureSigningMethods?.includes(
                                        method.credentialType() as InsecureSigningMethod
                                    )
                                }
                                onChange={(value) => {
                                    this.handleInsecureMethods(
                                        method.credentialType() as InsecureSigningMethod,
                                        value
                                    );
                                }}
                                data-testid={`touch-${method.credentialType()}`}
                                label={
                                    <span>
                                        {i18n(method.extendedInfo?.title)}
                                        &nbsp;
                                        <HelpTooltip
                                            width={260}
                                            text={i18n(
                                                method.extendedInfo?.tooltip
                                            )}
                                        />
                                    </span>
                                }
                            />
                        )
                    );
                })}

                <div className="signer-details-footer">
                    {!disabled && (
                        <VegaReactButton
                            onClick={this.persistSigner}
                            isDisabled={
                                !this.saveIsEnabled(isFormDirty) || !isValid
                            }
                            isLoading={caseFile.isFetching}
                            leftIcon={<CheckIcon className="h-4 w-4" />}>
                            {i18n`Save changes`}
                        </VegaReactButton>
                    )}
                </div>
            </div>
        );
    }

    signerHasSMS() {
        const { signer } = this.props;

        return signer.ssnType === PersonIdentifierType.SMS;
    }

    renderValidation() {
        const { signer, caseFile } = this.props;
        const isDeleted = signer.signingRequest.status === 3;
        const disabled = caseFile.data.status !== 1 || isDeleted;

        return (
            <div className="signer-details-validation">
                <label>{i18n`Social Security Number`}</label>
                <span className="field-description">
                    {i18n`You can assign a social security number to the signer.`}
                    &nbsp;
                    {i18n`Use this feature in cases where you want to make sure, that the eID used for signing is mapped to a certain social security number.`}
                </span>
                <InputValidation
                    triggers={aggregateSSN(
                        signer.socialSecurityNumberPlain,
                        signer.ssnType
                    )}
                    rules={[
                        {
                            error:
                                signer.validation?.ssn ||
                                signer.validation?.ssnCountry,
                            test: () =>
                                typeof signer.socialSecurityNumberPlain !==
                                    'undefined' ||
                                signer.socialSecurityNumberPlain !== '',
                        },
                    ]}>
                    <SsnInput
                        value={signer.socialSecurityNumberPlain}
                        ssnType={signer.ssnType}
                        inputClass="input-field"
                        placeholder={i18n`Type SSN`}
                        disabled={disabled}
                        onChange={({ value, ssnType }) =>
                            this.updateSigner({
                                socialSecurityNumberPlain: value,
                                ssnType: ssnType,
                            })
                        }
                    />
                </InputValidation>
                <br />

                <label>{i18n`VATIN`}</label>
                <span className="field-description">
                    {i18n`You can assign a VAT identification number to the signer.`}
                    &nbsp;
                    {i18n`Use this feature in cases where you want to make sure, that the eID used for signing is mapped to a certain VAT identification number.`}
                </span>
                <TextInput
                    value={signer.vatin}
                    className="input-field"
                    placeholder={i18n`Type VATIN`}
                    disabled={disabled}
                    onChange={(value: number) =>
                        this.updateSigner({ vatin: value })
                    }
                />

                <div className="signer-details-footer">
                    {!disabled && (
                        <VegaReactButton
                            onClick={this.persistSigner}
                            isDisabled={!this.saveIsEnabled(signer.hasChanged)}
                            isLoading={caseFile.isFetching}
                            leftIcon={<CheckIcon className="h-4 w-4" />}>
                            {i18n`Save changes`}
                        </VegaReactButton>
                    )}
                </div>
            </div>
        );
    }

    renderAccessControl() {
        const { signer, caseFile } = this.props;
        const isDeleted = signer.signingRequest.status === 3;
        const disabled =
            caseFile.data.status !== 1 ||
            isDeleted ||
            caseFile.data.sensitiveData;

        return (
            <div>
                <label>{i18n`Limit access to the documents`}</label>
                <p>
                    {i18n`This will require the signer to identify using an eID. You can further restrict access by adding validation below.`}
                </p>

                {caseFile.data.sensitiveData && (
                    <Message type="info">
                        <span>
                            {i18n`Access control is enabled for this signer because this case contains sensitive data`}
                            .&nbsp;
                            <a
                                className="penneo-message-link"
                                href="https://penneo.force.com/support/s/article/How-Access-Control-works "
                                target="_blank"
                                rel="noopener noreferrer">
                                {i18n`Learn more`}&nbsp;
                                <i className="far fa-external-link-alt"></i>
                            </a>
                        </span>
                    </Message>
                )}
                <br />

                <Checkbox
                    className="v2 checkbox"
                    checked={
                        caseFile.data.sensitiveData
                            ? caseFile.data.sensitiveData
                            : signer.signingRequest.accessControl
                    }
                    disabled={disabled}
                    onChange={(value: boolean) =>
                        this.updateSigner({ accessControl: value })
                    }
                    label={i18n`Set signer's information as private`}
                />

                <div className="signer-details-footer">
                    {!disabled && (
                        <VegaReactButton
                            onClick={this.persistSigner}
                            isDisabled={!this.saveIsEnabled(signer.hasChanged)}
                            isLoading={caseFile.isFetching}
                            leftIcon={<CheckIcon className="h-4 w-4" />}>
                            {i18n`Save changes`}
                        </VegaReactButton>
                    )}
                </div>
            </div>
        );
    }

    renderRestrictions() {
        const { signer, caseFile } = this.props;
        const isDeleted = signer.signingRequest.status === 3;
        /**
         * We check for two different scenarios in which
         * only access control is disabled, or the whole form
         */
        const disabledAll = caseFile.data.status !== 1 || isDeleted;
        const disabledAccess = caseFile.data.sensitiveData;
        const forceDisabled = disabledAll
            ? 'all'
            : disabledAccess
            ? 'accessControl'
            : undefined;

        const signerWithSSN = {
            ...signer,
            ssn: signer?.socialSecurityNumberPlain,
            accessControl: signer.signingRequest.accessControl,
        };

        return (
            <div>
                <RecipientRestrictions
                    isRegisteredLetter={signingFlowIsRegisteredLetter(
                        caseFile.data.caseFileTypeId
                    )}
                    sensitiveData={caseFile.data.sensitiveData}
                    recipient={signerWithSSN as unknown as Recipient}
                    handleChange={(key, value) =>
                        this.updateSigner({ [key]: value })
                    }
                    handleAccessControlChange={(value: boolean) =>
                        this.updateSigner({ accessControl: value })
                    }
                    handleSSNChange={(value, ssnType) =>
                        this.updateSigner({
                            socialSecurityNumberPlain: value,
                            ssnType: ssnType,
                        })
                    }
                    forceDisabled={forceDisabled}
                />

                <div className="signer-details-footer">
                    {forceDisabled !== 'all' && (
                        <VegaReactButton
                            onClick={this.persistSigner}
                            isDisabled={!this.saveIsEnabled(signer.hasChanged)}
                            isLoading={caseFile.isFetching}
                            leftIcon={<CheckIcon className="h-4 w-4" />}>
                            {i18n`Save changes`}
                        </VegaReactButton>
                    )}
                </div>
            </div>
        );
    }

    isDeletable = (signer: SignerEntity, casefile: CaseFileEntity): boolean => {
        type SigningRequestsWithDocs = {
            documentId: number;
            signOrder: number;
            signerId: number;
            status: number;
        };

        const signingRequestsAndDocuments = casefile.documents.map(
            (document: DocumentEntity) => {
                return document.signatureLines!.reduce((acc, curr) => {
                    const { signOrder, signerId } = curr;
                    const { status } =
                        casefile.signers.find(
                            (signer) => signer.id === signerId
                        )?.signingRequest ?? {};

                    acc.push({
                        documentId: document.id,
                        signOrder,
                        signerId,
                        status: status ?? CaseFileStatus.DRAFT,
                    });

                    return acc;
                }, [] as SigningRequestsWithDocs[]);
            }
        );
        const currentSignIterationIsSameAsCasefile =
            signingRequestsAndDocuments.reduce((acc, curr) => {
                const docs = curr;
                const signOrders = docs.find(
                    (doc) => doc.signOrder === casefile.signIteration
                );

                if (!signOrders) {
                    return acc;
                }

                acc.push(signOrders);

                return acc;
            }, [])?.length > 0;

        const onlyDocumentsWithCurrentSigner =
            signingRequestsAndDocuments.reduce((acc, curr) => {
                const listIncludesSigner =
                    curr.find(
                        (signingRequest) =>
                            signingRequest.signerId === signer.id
                    ) ?? false;

                if (listIncludesSigner) {
                    acc.push(curr);
                }

                return acc;
            }, [] as Array<Array<SigningRequestsWithDocs>>);

        const filteredSigningRequestsOnStatus =
            onlyDocumentsWithCurrentSigner.map((signingRequests) => {
                const filteredRequests = signingRequests.filter(
                    (signingRequest: SigningRequestsWithDocs) =>
                        [
                            CaseFileStatus.DRAFT,
                            CaseFileStatus.PENDING,
                            CaseFileStatus.SIGNED,
                        ].includes(signingRequest.status)
                );

                return filteredRequests.length;
            });

        const thereIsOnlyOneSigner =
            filteredSigningRequestsOnStatus.some(
                (filteredRequests) => filteredRequests === 1
            ) ?? false;

        // The signer is the only signer and have not rejected the casefile
        const signerIsTheOnlyOne =
            thereIsOnlyOneSigner &&
            signer.signingRequest.status !== CaseFileStatus.REJECTED;

        return !(currentSignIterationIsSameAsCasefile && signerIsTheOnlyOne);
    };

    renderActions() {
        const { signer, caseFile } = this.props;

        // Signer's Signing Request Status: 0 : 'new', 1 : 'pending', 2 : 'rejected', 3 : 'deleted', 4 : 'signed', 5 : 'undeliverable'
        const isRejected =
            signer.signingRequest.status === CaseFileStatus.REJECTED;
        const isSigned = signer.signingRequest.status === CaseFileStatus.SIGNED;
        const casefile: CaseFileEntity = caseFile.data;
        const isDeletable: boolean = this.isDeletable(signer, casefile);

        return (
            <div className="signer-details-actions">
                {!isSigned && (
                    <div className="signer-action">
                        <label>{i18n`Resend signing request`}</label>
                        <p>
                            {i18n`If a signer has not received the email with the signature request or if the signers email has been modified, you can resend the request for signature.`}
                            &nbsp;
                            {i18n`The request can also be resend as a reminder to a person that has not yet signed.`}
                        </p>
                        <Button
                            theme="blue"
                            onClick={() =>
                                this.resendSigningRequest('actions-tab')
                            }
                            icon="far fa-redo-alt"
                            renderIconLeft={true}>
                            {i18n('Resend request')}
                        </Button>
                    </div>
                )}

                {/* if not regiteredLetter */}
                {!isSigned && (
                    <div className="signer-action">
                        <label>{i18n`Delete the signer`}</label>
                        {!isDeletable && (
                            <p>
                                {i18n`${signer.name} is the only signer for one or more documents, and can therefore not be deleted.`}
                            </p>
                        )}
                        {this.state.promptDelete ? (
                            this.renderDelete()
                        ) : (
                            <>
                                {isDeletable && (
                                    <p>
                                        {i18n`Signers, that have not yet signed, can be deleted.`}
                                    </p>
                                )}
                                <Button
                                    disabled={!isDeletable}
                                    theme="red"
                                    variant="outline"
                                    icon="far fa-times"
                                    onClick={this.promptDelete}
                                    renderIconLeft={true}>
                                    {i18n`Delete Signer`}
                                </Button>
                            </>
                        )}
                    </div>
                )}
                {isRejected && (
                    <div className="signer-action">
                        <label>{i18n`Revert rejection`}</label>
                        <p>
                            {i18n`If a signer has rejected to sign, the rejection can be reverted.`}
                        </p>
                        <Button
                            theme="blue"
                            variant="outline"
                            onClick={this.reactivateSigner}
                            icon="far fa-history">
                            {i18n`Revert rejection`}
                        </Button>
                    </div>
                )}

                {
                    // Signer must be the owner of the case file and his status must be pending, status: 1
                    // (signer.signingRequest.status === 1 && caseFile.data.userId === signer.user.id) &&
                    // <div className="signer-action">
                    //     <label>Sign now</label>
                    //     <p>
                    //         This case file is still pending a signature from a person in your company.
                    //     </p>
                    //     <Button theme="green
                    //         icon="far fa-file-signature""
                    //         onClick={() => this.goSign()}>
                    //         {i18n`Sign now`}
                    //     </Button>
                    // </div>
                }
            </div>
        );
    }

    CasefileEventLogEntry = (event, signer, key) => {
        const data = SIGNER_LOGS[event.eventType];

        return (
            <div
                className={classnames('casefile-eventlog-entry', data?.color)}
                key={key}>
                <div className="casefile-eventlog-entry-timestamp">
                    {moment.unix(event.eventTime).format('DD-MM-YYYY HH:mm')}
                </div>

                <div className="casefile-eventlog-entry-icon">
                    <i className={data?.icon}></i>
                </div>

                <div className="casefile-eventlog-entry-details">
                    <div className="casefile-eventlog-entry-time-relative">
                        {moment.unix(event.eventTime).fromNow()}
                    </div>

                    <div className="casefile-eventlog-entry-description max-w-[450px]">
                        {data?.text()}
                    </div>
                </div>
            </div>
        );
    };

    renderEventLog() {
        const { signer } = this.props;

        if (!signer.eventLog) {
            return <h1>{i18n`There are no events for this signer yet`}</h1>;
        }

        const events = [...signer.eventLog] // copy array
            .sort((a: any, b: any) => a.eventTime - b.eventTime)
            .reverse();

        return (
            <div className="casefile-eventlog-container">
                <div className="casefile-eventlog-headers">
                    <div className="column-1 row">
                        {i18n`Time`}
                        &nbsp;
                        <i className="far fa-calendar-day"></i>
                    </div>
                    <div className="row">{i18n`Event`}</div>
                </div>
                <div className="casefile-eventlog">
                    {events.map((log: EventLogSignerEvent, key: number) =>
                        this.CasefileEventLogEntry(log, signer, key)
                    )}
                </div>
            </div>
        );
    }

    renderTabContent(tabIndex: number) {
        switch (tabIndex) {
            case 0:
                return this.renderMasterData();
            case 1:
                return this.renderValidation();
            case 2:
                return this.renderAccessControl();
            case 3:
                return this.renderActions();
            case 4:
                return this.renderEventLog();
            // In case Recipient restrictions is enabled, use this component
            case 5:
                return this.renderRestrictions();
            default:
                return null;
        }
    }

    renderStatusBanner() {
        const { signer, caseFile } = this.props;

        if (caseFile.data.status === 5 && signer.signingRequest.status === 1) {
            return <StatusBanner status={ABUNDANT_SIGNER_STATUS} />;
        } else {
            return (
                <StatusBanner
                    status={getSigningRequestStatus(
                        signer.signingRequest.status
                    )}
                />
            );
        }
    }

    render() {
        const { signer } = this.props;
        const { activeTab } = this.state;

        return (
            <FullScreenModal
                header={
                    <div>
                        <h1 className="signer-details-title">
                            {i18n`Signer details`}
                        </h1>
                        {signer.signingRequest && this.renderStatusBanner()}
                    </div>
                }
                displayModal={true}
                onCancel={this.discardChanges}>
                <div className="signer-details ui-v2">
                    <SignerTabs
                        activeTab={activeTab}
                        handleChange={this.handleTabChange}
                        signingRequestStatus={
                            signer.signingRequest &&
                            signer.signingRequest.status
                        }
                    />
                    {!signer.isLoaded ? (
                        <div>
                            <br />
                            <LoadingData />
                        </div>
                    ) : (
                        this.renderTabContent(activeTab)
                    )}
                </div>
            </FullScreenModal>
        );
    }
}

type SignerTabsProps = {
    activeTab: number;
    handleChange: Function;
    signingRequestStatus: number;
};

type SignerTab = {
    id: number;
    label: string;
};

const SignerTabs = ({
    activeTab,
    handleChange,
    signingRequestStatus,
}: SignerTabsProps) => {
    const isDeleted = signingRequestStatus === 3;

    /**
     * This will disable separated tabs for Validation
     * and Access control and instead show only
     * a Restrictions tab
     */
    const tabs = launchDarkly.variation(Flags.IMPROVED_RECIPIENT_SETTINGS)
        ? [
              {
                  id: 0,
                  label: 'Master data',
              },
              // Note we use a new id and respect the old ones
              {
                  id: 5,
                  label: 'Restrictions',
              },
              {
                  id: 3,
                  label: 'Actions',
              },
              {
                  id: 4,
                  label: 'Event log',
              },
          ]
        : [
              {
                  id: 0,
                  label: 'Master data',
              },
              {
                  id: 1,
                  label: 'Validation',
              },
              {
                  id: 2,
                  label: 'Access control',
              },
              {
                  id: 3,
                  label: 'Actions',
              },
              {
                  id: 4,
                  label: 'Event log',
              },
          ];

    return (
        <div className="penneo-menu-tabs no-padding-left">
            {tabs
                .filter((tab: SignerTab) => !(tab.id === 3 && isDeleted))
                .map((tab: SignerTab) => (
                    <div
                        key={tab.id}
                        className={classnames('penneo-menu-tab', {
                            active: activeTab === tab.id,
                        })}
                        onClick={() => handleChange(tab.id)}>
                        {i18n(tab.label)}
                    </div>
                ))}
        </div>
    );
};

export default connect((state: ReduxState, props: any) => {
    const signerDetailsFromCasefile = get(
        state.caseFileDetails,
        `signers[${props.params.signerId}]`,
        { isLoaded: false }
    );

    /**
     * If the signing request has enableInsecureSigning but there's no array of
     * SES (no insecureSigningMethods), we assign the default list of SES.
     * NOTE: if the flag is on, we use the customer setting array. Otherwise
     * we use all the methods.
     */
    const insecureMethods = launchDarkly.variation(
        Flags.SPLIT_INSECURE_SIGNING_METHODS
    )
        ? state.customer.details.data.allowedSimpleSigningMethods
        : insecureSigningMethodsDefault;

    let signer = {
        ...signerDetailsFromCasefile,
        ...{
            ...((signerDetailsFromCasefile?.signingRequest
                ?.enableInsecureSigning &&
                !signerDetailsFromCasefile?.signingRequest
                    ?.insecureSigningMethods && {
                    signingRequest: {
                        ...signerDetailsFromCasefile.signingRequest,
                        insecureSigningMethods: insecureMethods,
                    },
                }) ??
                {}),
        },
    };

    return {
        signer,
        signerId: Number(props.params.signerId),
        caseFile: state.caseFileDetails.casefile,
        caseFileId: Number(props.params.casefileId),
        customer: state.customer.details.data,
    };
})(SignerDetails);
