import React from 'react';
import { i18n } from 'Language';
import { FolderUserRights } from '../../redux/reducer';
import { ACCESS_RIGHTS_LABELS } from 'Constants';
import classnames from 'classnames';
import './action-drop-down.scss';
import Button from 'Common/components/Button';

type Props = {
    readOnly?: boolean;
    onSelect: (item: any) => void;
    style?: any;
    innerRef?: (ref: any) => any;
    selectedRight?: FolderUserRights;
    forwardedRef?: any;
};

type State = {
    display: boolean;
};

class ActionDropDown extends React.Component<Props, State> {
    actionRef: any;
    hasAddedScrollListener: boolean = false;

    constructor(props: Props) {
        super(props);

        this.state = {
            display: false,
        };
    }

    componentDidMount() {
        window.addEventListener('click', this.handleOnWindowClick);
    }

    componentDidUpdate() {
        if (this.props.forwardedRef && !this.hasAddedScrollListener) {
            this.hasAddedScrollListener = true;
            this.props.forwardedRef.addEventListener(
                'scroll',
                this.handleOnWindowClick
            );
        }
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.handleOnWindowClick);

        if (this.hasAddedScrollListener) {
            this.props.forwardedRef.removeEventListener(
                'scroll',
                this.handleOnWindowClick
            );
        }
    }

    handleOnWindowClick = (e: any) => {
        if (this.state.display && this.actionRef) {
            let nodeOne = e.target;
            let hasClickOnMainActionDropDownNode = false;

            while (nodeOne) {
                if (nodeOne === this.actionRef) {
                    hasClickOnMainActionDropDownNode = true;
                    break;
                }

                nodeOne = nodeOne.parentNode;
            }

            if (!hasClickOnMainActionDropDownNode) {
                this.setState({ display: false });
            }
        }
    };

    handleButtonOnClick =
        (selectedItem: string) => (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            this.setState({ display: false }, () =>
                this.props.onSelect(selectedItem)
            );
        };

    handleOnOpenAction = () => {
        this.setState({ display: !this.state.display });
    };

    handleRef = (ref: any) => {
        this.actionRef = ref;
        this.props.innerRef && this.props.innerRef(ref);
    };

    handleStyle = () => {
        if (typeof this.props.style === 'function') {
            return this.props.style();
        }

        return this.props.style;
    };

    render() {
        const { selectedRight = 'readonly', readOnly } = this.props;

        return (
            <div
                className={classnames('access-rights-container', {
                    readonly: readOnly,
                })}>
                <div
                    className="access-rights"
                    onClick={this.handleOnOpenAction}
                    ref={this.handleRef}>
                    <span className="access-right-label">
                        <i
                            className={`far fa-${ACCESS_RIGHTS_LABELS[selectedRight].icon}`}
                        />
                        &nbsp;
                        {i18n(ACCESS_RIGHTS_LABELS[selectedRight].label)}
                        <i className="far fa-chevron-down" />
                    </span>
                </div>
                {this.state.display && this.props.readOnly !== true && (
                    <div
                        className="action-drop-down"
                        style={this.handleStyle()}>
                        {Object.keys(ACCESS_RIGHTS_LABELS).map((x) => (
                            <Button
                                key={x}
                                onClick={this.handleButtonOnClick(x)}
                                disabled={selectedRight === x}
                                theme="white">
                                <i
                                    className={`far fa-${ACCESS_RIGHTS_LABELS[x].icon}`}
                                />
                                &nbsp; &nbsp;
                                {i18n(ACCESS_RIGHTS_LABELS[x].label)}
                            </Button>
                        ))}
                    </div>
                )}
            </div>
        );
    }
}
export default ActionDropDown;
