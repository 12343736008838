import React, { ChangeEvent } from 'react';
import { i18n } from 'Language';
import { connect } from 'react-redux';
import { ActionMeta } from 'react-select';
import { EmailTemplatesState } from 'EmailTemplates/redux/reducer';
import { Languages } from 'Language/Constants';
import { EmailTemplate, TemplateType } from 'types/EmailTemplates';
import { RecipientSigner } from 'types/Recipient';
import { ReduxState, dispatch } from 'Store';
import {
    addCustomEmailMessage,
    editEmailBody,
    editEmailSubject,
    removeCustomEmailMessage,
    setCustomEmailRecipients,
    updateEmailMessageWithTemplate,
} from 'Casefiles/redux/newCaseFile/actions';
import { AutocompleteEntry } from 'Common/components/AutocompleteTags';
import Button from 'Common/components/Button';
import { EmailMessageEditor } from 'Common/components/EmailMessageEditor';
import Loader from 'Common/components/Common/Loader';
import { i18nCustomLanguage } from 'Language/utils';
import {
    EmailMessage,
    NewCasefileState,
} from 'Casefiles/redux/newCaseFile/types';
import { ColorLegendCircle } from './utils';
import analytics from 'Common/Analytics';

export type Props = {
    casefileLanguage: Languages;
    defaultTemplates: {
        [TemplateType.COMPLETED]: number | false;
        [TemplateType.COPY_RECIPIENT]: number | false;
        [TemplateType.REMINDER]: number | false;
        [TemplateType.SIGNER]: number | false;
    };
    emailMessages: NewCasefileState['emailMessages'];
    emailTemplates: EmailTemplatesState['templates'];
    recipients: RecipientSigner[];
};

export function EmailMessages({
    casefileLanguage,
    defaultTemplates,
    emailMessages,
    emailTemplates,
    recipients,
}: Props) {
    const activeMessageType: TemplateType = TemplateType.SIGNER;

    function handleOnAddCustomMessage() {
        analytics.track(
            'Email messages: Clicked "Add new custom message button"'
        );

        const defaultTemplateId = defaultTemplates[TemplateType.SIGNER];

        const defaultTemplate = emailTemplates.find(({ id, type }) =>
            defaultTemplateId
                ? id === defaultTemplateId
                : type === TemplateType.SIGNER
        ) as EmailTemplate;

        const emailMessage: EmailMessage = {
            body: { content: defaultTemplate.message },
            default: defaultTemplate.default,
            id: defaultTemplate.id || undefined,
            subject: { content: defaultTemplate.subject },
            title: defaultTemplate.title || undefined,
        };

        dispatch(addCustomEmailMessage(emailMessage));
    }

    function convertRecipientsForAutocomplete(
        recipients: { name: string; email: string; tempId?: string }[]
    ) {
        return recipients
            .map((recipient) => {
                if (recipient.tempId === undefined) {
                    console.error(
                        'Unexpected behavior: one of the recipients is missing a temporary ID'
                    );

                    return undefined;
                }

                return recipient
                    ? {
                          label: `${recipient.name} (${recipient.email})`,
                          value: recipient.tempId,
                      }
                    : undefined;
            })
            .filter((recipient) => recipient !== undefined) as {
            label: string;
            value: string;
        }[];
    }

    function recipientsWithoutCustomMessages() {
        return recipients.filter((recipient) => {
            const indexOfMessage = emailMessages.custom.findIndex((message) => {
                const indexOfRecipient = message.recipients.findIndex(
                    (recipientInMessage) =>
                        recipientInMessage.tempId === recipient.tempId
                );

                if (indexOfRecipient > -1) return true;

                return false;
            });

            if (indexOfMessage === -1) return true;

            return false;
        });
    }

    function handleOnRemoveCustomMessage(index: number) {
        analytics.track(
            'Email messages: Clicked "Remove custom message button"'
        );

        dispatch(removeCustomEmailMessage(index));
    }

    function handleOnSelectTemplate(selectedValue: string, index?: number) {
        analytics.track(
            'Email messages: Picked an email template from the dropdown menu'
        );

        const template = emailTemplates.find(
            (template) => template.id === Number(selectedValue)
        );

        if (!template) return;

        dispatch(
            updateEmailMessageWithTemplate(activeMessageType, template, index)
        );
    }

    function getTemplateOptions(templateType: TemplateType) {
        if (emailTemplates.length < 1) return;

        const defaultMessage = emailTemplates.find(
            (template) => template.type === templateType
        );

        const userTemplates = emailTemplates.filter(
            (template) => template.id >= 0
        );

        return [
            { label: 'Default template', value: defaultMessage!.id },
            ...userTemplates.map((template) => ({
                label: template.title,
                value: template.id,
            })),
        ];
    }

    function handleOnSubjectChange(
        event: ChangeEvent<HTMLInputElement>,
        customMessageIndex?: number
    ) {
        const messageToModify: EmailMessage =
            customMessageIndex === undefined
                ? emailMessages.general.messages[activeMessageType]
                : emailMessages.custom[customMessageIndex].messages[
                      activeMessageType
                  ];

        // Since the editing subject toggles the message being a default one off, we
        // need to explicitly update the body with the current translation.
        if (messageToModify.default) {
            (async () => {
                const body = await i18nCustomLanguage(
                    messageToModify.body.content,
                    casefileLanguage
                );

                dispatch(
                    editEmailBody(body, activeMessageType, customMessageIndex)
                );
            })();
        }

        dispatch(
            editEmailSubject(
                event.target.value,
                activeMessageType,
                customMessageIndex
            )
        );
    }

    function handleOnBodyChange(
        event: ChangeEvent<HTMLTextAreaElement>,
        customMessageIndex?: number
    ) {
        const messageToModify =
            customMessageIndex === undefined
                ? emailMessages.general.messages![activeMessageType]
                : emailMessages.custom[customMessageIndex].messages[
                      activeMessageType
                  ];

        // Since editing the body toggles the message being a default one off, we
        // need to explicitly update the subject with the current translation.
        if (messageToModify.default) {
            (async () => {
                const subject = await i18nCustomLanguage(
                    messageToModify.subject.content,
                    casefileLanguage
                );

                dispatch(
                    editEmailSubject(
                        subject,
                        activeMessageType,
                        customMessageIndex
                    )
                );
            })();
        }

        dispatch(
            editEmailBody(
                event.target.value,
                activeMessageType,
                customMessageIndex
            )
        );
    }

    function handleOnAutocompleteAction(
        action: ActionMeta<AutocompleteEntry>,
        index: number
    ) {
        const oldRecipients = emailMessages.custom[index].recipients;

        switch (action.action) {
            case 'select-option': {
                analytics.track(
                    'Email messages: Added a recipient to custom message'
                );

                const newRecipients = [
                    ...oldRecipients,
                    recipients.find(
                        (recipient) => recipient.tempId === action.option?.value
                    )!,
                ];

                dispatch(setCustomEmailRecipients(index, newRecipients));
                break;
            }

            case 'remove-value': {
                analytics.track(
                    'Email messages: Removed a recipient from custom message'
                );

                const indexOfRecipient = oldRecipients.findIndex(
                    (recipient) => {
                        return recipient.tempId === action.removedValue.value;
                    }
                );

                const newRecipients = [
                    ...oldRecipients.slice(0, indexOfRecipient),
                    ...oldRecipients.slice(indexOfRecipient + 1),
                ];

                dispatch(setCustomEmailRecipients(index, newRecipients));
                break;
            }
            case 'clear': {
                analytics.track(
                    'Email messages: Cleared all recipients from custom message'
                );

                dispatch(setCustomEmailRecipients(index, []));
                break;
            }
            case 'pop-value': {
                const newRecipients = [
                    ...oldRecipients.slice(0, oldRecipients.length - 1),
                ];

                dispatch(setCustomEmailRecipients(index, newRecipients));
                break;
            }
        }
    }

    // If there is no general message, it also means the templates has not been
    // fetched yet.
    if (!emailMessages.general.messages) {
        return <Loader type="dots" inline={false} />;
    }

    return (
        <>
            <section aria-label={i18n`General message`}>
                <EmailMessageEditor
                    collapsible={true}
                    recipientsDescription={i18n`All signers except the ones included in a customized message.`}
                    heading={i18n`General message`}
                    message={{
                        body: emailMessages.general.messages[activeMessageType]
                            .body.content,
                        id: emailMessages.general.messages[activeMessageType]
                            .id,
                        isDefaultTemplate:
                            emailMessages.general.messages[activeMessageType]
                                .default,
                        subject:
                            emailMessages.general.messages[activeMessageType]
                                .subject.content,
                    }}
                    messageValidity={
                        emailMessages.general.validation[activeMessageType]
                    }
                    onChangeBody={(event) => handleOnBodyChange(event)}
                    onChangeSubject={(event) => handleOnSubjectChange(event)}
                    onChangeTemplate={(selectedValue) =>
                        handleOnSelectTemplate(selectedValue)
                    }
                    selectOptions={getTemplateOptions(activeMessageType)}
                    showSignatureNotice={true}
                    translationLanguage={casefileLanguage}
                />
            </section>
            {emailMessages.custom.map((emailMessage, index) => {
                const emailMessageKind =
                    emailMessage.messages[TemplateType.SIGNER];

                const availableRecipients = convertRecipientsForAutocomplete(
                    recipientsWithoutCustomMessages()
                );

                const currentRecipients = convertRecipientsForAutocomplete(
                    emailMessage.recipients
                );

                return (
                    <section
                        aria-label={`${i18n`Custom message`} ${index + 1}`}
                        key={index}>
                        <EmailMessageEditor
                            index={index}
                            heading={
                                <>
                                    <ColorLegendCircle index={index} />
                                    {`${i18n`Custom message`} ${index + 1}`}
                                </>
                            }
                            recipients={{
                                availableRecipients,
                                currentRecipients,
                                onPickSuggestion: (
                                    actionMeta: ActionMeta<{
                                        label: string;
                                        value: string;
                                    }>
                                ) =>
                                    handleOnAutocompleteAction(
                                        actionMeta,
                                        index
                                    ),
                            }}
                            recipientsDescription={`${i18n`Recipients of custom message`} ${
                                index + 1
                            }`}
                            collapsible={true}
                            message={{
                                body: emailMessageKind.body.content,
                                id: emailMessageKind.id,
                                isDefaultTemplate: emailMessageKind.default,
                                subject: emailMessageKind.subject.content,
                            }}
                            messageValidity={
                                emailMessage.validation[TemplateType.SIGNER]
                            }
                            onChangeBody={(event) =>
                                handleOnBodyChange(event, index)
                            }
                            onChangeSubject={(event) =>
                                handleOnSubjectChange(event, index)
                            }
                            onChangeTemplate={(selectedValue) =>
                                handleOnSelectTemplate(selectedValue, index)
                            }
                            selectOptions={getTemplateOptions(
                                activeMessageType
                            )}
                            showSignatureNotice={true}
                            translationLanguage={casefileLanguage}>
                            <div className="mt pull-right-flex">
                                <Button
                                    theme="red"
                                    variant="outline"
                                    onClick={() =>
                                        handleOnRemoveCustomMessage(index)
                                    }>
                                    {i18n('Remove message')}
                                </Button>
                            </div>
                        </EmailMessageEditor>
                    </section>
                );
            })}
            <div className="mt pull-right-flex">
                <Button
                    theme="blue"
                    variant="outline"
                    onClick={handleOnAddCustomMessage}>
                    {i18n('Add new custom message')}
                </Button>
            </div>
        </>
    );
}

export default connect((state: ReduxState) => ({
    defaultTemplates: state.settings.data.user.defaultEmailTemplates.casefile,
    emailMessages: state.newCasefile.emailMessages,
    emailTemplates: state.email.templates,
}))(EmailMessages);
