import React from 'react';
import { i18n } from 'Language';
import { Helmet } from 'react-helmet';
import EmailTemplateList, {
    Props,
} from 'EmailTemplates/components/EmailTemplateList';

const EmailTemplatesPage: React.FunctionComponent<Props> = (props) => {
    return (
        <div>
            <Helmet>
                <title>{i18n('Email templates')}</title>
            </Helmet>
            <div className="white-container">
                <h3 className="title">{i18n`Email templates`}</h3>
                <EmailTemplateList {...props} />
            </div>
        </div>
    );
};

export default EmailTemplatesPage;
