import React from 'react';
import { images } from 'Constants';
import UITooltip from 'Common/components/Vega/Tooltip';
import UILoadingSpinner from 'Common/components/Vega/LoadingSpinner';
import Button from 'Common/components/Button';
import {
    XMarkIcon,
    CheckCircleIcon,
    ExclamationTriangleIcon,
} from '@heroicons/react/20/solid';

import UIInlineMessage from 'Common/components/Vega/InlineMessage';
import { UtilityState } from 'DocumentServices/Common/Types';
import { ValidatorResponse } from '../Models/ValidatorResponse';
import { i18n } from 'Language';
import DocumentSection from './DocumentSection';
import SignaturesContainer from './SignarturesContainer';
import { resolveProperties } from '../Utils/Utils';

type Props = {
    documentState: UtilityState;
    fileName: string;
    response?: ValidatorResponse;
    donwloadUrl?: string;
    onReset?: () => void;
};

const DocumentFile = ({
    fileName,
    documentState,
    response,
    onReset,
}: Props) => {
    const {
        description,
        inlineDescription,
        inlineTitle,
        variant,
        icon,
        showInlineMessage,
        hasError,
    } = resolveProperties(documentState);

    const showDocumentAndSignatures =
        (documentState === UtilityState.UPLOAD_SUCCESS ||
            documentState === UtilityState.VALIDATION_ERROR) &&
        response;

    return (
        <>
            <div className="bg-primary-900 rounded-t-sm flex items-center py-2 px-4 gap-2">
                <h2 className="text-white text-xs font-bold leading-none">
                    {i18n`validatorValidationResults`}
                </h2>
            </div>
            <div className="p-4 sm:p-6 bg-white rounded-b-md border-solid border-[1px] border-t-0 border-gray-200 flex-col justify-start items-start gap-2 flex">
                <div className="flex flex-col w-full gap-2">
                    <section
                        aria-label={i18n`validatorDocumentValidationResults`}
                        className="flex flex-col w-full gap-2">
                        <div
                            className={`
                        flex flex-col sm:flex-row
                        bg-white rounded-[3px] border border-solid p-4
                        h-auto sm:h-[72px] w-full
                        ${
                            hasError
                                ? 'border-danger-700'
                                : documentState === UtilityState.UPLOAD_SUCCESS
                                ? 'border-success-600'
                                : 'border-neutral-300'
                        }
                    `}>
                            <div className="flex items-center gap-2">
                                <img
                                    className="w-6 sm:w-[27.5px] sm:h-[35px] flex-shrink-0"
                                    src={`${images}/file-component/${icon}`}
                                    alt="PDF file symbol icon"
                                />
                                <div
                                    className="text-sm text-neutral-900 font-semibold
                                        truncate max-w-[8rem] sm:max-w-[20rem]">
                                    {fileName}
                                </div>
                            </div>
                            <div
                                className="
                        flex items-center justify-end
                        mt-2 sm:mt-0 sm:ml-auto
                    ">
                                <div className="flex items-center gap-2 sm:gap-4">
                                    {description && (
                                        <span
                                            className={`
                                        text-sm leading-tight flex items-center gap-2
                                            ${
                                                hasError
                                                    ? 'text-danger-700'
                                                    : documentState ===
                                                      UtilityState.UPLOAD_SUCCESS
                                                    ? 'text-success-600'
                                                    : 'text-gray-500'
                                            }
                                    `}>
                                            {description}{' '}
                                            {documentState ===
                                                UtilityState.UPLOAD_SUCCESS && (
                                                <CheckCircleIcon
                                                    aria-label={i18n`validatorAriaDocumentValid`}
                                                    className="w-6 fill-success-600"
                                                />
                                            )}
                                            {hasError && (
                                                <ExclamationTriangleIcon
                                                    aria-label={i18n`validatorAriaDocumentInvalid`}
                                                    className="w-6 fill-danger-700"
                                                />
                                            )}
                                        </span>
                                    )}
                                    <div className="flex items-center">
                                        {documentState ===
                                            UtilityState.UPLOADING && (
                                            <>
                                                <UILoadingSpinner
                                                    variant="accent"
                                                    className="w-5 h-5 sm:w-6 sm:h-6"
                                                />
                                                <Button
                                                    variant="text"
                                                    onClick={onReset}
                                                    className="ml-1 sm:ml-2">
                                                    <XMarkIcon className="h-5 w-5 sm:h-6 sm:w-6 fill-neutral-700" />
                                                </Button>
                                                <UITooltip
                                                    placement="right"
                                                    offset={7}>
                                                    {i18n`Close`}
                                                </UITooltip>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {showInlineMessage && (
                            <div className="mt-1">
                                <UIInlineMessage
                                    variant={variant}
                                    description={inlineDescription}
                                    title={inlineTitle}
                                    hideCloseButton
                                    className="rounded-md"
                                />
                            </div>
                        )}
                        {showDocumentAndSignatures && (
                            <DocumentSection
                                response={response}
                                fileName={fileName}
                            />
                        )}
                    </section>
                    {showDocumentAndSignatures && (
                        <SignaturesContainer signatures={response.signatures} />
                    )}
                </div>
            </div>
        </>
    );
};

export default DocumentFile;
