import PropTypes from 'prop-types';
import React from 'react';
// import HoverTooltip from 'Common/components/Common/HoverTooltip.jsx';
import { i18n } from 'Language';
// child components
import CopyRecipients from './CopyRecipients.jsx';
import MessageSetup from './MessageSetup.jsx';
import DocumentOptions from './DocumentOptions.jsx';
import CasefileAdvancedOptions from './CasefileAdvancedOptions';
// action creators
import CasefileActions from '../../actions/CasefilesActionCreators';
import URIActions from '../../actions/URIActionCreators';
import Button from 'Common/components/Button';
import { Button as VegaReactButton } from '@penneo/vega-ui-react';

export default class CasefileSummary extends React.Component {
    static contextTypes = {
        router: PropTypes.object.isRequired,
    };

    static propTypes = {
        sendAtDate: PropTypes.object,
        sendLater: PropTypes.bool,
        expireAtDate: PropTypes.object,
        expireEnable: PropTypes.bool,
        messageTitle: PropTypes.string,
        casefile: PropTypes.object,
        recipients: PropTypes.array,
        messageTemplates: PropTypes.array,
        messages: PropTypes.object,
        messageInputErrors: PropTypes.object,
        recipientInputErrors: PropTypes.object,
        documents: PropTypes.array,
        signers: PropTypes.array,
        selectedFolderId: PropTypes.number,
        options: PropTypes.object,
        casefileSendValidation: PropTypes.func,
        resetData: PropTypes.func,
        sending: PropTypes.bool,
    };

    changeSendAtDate = (date) => {
        CasefileActions.setSendAtDate(date);
    };

    sendLaterToggle = (isSendLater) => {
        CasefileActions.sendLaterToggle(isSendLater);
    };

    changeExpireAtDate = (date) => {
        CasefileActions.setExpireAtDate(date);
    };

    expireEnableToggle = (isEnabled) => {
        CasefileActions.expireEnableToggle(isEnabled);
    };

    clearExpireDate = () => {
        CasefileActions.clearExpireDate();
    };

    clearSendDate = () => {
        CasefileActions.clearSendDate();
    };

    // Funciton that creates the new Case JSON String
    _sendAtDateToCasefile = (casefile, sendAtDate) => {
        // let formattedDate = new Date(sendAtDate.format());
        let formattedDate = sendAtDate.unix();

        casefile.sendAt = formattedDate;
    };

    changeIntegrationCode = (jsonObject) => {
        URIActions.setNewIntegrationCode(jsonObject);
    };

    _onBackClick = () => {
        let { router } = this.context;

        router.push({
            name: 'casefile-details',
        });
    };

    render() {
        let {
            sendAtDate,
            expireAtDate,
            recipients,
            casefile,
            messageTemplates,
            messages,
            recipientInputErrors,
            messageInputErrors,
            options,
            // documentsWithOptions,
            casefileSendValidation,
            sendLater,
            expireEnable,
            sending,
        } = this.props;

        return (
            <div className="casefile-summary">
                <div className="casefile-creation-wrapper">
                    <div className="horizontal-layout">
                        <div className="casefile-component-wrapper">
                            <CopyRecipients
                                recipients={recipients}
                                inputErrors={recipientInputErrors}
                                isCasefileSensitive={casefile.sensitiveData}
                            />
                            <br />
                            {options ? (
                                <DocumentOptions
                                    documentType={options}
                                    updateDocumentOptions={
                                        this.updateDocumentOptions
                                    }
                                />
                            ) : null}
                        </div>
                        <div className="casefile-component-wrapper">
                            <MessageSetup
                                language={casefile.language}
                                casefile={casefile}
                                messageTemplates={messageTemplates}
                                sendAtDate={sendAtDate}
                                sendLater={sendLater}
                                sendLaterToggle={this.sendLaterToggle}
                                expireAtDate={expireAtDate}
                                expireEnable={expireEnable}
                                expireEnableToggle={this.expireEnableToggle}
                                inputErrors={messageInputErrors}
                                messageTemplate={messages.signer}
                                changeSendAtDate={this.changeSendAtDate}
                                changeExpireAtDate={this.changeExpireAtDate}
                                clearSendDate={this.clearSendDate}
                                clearExpireDate={this.clearExpireDate}
                            />
                        </div>
                    </div>
                    <div className="horizontal-layout inline-flex justify-between items-center">
                        <div className="casefile-summary-buttons-left">
                            <Button
                                theme="gray"
                                variant="outline"
                                onClick={this._onBackClick}
                                disabled={sending}>
                                {i18n('Back')}
                            </Button>
                        </div>
                        <div className="pull-right pr inline-flex">
                            <CasefileAdvancedOptions
                                resetData={this.props.resetData}
                                sending={sending}
                            />

                            <VegaReactButton
                                priority="primary"
                                dataTestId="send-documents"
                                type="success"
                                onClick={casefileSendValidation}
                                isLoading={sending}>
                                {i18n`Send documents`}
                            </VegaReactButton>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
