import React from 'react';
import InvitationGeneralError from 'resources/images/folder-share/general-error.svg';
import { InvitationStatus } from './InvitationStatus';

export function InviteError() {
    return (
        <InvitationStatus
            imageSrc={InvitationGeneralError}
            imageAlt="Folder warning"
            titleKey="Something went wrong"
            bodyKey="pageUnavailableErrorText"
            supportLink={true}
            isWarning={true}
        />
    );
}
