import { AppThunk } from 'Store';
import {
    UserEmailTemplate,
    TemplateType,
    EmailTemplate,
    EmailTemplateGroup,
} from 'types/EmailTemplates';
import { KYCDefaultEmailTemplates } from 'KYC/Constants';
import { RegisteredLetterDefaultEmailTemplates } from 'RegisteredLetter/Constants';
import { Languages } from 'Language/Constants';
import {
    FETCH_MESSAGE_LIST_TEMPLATES_REQUEST,
    FETCH_MESSAGE_LIST_TEMPLATES_SUCCESS,
    FETCH_MESSAGE_LIST_TEMPLATES_FAILURE,
    FETCH_UPDATE_MESSAGE_TEMPLATE_REQUEST,
    FETCH_UPDATE_MESSAGE_TEMPLATE_SUCCESS,
    FETCH_UPDATE_MESSAGE_TEMPLATE_FAILURE,
    FETCH_DELETE_MESSAGE_TEMPLATE_REQUEST,
    FETCH_DELETE_MESSAGE_TEMPLATE_SUCCESS,
    FETCH_DELETE_MESSAGE_TEMPLATE_FAILURE,
    FETCH_CREATE_MESSAGE_TEMPLATE_REQUEST,
    FETCH_CREATE_MESSAGE_TEMPLATE_SUCCESS,
    FETCH_CREATE_MESSAGE_TEMPLATE_FAILURE,
} from './action-types';

export const getUserEmailTemplates =
    (
        group: EmailTemplateGroup = EmailTemplateGroup.CASEFILE,
        language = Languages.EN
    ): AppThunk<Promise<EmailTemplate[]>> =>
    async (dispatch, _, { api }) => {
        dispatch({ type: FETCH_MESSAGE_LIST_TEMPLATES_REQUEST });

        try {
            const defaultTpls = await getDefaultTemplates(group, api, language);
            const savedTpls = await api.SigningAPI.get(
                '/casefile/message/templates'
            );

            const data = [...defaultTpls, ...savedTpls];

            dispatch({
                type: FETCH_MESSAGE_LIST_TEMPLATES_SUCCESS,
                payload: data,
            });

            return Promise.resolve(data);
        } catch {
            dispatch({ type: FETCH_MESSAGE_LIST_TEMPLATES_FAILURE });

            return Promise.reject();
        }
    };

export const updateUserEmailTemplate =
    ({
        id,
        title,
        subject,
        message,
    }: UserEmailTemplate & { id: number }): AppThunk =>
    async (dispatch, _, { api }) => {
        dispatch({ type: FETCH_UPDATE_MESSAGE_TEMPLATE_REQUEST });

        try {
            await api.SigningAPI.patch(`/casefile/message/templates/${id}`, {
                title,
                subject,
                message,
            });

            dispatch({ type: FETCH_UPDATE_MESSAGE_TEMPLATE_SUCCESS });

            return Promise.resolve();
        } catch {
            dispatch({ type: FETCH_UPDATE_MESSAGE_TEMPLATE_FAILURE });

            return Promise.reject();
        }
    };

export const deleteUserEmailTemplate =
    (templateId: number): AppThunk =>
    async (dispatch, _, { api }) => {
        dispatch({ type: FETCH_DELETE_MESSAGE_TEMPLATE_REQUEST });

        try {
            await api.SigningAPI.delete(
                `/casefile/message/templates/${templateId}`
            );
            dispatch({ type: FETCH_DELETE_MESSAGE_TEMPLATE_SUCCESS });

            return Promise.resolve();
        } catch {
            dispatch({ type: FETCH_DELETE_MESSAGE_TEMPLATE_FAILURE });

            return Promise.reject();
        }
    };

export const createCustomerEmailTemplate = (template: UserEmailTemplate) => {
    return createEmailTemplate({
        ...template,
        ownedByCustomer: true,
    });
};

export const createUserEmailTemplate = (template: UserEmailTemplate) => {
    return createEmailTemplate(template);
};

const createEmailTemplate =
    (payload: UserEmailTemplate): AppThunk =>
    async (dispatch, _, { api }) => {
        dispatch({ type: FETCH_CREATE_MESSAGE_TEMPLATE_REQUEST });

        try {
            const resp = await api.SigningAPI.post(
                `/casefile/message/templates`,
                payload
            );

            dispatch({ type: FETCH_CREATE_MESSAGE_TEMPLATE_SUCCESS });

            return Promise.resolve(resp);
        } catch {
            dispatch({ type: FETCH_CREATE_MESSAGE_TEMPLATE_FAILURE });

            return Promise.reject();
        }
    };

const getDefaultTemplates = async (
    group: EmailTemplateGroup = EmailTemplateGroup.CASEFILE,
    api,
    language
): Promise<EmailTemplate[]> => {
    const emailTemplateGroup = {
        [EmailTemplateGroup.CASEFILE]: await getCasefileDefaultTemplates(
            api,
            language
        ),
        [EmailTemplateGroup.KYC]: getKYCDefaultTemplates(),
        [EmailTemplateGroup.REGISTERED_LETTER]:
            getRegisteredLetterDefaultTemplates(),
    }?.[group];

    return emailTemplateGroup.map(processDefaultTemplate);
};

const getCasefileDefaultTemplates = async (
    { SigningAPI },
    language
): Promise<EmailTemplate[]> => {
    const types = [
        TemplateType.SIGNER,
        TemplateType.COMPLETED,
        TemplateType.REMINDER,
        TemplateType.COPY_RECIPIENT,
    ];

    const requests = types.map(async (type) => {
        const requestUrl = `/casefile/message/templates/default?language=${language}&type=${type}`;
        const template = await SigningAPI.get(requestUrl);

        return {
            ...template,
            type,
        };
    });

    return await Promise.all(requests);
};

// KYC deault templates can't be fetched via the API yet, so we need to use
// the ones hardcoded in a constant
const getKYCDefaultTemplates = (): EmailTemplate[] => {
    const types = [TemplateType.INITIAL, TemplateType.REMINDER];

    return types.map((type) => ({
        ...KYCDefaultEmailTemplates[type],
        type,
    }));
};

/**
 * Registered Letter deault templates can't be fetched via the API yet, so we
 * need to use the ones hardcoded in a constant
 * */
const getRegisteredLetterDefaultTemplates = (): EmailTemplate[] => {
    const types = [TemplateType.INITIAL];

    return types.map((type) => ({
        ...RegisteredLetterDefaultEmailTemplates[type],
        type,
    }));
};

const processDefaultTemplate = (template, index): EmailTemplate => ({
    id: -1 * (index + 1),
    title: 'Default template',
    default: true,
    ...template,
});
