import React from 'react';
import { i18n } from 'Language';
import { TabbedRoute } from 'types/TabbedRoute';
import Analytics from 'Common/Analytics';
import RouterV3Tabs from 'Common/components/RouterV3Tabs';

type Props = {
    children?: JSX.Element;
};

export const KYCListTabRoutes = [
    {
        id: 'pending',
        label: 'Pending',
        query: {
            sort: '-createdAt',
            isFilled: false,
        },
        route: {
            name: 'kyc-list',
            params: {
                status: 'pending',
            },
        },
    },
    {
        id: 'filled',
        label: 'Filled',
        query: {
            sort: '-filledAt',
            isFilled: true,
        },
        route: {
            name: 'kyc-list',
            params: {
                status: 'filled',
            },
        },
    },
];

const KYCListTabs: React.FunctionComponent<Props> = ({ children }) => {
    return <RouterV3Tabs routes={normalizeRoutes()}>{children}</RouterV3Tabs>;

    /**
     * Converts the structure of the routes defined in the contants so that it
     * matches the expected structure of `TabbedRoute`s. This way the routes
     * can be consumed by <RouterV3Tabs />
     */
    function normalizeRoutes(): TabbedRoute[] {
        return KYCListTabRoutes.map((kycRoute): TabbedRoute => {
            const { id, route } = kycRoute;
            const title = buildTabbedRouteTitle(kycRoute);

            return {
                ...route,
                tab: {
                    id,
                    title,
                    noPadding: true,
                    onClick: () => {
                        Analytics.track('kyc management - change view', {
                            id,
                        });
                    },
                },
            };
        });
    }

    /**
     * Adds the item count to the tab title
     */
    function buildTabbedRouteTitle(tab): JSX.Element {
        const { label } = tab;

        return i18n(label);
    }
};

export default KYCListTabs;
