/* eslint react/no-find-dom-node: 0 */
import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import FormStore from '../../stores/FormStore';
import FormActions from '../../actions/FormActionCreators';
import TemplateEditorField from '../FormTemplate/TemplateEditorField';
import TemplateEditorTextArea from '../FormTemplate/TemplateEditorTextArea';
import DocumentField from './DocumentField.jsx';
import Constants from '../../Constants';
import { DropTarget } from 'react-dnd';

let initialPosition = { posX: null, posY: null };

let dropCoordinate = {
    // React DnD drop functionality
    drop(props, monitor, component) {
        let item = monitor.getItem();

        if (item.type === 'sidebar-field') {
            let dropCoords = monitor.getSourceClientOffset();

            component.dropHandler(props, component, dropCoords, item);
        } else {
            let coords = monitor.getSourceClientOffset();

            component.dropHandler(props, component, coords, item);
            props.save();
        }
    },
};

// Set this component as droppable, React Dnd
function collect(connect, monitor) {
    return {
        connectDropTarget: connect.dropTarget(),
        isOver: monitor.isOver(),
    };
}
// Droppable React DnD component
export default DropTarget(
    Constants.ItemTypes.FIELD,
    dropCoordinate,
    collect
)(
    class DocumentDrop extends React.Component {
        static propTypes = {
            formId: PropTypes.string,
            fields: PropTypes.array,
            getCoords: PropTypes.func,
            index: PropTypes.number,
            dimensions: PropTypes.object,
            mapping: PropTypes.array,
            doc: PropTypes.array,
            editMode: PropTypes.bool,
            updateField: PropTypes.func,
            highlightedField: PropTypes.number,
            zoom: PropTypes.number,
            disableEdit: PropTypes.bool,
            connectDropTarget: PropTypes.func.isRequired,
            save: PropTypes.func.isRequired,
            editorSettings: PropTypes.object,
        };

        state = {
            startX: null,
        };

        /**
         * Creates an element based on the dropped field type on the documents' page.
         * @param event {event} onDrop event from finalized drag action.
         */
        dropHandler = (props, component, dropCoords, item) => {
            let rect = ReactDOM.findDOMNode(component).getBoundingClientRect();
            // Calculate pointer position inside target. (Pointer position - Target Position)
            let posX = dropCoords.x - 1 - rect.left;
            let posY = dropCoords.y - 1 - rect.top;

            // Get DOM Object Bounds
            if (item.type === 'sidebar-field') {
                // Render Element in position
                this.createElement(posX, posY, item.fieldId);
                FormActions.editorFieldAdded();
            } else {
                this.updateElement(posX, posY, item);
            }
        };

        /**
         * Adds field to field mapping state, and updates the fieldMap state
         * which renders a new field in the document, based on the fieldId.
         *
         * @param target  {DOM Node} Target DOM Element for onDrop event
         * @param x       {integer}  Horizontal pixel offset of element within target
         * @param y       {integer}  Vertical pixel offset of element within target
         * @param fieldId {integer}  Field type ID, references a type of field in the form
         */
        createElement = (x, y, fieldId) => {
            let target = event.target; /* eslint no-restricted-globals:0 */

            if (!target.dataset.page || !x || !y || !fieldId) {
                return;
            }

            initialPosition.posX = x;
            initialPosition.posY = y;

            let page = parseInt(target.dataset.page, 10) + 1;
            let { mapping, formId } = this.props;

            let element = {
                documentId: this.props.doc[0].id,
                page: page,
                fieldId: fieldId,
                height: FormStore.convertPointsToPreviewPixels(10, page),
                width: 200,
                x: x,
                y: y,
                order: mapping.length + 1,
            };

            let data = FormStore.getPercentageMapping(element);

            FormActions.createFormFieldMapping(formId, data);
        };

        updateElement = (x, y, mapping) => {
            // let {index} = this.props;
            let { id, width, height } = mapping;
            let target = event.target;

            if (!target.dataset.page || !x || !y || !id) {
                return;
            }

            initialPosition.posX = x;
            initialPosition.posY = y;

            let page = parseInt(target.dataset.page, 10) + 1;
            let field = FormStore.getFormFieldMapping(id);

            let element = {
                documentId: this.props.doc[0].id,
                page: field.page,
                fieldId: field.fieldId,
                height:
                    height || FormStore.convertPointsToPreviewPixels(10, page),
                width: width,
                x: x,
                y: y,
                order: field.order,
            };

            let data = FormStore.getPercentageMapping(element);

            FormStore.updateFormFieldMapping(id, data);
        };

        removeFieldMapping = (mappingId) => {
            let { formId } = this.props;

            FormActions.deleteFormFieldMapping(formId, mappingId);
        };

        getSetting = (setting) => {
            return FormStore.getEditorSettings()[setting];
        };

        moveEventStart = (mapping, event, startX, startWidth) => {
            this.setState({
                resizeField: true,
                startX: startX,
                startWidth: startWidth,
                currentMapping: mapping,
            });
        };

        mouseUpHandler = () => {
            this.setState({
                resizeField: true,
                startX: null,
                startWidth: null,
                currentMapping: null,
            });

            this.props.save(); // Save mapping
        };

        resizeMapping = (event) => {
            let { startX, startWidth, currentMapping } = this.state; // resizing data
            let { dimensions } = this.props;

            if (!currentMapping) {
                return false;
            }

            let pageDimensions = dimensions[currentMapping.page];

            let delta = event.clientX - startX;

            let mappingWidth = startWidth + delta; // mapping preview width

            let update = {
                width: mappingWidth / pageDimensions.width, // Convert to percentage
            };

            FormStore.updateFormFieldMapping(currentMapping.id, update);
        };

        resizeTextarea = (mapping, mappingDimensions) => {
            let { dimensions } = this.props;

            let pageDimensions = dimensions[mapping.page];
            let update = {
                height: mappingDimensions.height / pageDimensions.height,
                width: mappingDimensions.width / pageDimensions.width, // Convert to percentage
            };

            FormStore.updateFormFieldMapping(mapping.id, update);
            this.props.save();
        };

        renderField = (fieldMap, index) => {
            let { dimensions } = this.props;
            let pageDimensions = dimensions[fieldMap.page];

            if (!pageDimensions) {
                return false;
            }

            if (this.props.editMode) {
                return this.renderEditorField(fieldMap, index, pageDimensions);
            }

            return this.renderMergedField(fieldMap, index, pageDimensions);
        };

        // Render Draggable and Edittable Field on this(DocumentDrop)
        renderEditorField = (fieldMap, index) => {
            let { save } = this.props;

            let field = FormStore.getFieldById(fieldMap.fieldId);

            switch (field.metaData.type) {
                case 'textarea':
                    return (
                        <TemplateEditorTextArea
                            key={index}
                            mapping={fieldMap}
                            field={field}
                            resizeMapping={this.resizeTextarea}
                            index={index}
                            initialPosition={initialPosition}
                            showOrder={this.props.editorSettings.showOrder}
                            hideSourceOnDrag={true}
                            moveEventStart={this.moveEventStart}
                            save={save}
                            remove={this.removeFieldMapping}
                        />
                    );
                default:
                    return (
                        <TemplateEditorField
                            key={index}
                            mapping={fieldMap}
                            field={field}
                            resizeMapping={this.resizeMapping}
                            index={index}
                            initialPosition={initialPosition}
                            showOrder={this.props.editorSettings.showOrder}
                            hideSourceOnDrag={true}
                            moveEventStart={this.moveEventStart}
                            save={save}
                            remove={this.removeFieldMapping}
                        />
                    );
            }
        };

        renderMergedField = (fieldMap, index, pageDimensions) => {
            let { zoom, updateField, disableEdit, highlightedField } =
                this.props;
            let field = FormStore.getFieldById(fieldMap.fieldId);

            return (
                <DocumentField
                    key={index}
                    zoom={zoom}
                    dimensions={pageDimensions}
                    mapping={fieldMap}
                    field={field}
                    highlightedField={highlightedField}
                    index={index}
                    updateField={updateField}
                    disableEdit={disableEdit}
                />
            );
        };

        render() {
            let {
                mapping,
                fields,
                dimensions,
                getCoords,
                index,
                connectDropTarget,
            } = this.props;

            let { resizeField } = this.state;

            return connectDropTarget(
                <div
                    className={
                        this.props.editorSettings.showGrid
                            ? 'page-field-overlay show-grid'
                            : 'page-field-overlay'
                    }
                    data-page={index}
                    onClick={getCoords}
                    // onMouseMove={this.mousePosition}>
                    onMouseMove={resizeField ? this.resizeMapping : null}
                    onMouseUp={resizeField ? this.mouseUpHandler : null}>
                    {fields.length > 0 && dimensions[index + 1]
                        ? mapping.map((fieldMap, mappingIndex) =>
                              // Only render fields that are mapped to current page
                              fieldMap.page === index + 1
                                  ? this.renderField(fieldMap, mappingIndex)
                                  : // else, render nothing
                                    false
                          )
                        : // Don't render mappings if there are no fields
                          false}
                </div>
            );
        }
    }
);
