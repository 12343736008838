import { i18n } from 'Language';
import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { ClientsMainContainer } from './ClientsMainContainer';
import { ClientsListHeader } from './ClientsListHeader';
import { useAppDispatch, useAppSelector } from 'Store';
import { clientsState, fetchClients, resetLastDeletedClient } from './redux';
import Table, { Config } from 'Common/components/BaseTable';
import { Client, ClientLinkEntityType } from './types';
import { notify } from 'react-notify-toast';
import { ReactRouter } from 'types/Router';
import { debounce } from 'lodash';
import { UserEntity as User } from 'types/User';
import {
    isCustomerAdministrator,
    isPenneoAdministrator,
} from 'Common/utils/userPermissions';
import { Link } from 'react-router';
import LaunchDarkly, { Flags } from 'Common/LaunchDarkly';

export const DEBOUNCE_SEARCH_DELAY = 500;

type Props = {
    router: ReactRouter;
    user: User;
};

export const ClientsList = ({ router, user }: Props) => {
    const dispatch = useAppDispatch();
    const isAdmin =
        isPenneoAdministrator(user) || isCustomerAdministrator(user);
    const { clientList, totalClients, loading, error, lastDeletedClient } =
        useAppSelector(clientsState);
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);
    const [sorting, setSorting] = useState('');
    const [querySorting, setQuerySorting] = useState<'ASC' | 'DESC'>('ASC');
    const [search, setSearch] = useState('');

    const editLink = useCallback(
        (client: Client, text: string) =>
            isAdmin ? (
                <Link
                    to={{
                        name: 'client-edit',
                        params: {
                            clientId: client.id,
                        },
                    }}>
                    {text}
                </Link>
            ) : (
                <>{text}</>
            ),
        [isAdmin]
    );

    const viewLink = (
        client: Client,
        text: string,
        entityType: ClientLinkEntityType
    ) => (
        <Link
            to={{
                name: 'client-view',
                params: {
                    clientId: client.id,
                    entityType: entityType,
                },
            }}>
            {text}
        </Link>
    );

    const tableConfig: Config = {
        name: {
            label: i18n`Company name`,
            tdClassName: 'column-name',
            component: (client: Client) => editLink(client, client.name),
        },
        reference: {
            label: i18n`Reference ID`,
            tdClassName: 'column-reference',
            component: (client: Client) =>
                client.reference ? editLink(client, client.reference) : <></>,
        },
        casefiles: {
            label: i18n`Casefiles`,
            tdClassName: 'column-casefiles',
            component: (client: Client) =>
                viewLink(client, i18n`View`, ClientLinkEntityType.casefile),
            disableSort: true,
        },
        contacts: {
            label: i18n`Contacts`,
            tdClassName: 'column-contacts',
            component: () => <>-</>,
            disableSort: true,
        },
    };

    const fetchClientList = useCallback(
        (searchTerm?: string) => {
            dispatch(
                fetchClients({
                    per_page: limit,
                    page,
                    sort: querySorting,
                    ...(searchTerm && { name: searchTerm }),
                })
            );
        },
        [limit, page, querySorting]
    );

    const debouncedSearchClients = useMemo(
        () => debounce(fetchClientList, DEBOUNCE_SEARCH_DELAY),
        [fetchClientList]
    );

    useEffect(() => {
        // access control
        if (!LaunchDarkly.variation(Flags.CLIENTS)) {
            router.push('/404');
        }
    }, []);

    useEffect(() => {
        /**
         * To prevent "spamming" the API with calls for every character typed
         * on the search field, we use a debounced version of the method.
         * This version will only be used when there's a search term.
         */
        if (!search) {
            fetchClientList();

            return;
        }

        debouncedSearchClients(search);
    }, [limit, page, querySorting, search]);

    useEffect(() => {
        if (error && !loading) {
            notify.show(
                <span>
                    {i18n(
                        'An error occurred trying to get the list of clients'
                    )}
                </span>,
                'error',
                6000
            );

            return;
        }
    }, [error, loading]);

    useEffect(() => {
        if (!lastDeletedClient) {
            return;
        }

        notify.show(
            <span>{i18n`${lastDeletedClient} was successfully deleted`}</span>,
            'success',
            3000
        );

        dispatch(resetLastDeletedClient());
    }, [lastDeletedClient]);

    /**
     * We have to do some logic when the limit changes,
     * as it can conflict with the current page number
     */
    const handleLimitChange = useCallback(
        (newLimit: number) => {
            if (totalClients <= newLimit) {
                setPage(1);
            }

            setLimit(newLimit);
        },
        [totalClients]
    );

    /**
     * 'sorting' keeps track of the Table component selected sorting,
     * and 'querySorting' is to sort the Client list
     * to be fetched
     */
    const handleSortingChange = (newSorting: string) => {
        setSorting(newSorting);
        setQuerySorting(!newSorting.includes('-') ? 'ASC' : 'DESC');
    };

    return (
        <ClientsMainContainer title="Clients">
            <div className="clients-list">
                <Table
                    limit={limit}
                    page={page}
                    sort={sorting}
                    config={tableConfig}
                    dataSource={clientList}
                    dataCount={totalClients}
                    isLoading={loading}
                    emptyTableComponent={
                        <div className="clients-list-empty">{i18n`The list is empty`}</div>
                    }
                    headComponent={
                        <ClientsListHeader
                            isAdmin={isAdmin}
                            dataCount={totalClients}
                            searchValue={search}
                            onSearchInputChange={setSearch}
                            clearSearch={() => setSearch('')}
                            onNewClient={() => router.push('/clients/new')}
                        />
                    }
                    onSortChange={handleSortingChange}
                    onLimitChange={handleLimitChange}
                    onNext={setPage}
                    onPrev={setPage}
                    onPageChange={setPage}
                />
            </div>
        </ClientsMainContainer>
    );
};
