import React, { ComponentType } from 'react';
import PropTypes from 'prop-types';

interface RouterContext {
    router: {
        push: (path: string) => void;
        createHref: (params: {
            name: string;
            params: any;
            query?: any;
        }) => string;
    };
}

interface WithRouterProps {
    router: RouterContext['router'];
}

const WithRouter = <P extends object>(
    WrappedComponent: ComponentType<P & WithRouterProps>
): ComponentType<P> => {
    const Wrapper = (props, context: RouterContext) => {
        return <WrappedComponent {...props} router={context.router} />;
    };

    Wrapper.contextTypes = {
        router: PropTypes.object.isRequired,
    };

    Wrapper.contextType = PropTypes.object;

    return Wrapper;
};

export default WithRouter;
