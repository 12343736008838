import { useEffect } from 'react';
import { ReactRouter } from 'types/Router';
import {
    acceptAndHandleInvitation,
    validateAndHandleInvitation,
} from 'FolderShareInvite/utils/apiFlow';
import { getDecodedPenneoToken } from 'Auth/utils/jwt';
import launchDarkly, { Flags } from 'Common/LaunchDarkly';

type Props = {
    params: {
        challengeKey: string;
    };
    location: {
        query: {
            fromProfileSelect?: string;
        };
    };
    router: ReactRouter;
};

export function AcceptFolderShareInvite({ params, location, router }: Props) {
    const { challengeKey } = params;
    const cameFromProfileSelect = location.query?.fromProfileSelect === 'true';
    const token = getDecodedPenneoToken();
    const userSwitcherEnabled = launchDarkly.variation(
        Flags.USER_SWITCHER_ENABLED
    );
    const shouldRedirectToProfileSelect =
        token && token.u_qty > 1 && userSwitcherEnabled;

    useEffect(() => {
        if (cameFromProfileSelect) {
            acceptAndHandleInvitation({ challengeKey, router });
        } else {
            validateAndHandleInvitation({
                challengeKey,
                router,
                profileSelector: shouldRedirectToProfileSelect,
            });
        }
    }, []);

    return null;
}
