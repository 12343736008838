import { i18n } from 'Language';
import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'Store';
import Table, { Config } from 'Common/components/BaseTable';
import { debounce } from 'lodash';
import CustomHeader from './CustomHeader';
import { TrashItem } from './redux/types';
import {
    TrashFetchParams,
    fetchTrash,
    manageSelectedRow,
} from './redux/actions';
import NoTableData from '../../../Forms/components/Common/CustomNoTableData';
import moment from 'moment';

export const DEBOUNCE_SEARCH_DELAY = 500;

export const TrashCanTable = () => {
    const dispatch = useAppDispatch();
    const { trashList, itemCount, selectedRows, isFetching } = useAppSelector(
        (state) => state.trash
    );
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState('');
    const [params, setParams] = useState<TrashFetchParams>({
        per_page: 10,
        page: 1,
    });

    /**
     * Table configuration and components
     */
    const NoResults = useMemo(
        () => () =>
            (
                <div className="my-8 text-center">
                    <h2>{i18n`No results found for "${search}"`}</h2>
                    <a onClick={() => setSearch('')}>
                        {i18n`Clear search`}&nbsp;
                        <i className="fa fa-times"></i>
                    </a>
                </div>
            ),
        [search]
    );

    const RowSelector = useMemo(
        () => (item: TrashItem) =>
            (
                <label key={item.id} className="custom-checkbox">
                    <input
                        type="checkbox"
                        checked={
                            !!selectedRows.find((row) => row.id === item.id)
                        }
                        onChange={() =>
                            dispatch(manageSelectedRow({ id: item.id }))
                        }
                    />
                    <span className="check">
                        <i className="fas fa-check" />
                    </span>
                </label>
            ),
        [selectedRows]
    );

    const tableConfig: Config = {
        checkbox: {
            thClassName: 'column-checkbox',
            tdClassName: 'column-checkbox',
            component: (item: TrashItem) => RowSelector(item),
            disableSort: true,
        },
        id: {
            label: `${i18n`Casefile`} ${i18n`Id`}`,
            tdClassName: 'column-id',
            component: (item: TrashItem) => <>{item.id}</>,
            disableSort: true,
        },
        title: {
            label: i18n`Title`,
            tdClassName: 'column-title',
            component: (item: TrashItem) => <>{item.title}</>,
            disableSort: true,
        },
        deleted: {
            label: i18n`Deleted At`,
            tdClassName: 'column-deleted',
            component: (item: TrashItem) => (
                <>
                    {item.deletedAt
                        ? moment.unix(item.deletedAt).format('YYYY-MM-DD')
                        : ''}
                </>
            ),
            disableSort: true,
        },
    };

    const fetchTrashList = useCallback(
        (searchTerm?: string) => {
            /**
             * We set the parameters for the current fetch
             * and for the one triggered by restoring/deleting items
             */
            const currentParams: TrashFetchParams = {
                per_page: limit,
                page,
                ...(searchTerm && { search_phrase: searchTerm }),
            };

            dispatch(fetchTrash(currentParams));

            setParams(currentParams);
        },
        [limit, page]
    );

    const debouncedSearchClients = useMemo(
        () => debounce(fetchTrashList, DEBOUNCE_SEARCH_DELAY),
        [fetchTrashList]
    );

    useEffect(() => {
        /**
         * To prevent "spamming" the API with calls for every character typed
         * on the search field, we use a debounced version of the method.
         * This version will only be used when there's a search term.
         */
        if (!search) {
            fetchTrashList();

            return;
        }

        debouncedSearchClients(search);
    }, [limit, page, search]);

    /**
     * We have to do some logic when the limit changes,
     * as it can conflict with the current page number
     */
    const handleLimitChange = useCallback(
        (newLimit: number) => {
            if (itemCount <= newLimit || itemCount <= newLimit * page) {
                setPage(1);
            }

            setLimit(newLimit);
        },
        [itemCount, page]
    );

    return (
        <Table
            limit={limit}
            page={page}
            config={tableConfig}
            dataSource={trashList ?? []}
            dataCount={itemCount}
            isLoading={isFetching}
            emptyTableComponent={
                search ? (
                    <NoResults />
                ) : (
                    <NoTableData
                        icon="fa-recycle"
                        message="Your recycle bin is empty!"
                        subText="Deleted items will be found on this page"
                    />
                )
            }
            headComponent={
                <CustomHeader
                    searchValue={search}
                    changeFilter={setSearch}
                    currentResults={trashList}
                    params={params}
                />
            }
            onLimitChange={handleLimitChange}
            onNext={setPage}
            onPrev={setPage}
            onPageChange={setPage}
        />
    );
};
