import { storage } from 'Core';
import { StorageKeys, Integrations } from 'Constants';

import axios from 'axios';

import { AppThunk } from 'Store';
import { SilverfinSettings, CustomerSettingKeys } from 'Settings/redux/types';
import { updateCustomerSettings } from 'Settings/redux/actions';
import Analytics from 'Common/Analytics';

const _getHostname = (subdomain) =>
    `${subdomain}.${Integrations.Silverfin.baseDomain}`;

/**
 * Perform a request to the silverfin-adaptor passing the OAuth code, the
 * Firm ID (Silverfin customer account) and some other settings so that the
 * adaptor can “enable” the integration by adding buttons in the Silverfin UI
 * @param code The OAuth code coming from the Silverfin callback
 * @param current the current Silverfin settings
 */
const _enable = async (
    code: string,
    current: SilverfinSettings
): Promise<SilverfinSettings> => {
    const { userId, subdomain }: PreCollectData = JSON.parse(
        storage.get(StorageKeys.SILVERFIN_STATE)
    );

    try {
        const { REGISTER_PENNEO_URL, REDIRECT_URI, TARGET_URL } =
            Integrations.Silverfin.getUrls();

        await axios.post(REGISTER_PENNEO_URL, {
            code,
            firmId: current.silverfinFirmId,
            hostname: _getHostname(subdomain),
            redirectUri: REDIRECT_URI,
            targetUrl: TARGET_URL,
        });

        return {
            name: current.name,
            enabled: true,
            enabledAt: new Date(),
            enabledBy: userId,
            subdomain,
            silverfinFirmId: current.silverfinFirmId,
        };
    } catch (error) {
        Analytics.track('integration - enabling error', {
            statusCode: error.code ?? 500,
            ...(error.message && { error: error.message }),
        });
        throw Error('Failed to enable the integration');
    }
};

/**
 * Perform a request to the silverfin-adaptor passing the OAuth code, the
 * Firm ID (Silverfin customer account) and some other settings so that the
 * adaptor can “disable” the integration by removing buttons in the Silverfin UI
 * @param code The OAuth code coming from the Silverfin callback
 * @param silverfinSettings the current Silverfin settings
 */
const _disable = async (
    code: string,
    silverfinSettings: SilverfinSettings
): Promise<SilverfinSettings> => {
    const { subdomain }: PreCollectData = JSON.parse(
        storage.get(StorageKeys.SILVERFIN_STATE)
    );

    try {
        await axios.post(
            Integrations.Silverfin.getUrls().UNREGISTER_PENNEO_URL,
            {
                code,
                firmId: silverfinSettings.silverfinFirmId,
                hostname: _getHostname(subdomain),
                redirectUri: Integrations.Silverfin.getUrls().REDIRECT_URI,
            }
        );

        return {
            name: silverfinSettings.name,
            enabled: false,
            enabledAt: null,
            enabledBy: null,
            subdomain: null,
            silverfinFirmId: null,
        };
    } catch (error) {
        Analytics.track('integration - disabling error', {
            statusCode: error.code ?? 500,
            ...(error.message && { error: error.message }),
        });
        throw Error('Failed to disable the integration');
    }
};

const _toggleIntegration = async (
    code: string,
    silverfinSettings: SilverfinSettings
): Promise<SilverfinSettings> => {
    if (silverfinSettings.enabled) {
        return await _disable(code, silverfinSettings);
    } else {
        return await _enable(code, silverfinSettings);
    }
};

interface PreCollectData {
    userId: number;
    subdomain: string;
}

export const SilverfinActions = {
    init: async (userId: number, subdomain: string) => {
        try {
            const redirectionState: PreCollectData = {
                userId,
                subdomain,
            };

            storage.set(
                StorageKeys.SILVERFIN_STATE,
                JSON.stringify(redirectionState)
            );

            const response = await axios.post(
                Integrations.Silverfin.getUrls().INIT_AUTH_URL,
                {
                    redirectUri: Integrations.Silverfin.getUrls().REDIRECT_URI,
                    hostname: _getHostname(subdomain),
                }
            );

            window.location.href = response.data;
        } catch (error) {
            console.log(error);
        }
    },

    collect:
        (code: string, silverfinFirmId: string | null): AppThunk =>
        async (dispatch, getState) => {
            const silverfinSettings = getState().settings.data.customer
                .silverfin as SilverfinSettings;

            try {
                const silverfinSettingsUpdated = await _toggleIntegration(
                    code,
                    {
                        ...silverfinSettings,
                        silverfinFirmId,
                    }
                );

                await dispatch(
                    updateCustomerSettings(
                        CustomerSettingKeys.silverfin,
                        silverfinSettingsUpdated
                    ) as any
                );

                storage.clear(StorageKeys.SILVERFIN_STATE);
            } catch (error) {
                console.log('Error: ' + error.message);
            }
        },
};
