import { ReactRouter } from 'types/Router';
import {
    fetchInvitationDetails,
    acceptFolderInvitation,
    InvitationDetails,
} from './api';
import {
    navigateToProfileSelect,
    navigateToErrorPage,
    navigateToArchive,
} from './redirects';

export const validateInvitation = async (
    challengeKey: string,
    router: ReactRouter
): Promise<InvitationDetails | undefined> => {
    const result = await fetchInvitationDetails(challengeKey);

    if (result.error || (result.data && result.data.isExpired)) {
        const errorType =
            result.error?.status === 404 || result.data?.isExpired
                ? 'expired'
                : 'error';

        navigateToErrorPage(router, challengeKey, errorType);

        return;
    }

    return result.data;
};

export const validateAndHandleInvitation = async ({
    challengeKey,
    router,
    profileSelector,
}: {
    challengeKey: string;
    router: ReactRouter;
    profileSelector?: boolean;
}): Promise<void> => {
    const invitationDetails = await validateInvitation(challengeKey, router);

    if (!invitationDetails) return;

    if (profileSelector) {
        navigateToProfileSelect(
            router,
            challengeKey,
            invitationDetails.folder.folderName
        );
    } else {
        acceptAndHandleInvitation({ challengeKey, router });
    }
};

export const acceptAndHandleInvitation = async ({
    challengeKey,
    router,
}: {
    challengeKey: string;
    router: ReactRouter;
}): Promise<boolean> => {
    const result = await acceptFolderInvitation(challengeKey);

    if (result.data || result.error?.status === 409) {
        navigateToArchive(router);

        return true;
    }

    const errorType = result.error?.status === 404 ? 'expired' : 'error';

    navigateToErrorPage(router, challengeKey, errorType);

    return false;
};
