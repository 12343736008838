import React, { useCallback, useEffect, useState } from 'react';
import { RadioButton } from 'Common/components';
import { i18n } from 'Language';
import HelpTooltip from 'Common/components/HelpTooltip';
import { ValidationType } from './RecipientRestrictions';
import { PersonIdentifierType } from '../../../EID/types';

type Props = {
    validationType: ValidationType;
    sensitiveData: boolean;
    accessControl?: boolean;
    handleAccessControlChange: (value: boolean) => void;
    forceDisabled?: 'accessControl' | 'all';
};

export const RecipientViewingRestrictionsEditor = ({
    validationType,
    sensitiveData,
    accessControl,
    handleAccessControlChange,
    forceDisabled,
}: Props) => {
    /**
     * If we are in 'viewing' restrictions, Access control or a sensitive casefile
     * will be enough to mark as 'restricted.' On 'signing', it's a bit more complex.
     */
    const [isRestricted, setIsRestricted] = useState(!!accessControl);
    const [restrictedTooltip, setRestrictedTooltip] = useState('');
    const [notRestrictedTooltip, setNotRestrictedTooltip] = useState('');

    const hasSMS = useCallback(
        () => validationType === PersonIdentifierType.SMS,
        [validationType]
    );

    const handleRestrictedChange = (newValue: boolean) => {
        setIsRestricted(newValue);
        handleAccessControlChange(newValue);
    };

    useEffect(() => {
        const mustBeRestricted = hasSMS() || sensitiveData;

        if (mustBeRestricted) {
            setIsRestricted(true);

            if (!isRestricted) {
                handleAccessControlChange(true);
            }
        }
    }, [sensitiveData, hasSMS, isRestricted]);

    useEffect(() => {
        let restricted = '';
        let notRestricted = '';

        if (sensitiveData) {
            restricted =
                'Identification is required because this casefile contains sensitive data.';
        } else {
            switch (validationType) {
                case 'none':
                    notRestricted =
                        'When identification is not required, anyone can view the documents through the signing link.';
                    restricted =
                        'When identification is required, an eID must be used to open the link.';
                    break;
                case 'ssn':
                case 'vatin':
                    notRestricted =
                        'When identification is not required, anyone can view the documents through the signing link.';
                    restricted =
                        'When identification is required, only the person with an eID matching the signing permissions can open the signing link.';
                    break;
                case 'sms':
                    restricted =
                        'When SMS verification is enabled, only the person with the matching phone number can view the documents.';
                    break;
            }
        }

        setRestrictedTooltip(restricted);
        setNotRestrictedTooltip(notRestricted);
    }, [sensitiveData, validationType]);

    /**
     * Since access control has many scenarios in which it can be locked,
     * we make it disabled in a separate constant
     */
    const isRestrictedDisabled =
        forceDisabled === 'all' ||
        forceDisabled === 'accessControl' ||
        sensitiveData ||
        hasSMS();

    return (
        <div className="recipient-restrictions mb-4 border-solid border-0 border-b border-neutral-100">
            <label>{i18n`Require identification to view the documents`}</label>

            <div className="restrictions">
                <RadioButton
                    className="my-4"
                    checked={!isRestricted}
                    disabled={isRestrictedDisabled}
                    onChange={() => handleRestrictedChange(false)}
                    name="viewing-not-restricted"
                    label={i18n`Not required`}>
                    {notRestrictedTooltip !== '' ? (
                        <>
                            &nbsp;
                            <HelpTooltip text={i18n`${notRestrictedTooltip}`} />
                        </>
                    ) : undefined}
                </RadioButton>
                <RadioButton
                    className="my-4"
                    checked={isRestricted}
                    disabled={isRestrictedDisabled}
                    onChange={() => handleRestrictedChange(true)}
                    name="viewing-restricted"
                    label={i18n`Required`}>
                    {restrictedTooltip !== '' ? (
                        <>
                            &nbsp;
                            <HelpTooltip text={i18n`${restrictedTooltip}`} />
                        </>
                    ) : undefined}
                </RadioButton>
            </div>
        </div>
    );
};
