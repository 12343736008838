import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import Modal from 'Common/components/Modal';
import Card from 'Common/components/Card';
import Loader from 'Common/components/Common/Loader';
import Button from 'Common/components/Button';
import { images, Integrations } from 'Constants';
import { i18n } from 'Language';

import './customer-integrations-modal.scss';

// Redux
import { connect } from 'react-redux';
import { SilverfinActions } from 'Auth/components/CustomerIntegrations/redux/actions';
import { SilverfinSettings } from 'Settings/redux/types';
import { AppDispatch, ReduxState } from 'Store';
import UserActions from '../../actions/UserActionCreators';
import UserStore from 'Auth/stores/UserStore';
import { UserEntity } from '../../../types/User';
import { Router } from 'react-router';

type AppContextType = {
    router: Router;
};

type Props = {
    params: any;
    dispatch: AppDispatch;
    silverfin: SilverfinSettings;
    isFetching: boolean;
    location: any;
    userId: number;
    customerId: number;
};

type State = {
    showConfirmation: boolean;
    customerSubdomain: string;
    enabledById?: number;
    enabledBy?: UserEntity;
};

class CustomerIntegrationsModal extends React.Component<Props, State> {
    static contextTypes = {
        router: PropTypes.object.isRequired,
    };

    context!: AppContextType;

    constructor(props: Props) {
        super(props);

        this.state = {
            showConfirmation: false,
            customerSubdomain: '',
        };
    }

    closeModal = () => {
        this.context.router.push({
            name: 'company-settings',
        });
    };

    toggleSilverfinIntegration = () => {
        const { enabled, subdomain } = this.props.silverfin;
        const subdomainToToggle = enabled
            ? (subdomain as string)
            : this.state.customerSubdomain;

        SilverfinActions.init(this.props.userId, subdomainToToggle);
    };

    renderLogo() {
        return <img src={`${images}/silverfin_logo.png`} />;
    }

    renderName() {
        return <h3 className="name">{this.props.silverfin.name.toString()}</h3>;
    }

    renderDate() {
        const enabledAt = this.props.silverfin.enabledAt;

        if (enabledAt) {
            return <div>{`${i18n`Added`} ${moment(enabledAt).fromNow()}`}</div>;
        }
    }

    isIntegrationEnabledByOtherUser() {
        const { silverfin, userId } = this.props;

        return silverfin.enabled && silverfin.enabledBy !== userId;
    }

    componentDidMount() {
        UserStore.addChangeListener(this.onUserStoreChange);

        if (this.isIntegrationEnabledByOtherUser()) {
            this.loadEnabledBy();
        }
    }

    loadEnabledBy() {
        const { silverfin, customerId } = this.props;

        if (silverfin.enabledBy) {
            this.setState({ enabledById: silverfin.enabledBy });
            UserActions.fetchUser(silverfin.enabledBy, customerId);
        }
    }

    onUserStoreChange = () => {
        this.setState({
            enabledBy:
                this.state.enabledById &&
                UserStore.getUser(this.state.enabledById),
        });
    };

    renderMessage() {
        const enabler = this.state.enabledBy;

        if (!this.isIntegrationEnabledByOtherUser()) {
            return;
        }

        let message = i18n`To disable it please contact `;

        if (enabler?.fullName && enabler?.email) {
            message += i18n`${enabler.fullName} at ${enabler.email} or `;
        }

        message += i18n`your administrator.`;

        return (
            <div className="integration-message">
                {i18n`The integration was not enabled by you.`}
                <br />
                {message}
            </div>
        );
    }

    renderState() {
        const { silverfin } = this.props;
        const status = silverfin.enabled ? 'active' : 'inactive';

        return (
            <span className={`integration-status-${status}`}>
                <i className="fas fa-circle" />
                &nbsp;
                {i18n(status)}
            </span>
        );
    }

    renderActions() {
        const { silverfin } = this.props;
        const { showConfirmation } = this.state;

        if (showConfirmation) {
            return this.renderDisableConfirmation();
        }

        return silverfin.enabled
            ? this.renderDisableButton()
            : this.renderEnableButton();
    }

    renderDisableConfirmation() {
        return (
            <>
                <div className="confirmation-question">
                    {i18n`Are you sure you want to disable it?`}
                </div>
                <br />
                <Button
                    theme="red"
                    variant="outline"
                    onClick={this.toggleSilverfinIntegration}>
                    {i18n('Yes, disable')}
                </Button>
                <Button
                    theme="gray"
                    onClick={() => this.setState({ showConfirmation: false })}>
                    {i18n('Cancel')}
                </Button>
            </>
        );
    }

    renderEnableButton() {
        const { customerSubdomain } = this.state;

        return (
            <Button
                theme="green"
                icon="far fa-link"
                disabled={customerSubdomain.length === 0}
                onClick={this.toggleSilverfinIntegration}>
                {i18n('Enable')}
            </Button>
        );
    }

    renderDisableButton() {
        const { silverfin, userId } = this.props;
        const isDisabled = silverfin.enabledBy !== userId;

        return (
            <Button
                theme="red"
                variant="outline"
                icon="far fa-unlink"
                disabled={isDisabled}
                onClick={() => this.setState({ showConfirmation: true })}>
                {i18n('Disable')}
            </Button>
        );
    }

    onSubdomainChanged = (e) => {
        this.setState({ customerSubdomain: e.target.value });
    };

    renderHostnameInput() {
        const { enabled, subdomain } = this.props.silverfin;

        if (this.state.showConfirmation) {
            return;
        }

        return (
            <div className="hostname-switcher">
                <label>
                    {enabled ? (
                        subdomain
                    ) : (
                        <input
                            type="text"
                            value={this.state.customerSubdomain}
                            placeholder={i18n`your subdomain`}
                            onChange={this.onSubdomainChanged}
                        />
                    )}
                    .{Integrations.Silverfin.baseDomain}
                </label>
            </div>
        );
    }

    render() {
        const { isFetching } = this.props;

        return (
            <Modal
                xlarge={true}
                header={<h3 className="mt0">{i18n`Manage Integrations`}</h3>}
                onClose={this.closeModal}>
                <div className="user-integrations">
                    {isFetching ? (
                        <Loader />
                    ) : (
                        <div className="user-integrations-container">
                            <Card enableHover theme="darkblue">
                                <div className="user-integration">
                                    <div className="user-integration-logo">
                                        {this.renderLogo()}
                                    </div>

                                    <div className="user-integration-details">
                                        {this.renderName()}
                                        {this.renderDate()}
                                        {this.renderState()}
                                        {this.renderMessage()}
                                    </div>

                                    <div className="user-integration-actions">
                                        {this.renderHostnameInput()}
                                        {this.renderActions()}
                                    </div>
                                </div>
                            </Card>
                        </div>
                    )}
                </div>
            </Modal>
        );
    }
}

export default connect((state: ReduxState) => ({
    silverfin: state.settings.data.customer.silverfin,
    isFetching: state.settings.isFetching,
    error: state.settings.error,
}))(CustomerIntegrationsModal);
