import React from 'react';
import { i18n } from 'Language';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { CasefileState } from './redux/types';
import { DocumentEntity } from 'types/Document';
import FullScreenModal from 'Common/components/FullScreenModal';
import { connect } from 'react-redux';
import StatusBanner from './StatusBanner';

import Tooltip from 'Common/components/Tooltip';
import { ReduxState } from 'Store';
import { getDocumentStatus } from './statusCodes';

import './casefile-document-details.scss';
import Button from 'Common/components/Button';
import { checkPDFSupport, PdfDocument } from 'Common/components/PdfPreviewer';
import { getUserAccess } from 'Casefiles/utils';
import useCustomerRedirect from 'Common/hooks/useCustomerRedirect';
import { ReactRouter } from 'types/Router';
import {
    ARCHIVE_ROUTE_NAMES,
    PERSONAL_ARCHIVE_ROUTE_NAMES,
    Routes,
} from 'Constants';
import Analytics from 'Common/Analytics';
import { temporaryPDFDownload } from 'Casefiles/utils/file';
import { fetchDocumentContent } from 'Casefiles/components/casefiles2/utils';
import { Router } from 'react-router';

type AppContextType = {
    router: Router;
};

type Props = {
    casefile: CasefileState;
    document: DocumentEntity;
    params: {
        documentId: string;
    };
    documentId: number;
    goBackRoute?: {
        name: string;
        params?: {
            [key: string]: any;
        };
    };
    router: ReactRouter;
};

type State = {
    pdfData?: Blob;
    isNativePDFSupported: boolean;
};

const CasefileDocumentDetailsWrapper = (props: Props) => {
    const { isSignersArchive: shouldRedirect } = getUserAccess();

    useCustomerRedirect({
        shouldRedirect,
        router: props.router,
        redirectRoute: Routes.defaultV2RouteName,
        shouldRedirectNonCustomer: false,
    });

    return <CasefileDocumentDetails {...props} />;
};

class CasefileDocumentDetails extends React.Component<Props, State> {
    static contextTypes = {
        router: PropTypes.object,
    };

    context!: AppContextType;

    constructor(props: Props) {
        super(props);

        this.state = {
            isNativePDFSupported: checkPDFSupport(),
        };
    }

    componentDidMount = () => {
        const { document: data, casefile } = this.props;
        const { isNativePDFSupported } = this.state;

        if (!data || !data.id) {
            return false;
        }

        if (!isNativePDFSupported) {
            this.getDocumentPreview(data.id, casefile.data.id);
        }
    };

    getDocumentPreview = async (documentId, caseId) => {
        const blob = caseId
            ? await fetchDocumentContent(documentId)
            : await temporaryPDFDownload(documentId);

        this.setState({ pdfData: blob });
    };

    getBackLinkRoute = () => {
        if (this.props.goBackRoute) {
            return this.props.goBackRoute;
        }

        const { isSignersArchive } = getUserAccess();

        return {
            name: isSignersArchive
                ? PERSONAL_ARCHIVE_ROUTE_NAMES.casefileDetailsRoute
                : ARCHIVE_ROUTE_NAMES.casefileDetailsRoute,
            params: this.props.params,
        };
    };

    trackDocumentDownload = () => {
        const { isSignersArchive } = getUserAccess();

        Analytics.track('archive - download document', {
            isSignersArchive,
        });
    };

    closeModal = () => {
        this.context.router.push(this.getBackLinkRoute());
    };

    render() {
        const { casefile, document: data } = this.props;
        const { pdfData, isNativePDFSupported } = this.state;

        if (casefile.isLoaded === false) {
            return false;
        }

        if (!data || !data.id) {
            return false;
        }

        const status = getDocumentStatus(data.status);

        return (
            <div className="casefile-document-details">
                <FullScreenModal
                    displayModal={true}
                    onCancel={this.closeModal}
                    header={
                        <div className="casefile-document-details-header">
                            <div className="casefile-document-details-type text-blue">
                                <i className="far fa-tag" />
                                &nbsp;
                                {data.type ? (
                                    <span>{data.type.name}</span>
                                ) : (
                                    <span>{i18n`Document`}</span>
                                )}
                            </div>
                            <div className="casefile-document-details-actions pull-right">
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={`/document/pdf/${data.id}`}>
                                    <Button
                                        theme="blue"
                                        className="mr"
                                        variant="outline"
                                        icon="far fa-expand-arrows-alt"
                                        renderIconLeft={true}>
                                        {i18n`View PDF`}
                                    </Button>
                                </a>
                                <a
                                    download=""
                                    rel="noopener noreferrer"
                                    href={`/document/pdf/${data.id}/attachment`}
                                    onClick={this.trackDocumentDownload}>
                                    <Button
                                        theme="blue"
                                        variant="outline"
                                        icon="far fa-arrow-alt-to-bottom"
                                        renderIconLeft={true}>
                                        {i18n`Download PDF`}
                                    </Button>
                                </a>
                            </div>
                            <h1 className="casefile-document-details-title">
                                {data.title}
                            </h1>
                            <div className="casefile-document-details-key">
                                <span className="casefile-document-details-key-label">
                                    {i18n`Document key`}&nbsp;
                                    <Tooltip
                                        direction="down"
                                        showArrow={true}
                                        text={
                                            <span className="casefile-document-details-key-tooltip">
                                                {i18n`The Penneo document key is the "fingerprint" of the document.`}
                                                <br />
                                                <br />
                                                {i18n`After your files are processed by Penneo, this key is added to every page on the right-hand side and it's used for verification of validity`}
                                            </span>
                                        }>
                                        <i className="far text-blue fa-question-circle" />
                                    </Tooltip>
                                </span>
                                <span className="casefile-document-details-key-id">
                                    {data.documentId}
                                </span>
                            </div>

                            {data.signable ? (
                                <StatusBanner status={status} />
                            ) : (
                                <StatusBanner
                                    status={{
                                        name: 'Attachment',
                                        details:
                                            'This document does not get signed',
                                        icon: 'paperclip',
                                        color: 'gray',
                                    }}
                                />
                            )}
                        </div>
                    }>
                    <PdfDocument
                        isNativePDFSupported={isNativePDFSupported}
                        pdfData={
                            isNativePDFSupported
                                ? `/document/pdf/${data.id}/inline`
                                : pdfData
                        }
                    />
                </FullScreenModal>
            </div>
        );
    }
}

export default connect((state: ReduxState, props: Props) => {
    // Safe getter
    const documents = get(state, 'caseFileDetails.casefile.data.documents', [
        {},
    ]);

    return {
        casefile: get(state, 'caseFileDetails.casefile'),
        document: documents.find(
            (d) => d.id === Number(props.params.documentId)
        ),
        documentId: Number(props.params.documentId),
    };
})(CasefileDocumentDetailsWrapper);
