import PropTypes from 'prop-types';
import React from 'react';
import AuthStore from '../../stores/AuthStore';
import AuthActions from '../../actions/AuthActionCreators';
import { TranslationStore } from 'Language';
import { Languages } from 'Language/Constants';
import { env, Platforms } from 'Constants';
import { Intent } from 'OpenID/Constants';
import { isMethodAvailableToLogIn, LOGIN_METHODS } from 'EID';
import { debug } from 'Core';
import { i18n } from 'Language';
import { getDecodedPenneoToken } from 'Auth/utils/jwt';
import launchDarkly, { Flags } from 'Common/LaunchDarkly';
import type { Incident } from 'Common/components/StatusPageMessage/StatusPageMessage';

// Components
import PasswordLoginWidget from '../Password/PasswordLoginWidget';
import EIDWidget from '../Common/EIDWidget';
import StatusNotification from 'Common/components/Common/StatusNotification';
import AccountSetupError from 'Common/components/AccountSetupError';
import LoginUtils, { DefaultLoginRoute } from 'Auth/utils/login';
import { ERRORS } from 'Core/Errors';

import PenneoLogoWhite from 'resources/images/penneo-white.png';
import LoginFooter from './children/LoginFooter';
import LoginLanguageSelector from './children/LoginLanguageSelector';

import {
    fetchSummary,
    fetchIncidentIntervalDuration,
} from 'Common/components/StatusPageMessage/status-page-api';

import { Router } from 'react-router';

type AppContextType = {
    router: Router;
};

const ALLOWED_ERRORS = [ERRORS.AUTH_ACCOUNT_NOT_SETUP];

export type Props = {
    children?: JSX.Element;
    location: {
        query: {
            allowed?: string;
            edge?: 'true';
        };
    };
    logo: string;
};

type State = {
    mobile: boolean;
    isFetching: boolean;
    isAuthenticated: boolean;
    isValidatingIdentity: boolean;
    localAuthenticating: boolean;
    localCredentials?: {
        key: string;
        secret: string;
    };
    authError?: {
        errorCode: ERRORS;
        resendToken: string;
    };
    language: Languages;
    incidents: Incident[];
};

export default class LoginPage extends React.Component<Props, State> {
    static contextTypes = {
        router: PropTypes.object,
    };

    context!: AppContextType;

    electron: any = null;
    validateMethods: any = null;

    interval; // interval variable, used to clear interval when component is unmounted.
    mounted: boolean | undefined = undefined;

    constructor(props) {
        super(props);

        const { query } = this.props.location;

        this.validateMethods = query.allowed?.replace(/'/g, '').split(',');
        this.state = {
            mobile: false,
            isFetching: false,
            isAuthenticated: false,
            isValidatingIdentity: !!this.validateMethods,
            localAuthenticating: false,
            language: TranslationStore.getLanguage(),
            incidents: [],
        };
    }

    async componentDidMount() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();

        this.handleElectron();

        AuthStore.addChangeListener(this.onChange);
        TranslationStore.addChangeListener(this.onChange);
        this.interval = setInterval(
            this.fetchIncidents,
            fetchIncidentIntervalDuration
        );
        this.mounted = true;
        await this.fetchIncidents();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);

        AuthStore.removeChangeListener(this.onChange);
        TranslationStore.removeChangeListener(this.onChange);

        this.mounted = false;
        clearInterval(this.interval);
    }

    fetchIncidents = async () => {
        if (!this.mounted) return;

        const incidentData = await fetchSummary();

        this.setState({ incidents: incidentData });
    };

    handleElectron() {
        if (env.platform !== Platforms.DESKTOP) {
            return false;
        }

        this.electron = (window as any).require('electron');

        if (this.electron) {
            this.attachElectronEvents();
            this.electron.ipcRenderer.send('search-for-credential-file');
        }
    }

    attachElectronEvents = () => {
        this.electron.ipcRenderer.on(
            'found-credential-file',
            this.loginAPICredentials
        );
    };

    removeElectronEvents = () => {
        if (!this.electron) {
            return false;
        }

        // Remove electron listeners
        this.electron.ipcRenderer.removeListener(
            'found-credential-file',
            this.loginAPICredentials
        );
    };

    loginAPICredentials = (_, credentials: State['localCredentials']) => {
        if (!credentials) {
            return false;
        }

        this.setState({ localCredentials: credentials });
    };

    handleLoginLocal = () => {
        this.setState({ localAuthenticating: true });
        AuthActions.authenticate(
            '/token/wsse',
            this.state.localCredentials,
            {}
        );
    };

    disableLoginLocal = () => {
        const { localCredentials, ...rest } = this.state;

        this.setState(rest);
    };

    componentDidUpdate() {
        const { isFetching, isAuthenticated } = this.state;

        const userSwitcherEnabled = launchDarkly.variation(
            Flags.USER_SWITCHER_ENABLED
        );

        if (!isFetching && isAuthenticated) {
            const token = getDecodedPenneoToken();

            if (token && token.u_qty > 1 && userSwitcherEnabled) {
                return this.redirectToProfileSelector();
            }

            return this.redirectAfterLogin();
        }
    }

    onChange = () => {
        this.setState({
            isFetching: AuthStore.isFetching(),
            isAuthenticated: AuthStore.isAuthenticated(),
            authError: AuthStore.getAuthError(),
            language: TranslationStore.getLanguage(),
        });
    };

    redirectAfterLogin = () => {
        let loginURL = LoginUtils.getDefaultRoute();

        debug.log('Login to:', loginURL);

        this.handleRedirect(loginURL);
    };

    redirectToProfileSelector = () => {
        return this.context.router.push({
            name: 'profile-select',
        });
    };

    // @duplicate: OpenIDCallbackContainer.jsx
    handleRedirect = (path: DefaultLoginRoute) => {
        if (path.type === 'route') {
            return this.context.router.replace(path.route);
        }

        return window.location.replace(path.url);
    };

    renderChildren = (children: JSX.Element) => {
        return React.cloneElement(children, {
            error: this.state.authError,
            redirect: this.redirectAfterLogin,
        });
    };

    /**
     * Listens to Browser Resizing events.
     * Sets mobile state
     */
    handleResize = () => {
        if (window.innerWidth < 480) {
            this.setState({ mobile: true });
        } else {
            this.setState({ mobile: false });
        }
    };

    renderError() {
        const { authError } = this.state;

        if (!authError) {
            return false;
        }

        const { errorCode, resendToken } = authError;

        // Allow only certain whitelisted codes
        switch (errorCode) {
            case ERRORS.AUTH_ACCOUNT_NOT_SETUP:
                return (
                    <div className="login-widget">
                        <AccountSetupError
                            resendToken={resendToken}
                            errorCode={errorCode}
                            onBack={this.resetError}
                        />
                    </div>
                );
            default:
                return false;
        }
    }

    resetError = () => {
        return AuthActions.clearAuthError();
    };

    renderErrorNotification = () => {
        const lastNotification =
            this.state.incidents?.length > 0 &&
            this.state.incidents[this.state.incidents.length - 1];

        return (
            lastNotification && (
                <a
                    className="login-status"
                    href={lastNotification.shortlink}
                    target="_blank"
                    rel="noopener noreferrer">
                    <h2>{lastNotification.name}</h2>
                </a>
            )
        );
    };

    renderContent() {
        const { children } = this.props;
        const { isValidatingIdentity } = this.state;

        if (children) {
            return (
                <div className="login-widget">
                    {this.renderChildren(children)}
                </div>
            );
        }

        const intent = isValidatingIdentity ? Intent.VALIDATE_ID : Intent.LOGIN;

        return (
            <div
                className={`auth-methods ${
                    isValidatingIdentity ? 'auth-validate' : ''
                }`}>
                {this.renderErrorNotification()}
                {!isValidatingIdentity && <PasswordLoginWidget />}
                <EIDWidget
                    intent={intent}
                    methods={this.getLoginMethods()}
                    title={
                        isValidatingIdentity
                            ? i18n`Confirm your identity`
                            : i18n`Log in with a secure Electronic ID`
                    }
                    allowed={this.validateMethods || null}
                />
            </div>
        );
    }

    getLoginMethods() {
        const { isValidatingIdentity } = this.state;

        if (isValidatingIdentity) {
            return LOGIN_METHODS;
        }

        // Methods behind a LD flag can be shown by adding ?edge=true in the url.
        // This is necessary because in the login page we are not able to
        // target users by customer with LD (as they are not logged in yet).
        const { query } = this.props.location;
        const canShowExperimentalMethods = query.edge === 'true';

        return LOGIN_METHODS.filter((method) => {
            // This can be true only if there is no LD flag, or if the
            // LD flag is enabled for everyone...
            if (isMethodAvailableToLogIn(method)) {
                return true;
            }

            // ...otherwise the flag check will always return false,
            // and that's when we use the query param
            return canShowExperimentalMethods;
        });
    }

    render() {
        const logo = this.props.logo || PenneoLogoWhite;
        const {
            mobile,
            localCredentials,
            localAuthenticating,
            authError,
            isValidatingIdentity,
        } = this.state;

        const hasError =
            authError &&
            authError.errorCode &&
            ~ALLOWED_ERRORS.indexOf(authError.errorCode);

        return (
            <div>
                {!isValidatingIdentity && (
                    <div className="penneo-login-image-overlay" />
                )}
                <div className="penneo-login-background-overlay"></div>
                <div className="penneo-login-page">
                    {!mobile && (
                        <StatusNotification incidents={this.state.incidents} />
                    )}

                    <div className="login-widget-container">
                        <div className="login-widget-header">
                            <div className="branding-logo">
                                <img
                                    alt="Penneo branding logo"
                                    src={logo}
                                    height="24"
                                />
                            </div>

                            {!isValidatingIdentity && (
                                <LoginLanguageSelector mobile={mobile} />
                            )}

                            {localCredentials && (
                                <div className="login-widget-local">
                                    {!localAuthenticating ? (
                                        <div>
                                            {i18n`Hey! We can see you have used an old version
                                                of Penneo before.
                                                Do you want to log in using your previous session?`}

                                            <div className="cta">
                                                <a
                                                    className="success"
                                                    onClick={
                                                        this.handleLoginLocal
                                                    }>
                                                    {i18n`Yes, restore session`}
                                                </a>
                                            </div>

                                            <a
                                                className="cancel"
                                                onClick={
                                                    this.disableLoginLocal
                                                }>
                                                {i18n`Not now`}
                                            </a>
                                        </div>
                                    ) : (
                                        <div>
                                            {i18n`Restoring previous session`}
                                            ...
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>

                        {/* Render EID Login or Default Content */}
                        {hasError ? this.renderError() : this.renderContent()}

                        <LoginFooter />
                    </div>
                </div>
            </div>
        );
    }
}
