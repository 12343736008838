import { ToastContext } from 'Common/components/ToastContext';
import { useContext } from 'react';

export const useToast = () => {
    const context = useContext(ToastContext);

    if (!context) {
        console.error('ToastContext not found');

        return undefined;
    }

    return context;
};
