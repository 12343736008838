import PropTypes from 'prop-types';
import React from 'react';
import Loading from 'Common/components/Loaders/LoadingData';
import { modal } from 'Common/components/Common/Modal';
import assign from 'object-assign';
import { i18n } from 'Language';
import CustomerSettingsForm from './CustomerSettingsForm';
import { fetchSettings, fetchManagedSettings } from 'Settings/redux/actions';
import { fetchCustomer } from 'Auth/redux/customer/actions';
import { connect } from 'react-redux';
import { AppDispatch } from 'Store';
import { CustomerEntity as Customer } from 'types/Customer';
import { UserEntity as User } from 'types/User';

import CustomerStore from 'Auth/stores/CustomerStore';
import CustomerActions from 'Auth/actions/CustomerActionCreators';
import { notify } from 'react-notify-toast';
import { Router } from 'react-router';

type AppContextType = {
    router: Router;
};

type Props = {
    dispatch: AppDispatch;
    user: User;
    params: {
        customerId: string;
    };
    children: JSX.Element;
    route: any;
    routes: any;
    location: {
        pathname: string;
    };
    modal: JSX.Element;
};

type State = {
    customer?: Customer;
    action?: string;
    customerBackup?: Customer;
};

class CustomerContainer extends React.Component<Props, State> {
    static contextTypes = {
        router: PropTypes.object.isRequired,
    };

    context!: AppContextType;

    state: State = {};

    componentDidMount() {
        CustomerStore.addChangeListener(this.onChange);
        this.loadData();
    }

    componentWillUnmount() {
        const { customer } = this.state;

        // Reset any unsaved changes in current customer
        if (customer && CustomerStore.getCustomerUnsavedChanges(customer.id)) {
            CustomerActions.resetCustomerChanges(customer.id);
        }

        CustomerStore.removeChangeListener(this.onChange);
    }

    // Display child routes in modal
    componentWillUpdate(nextProps: Props, nextState: State) {
        // If customer is saved successfully.
        if (
            !nextProps.children &&
            nextState.action === 'CUSTOMER_UPDATE_SUCCESS' &&
            this.state.action === 'CUSTOMER_UPDATE_REQUEST'
        ) {
            notify.show(
                <span>
                    {i18n`The changes were saved`}&nbsp;
                    <i className="fa fa-check" />
                </span>,
                'success',
                3000
            );
        }

        // If customer is saved successfully.
        if (
            !nextProps.children &&
            nextState.action === 'CUSTOMER_UPDATE_ERROR' &&
            this.state.action === 'CUSTOMER_UPDATE_REQUEST'
        ) {
            notify.show(
                <span>
                    {i18n`An error occurred, please try again`}&nbsp;
                    <i className="fa fa-times" />
                </span>,
                'error',
                3000
            );
        }

        if (!nextProps.children) {
            return modal.hide();
        }

        // When the application transitions from modal closed to
        // modal open. Reset the store errors, to avoid displaying
        // errors in modals from failures in the main form.
        if (!this.props.children && nextProps.children) {
            CustomerActions.resetError();
        }

        if (nextProps.children && !nextState.customer) {
            return this.openLoadingModal();
        }

        this.openModal(nextProps.children, nextState);
    }

    componentDidUpdate = async (prevProps: Props) => {
        const { customerId } = this.props.params;
        const { customerId: prevCustomerId } = prevProps.params;

        if (customerId !== prevCustomerId) {
            await this.getSettings();
        }
    };

    onChange = (action: any) => {
        const customerId = this.getActiveCustomerId();

        this.setState({
            customerBackup: CustomerStore.getCustomerCopy(customerId),
            customer: CustomerStore.getCustomer(customerId),
            action,
        });
    };

    loadData = async () => {
        const { dispatch } = this.props;
        const customerId = this.getActiveCustomerId();

        await this.getSettings();
        /**
         * @NOTE
         * Fetching of the customer data is temporarily duplicated as the redux fetch function uses `v2`
         * of the endpoint whereas the flux fetch uses `v1`. The use of `v2` endpoint has been needed when the
         * temporary case file storage has been implemented, as this feature's data field exists only on `v2`.
         */
        CustomerActions.fetchCustomer(customerId);
        dispatch(fetchCustomer(customerId));
    };

    /**
     * Fetches customer settings.
     *
     * If there is a {customerId} in the URL params, most likely the customer
     * is different from user's customer and user is managing other customer's
     * settings - thus those managed settings need to be fetched.
     */
    getSettings = async () => {
        const { dispatch, params } = this.props;

        await dispatch(fetchSettings());

        if (params.customerId) {
            dispatch(fetchManagedSettings(Number(params.customerId)));
        }
    };

    /**
     *  Returns the ID for the customer being currently managed.
     *  If there's no customerId specified in the URL, picks the current user's customer.
     *
     *  Current user's customer is always the first one in the array of {customerIds}
     */
    getActiveCustomerId = () => {
        const { user, params } = this.props;

        return params.customerId
            ? Number(params.customerId)
            : user.customerIds[0];
    };

    closeModal = () => {
        const { routes } = this.props;
        const name = routes[routes.length - 2].name;

        this.context.router.push({
            name,
            params: {
                customerId: this.getActiveCustomerId(),
            },
        });
    };

    openLoadingModal = () => {
        modal.show({ body: <Loading /> });
    };

    openModal = (component: JSX.Element, state: State) => {
        const _props = {
            close: this.closeModal,
            user: this.props.user,
            customer: state.customer,
            action: state.action,
            updateCustomer: this.updateCustomer,
        };

        const config = {
            title: null,
            body: React.cloneElement(component, _props),
            onClose: this.closeModal,
            buttons: null,
            preventClose: false,
        };

        modal.show(config);
    };

    updateCustomer = (customer: Customer) => {
        const state = assign({}, this.state.customer, customer);

        CustomerActions.updateCustomer(state);
    };

    saveCustomer = () => {
        const { customer } = this.state;

        if (customer) {
            CustomerActions.persistCustomer(customer.id, customer);
        }
    };

    render() {
        const { customer, customerBackup, action, ...restOfState } = this.state;
        const { user, children, location, modal } = this.props;
        const isSaving = !children && action === 'CUSTOMER_UPDATE_REQUEST';

        if (!customer || !customerBackup) {
            return <Loading />;
        }

        return (
            <>
                {modal &&
                    React.cloneElement(modal, {
                        userId: user.id,
                        customerId: customer.id,
                        onClose: this.closeModal,
                    })}
                <CustomerSettingsForm
                    user={user}
                    pathname={location.pathname}
                    isSaving={isSaving}
                    updateCustomer={this.updateCustomer}
                    saveCustomer={this.saveCustomer}
                    customer={customer}
                    customerBackup={customerBackup}
                    {...restOfState}
                />
            </>
        );
    }
}

export default connect()(CustomerContainer);
