import React from 'react';

// @see: https://github.com/YouCanBookMe/react-datetime
import DateTime from 'react-datetime';
import moment from 'moment';

import TooltipRequired from 'Common/components/TooltipRequired';
import classnames from 'classnames';

import './react-datetime.scss';

type Props = {
    className?: string;
    onChange: Function;
    label?: string | Object;
    name?: string;
    placeholder?: string;
    inline?: boolean;
    required?: boolean;
    disabled?: boolean;
    value?: number;
};

export default class DateInput extends React.Component<Props> {
    static defaultProps = {
        value: '',
    };

    handleChange = (value) => {
        if (typeof value === 'string') {
            return false;
        }

        this.props.onChange(value.unix());
    };

    render() {
        // HTML Properties
        const { name, placeholder, disabled, required, inline } = this.props;

        // Data
        const { label } = this.props;

        const className = classnames('input', this.props.className, {
            'inline-block': inline,
        });

        return (
            <div className={className}>
                {label && (
                    <label>
                        <>
                            {label}
                            {required && <TooltipRequired />}
                        </>
                    </label>
                )}

                <DateTime
                    value={
                        this.props.value ? moment.unix(this.props.value) : ''
                    }
                    onChange={this.handleChange}
                    timeFormat={'HH:mm'}
                    timeConstraints={{
                        minutes: { min: 0, max: 59, step: 15 },
                    }}
                    inputProps={{
                        className: className,
                        placeholder: placeholder,
                        disabled: disabled,
                        name: name,
                        required: required,
                    }}
                />
            </div>
        );
    }
}
