import React, { useEffect, useState } from 'react';
import { i18n } from 'Language';
import ProfilesList from 'Common/components/ProfileSelector/ProfilesList';
import { ProfileItem } from 'Auth/redux/profiles/types';
import { UserEntity } from 'types/User';
import { images } from 'Constants';
import { connect } from 'react-redux';
import { ReduxState, dispatch } from 'Store';
import { fetchProfiles } from 'Auth/redux/profiles/actions';

import { Link } from 'react-router';
import Button from 'Common/components/Button';

import './profile-selector.scss';
import Loader from '../Common/Loader';
import {
    validateInvitation,
    extractChallengeKeyFromUrl,
} from 'FolderShareInvite';

type Props = {
    router: any;
    user: UserEntity;
    profiles: ProfileItem[];
    hasMultipleIdentities: boolean;
    isLoaded: boolean;
    location: { query: { redirect?: string }; state?: { folderName?: string } };
};

const logoPath = `${images}/logo-header.png`;

const MultipleIdentities = ({
    hasMultipleIdentities,
}: {
    hasMultipleIdentities: boolean;
}) => {
    if (!hasMultipleIdentities) {
        return null;
    }

    return (
        <aside className="border-0 border-y border-solid border-neutral-200 mb-4 mt-0 py-4 px-4">
            <p className="inline-grid m-0 grid-cols-[auto_minmax(95%,_1fr)]">
                <i className="fa fa-info rounded-full bg-secondary-600 text-white w-5 h-5 align-center text-center align-middle mr-2 my-1 mt-0 p-[0.3rem] text-[9px]"></i>
                <span className="text-[12px] text-neutral-600">
                    {i18n(
                        'Due to changes in provider formatting, your national eID may now be connected to multiple accounts with signed documents'
                    )}
                </span>
            </p>
        </aside>
    );
};

export function ProfileSelector(props: Props) {
    const { user, profiles, hasMultipleIdentities, router } = props;
    const [sharedFolderName, setSharedFolderName] = useState<
        string | undefined
    >(undefined);
    const [isCheckingRedirect, setIsCheckingRedirect] = useState<boolean>(true);
    const [redirectUrl, setRedirectUrl] = useState(
        props.location.query.redirect
    );

    useEffect(() => {
        const checkRedirection = async () => {
            // redirected from accept folder share invite (not part of the login flow)
            if (props.location.state?.folderName) {
                setSharedFolderName(props.location.state.folderName);
                setRedirectUrl(`${redirectUrl}?fromProfileSelect=true`);
                setIsCheckingRedirect(false);
                dispatch(fetchProfiles());

                return;
            }

            // redirected from accept folder share invite (part of the login flow)
            const challengeKey = extractChallengeKeyFromUrl(redirectUrl);

            if (challengeKey) {
                const result = await validateInvitation(challengeKey, router);

                setSharedFolderName(result?.folder.folderName);
                setRedirectUrl(`${redirectUrl}?fromProfileSelect=true`);
            }

            setIsCheckingRedirect(false);
            dispatch(fetchProfiles());
        };

        checkRedirection();
    }, []);

    const getHelpLink = () => {
        if (user.language === 'da') {
            return 'https://www.support.penneo.com/hc/da/articles/20416323901981-Valg-af-konti-ved-login-p%C3%A5-Penneo';
        } else if (user.language === 'fr') {
            return 'https://www.support.penneo.com/hc/fr/articles/20416323901981-S%C3%A9lecteur-de-compte-lors-de-la-connexion-%C3%A0-Penneo';
        }

        return 'https://www.support.penneo.com/hc/en-gb/articles/20505278550941-My-signer-can-t-sign-with-MitID-because-name-and-address-are-protected-in-the-CPR-register';
    };

    if (isCheckingRedirect) {
        return <Loader />;
    }

    return (
        <div className="profile-select-page-container">
            <div className="profile-select-penneo-logo">
                <img src={logoPath} height="55" alt="Penneo Logo" />
            </div>

            <div className="profiles-list-container">
                <h2 className="text-center text-2xl my-4 mt-8 leading-8">{i18n`Select Account`}</h2>
                {sharedFolderName && (
                    <div>
                        <p className="text-center font-semibold">
                            {i18n`${sharedFolderName} selectProfileFolderShare`}
                        </p>
                    </div>
                )}
                <ProfilesList
                    currentUser={user}
                    profiles={profiles}
                    redirectUrl={redirectUrl}
                    hasMultipleIdentities={hasMultipleIdentities}
                />
                <MultipleIdentities
                    hasMultipleIdentities={hasMultipleIdentities}
                />
                <Link
                    className="block overflow-hidden"
                    to={{
                        name: 'login-route',
                    }}>
                    <Button
                        theme="gray"
                        variant="text"
                        renderIconLeft={true}
                        icon="fas fa-chevron-left"
                        className="pull-left">
                        {i18n`Go back to login`}
                    </Button>
                </Link>
            </div>
            <div className="profile-select-links-container">
                <a
                    className="profile-select-link"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={getHelpLink()}>
                    {i18n`Read more about account selector`}
                    {`\u00A0`}
                    <i className="fas fa-external-link text-[12px]"></i>
                </a>
            </div>
        </div>
    );
}

export default connect((state: ReduxState) => ({
    profiles: state.profiles.data,
    hasMultipleIdentities: state.profiles.hasMultipleIdentities,
    isLoaded: state.profiles.isLoaded,
}))(ProfileSelector);
