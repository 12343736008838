import React from 'react';
import { Route } from 'react-router';
import { AcceptFolderShareInvite } from './Components/AcceptFolderShareInvite';
import { InviteExpired } from './Components/InvitationExpired';
import { InviteError } from './Components/InvitationError';

const userRoutes = (
    <Route>
        <Route
            name="accept-folder-share"
            path="folder/invite/show/:challengeKey"
            component={AcceptFolderShareInvite}
        />
        <Route
            name="accept-folder-share-not-found"
            path="folder/invite/show/:challengeKey/not-found"
            component={InviteExpired}
        />
        <Route
            name="accept-folder-share-error"
            path="folder/invite/show/:challengeKey/error"
            component={InviteError}
        />
    </Route>
);

export const AcceptFolderInvitationRoutes = { userRoutes };
