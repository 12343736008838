import 'babel-polyfill'; // Polyfill for ES5 Environments
import ES6Promise from 'es6-promise'; // polyfill
import { enableES5 } from 'immer';
import { sentry } from './Common/SentryLogger';
import Routing from './Common/Routing';
import amplitude from './Common/Amplitude';
import launchDarkly from './Common/LaunchDarkly';
import { env, Platforms } from './Constants';
import { getPlatform } from 'utils/misc';

// Import Vega UI React Styles
import '@penneo/vega-ui-react/styles.css';

// Import stylesheet
import './Common/styles/_tailwind.css';
import './Common/styles/main.scss';

// Add shim for window.location.origin
import 'location-origin';
import hideConsoleMessages from 'utils/console';
import ReactDOM from 'react-dom/client';

ES6Promise.polyfill();

// Initialize Logging Clients
const initLogging = () => {
    sentry.install();
    amplitude.init();
};

const bootstrap = Promise.all([
    getPlatform(), // Determine application platform
    launchDarkly.init(), // Initialize Feature Flag Client
]);

// Render React Router
bootstrap
    .then((response) => {
        const [platform = Platforms.WEB] = response;

        env.platform = platform;

        // Initialize routing for platform, in target DOM element.
        const target = ReactDOM.createRoot(
            document.getElementById('main') as HTMLElement
        );

        initLogging();
        // @see https://immerjs.github.io/immer/docs/installation#pick-your-immer-version
        enableES5();

        Routing.initRouter(platform, target);
    })
    .catch((e) => {
        throw e;
    });

// Support HMR
if ((module as any).hot) {
    (module as any).hot.accept('./Common/Routing', () => {
        const HotRouting = require('./Common/Routing').default;
        // Initialize routing for platform, in target DOM element.
        let target = document.getElementById('main');

        HotRouting.initRouter(env.platform, target);
    });

    hideConsoleMessages([
        {
            marker: 'react-router',
            levels: ['error'],
        },
        {
            marker: 'react-unsafe-component-lifecycles',
            levels: ['warn'],
        },
    ]);
}
