import createReducer from 'create-reducer';
import produce from 'immer';
import { CaseFileEntity } from 'types/CaseFile';
import { SimpleFolderEntity } from 'types/Folder';
import {
    DocumentState,
    SignerState,
    CasefileState,
    EventLogState,
    CasefileReducerState,
    CasefileAttachmentsState,
    FolderState,
} from './types';
import {
    CASEFILE_ATTACHMENTS_FETCH_SUCCESS,
    CASEFILE_FETCH_FAILURE,
    CASEFILE_FETCH_REQUEST,
    CASEFILE_FETCH_SUCCESS,
    CASEFILE_FOLDER_FETCH_FAILURE,
    CASEFILE_FOLDER_FETCH_REQUEST,
    CASEFILE_FOLDER_FETCH_SUCCESS,
    CASEFILE_FOLDER_RESET,
    CASEFILE_PERSIST_FAILURE,
    CASEFILE_PERSIST_REQUEST,
    CASEFILE_PERSIST_SUCCESS,
    CASEFILE_REFRESH_SUCCESS,
    CASEFILE_UPDATE_LOCAL,
    CLEAR_CASEFILE_DETAILS_REDUCER,
    CLEAR_SIGNATURE_LINES_ACTIVE_AT,
    DOCUMENTS_BACKUP,
    DOCUMENTS_BACKUP_RESTORE,
    DOCUMENT_FETCH_FAILURE,
    DOCUMENT_FETCH_REQUEST,
    DOCUMENT_FETCH_SUCCESS,
    EVENTLOG_FETCH_FAILURE,
    EVENTLOG_FETCH_REQUEST,
    EVENTLOG_FETCH_SUCCESS,
    EXTEND_EXPIRATION_FAILURE,
    EXTEND_EXPIRATION_REQUEST,
    EXTEND_EXPIRATION_SUCCESS,
    SIGNER_BACKUP_FAILURE,
    SIGNER_BACKUP_REQUEST,
    SIGNER_BACKUP_SUCCESS,
    SIGNER_LOG_FETCH_SUCCESS,
    SIGNER_PERSIST_SUCCESS,
    SIGNER_RESTORE_LOCAL,
    SIGNER_UPDATE_LOCAL,
    UPDATE_SIGNATURE_LINES_ACTIVE_AT,
    CASEFILE_UPDATE_SIGNER,
} from './action-types';
import { AuthStore } from 'Auth';
import LaunchDarkly, { Flags } from 'Common/LaunchDarkly';
import { isCasefileOwner } from 'Casefiles/utils';

const casefileDetailsInitialState: CasefileState = {
    data: {} as CaseFileEntity,
    isFetching: false,
    isLoaded: false,
    hasChanged: false,
    error: null,
};

const documentInitialState: DocumentState = {};
const signerInitialState: SignerState = {};
const casefileAttachmentsInitialState: CasefileAttachmentsState = {
    data: [],
    isLoaded: false,
};
const eventLogInitialState: EventLogState = {
    data: {},
    isFetching: false,
    error: null,
};
const folderInitialState: FolderState = {
    data: {} as SimpleFolderEntity,
    isFetching: false,
    error: null,
};

export const initialState: CasefileReducerState = {
    casefile: casefileDetailsInitialState,
    documents: documentInitialState,
    signers: signerInitialState,
    eventLog: eventLogInitialState,
    folder: folderInitialState,
    attachments: casefileAttachmentsInitialState,
};

const casefileAttachmentsReducer = createReducer<CasefileAttachmentsState>(
    casefileAttachmentsInitialState,
    {
        [CASEFILE_ATTACHMENTS_FETCH_SUCCESS]: (state, payload) => ({
            ...state,
            isLoaded: true,
            data: [...payload],
        }),
        [CLEAR_CASEFILE_DETAILS_REDUCER]: () => casefileAttachmentsInitialState,
    }
);

const eventLogReducer = createReducer<EventLogState>(eventLogInitialState, {
    [EVENTLOG_FETCH_REQUEST]: (state) => ({
        ...state,
        isFetching: true,
        error: null,
    }),
    [EVENTLOG_FETCH_FAILURE]: (state, payload) => ({
        ...state,
        isFetching: false,
        error: payload.error,
    }),
    [EVENTLOG_FETCH_SUCCESS]: (state, payload) => {
        return {
            ...state,
            data: {
                ...state.data,
                ...payload,
            },
            isFetching: false,
            error: null,
        };
    },
    [CLEAR_CASEFILE_DETAILS_REDUCER]: () => eventLogInitialState,
});

const folderReducer = createReducer<FolderState>(folderInitialState, {
    [CASEFILE_FOLDER_RESET]: (state) => ({
        ...state,
        ...folderInitialState,
    }),
    [CASEFILE_FOLDER_FETCH_REQUEST]: (state) => ({
        ...state,
        // clear previous folder details, to prevent inconsistent data
        data: {} as SimpleFolderEntity,
        isFetching: true,
        error: null,
    }),
    [CASEFILE_FOLDER_FETCH_FAILURE]: (state, payload) => ({
        ...state,
        isFetching: false,
        error: payload.error,
    }),
    [CASEFILE_FOLDER_FETCH_SUCCESS]: (state, payload: SimpleFolderEntity) => {
        return {
            ...state,
            data: payload,
            isFetching: false,
            error: null,
        };
    },
});

const caseFileReducer = createReducer<CasefileState>(
    casefileDetailsInitialState,
    {
        [CASEFILE_FETCH_REQUEST]: (state) => ({
            ...state,
            isFetching: true,
            isLoaded: false,
            error: null,
        }),
        [CASEFILE_FETCH_FAILURE]: (state, payload) => ({
            ...state,
            isFetching: false,
            isLoaded: false,
            error: payload.error,
        }),
        [CASEFILE_FETCH_SUCCESS]: (state, payload: CaseFileEntity) => {
            /**
             * TODO:
             * Remove all the privacy logic below once the new endpoint
             * is done. These checks and payload manipulation
             * should not be necessary in the future, and the payload should be use as-is.
             */
            let casefileData = {
                ...payload,
            };

            // We check for casefile ownership
            const isOwner = isCasefileOwner(casefileData);

            if (LaunchDarkly.variation(Flags.SIGNER_VIEW)) {
                /**
                 * If the visibility mode of the casefile
                 * is set to show only documents to be signed,
                 * we strip the ones this user should not
                 * have access to
                 */
                if (casefileData.visibilityMode === 1) {
                    const currentUser = AuthStore.getAuthDetails();

                    /**
                     * If the user is a signer in the casefile
                     * and doesn't own it,
                     * we only show the documents they can have
                     * access to.
                     */
                    const signer = casefileData.signers.find(
                        (signer) => signer?.user?.id === currentUser.uid
                    );

                    const signerDocs = signer
                        ? casefileData.documents.filter(
                              (doc) =>
                                  doc.signatureLines?.find(
                                      (line) => line.signerId === signer.id
                                  ) || !doc.signable
                          )
                        : undefined;

                    /**
                     * signer and signerDocs can be undefined,
                     * therefore we check for them before using them.
                     * Also note that we also check if the user owns the casefile,
                     * because they could also be a signer and we don't want
                     * to hide any docs if that's the case.
                     */
                    if (!isOwner && signer && signerDocs) {
                        casefileData = {
                            ...payload,
                            documents: signerDocs,
                        };
                    }
                }
            }

            return {
                ...state,
                data: casefileData,
                isFetching: false,
                isLoaded: true,
                error: null,
                isOwner,
            };
        },
        [CASEFILE_REFRESH_SUCCESS]: (state, payload) => {
            return {
                ...state,
                data: {
                    ...state.data,
                    ...payload,
                },
                isFetching: false,
                isLoaded: true,
                error: null,
            };
        },
        [CASEFILE_UPDATE_SIGNER]: (state) => {
            return {
                ...state,
                isFetching: true,
            };
        },
        [CASEFILE_UPDATE_LOCAL]: (state, payload) => {
            return {
                ...state,
                data: {
                    ...state.data,
                    ...payload,
                },
                hasChanged: true,
            };
        },
        [CASEFILE_PERSIST_REQUEST]: (state) => {
            return {
                ...state,
                error: null,
                isFetching: true,
            };
        },
        [CASEFILE_PERSIST_SUCCESS]: (state) => {
            return {
                ...state,
                error: null,
                isFetching: false,
                hasChanged: false,
            };
        },
        [CASEFILE_PERSIST_FAILURE]: (state, payload) => {
            return {
                ...state,
                error: payload.error,
                isFetching: false,
            };
        },
        [EXTEND_EXPIRATION_REQUEST]: (state) =>
            produce(state, (draftState) => {
                draftState.error = null;
                draftState.isFetching = true;
            }),
        [EXTEND_EXPIRATION_SUCCESS]: (state) =>
            produce(state, (draftState) => {
                draftState.error = null;
                draftState.isFetching = false;
                draftState.hasChanged = false;
            }),
        [EXTEND_EXPIRATION_FAILURE]: (state, payload) =>
            produce(state, (draftState) => {
                draftState.error = payload.error;
                draftState.isFetching = false;
            }),
        [CLEAR_CASEFILE_DETAILS_REDUCER]: () => casefileDetailsInitialState,
        [UPDATE_SIGNATURE_LINES_ACTIVE_AT]: (state, payload) =>
            produce(state, (draftState) => {
                payload.signatureLinesToUpdate.map(
                    ({ signerTypeOriginalRole }) => {
                        draftState.data.documents.map((document) => {
                            document.signatureLines?.map((signatureLine) => {
                                if (
                                    signatureLine.signerTypeOriginalRole ===
                                        signerTypeOriginalRole &&
                                    signatureLine.signerId ===
                                        payload.signatureLinesToUpdate[0]
                                            .signerId
                                ) {
                                    signatureLine.activeAt = payload.activeAt;
                                    signatureLine.hasChanged = true;
                                }
                            });
                        });
                    }
                );
            }),
        [CLEAR_SIGNATURE_LINES_ACTIVE_AT]: (state, payload) =>
            produce(state, (draftState) => {
                payload.signatureLinesToUpdate.map(
                    ({ signerTypeOriginalRole }) => {
                        draftState.data.documents.map((document) => {
                            document.signatureLines?.map((signatureLine) => {
                                if (
                                    signatureLine.signerTypeOriginalRole ===
                                        signerTypeOriginalRole &&
                                    signatureLine.signerId ===
                                        payload.signatureLinesToUpdate[0]
                                            .signerId
                                ) {
                                    delete signatureLine.activeAt;
                                    signatureLine.hasChanged = true;
                                }
                            });
                        });
                    }
                );
            }),
        [DOCUMENTS_BACKUP]: (state) => {
            return {
                ...state,
                data: {
                    ...state.data,
                    initialDocuments: [...state.data.documents],
                },
            };
        },
        [DOCUMENTS_BACKUP_RESTORE]: (state) => {
            return {
                ...state,
                data: {
                    ...state.data,
                    documents: [...state.data.initialDocuments],
                },
            };
        },
    }
);

const documentReducer = createReducer<DocumentState>(documentInitialState, {
    [DOCUMENT_FETCH_REQUEST]: (state, payload) => {
        return {
            ...state,
            [payload]: {
                isLoaded: false,
                isFetching: true,
                error: null,
            },
        };
    },
    [DOCUMENT_FETCH_FAILURE]: (state, payload) => ({
        ...state,
        [payload.id]: {
            isLoaded: true,
            isFetching: false,
            error: payload.error,
        },
    }),
    [DOCUMENT_FETCH_SUCCESS]: (state, payload) => ({
        ...state,
        [payload.id]: {
            ...payload,
            isLoaded: true,
            isFetching: false,
            error: null,
        },
    }),
    [CLEAR_CASEFILE_DETAILS_REDUCER]: () => documentInitialState,
});

const signerReducer = createReducer<SignerState>(signerInitialState, {
    [SIGNER_BACKUP_REQUEST]: (state, payload) => {
        return {
            ...state,
            [payload]: {
                isLoaded: false,
                isFetching: true,
                error: null,
            },
        };
    },
    [SIGNER_BACKUP_FAILURE]: (state, payload) => ({
        ...state,
        [payload.id]: {
            isLoaded: true,
            isFetching: false,
            error: payload.error,
        },
    }),
    [SIGNER_BACKUP_SUCCESS]: (state, payload) => ({
        ...state,
        [payload.id]: {
            ...payload,
            isLoaded: true,
            hasChanged: false,
            isFetching: false,
            error: null,
        },
    }),
    [SIGNER_LOG_FETCH_SUCCESS]: (state, payload) => ({
        ...state,
        [payload.id]: {
            ...state[payload.id],
            ...payload,
        },
    }),
    [SIGNER_UPDATE_LOCAL]: (state, payload) => ({
        ...state,
        [payload.id]: {
            ...payload,
            hasChanged: true,
        },
    }),
    [SIGNER_RESTORE_LOCAL]: (state, signerId) => ({
        ...state,
        [signerId]: {
            ...state[signerId].__initial, // Apply initial state to signer object
            __initial: state[signerId].__initial, // recreate initial property.
            isLoaded: true,
            isFetching: false,
            hasChanged: false,
            error: null,
        },
    }),
    [SIGNER_PERSIST_SUCCESS]: (state, signer) => ({
        ...state,
        [signer.id]: {
            ...state[signer.id], // Preserve previous state.
            ...signer, // Append changed properties
            isLoaded: true,
            isFetching: false,
            hasChanged: false,
            error: null,
        },
    }),
    [CLEAR_CASEFILE_DETAILS_REDUCER]: () => signerInitialState,
});

const CaseFileDetailsReducer = (
    state: CasefileReducerState = initialState,
    action?: any
) => ({
    casefile: caseFileReducer(state.casefile, action, state),
    documents: documentReducer(state.documents, action, state),
    signers: signerReducer(state.signers, action, state),
    eventLog: eventLogReducer(state.eventLog, action, state),
    folder: folderReducer(state.folder, action, state),
    attachments: casefileAttachmentsReducer(state.attachments, action, state),
});

export default CaseFileDetailsReducer;
