import React from 'react';
import classnames from 'classnames';
import { i18n } from 'Language';
import Button from 'Common/components/Button';
import SearchBar from 'Casefiles/components/general/SearchBar';

type Props = {
    isAdmin: boolean;
    dataCount: number;
    searchValue: string;
    onNewClient: () => void;
    onSearchInputChange: (value: string) => void;
    clearSearch: () => void;
};

export const ClientsListHeader = ({
    isAdmin,
    onNewClient,
    searchValue,
    clearSearch,
    onSearchInputChange,
    dataCount,
}: Props) => (
    <div className="penneo-casefiles">
        <div className="top-actions">
            <span className="top-actions-search">
                <div
                    className={classnames('archive-search-bar-container', {
                        active: searchValue !== '',
                    })}>
                    <div className="archive-search-bar">
                        <SearchBar
                            value={searchValue}
                            onChange={onSearchInputChange}
                            placeholder={i18n('Type to filter')}
                        />
                        {searchValue !== '' && (
                            <div className="archive-search-bar-clear-button">
                                <span onClick={clearSearch}>
                                    <i className="far fa-times" />
                                    &nbsp;
                                    <span className="archive-search-bar-clear-button-label">
                                        {i18n`clear`}
                                    </span>
                                </span>
                            </div>
                        )}
                    </div>
                    <span className="archive-search-bar-label">
                        {dataCount === 0 && i18n`No results`}
                        {dataCount === 1 && i18n`1 item`}
                        {dataCount > 1 && i18n`${dataCount} items`}
                    </span>
                </div>
            </span>
            {isAdmin && (
                <Button theme="blue" variant="outline" onClick={onNewClient}>
                    {i18n`Create new client`}
                </Button>
            )}
        </div>
    </div>
);
