import { debug, storage } from 'Core';
import Analytics from 'Common/Analytics';
import {
    CustomerBranding,
    SignerValidationParameters,
    SigningProcessWithOutcome,
    signingStages,
} from 'types/SigningProcess';
import Constants from 'Constants';
import { defaultBranding } from 'Branding/Constants';
import LaunchDarkly, { Flags } from 'Common/LaunchDarkly';
import { TranslationStore } from 'Language';
import { PersonIdentifierType } from 'EID/types';
import { Languages } from 'Language/Constants';
import { jwtDecode } from 'jwt-decode';

/**
 * Track all events that happen within the new Signing page flow
 * @param {string} label The label to append to the common prefix
 * @param {object} eventProps Event properties to bundle with the event
 */
export const trackSigning = (label: string, eventProps?: object) => {
    const eventType = `Signing page - ${label}`;

    Analytics.track(eventType, eventProps);

    debug.isDebugEnabled && console.log([eventType, eventProps]);
};

/**
 * Returns current branding or default Penneo branding if customer hasn't one set up
 */
export const signingBranding = (
    branding?: CustomerBranding
): CustomerBranding => {
    if (branding) {
        return branding;
    }

    const {
        siteUrl,
        imageUrl: logoUrl,
        textColor,
        highlightColor,
        backgroundColor,
    } = defaultBranding;

    return {
        siteUrl,
        logoUrl,
        textColor,
        highlightColor,
        backgroundColor,
    };
};

export enum signingColorAreas {
    text = 'text',
    buttons = 'buttons',
}

/**
 * Check validity of specific areas of customer branding for Signing.
 * We use this to make sure buttons and text are visible.
 * Note: we convert to lowercase, for some clients have same colors but in different cases
 * Note2: we also use this function conditionally, in case client hasn't set up that color
 */
export const checkColorSchemeValid = (
    branding: CustomerBranding,
    area: signingColorAreas = signingColorAreas.text
) =>
    area === signingColorAreas.text
        ? branding.textColor?.toLowerCase() !==
          branding.backgroundColor?.toLowerCase()
        : branding.textColor?.toLowerCase() !==
          branding.highlightColor?.toLowerCase();

/**
 * Simple set of methods to set or check whether we are using V2 Signing
 */
const signingKey = 'penneo-v2-signing';

export const isV2Signing = {
    set: (stage = signingStages.init) => storage.set(signingKey, stage),
    get: () => storage.get(signingKey) as signingStages,
    check: () => {
        /**
         * We check for the url where we are doing this check.
         * Only in the Dashboard and V2 Signing page we just return a boolean
         * In any other page, we also clear the storage (to avoid errors in V1)
         */
        const reg = /(signing|dashboard|name-address-protection)\/?/;
        const safe = reg.test(window.location.href);

        if (!safe) {
            storage.clear(signingKey);

            return false;
        }

        return !!storage.get(signingKey);
    },
    clear: () => storage.clear(signingKey),
};

/**
 * Simple method to check if the provided url
 * corresponds to a V1 Signing link
 */
export const isV1Signing = (url?: string) => {
    if (!url) {
        return false;
    }

    const regex = /(penneo.com(:8888)?\/casefile\/sign\/)/i;

    return regex.test(url);
};

/**
 * Returns the base url including PR-XXX (if neccesary)
 */
export const signingUrlBase = () => {
    const base = /(PR-\d+\/)/.exec(window.location.pathname);

    if (base) {
        return base[0];
    }

    return '';
};

/**
 * Returns a V2 signing page link from a V1 url
 */
export const newSigningUrl = (url: string) => {
    const challengeKey = url.match(/[0-9A-Z]{5}(-[0-9A-Z]{5}){5}/);
    const base = signingUrlBase();

    return challengeKey ? `${base}signing/${challengeKey[0]}` : url;
};

/**
 * Create a redirection url after succesfully signing a casefile in V2 Signing
 * (Backwards compatible with V1)
 */
export const signingRedirectUrl = async (
    stage = signingStages.signed,
    challengeKey?: string
) => {
    if (isV2Signing.check()) {
        /**
         * We check if we are logged in as a customer and, if not,
         * redirect you to the casefile finalization url.
         * Otherwise, redirect you back to the Dashboard
         */
        const { PENNEO_TOKEN_KEY, PENNEO_REFRESH_KEY } = Constants;

        if (
            !storage.get(PENNEO_TOKEN_KEY) &&
            !storage.get(PENNEO_REFRESH_KEY)
        ) {
            // cleanup before redirection
            isV2Signing.clear();

            /**
             * A/B test: redirecting users to Penneo.com urls
             * if the tag is present, evaluates to true (option is 1 or 2) AND
             * the user signed (if rejected ignore this)
             */
            await LaunchDarkly.waitReady();

            if (
                !!LaunchDarkly.variation(
                    Flags.SIGNING_PAGE_A_B_TEST_REDIRECT
                ) &&
                stage === signingStages.signed
            ) {
                const language: string = TranslationStore.getLanguage();
                const option =
                    LaunchDarkly.variation(
                        Flags.SIGNING_PAGE_A_B_TEST_REDIRECT
                    ) === 1
                        ? 'thank-you-for-signing'
                        : 'thanks-for-signing';
                const url = `https://penneo.com/${
                    language !== 'en' ? `${language}/` : ''
                }${option}/`;

                trackSigning('A/B test Penneo.com redirection', { url });

                return url;
            }

            const uriOrigin = challengeKey
                ? window.location.origin
                : window.location.href;
            const appendChallenge = challengeKey
                ? `/signing/${challengeKey}`
                : '';
            const endLocation = `${uriOrigin + appendChallenge}/${stage}`;

            return endLocation;
        } else {
            // Keep track of the stage we are in the signing process before redirection
            isV2Signing.set(stage);

            /**
             * We clear the language set by the current signing request
             * so it doesn't change the interface of logged in user
             * when going back to their Dashboard
             */
            storage.clear('penneo-language');

            return `${signingUrlBase()}dashboard`;
        }
    }

    return stage;
};

/**
 * Store basic casefile info to properly display success/rejection screen
 * TODO - rely on endpoint for this data instead
 */
export const signingData = {
    set: (key: string, data: SigningProcessWithOutcome) =>
        storage.set(key, JSON.stringify(data)),
    get: (key: string): SigningProcessWithOutcome =>
        JSON.parse(storage.get(key)),
    clear: (key: string) => storage.clear(key),
};

/**
 * Storage methods for ID Validation
 */
const validationKey = 'penneo-v2-validation';

export const V2Validation = {
    set: (token: string) => storage.set(validationKey, token),
    get: () => storage.get(validationKey),
    getProvider: (): string | undefined => {
        const token = storage.get(validationKey);

        if (token) {
            return jwtDecode<{ p: string }>(token).p;
        }
    },
    clear: () => storage.clear(validationKey),
};

export const getExpectedIdentifierType = (
    validationData: SignerValidationParameters
) => {
    const { expectedEid, isSpecificSignerExpected, expectedVatin } =
        validationData;

    if (isSpecificSignerExpected && expectedVatin) {
        // This is a special case that was never actually supported.
        // It is possible to set both a VATIN and NIN for a signer, using certain methods.
        // We have no ID providers that can validate both, so the casefile is actually in a state where it can't be signed,
        // and the signers will see that nothing they can do will allow them to see the documents.
        // The behaviour of filtering for the expectedEid value is in line with how the app behaved in the past.
        return expectedEid;
    }

    if (expectedVatin) {
        return PersonIdentifierType.VATIN;
    }

    return expectedEid;
};

export function getSignerLanguage() {
    return storage.get('penneo-signer-selected-language');
}

export function setSignerLanguage(languageCode: Languages) {
    storage.set('penneo-signer-selected-language', languageCode);
}
