export const UtilityState = {
    IDLE: 'idle',
    UPLOADING: 'uploading',
    UPLOAD_ERROR: 'uploadError',
    NOT_PENNEO_DOCUMENT: 'notPenneoDocument',
    UPLOAD_SUCCESS: 'uploadSuccess',
    NO_ACTION_NEEDED: 'noActionNeeded',
    VALIDATION_ERROR: 'validationError',
} as const;

export type UtilityState = (typeof UtilityState)[keyof typeof UtilityState];
