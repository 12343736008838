import React from 'react';
import { Link } from 'react-router';
import { connect, ConnectedProps } from 'react-redux';
import classnames from 'classnames';
import { AppDispatch } from 'Store';
import {
    isMethodAvailable,
    LOGIN_METHODS,
    LoginMethod,
    OpenIDMethod,
} from 'EID';
import { Intent, ServiceIDs } from 'OpenID/Constants';
import { getUrlId, init, shouldUseEIDWebcomponent } from 'OpenID/redux/actions';
import { i18n } from 'Language';
import '@penneo/eid-webcomponent/dist/react-types';
import { EIDButtonWrapper } from 'EID/components/EIDButtonWrapper';
import { CustomerSigningMethod } from 'types/Customer';

/**
 * The number of methods that need to be present for
 * the list to switch to compact mode
 */
const COMPACT_LIST_THRESHOLD = 7;

export const mapDispatchToProps = (dispatch: AppDispatch) => ({
    init: (serviceId: string, intent: Intent) =>
        dispatch(init(serviceId as ServiceIDs, intent)),
});

const connector = connect(null, mapDispatchToProps);

// @see https://redux.js.org/usage/usage-with-typescript/#typing-the-connect-higher-order-component
export type Props = ConnectedProps<typeof connector> & {
    methods?: LoginMethod[];
    intent?: Intent.LOGIN | Intent.VALIDATE_ID | Intent.ADD;
    allowed?: string[];
    title?: string;
};

export const EIDWidget: React.FunctionComponent<Props> = (props: Props) => {
    const { init, intent = Intent.LOGIN } = props;
    let { methods = LOGIN_METHODS } = props;

    // Filter list of EIDs by allowed methods if present
    if (props.allowed) {
        methods = methods.filter(
            (m) =>
                props.allowed &&
                props.allowed.indexOf(m.credentialType()) !== -1
        );
    }

    methods = methods.filter((method) => {
        return (
            isMethodAvailable(
                method,
                // 'sign' is used for both signing and signer validation, in accordance with
                intent === 'login' ? 'login' : 'login'
                /**
                 * the openid engine right now distinguishes between login and sign, but with SMS you will actually do id validation
                 * ID validation is unfortunately "bundled" with sign atm, and we'll need to refactor this in the future
                 */
            ) && method.type !== CustomerSigningMethod.SMS
        );
    });

    return (
        <div className="eid-widget">
            {props.title && <h4 className="eid-title">{props.title}</h4>}
            <ul
                className={classnames('eid-widget-list', {
                    'eid-widget-list--compact':
                        methods.length >= COMPACT_LIST_THRESHOLD,
                })}>
                {methods.map((method: OpenIDMethod | LoginMethod) =>
                    shouldUseEIDWebcomponent(
                        getUrlId(
                            method.type as ServiceIDs,
                            intent
                        ) as ServiceIDs
                    ) ? (
                        <li key={method.type}>
                            <div className="item-wrapper">
                                <EIDButtonWrapper
                                    key={method.type}
                                    method={method as OpenIDMethod}
                                    intent={intent}
                                />
                            </div>
                        </li>
                    ) : (
                        <li key={method.type}>
                            <div className="item-wrapper">
                                <Link
                                    data-testid={method.type}
                                    to={method.paths && method.paths[intent]}
                                    onClick={
                                        method.openid
                                            ? init.bind(
                                                  null,
                                                  method.type,
                                                  intent
                                              )
                                            : null
                                    }>
                                    <div
                                        id={method.type}
                                        className="item-content">
                                        {method.flag && (
                                            <div className="auth-flag">
                                                <img src={method.flag} />
                                            </div>
                                        )}
                                        <div className="auth-logo">
                                            <img
                                                height="120"
                                                width="120"
                                                src={method.logo}
                                            />
                                        </div>
                                        <div className="method-title">
                                            {i18n(method.title)}
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </li>
                    )
                )}
            </ul>
        </div>
    );
};

export default connector(EIDWidget);
