import React, { useEffect, useState } from 'react';
import CasefileAccessDenied from '../errors/CasefileAccessDenied';
import useCustomerRedirect from 'Common/hooks/useCustomerRedirect';
import { env, Platforms, Routes } from 'Constants';
import { getUserAccess } from 'Casefiles/utils';
import AuthStore from 'Auth/stores/AuthStore';
import Loader from 'Common/components/Common/Loader';

const CasefilesWrapper = (props) => {
    const { router } = props;

    const { isSignersArchive } = getUserAccess();
    const shouldRedirect =
        isSignersArchive && env.platform !== Platforms.DESKTOP;

    useCustomerRedirect({
        shouldRedirect: shouldRedirect,
        shouldRedirectNonCustomer: true,
        redirectRoute: Routes.defaultSignersArchiveRoute,
        router,
    });

    if (shouldRedirect) {
        return <Loader />;
    } else {
        return <Casefiles {...props} />;
    }
};

const Casefiles = ({ children, language }) => {
    const [userData, setUserData] = useState(AuthStore.getUser());

    useEffect(() => {
        const onChange = () => {
            setUserData(AuthStore.getUser());
        };

        AuthStore.addChangeListener(onChange);

        onChange();

        return () => {
            AuthStore.removeChangeListener(onChange);
        };
    }, []);

    if (!userData) {
        return <Loader />;
    }

    const noRights = !userData.rights;
    const noCustomers =
        !userData.customerIds || userData.customerIds.length === 0;

    if (noCustomers || noRights) {
        return <CasefileAccessDenied user={userData} />;
    }

    return (
        <div className="penneo-casefiles">
            {React.cloneElement(children, {
                user: userData,
                language,
            })}
        </div>
    );
};

export default CasefilesWrapper;
