import Constants, {
    ENVIRONMENTS,
    Platforms,
    SIGN_CHALLENGE_KEY_REGEX,
    env,
} from 'Constants';

export function moveArray(
    arr: any[],
    oldIndex: number,
    newIndex: number
): any[] {
    const newArray = arr.slice(0);

    newArray.splice(newIndex, 0, newArray.splice(oldIndex, 1)[0]);

    return newArray;
}

export function convertUTF16ToUTF8(utf16input: string): string {
    const encoder = new TextEncoder();

    const utf8Bytes = encoder.encode(utf16input);
    const utf8CharCodeArray = Array.from(utf8Bytes);
    const utf8String = String.fromCharCode(...utf8CharCodeArray);

    return utf8String;
}

export const getChallengeKeyFromSignUrl = (url: string) =>
    url.match(SIGN_CHALLENGE_KEY_REGEX)?.[0];

// Determine if application is running inside of Electron context.
export const getPlatform = () => {
    const userAgent = navigator.userAgent.toLowerCase();

    if (userAgent.indexOf(' electron/') > -1) {
        // Electron-specific code
        env.electron = (window as any).require('electron');

        return Promise.resolve(Platforms.DESKTOP);
    }

    return Promise.resolve(Platforms.WEB);
};

export const getEnvironment = () => {
    const { PENNEO_ORIGIN } = Constants;

    switch (PENNEO_ORIGIN) {
        case 'https://app.penneo.com':
            return ENVIRONMENTS.PRODUCTION;
        case 'https://sandbox.penneo.com':
            return ENVIRONMENTS.SANDBOX;
        case 'https://staging.penneo.com':
            return ENVIRONMENTS.STAGING;
        case 'https://dev.penneo.com:8888':
        default:
            return ENVIRONMENTS.DEVELOPMENT;
    }
};

/**
 * Initially grabbed from
 * https://github.com/braintree/sanitize-url/blob/b70161daebd9523590fc6feda017d0489a8f6002/src/index.ts
 * */
export const sanitizeUrl = (url: string = ''): string => {
    const { PENNEO_ORIGIN } = Constants;
    const invalidProtocolRegex = /^([^\w]*)(javascript|data|vbscript)/im;
    const htmlEntitiesRegex = /&#(\w+)(^\w|;)?/g;
    const ctrlCharactersRegex =
        /[\u0000-\u001F\u007F-\u009F\u2000-\u200D\uFEFF]/gim;
    const urlSchemeRegex = /^([^:]+):/gm;
    const relativeFirstCharacters = ['.', '/'];
    const containsAtSymbol = /[@]/.test(url);

    const isUriStringEncoded = /(?!%22)[!@#$%^&*]/.test(url);

    const uriStringDecoded =
        (isUriStringEncoded && decodeURIComponent(url)) || url;

    function isRelativeUrlWithoutProtocol(url: string): boolean {
        return relativeFirstCharacters.indexOf(url[0]) > -1;
    }

    // adapted from https://stackoverflow.com/a/29824550/2601552
    function decodeHtmlCharacters(str: string) {
        return str.replace(htmlEntitiesRegex, (match, dec) => {
            return String.fromCharCode(dec);
        });
    }

    if (containsAtSymbol) {
        return PENNEO_ORIGIN;
    }

    const sanitizedUrl = decodeHtmlCharacters(uriStringDecoded)
        .replace(ctrlCharactersRegex, '')
        .trim();

    if (!sanitizedUrl) {
        return PENNEO_ORIGIN;
    }

    if (isRelativeUrlWithoutProtocol(sanitizedUrl)) {
        if (isUriStringEncoded) {
            return url;
        }

        return sanitizedUrl;
    }

    const urlSchemeParseResults = sanitizedUrl.match(urlSchemeRegex);

    if (!urlSchemeParseResults) {
        if (isUriStringEncoded) {
            return url;
        }

        return sanitizedUrl;
    }

    const urlScheme = urlSchemeParseResults[0];

    if (invalidProtocolRegex.test(urlScheme)) {
        return PENNEO_ORIGIN;
    }

    if (isUriStringEncoded) {
        return url;
    }

    return sanitizedUrl;
};

/**
 * This is a simple method to use responsive design
 * by detecting the size of the current window.
 * @returns Viewport size for responsive design, as small/medium/large screen
 */
export const viewportSize = () => {
    const width = window.innerWidth;
    let size = 'large';

    if (width < 768) {
        size = 'medium';
    }

    if (width < 480) {
        size = 'small';
    }

    return size;
};

/**
 * Simple mobile check
 * @returns true if current window size is considered to be mobile
 */
export const isMobile = () => viewportSize() === 'small';
